import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"

interface Args {
  body: string
  commentId: string
  body_content_type: "markdown" | "html"
}

export const useEditComment = () => {
  const queryClient = useQueryClient()

  const editComment = useCallback(
    async (options: Args) => await axiosClient.patch(apiRoutes.editComments(options.commentId), options),
    []
  )

  const {
    data,
    mutateAsync: editCommentMutation,
    status
  } = useMutation({
    mutationKey: ["editComment"],
    mutationFn: editComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["change"] })
      queryClient.invalidateQueries({ queryKey: ["discussions"] })
    }
  })

  return { data, editCommentMutation, status }
}
