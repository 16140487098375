import { Repository } from "@/models/Repository"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { LocalStorageService } from "@/lib/localStorageService.ts"

interface Args {
  repositoryFromLocalStorage?: string
  branchFromLocalStorage?: string
  setCurrentRepositoryName: Dispatch<SetStateAction<string | undefined>>
  setCurrentBranch: Dispatch<SetStateAction<string | undefined>>
  repositories: Repository[]
}

const localStorageService = new LocalStorageService("localStorage")

export const useSync = ({
  branchFromLocalStorage,
  repositoryFromLocalStorage,
  setCurrentBranch,
  setCurrentRepositoryName,
  repositories
}: Args) => {
  const { org, branch, repository } = useParams()
  const { pathname } = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    const setRepoAndBranch = (repository: string, branch: string) => {
      setCurrentRepositoryName(repository)
      setCurrentBranch(branch)
      navigate(`/code/${repository}/${branch}`)
    }
    // sync with local storage
    if (!org && !branch && !repository) {
      if (repositoryFromLocalStorage && branchFromLocalStorage) {
        setRepoAndBranch(repositoryFromLocalStorage, branchFromLocalStorage)
      } else if (repositories.length > 0) {
        const lastUpdatedRepository = repositories.sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        )[0]
        if (lastUpdatedRepository) {
          localStorageService.setItem("repositoryFullName", lastUpdatedRepository.fullName)
          localStorageService.setItem("branch", lastUpdatedRepository.defaultBranch)
          setRepoAndBranch(lastUpdatedRepository.fullName, lastUpdatedRepository.defaultBranch)
        }
      }
    }
  }, [
    pathname,
    repositoryFromLocalStorage,
    branchFromLocalStorage,
    org,
    branch,
    repository,
    navigate,
    setCurrentBranch,
    setCurrentRepositoryName,
    repositories
  ])
}
