import { UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query"
import { errorHandler } from "@/services/api/helpers"
import { useToast } from "../useToast"
import { useTranslation } from "react-i18next"
import { axiosClient } from "@/services/api/axiosClient.ts"
import { apiRoutes } from "@/services/api/api-routes.ts"

interface Options extends Partial<UndefinedInitialDataOptions> {
  code: string | null
}

export const useSlackInstallation = ({ code }: Options) => {
  const { toast } = useToast()
  const { t } = useTranslation("Integrations")

  const { error, isLoading, data } = useQuery({
    queryKey: ["slack-callback", code],
    queryFn: async () => {
      try {
        if (!code) {
          throw new Error(t("NoCode"))
        }

        const res = await axiosClient.post(apiRoutes.integrations.post, {
          integration_type: "slack",
          credentials: {
            code: code
          },
          metadata: null
        })
        toast({
          title: t("successToast.title"),
          description: t("successToast.description")
        })

        return res.data
      } catch (error) {
        toast({
          variant: "destructive",
          description: errorHandler(error)
        })
        throw new Error(errorHandler(error))
      }
    }
  })

  return { error, isLoading, data }
}
