import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { CommentOptions } from "@/models/FCC"

export const useCreateComment = () => {
  const queryClient = useQueryClient()

  const createComment = useCallback(
    async (options: CommentOptions) => await axiosClient.post(apiRoutes.comments, options),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["createComment"],
    mutationFn: createComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["change"] })
      queryClient.invalidateQueries({ queryKey: ["discussions"] })
    },
    onError: (error) => {
      console.error("Failed to create comment:", error)
    }
  })

  return { data, mutateAsync, status }
}
