import { CIRun } from "@/models/Changes"
import { CheckIcon, DotIcon, XIcon } from "lucide-react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { cn } from "@/lib/utils"
import { Changes } from "@/services/i18n/locales/en/Changes"
type BuildStatusType = "success" | "failure" | "pending"

interface Props {
  runs: CIRun[]
  status: BuildStatusType
}

const STATUS_CONFIG = {
  success: {
    icon: CheckIcon,
    className: "text-success-dark",
    strokeWidth: 2
  },
  failure: {
    icon: XIcon,
    className: "text-error-dark",
    strokeWidth: 2
  },
  pending: {
    icon: DotIcon,
    className: "text-highlight-dark",
    strokeWidth: 6
  }
} as const

export const BuildStatus = ({ runs, status }: Props) => {
  const config = STATUS_CONFIG[status]
  const Icon = config.icon

  const count = status !== "success" ? runs.filter((run) => run.status === status).length : 0

  const tooltipText = status === "success" ? Changes.BuildStatus.success : Changes.BuildStatus[status](count)

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Icon className={cn(config.className, "size-4 cursor-default ml-1")} strokeWidth={config.strokeWidth} />
        </TooltipTrigger>
        <TooltipContent>{tooltipText}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
