import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"

interface Args {
  fileId: string
  commitId: string
}

export const useAddViewedFile = () => {
  const queryClient = useQueryClient()

  const addViewed = useCallback(
    async ({ fileId, commitId }: Args) =>
      await axiosClient.post(apiRoutes.changes.viewed.post(fileId), {
        commit: commitId
      }),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["addViewed"],
    mutationFn: addViewed,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["change"] })
    },
    onError: (error) => {
      console.error("Failed to add viewed entry to file version", error)
    }
  })

  return { data, mutateAsync, status }
}
