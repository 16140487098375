import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { Repository } from "@/models/Repository"
import { RepoAction } from "@/models/Repository"

interface FetchRepositoriesArgs {
  action?: RepoAction
}

export const useFetchRepositories = ({ action }: FetchRepositoriesArgs = {}) => {
  const { error, isLoading, data } = useQuery<{ repositories: Repository[] }, unknown>({
    queryKey: ["repositories", action],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.repositories, { params: { action } })
      return response.data
    }
  })

  return { error, isLoading, data: data?.repositories || [] }
}
