export interface Repository {
  id: string
  orgId: string
  avatarUrl: string
  fullName: string
  isPrivate: boolean
  isFork: boolean
  createdAt: string
  updatedAt: string
  size: number
  stargazers: number
  license: string | null
  defaultBranch: string
  installationIdRepoOwner: string
  installationIdRepoFullName: string
  openChanges: number
  description: string | null
}

export interface BranchInfo {
  name: string
  head_commit: string | null
  commits_ahead: number | null
  commits_behind: number | null
}

export enum RepoAction {
  READ = "read",
  WRITE = "write"
}
