import { useRouteError, isRouteErrorResponse, Navigate, Link } from "react-router-dom"

export default function ErrorPage() {
  document.title = "Baz"
  const error = useRouteError()
  let errorMessage: string

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.data || error.statusText
    if (error.status === 404) {
      return <Navigate to="/not-found" />
    }
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === "string") {
    errorMessage = error
  } else {
    console.error(error)
    errorMessage = "Unknown error"
  }

  return (
    <div id="error-page" className="flex w-full flex-col items-center p-4">
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorMessage}</i>
      </p>
      <p>
        Back to
        <Link className="ml-1 text-link underline" to="/">
          Home page
        </Link>
      </p>
    </div>
  )
}
