import { useCallback } from "react"
import { X } from "lucide-react"
import { Discussion, NewCommentBubble as NewCommentBubbleI } from "@/models/Discussions"
import { useSyncElementPosition } from "../../Inline/useSyncElementPosition"
import { useDiscussions } from "@/services/store/useDiscussions"
import { Button, TooltipProvider } from "@/components"
import { NewCommentBubbleForm } from "./NewCommentBubbleForm"

interface Props {
  current: NewCommentBubbleI
  prev?: Discussion | NewCommentBubbleI
  discussionNumber: number
  total: number
  discussions: Array<Discussion | NewCommentBubbleI>
  CRId?: string
  commitSha?: string
}

export const NewCommentBubble = ({ current, discussionNumber, discussions, total, prev, CRId, commitSha }: Props) => {
  const { topPosition } = useSyncElementPosition({ current, discussionNumber, discussions, total, prev })
  const {
    removeNewCommentBubbleFromDiscussions,
    clearCurrentOpenedDiscussion,
    expandedStateBeforeComment,
    minimizeDiscussionsContainer
  } = useDiscussions()

  const handleClose = useCallback(() => {
    removeNewCommentBubbleFromDiscussions(current)
    clearCurrentOpenedDiscussion()

    if (expandedStateBeforeComment === false) {
      minimizeDiscussionsContainer()
    }
  }, [
    removeNewCommentBubbleFromDiscussions,
    current,
    clearCurrentOpenedDiscussion,
    expandedStateBeforeComment,
    minimizeDiscussionsContainer
  ])

  return (
    <TooltipProvider>
      <div
        data-discussion-number={discussionNumber}
        style={{ top: topPosition || 0 }}
        className="absolute left-5 z-40 w-laptop-discussion-width rounded-r-xl rounded-bl-xl border border-border bg-background py-1 pl-4 pr-0 shadow-lg 2xl:w-desktop-discussion-width"
      >
        <div className="relative mb-2">
          <Button
            className="absolute right-1"
            size="sm"
            aria-label="Cancel comment"
            variant="ghost"
            onClick={handleClose}
          >
            <X width={16} height={16} />
          </Button>
        </div>

        <NewCommentBubbleForm current={current} CRId={CRId} commitSha={commitSha} />
      </div>
    </TooltipProvider>
  )
}
