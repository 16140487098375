import { CellContext } from "@tanstack/react-table"
import { FileCodeLine } from "../../types"
import { getFileExtension, renderRegularLine } from "@/components/designSystem/FCC/utils/codeCellComputations"
import { InsightsLabel } from "@/components/designSystem/Insights/InsightsLabel.tsx"
import { isRowWithInsights } from "@/components/designSystem/Insights/helpers.tsx"

interface Props {
  info: CellContext<FileCodeLine, FileCodeLine>
  columnSide?: "left" | "right"
}

export const CodeCell = ({ info, columnSide }: Props) => {
  const line = info.getValue()
  const fileExtension = getFileExtension(info.table.options.meta?.filePath)
  const computedCellValue = renderRegularLine(line.content, fileExtension)

  const insightSide = columnSide!
  const hasInsights = isRowWithInsights(info.table, line.lineNumber, insightSide)

  return (
    <div className="relative flex size-full select-text flex-wrap items-center break-all">
      {computedCellValue}
      {hasInsights && <InsightsLabel table={info.table} lineNumber={line.lineNumber} side={insightSide} />}
    </div>
  )
}
