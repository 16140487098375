import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "../useToast"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

export const useApprove = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation("Toolbar")
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const approveChangeRequest = useCallback(
    async (changeId: string) => await axiosClient.post(apiRoutes.changes.approve.post(changeId), {}),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["approveChangeRequest"],
    mutationFn: approveChangeRequest,
    onSuccess: () => {
      toast({
        variant: "default",
        description: t("SuccessApprove")
      })

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["change"] })
        queryClient.invalidateQueries({ queryKey: ["mergeStatus"] })

        navigate(pathname)
      }, 3000)
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: t("FailedApprove") + ghWriteErrorHandler(error)
      })
    }
  })

  return { data, mutateAsync, status }
}
