import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"

export type ResolveCommentState = "pending" | "resolved"

interface Args {
  state: ResolveCommentState
  id: string
}

export const useResolveComment = () => {
  const queryClient = useQueryClient()

  const resolveComment = useCallback(
    async ({ id, state }: Args) => await axiosClient.patch(apiRoutes.resolveComment(id), { state }),
    []
  )

  const {
    data,
    mutateAsync: resolveCommentMutation,
    status
  } = useMutation({
    mutationKey: ["resolveComment"],
    mutationFn: resolveComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["change"] })
      queryClient.invalidateQueries({ queryKey: ["discussions"] })
    }
  })

  return { data, resolveCommentMutation, status }
}
