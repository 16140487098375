import "./progress-bar.css"

interface Props {
  isError: boolean
}

export function ProgressBar({ isError }: Props): JSX.Element {
  return isError ? (
    <div className="progress-bar-error" />
  ) : (
    <div className="progress-bar">
      <div className="progress-bar-value"></div>
    </div>
  )
}
