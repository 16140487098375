function CollapseAllSVG({ color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{ color: color }}
    >
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5.333 14.333L8 11.667l2.666 2.666" />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5.333 2L8 4.667 10.666 2" />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M2 8h12" />
    </svg>
  )
}

export default CollapseAllSVG
