interface Props {
  resultsCount?: number
  resultsName?: string
}

export const ResultsDisplay = ({ resultsCount, resultsName }: Props) => {
  return resultsCount && resultsName ? (
    <div className="mr-4 cursor-default text-lg text-primary">{resultsCount + " " + resultsName}</div>
  ) : null
}
