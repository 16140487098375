export const OnBoarding = {
  ConnectBazToGithub: "Connect Baz to GitHub",
  GithubConnectionExplained: "Baz connects to GitHub APIs to create, review and merge code changes.",
  CTAConnectToGithub: "Connect to GitHub",
  OnboardingToBaz: "Onboarding to Baz",
  OnboardUsers: "Onboarding Users",
  OnboardRepositories: "Onboarding Repositories",
  AnalyzeChanges: "Analyzing Change Requests",
  SomethingWentWrong: "Oops, something went wrong. Please contact the Baz support team for assistance.",
  SignOut: "Sign out",
  Start: "Start building unbreakable code, connect your\n" + "team's repositories to Baz",
  OrgOnlySupport: "Baz currently supports only organization accounts",
  ReviewerWorkflow: "Baz Reviewer Workflows",
  ReviewerDetails: "Baz will automatically review your change requests to detect:",
  LetsGo: "Let's Go!",
  Customize: "You can customize these capabilities at any time from the settings.",
  OnboardingStatus: "Onboarding Status",
  OnboardingInProgress:
    "Your change requests have been successfully onboarded. Analysis is in progress, and your analyzed change requests will be available soon."
}
