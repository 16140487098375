import { PaymentState } from "@/models/Subscription"

export function getTitleText(state: PaymentState, isAdmin: boolean): string {
  if (!isAdmin) {
    return "Something went wrong"
  }

  switch (state) {
    case "paused":
    case "past_due":
    case "canceled":
    case "unpaid":
      return "Upgrade to keep building unbreakable things!"
    case "incomplete":
    case "incomplete_expired":
      return "Payment Issue"
    default:
      return "Upgrade to keep building unbreakable things!"
  }
}

export function getInfoText(state: PaymentState, isAdmin: boolean): string {
  if (!isAdmin) {
    return "It looks like there's an issue with your team's subscription. Please contact your admin to continue building unbreakable things with Baz."
  }

  switch (state) {
    case "paused":
      return "Your trial period is over, but you can continue using Baz by upgrading your account."
    case "incomplete":
    case "incomplete_expired":
      return "We encountered a payment issue. Please check your credit card details or choose a new payment method."
    case "past_due":
    case "canceled":
    case "unpaid":
      return "Your subscription has expired, but you can continue using Baz by renewing your license."
    default:
      return "It looks like there's an issue with your team's subscription. Please contact your admin to continue building unbreakable things with Baz."
  }
}

export function getActionButtonText(state: PaymentState): string {
  switch (state) {
    case "paused":
      return "Upgrade Now"
    default:
      return "Update Payment"
  }
}
