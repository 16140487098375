import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { ReviewerConfig } from "@/models/ReviewerConfig.ts"

export const useFetchReviewerConfig = () => {
  const { error, isLoading, data } = useQuery<ReviewerConfig, unknown>({
    queryKey: ["reviewer-config"],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.reviewerConfig.get)
      return {
        ...response.data,
        excludedConfig: response.data.excludedConfig.map((config: { excludedPaths: string[] }) => ({
          ...config,
          excludedPaths: config.excludedPaths.join(",")
        }))
      }
    }
  })

  return { error, isLoading, data }
}
