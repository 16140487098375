import { useMemo } from "react"
import { computeContentArgs } from "@/pages/code/utils.ts"
import { useFetchContent } from "@/hooks/queries/useFetchContent.tsx"
import { useChangeRequest } from "@/services/store/useChangeRequest.ts"

interface Props {
  filePath: string
  shouldFetch: boolean
}

export const useContent = ({ filePath, shouldFetch }: Props) => {
  const { changeRequest } = useChangeRequest()

  const computedContentArgs = useMemo(
    () =>
      computeContentArgs({
        path: filePath,
        branchName: changeRequest?.head_branch,
        repositoryId: changeRequest?.repository_id,
        type: "file"
      }),
    [changeRequest, filePath]
  )

  const {
    data: content,
    error: contentError,
    isLoading: contentLoading,
    refetch
  } = useFetchContent({ fetchArgs: computedContentArgs, path: filePath, enabled: shouldFetch })

  return {
    content,
    contentError,
    contentLoading,
    refetch
  }
}
