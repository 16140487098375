import { useEffect } from "react"
import { sortDiscussionsBasedOnFileOrder } from "../utils"
import { useDiscussions } from "@/services/store/useDiscussions"
import { ChangeRequest } from "@/models/Changes"
import { GeneratedDiscussions } from "./GeneratedDiscussions"
import { Discussion } from "@/models/Discussions"
import { Button } from "@/components/ui/Button"
import { ArrowLeftToLine, ArrowRightToLine } from "lucide-react"

interface Props {
  discussions: Discussion[]
  changeRequest: ChangeRequest
  filePaths: string[]
}

export const InlineDiscussionsLayout = ({ discussions: discussionsFromBE, changeRequest, filePaths }: Props) => {
  const {
    discussions,
    setDiscussions,
    discussionsExpanded,
    toggleExpandDiscussionsContainer,
    setDiscussionsWithTop,
    toggleManualRecalculationTrigger
  } = useDiscussions()
  const commitSha = changeRequest?.commits[0]?.sha

  useEffect(() => {
    setDiscussions(sortDiscussionsBasedOnFileOrder(discussionsFromBE, filePaths))
  }, [discussionsFromBE, filePaths, setDiscussions])

  useEffect(() => {
    setDiscussionsWithTop()
  }, [setDiscussionsWithTop, discussions])

  const onToggle = () => {
    toggleExpandDiscussionsContainer()
    toggleManualRecalculationTrigger()
  }

  return (
    <div
      className={`
        relative 
        h-full
        ${discussionsExpanded ? "min-w-laptop-discussion-container 2xl:min-w-desktop-discussion-container" : "min-w-minimized-discussion-container"} 
    `}
    >
      <Button onClick={onToggle} variant="outline" size="sm" className="float-right mt-2 size-6 p-0">
        {discussionsExpanded ? <ArrowRightToLine width={14} height={14} /> : <ArrowLeftToLine width={14} height={14} />}
      </Button>
      <GeneratedDiscussions discussions={discussions} CRId={changeRequest.id} commitSha={commitSha} />
    </div>
  )
}
