import { NavLink } from "react-router-dom"
import BazIconLight from "@/assets/icons/BazAvatar-light.svg?react"
import BazIconDark from "@/assets/icons/BazAvatar-dark.svg?react"
import { routes } from "@/lib/routes.ts"
import { Button } from "@/components"
import { useMemo } from "react"
import { useSettings } from "@/services/store/useSettings.ts"

export const Navbar = () => {
  const { selectedTheme } = useSettings()
  const links = useMemo(
    () => [
      {
        href: routes.root,
        Icon: selectedTheme === "light" ? BazIconLight : BazIconDark,
        key: "baz"
      },
      {
        href: routes.code.root,
        key: "main",
        content: "Code"
      },
      {
        href: routes.changes.root,
        content: "Changes"
      }
    ],
    [selectedTheme]
  )

  return (
    <div className="flex h-full gap-2">
      {links.map(({ href, Icon, key, content }) => (
        <div key={key || href} className="flex items-center">
          {Icon ? <Icon width={32} height={32} className="mr-6" /> : null}
          {content ? (
            <NavLink to={href} key={key || href} className="">
              {({ isActive }) => (
                <Button className={`h-8 px-2 ${isActive && "bg-primary"}`} variant={isActive ? "default" : "ghost"}>
                  {content}
                </Button>
              )}
            </NavLink>
          ) : null}
        </div>
      ))}
    </div>
  )
}
