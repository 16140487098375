import { Comment, Discussion } from "@/models/Discussions"

export const extractComments = (discussions: Discussion[] | null): Comment[] => {
  if (!discussions) {
    return []
  }

  return discussions.reduce((acc, discussion) => {
    acc.push(...discussion.comments)

    return acc
  }, [] as Comment[])
}
