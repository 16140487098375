import { useRef, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Popover, PopoverContent, PopoverTrigger, Insights } from "@/components"
import { Stat } from "@/models/Insight"
import { getTableInsights } from "@/components/designSystem/Insights/helpers.tsx"
import { Table } from "@tanstack/react-table"
import { FccRow, Side } from "@/models/FCC.ts"
import { FileCodeLine } from "@/components/designSystem/FC/types.ts"

const getTotalInvocationsAvgTime = (stats: Stat[]) => {
  let sumDuration = 0
  let sumInvocations = 0

  stats
    .filter((item) => item.stat_type == "invocations")
    .forEach((s) => {
      const matching_avg_duration =
        stats.find((i) => i.stat_type == "avg_duration_ns" && s.entrypoint == i.entrypoint)?.stat_value || 0
      sumInvocations += s.stat_value
      sumDuration += s.stat_value * matching_avg_duration
    })

  const totalInvocationsAvgTime = sumInvocations > 0 ? sumDuration / sumInvocations / 1000000 : 0
  return { sumOfInvocations: sumInvocations, totalInvocationsAvgTime }
}

interface Props {
  table: Table<FccRow> | Table<FileCodeLine>
  lineNumber: number | null
  side: Side
}

export const InsightsLabel = ({ table, lineNumber, side }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation("Insight")
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const { stats, runErrors, logErrors, breakingChanges } = getTableInsights(table, lineNumber, side)

  const handleMouseEnter = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
      closeTimeoutRef.current = null
    }
    setOpen(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    closeTimeoutRef.current = setTimeout(() => {
      setOpen(false)
      closeTimeoutRef.current = null
    }, 100)
  }, [])

  const { sumOfInvocations, totalInvocationsAvgTime } = getTotalInvocationsAvgTime(stats)

  const invocationLabel = sumOfInvocations > 1 ? t("Invocations") : t("Invocation")
  const breakingChangesLabel = breakingChanges.length > 1 ? t("BreakingChanges") : t("BreakingChange")

  const formatInvocationSummary = (count: number, avgTime: number) =>
    `${count} ${invocationLabel}, ${avgTime.toFixed(2)} ${t("MsAvg")} `

  const formatErrorCount = (count: number) => `${count} ${count === 1 ? t("Error") : t("Errors")} `

  const invocationLabelWithCount =
    sumOfInvocations > 0 ? formatInvocationSummary(sumOfInvocations, totalInvocationsAvgTime) : null

  const logErrorsCount = logErrors.length > 0 ? formatErrorCount(logErrors.length) : null

  const breakingChangesWithCount =
    breakingChanges.length > 0 ? `${breakingChanges.length} ${breakingChangesLabel}` : null

  return (
    <div className="relative flex items-center">
      <Popover open={open}>
        <PopoverTrigger
          asChild
          aria-label="insights"
          className="flex outline-0"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="ml-2 w-fit break-normal rounded border border-b-border bg-background px-2 font-inter text-xxs leading-5 text-primary">
            {invocationLabelWithCount}
            {logErrorsCount}
            {breakingChangesWithCount}
          </span>
        </PopoverTrigger>

        <PopoverContent
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="z-40 w-fit border-none bg-transparent p-0 text-xs shadow-none"
          align="start"
        >
          <Insights
            stats={stats}
            runErrors={runErrors}
            logErrors={logErrors}
            breakingChanges={breakingChanges}
            table={table}
            side={side}
            lineNumber={lineNumber}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
