import {
  Badge,
  Button,
  Card,
  CardContent,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components"
import { getActionsNeeded, Installation, InstallationStatus } from "@/models/GithubIntegrations"
import { useTranslation } from "react-i18next"
import Github from "@/assets/icons/Github.svg?react"
import { AlertTriangle, CheckCircle, EllipsisIcon } from "lucide-react"
import { Link } from "react-router-dom"
import { Timestamp } from "@/components/ui/Timestamp.tsx"
import { useMemo } from "react"

interface Props {
  installation: Installation
}

const Icon = ({ status }: { status: InstallationStatus }) => {
  return status === "Active" || status === "Limited" ? (
    <CheckCircle width={12} height={12} className="ml-1 text-success-normal" />
  ) : (
    <AlertTriangle width={12} height={12} className="ml-1 text-error-normal" />
  )
}

export const RepoCard = ({ installation }: Props) => {
  const { t } = useTranslation("Integrations")

  const actionsNeeded = useMemo(() => getActionsNeeded(installation), [installation])

  const remainingActions = actionsNeeded.slice(1)

  return (
    <Card className="min-h-[125px] w-full rounded-md border shadow-none" data-testid="github-repo-card">
      <CardContent className="flex flex-col p-4 text-fill">
        <div className="mb-4 flex items-center">
          <Github width={32} height={30} className="mr-4" />

          <div className="w-full">
            <div className="flex w-full justify-between">
              <h1 className="flex items-center text-sm text-primary">
                {installation.account} <Icon status={installation.status} />
              </h1>

              <DropdownMenu modal={false}>
                <DropdownMenuTrigger disabled={!remainingActions.length}>
                  <Button disabled={!remainingActions.length} variant="ghost" size="icon" className="size-6 p-1">
                    <EllipsisIcon size={20} width={20} height={20} />
                  </Button>
                </DropdownMenuTrigger>

                <DropdownMenuContent align="end">
                  {remainingActions.map(({ label, link }) => (
                    <DropdownMenuItem key={label} className="w-full">
                      <Link to={link} target="_blank" rel="noopener noreferrer" className="w-full text-xs">
                        {t(label)}
                      </Link>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

            <Timestamp time={installation.lastUpdatedAt} />
          </div>
        </div>

        {actionsNeeded.length > 0 ? (
          <Link className="flex w-fit text-fill" to={actionsNeeded[0].link} target="_blank" rel="noopener noreferrer">
            <Badge data-testid="github-card-badge" variant="outline" className="h-8 cursor-pointer">
              {t(actionsNeeded[0].label)}
            </Badge>
          </Link>
        ) : null}
      </CardContent>
    </Card>
  )
}
