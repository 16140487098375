import { Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { FccRow } from "@/models/FCC"
import { useDiscussions } from "@/services/store/useDiscussions"
import { HeaderContext } from "@tanstack/react-table"
import ExpandAllSVG from "@/assets/icons/ExpandAllSVG"
import CollapseAllSVG from "@/assets/icons/CollapseAllSVG"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useContent } from "@/hooks/useContent"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { isExpandableLine } from "@/components/designSystem/FCC/utils"
import { useIsExpanderEnabled } from "@/hooks/useIsExpanderEnabled"

interface Props {
  info: HeaderContext<FccRow, FccRow>
}

export const ExpandAllCell = ({ info }: Props) => {
  const { toggleManualRecalculationTrigger } = useDiscussions()
  const expand = info.table.getToggleAllRowsExpandedHandler()
  const { t } = useTranslation("FCC")
  const { tables } = useChangeRequest()
  const filePath = info.table?.options?.meta?.filePath || ""
  const currentTable = tables[filePath]
  const { refetch } = useContent({ filePath, shouldFetch: currentTable.shouldFetchContent })
  const isExpanderEnabled = useIsExpanderEnabled()
  const areAllRowsExpanded = info.table.getIsAllRowsExpanded()

  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      const needsRefetch = currentTable.rows?.some(
        (line) => isExpandableLine(line) && (line.subRows?.length === 1 || line.subRows?.length === 0)
      )
      if (needsRefetch) {
        await refetch()
      }
      expand(event)
      toggleManualRecalculationTrigger()
    },
    [currentTable.rows, expand, toggleManualRecalculationTrigger, refetch]
  )

  return (
    <div className="flex h-7 cursor-pointer items-center justify-center text-primary">
      <Tooltip>
        <TooltipTrigger
          className={"disabled:cursor-not-allowed disabled:text-hint"}
          disabled={!isExpanderEnabled}
          onClick={(e) => handleClick(e)}
          aria-label={`Expand All`}
        >
          {areAllRowsExpanded ? (
            <CollapseAllSVG color="var(--base-primary)" />
          ) : (
            <ExpandAllSVG color="var(--base-primary)" />
          )}
        </TooltipTrigger>
        {isExpanderEnabled && <TooltipContent>{areAllRowsExpanded ? t("CollapseAll") : t("ExpandAll")}</TooltipContent>}
      </Tooltip>
    </div>
  )
}
