import { type ClassValue, clsx } from "clsx"
import { MutableRefObject } from "react"
import { twMerge } from "tailwind-merge"
import Prism from "prismjs"
import "prismjs/components/prism-markup-templating" // do not delete!
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-jsx"
import "prismjs/components/prism-typescript"
import "prismjs/components/prism-tsx"
import "prismjs/components/prism-python"
import "prismjs/components/prism-rust"
import "prismjs/components/prism-toml"
import "prismjs/components/prism-sql"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-yaml"
import "prismjs/components/prism-markdown"
import "prismjs/components/prism-haml"
import "prismjs/components/prism-json"
import "prismjs/components/prism-java"
import "prismjs/components/prism-go"
import "prismjs/components/prism-hcl"
import "prismjs/components/prism-css"
import "prismjs/components/prism-ruby"
import "prismjs/components/prism-csharp"
import "prismjs/components/prism-c"
import "prismjs/components/prism-cpp"
import "prismjs/components/prism-php"
import "prismjs/components/prism-swift"
import { marked, Renderer } from "marked"
import DOMPurify from "dompurify"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function extractInitials(name?: string) {
  const formatted = formatName(name).trim()
  const [firstName, lastName] = formatted.split(" ")

  if (firstName && lastName) {
    return firstName[0]?.toUpperCase() + lastName[0]?.toUpperCase()
  }

  if (firstName) {
    return firstName[0]?.toUpperCase()
  }

  console.error("Unable to parse " + name)
  return "I"
}

export function formatName(name?: string) {
  if (!name) {
    return "Invalid"
  }

  if (name.startsWith("https://github.com")) {
    const gh_login = name.split("/").pop()
    if (gh_login) {
      return gh_login
    }
    return "Invalid"
  }

  return name
}

export function uppercaseString(text: string) {
  return text.slice(0, 1).toUpperCase() + text.slice(1)
}

export function convertToPercentage(num1: number, num2: number) {
  const total = num1 + num2

  const percentage1 = Math.round((num1 / total) * 100)
  const percentage2 = Math.round((num2 / total) * 100)

  return [percentage1, percentage2] as const
}

export function isEllipsisActive<ElementT extends HTMLElement>(elementRef: MutableRefObject<ElementT | null>) {
  if (!elementRef.current) return false

  return elementRef.current.offsetWidth < elementRef.current.scrollWidth
}

interface LanguageInfo {
  prismLanguage: Prism.Grammar
  displayName: string
}

export const fileExtensionMapping: { [key: string]: LanguageInfo } = {
  js: { prismLanguage: Prism.languages.javascript, displayName: "JavaScript" },
  jsx: { prismLanguage: Prism.languages.jsx, displayName: "JavaScript" },
  ts: { prismLanguage: Prism.languages.typescript, displayName: "TypeScript" },
  tsx: { prismLanguage: Prism.languages.tsx, displayName: "TypeScript" },
  py: { prismLanguage: Prism.languages.python, displayName: "Python" },
  rs: { prismLanguage: Prism.languages.rust, displayName: "Rust" },
  toml: { prismLanguage: Prism.languages.toml, displayName: "TOML" },
  sql: { prismLanguage: Prism.languages.sql, displayName: "SQL" },
  bash: { prismLanguage: Prism.languages.bash, displayName: "Bash" },
  tpl: { prismLanguage: Prism.languages.yaml, displayName: "YAML" },
  yaml: { prismLanguage: Prism.languages.yaml, displayName: "YAML" },
  yml: { prismLanguage: Prism.languages.yaml, displayName: "YAML" },
  md: { prismLanguage: Prism.languages.markdown, displayName: "Markdown" },
  html: { prismLanguage: Prism.languages.haml, displayName: "HTML" },
  json: { prismLanguage: Prism.languages.json, displayName: "JSON" },
  java: { prismLanguage: Prism.languages.java, displayName: "Java" },
  go: { prismLanguage: Prism.languages.go, displayName: "Go" },
  hcl: { prismLanguage: Prism.languages.hcl, displayName: "HCL" },
  css: { prismLanguage: Prism.languages.css, displayName: "CSS" },
  tf: { prismLanguage: Prism.languages.hcl, displayName: "Terraform" },
  rb: { prismLanguage: Prism.languages.ruby, displayName: "Ruby" },
  cs: { prismLanguage: Prism.languages.csharp, displayName: "C#" },
  c: { prismLanguage: Prism.languages.c, displayName: "C" },
  cpp: { prismLanguage: Prism.languages.cpp, displayName: "C++" },
  php: { prismLanguage: Prism.languages.php, displayName: "PHP" },
  swift: { prismLanguage: Prism.languages.swift, displayName: "Swift" }
}

export function getFileLanguageFromPath(filePath: string): string {
  const parts = filePath.split(".")
  const extension = parts[parts.length - 1]

  const language = fileExtensionMapping[extension]?.displayName

  return language || "Unknown"
}

export function splitPath(path: string) {
  const lastIndex = path.lastIndexOf("/")
  if (lastIndex === -1) {
    // No '/' found, the entire path is a file name
    return { fileName: path, path: "" }
  }
  const fileName = path.substring(lastIndex + 1) // Extract the file name
  const remainingPath = path.substring(0, lastIndex) // Extract the remaining path
  return { fileName, path: remainingPath }
}

export function parseAndSanitizeComment(comment: string): string {
  const renderer = new Renderer()
  const parsedComment = marked.parseInline(comment, { renderer }) as string
  return DOMPurify.sanitize(parsedComment, {
    RETURN_DOM_FRAGMENT: false,
    RETURN_TRUSTED_TYPE: false
  })
}
