import { TableBody as BaseTableBody, TableCell, TableRow } from "@/components"
import { Cell, Row, Table, flexRender } from "@tanstack/react-table"
import React from "react"
import { FileCodeLine } from "../types"
import { setCellTextAlignment } from "../utils"

interface Props {
  table: Table<FileCodeLine>
}

const FCTableCell = ({ cell }: { cell: Cell<FileCodeLine, unknown> }) => {
  const textPlacement = setCellTextAlignment(cell.column.id)
  return (
    <TableCell className={`h-5 p-0 align-top ${textPlacement}`}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </TableCell>
  )
}

const FCTableRow = ({ row }: { row: Row<FileCodeLine> }) => {
  return (
    <React.Fragment>
      <TableRow className="border-b-0 hover:bg-inherit">
        {row.getVisibleCells().map((cell) => (
          <FCTableCell cell={cell} key={cell.id} />
        ))}
      </TableRow>
    </React.Fragment>
  )
}

const TableBody = ({ table }: Props) => {
  return (
    <BaseTableBody className="select-none font-mono text-[12px]">
      {table.getRowModel().rows.map((row) => (
        <FCTableRow key={row.id} row={row} />
      ))}
    </BaseTableBody>
  )
}

export default TableBody
