import { SideMenu } from "./SideMenu"
import { useRepositories } from "./hooks/useRepositories"
import { useBranches } from "./hooks/useBranches"
import { useContent } from "./hooks/useContent"
import { useSync } from "./hooks/useSync"
import { FileContent } from "./FileContent"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { Toaster } from "@/components/ui/toaster.tsx"

export function Code() {
  const {
    repositoryFromLocalStorage,
    repositoryProps,
    currentRepository,
    setCurrentRepositoryName,
    repositoriesError,
    repositories
  } = useRepositories()
  const { branchFromLocalStorage, branchProps, setCurrentBranch } = useBranches({ currentRepository })
  const { content, contentMetadata, fileError, fileLoading } = useContent({ currentRepository })
  document.title = `${currentRepository ? currentRepository.fullName : "Code"}`

  useSync({
    branchFromLocalStorage,
    repositoryFromLocalStorage,
    setCurrentBranch,
    setCurrentRepositoryName,
    repositories
  })

  const isLoading = repositoryProps.repositoriesLoading
  const repositoriesLength = repositoryProps.repositories?.length

  return (
    <div className="flex w-full">
      {!isLoading && repositoriesLength >= 0 ? (
        <>
          <SideMenu {...branchProps} {...repositoryProps} />
          <FileContent
            fileError={fileError}
            fileLoading={fileLoading}
            content={content}
            contentMetadata={contentMetadata}
          />
        </>
      ) : (
        <SpinnerLoader />
      )}
      {repositoriesError ? <Toaster /> : null}
    </div>
  )
}
