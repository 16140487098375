import { cn } from "@/lib/utils.ts"

interface Props {
  children: React.ReactNode
  className?: string
}

export function CenteredLayout({ children, className }: Props) {
  return (
    <div
      className={cn(
        `flex h-screen w-full items-center justify-center bg-fill light bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-green-50 via-white to-[#CFCFE8]`,
        className
      )}
    >
      {children}
    </div>
  )
}
