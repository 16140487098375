import { CenteredLayout } from "../CenteredLayout.tsx"
import { useNavigate } from "react-router-dom"
import BazLogoLight from "@/assets/icons/BazLogo-light.tsx"
import { Descope } from "@descope/react-sdk"
import { LocalStorageService } from "@/lib/localStorageService.ts"
import { useTranslation } from "react-i18next"

const logger = {
  error: (title: string, description: string) => {
    if (title === "User not invited") {
      window.location.href = "https://baz.co/contact"
    }
    console.error("Flow error ", title, description)
  },
  info: (_title: string, _description: string, _state: never) => {},
  warn: (_title: string, _description: string) => {},
  debug: (_title: string, _description: string) => {}
}

const localStorageService = new LocalStorageService("sessionStorage")
const BazLogo = BazLogoLight

export default function Login() {
  document.title = "Baz"
  const navigate = useNavigate()
  const from = localStorageService.getItem("from")
  const { t } = useTranslation("Auth")

  return (
    <CenteredLayout>
      <section className="flex w-[414px] flex-col">
        <div className="mb-6 flex h-12 w-full items-center justify-center">
          <BazLogo width="169" height="50" />
        </div>

        <div className="mb-9 min-h-[188px] w-full rounded-2xl bg-white px-6 pb-6 pt-10 shadow-xl">
          <Descope
            flowId={"sign-up-or-in"}
            onSuccess={() => navigate(from ? from : "/")}
            onError={(e) => console.error(`Could not log in, ${JSON.stringify(e)}`)}
            theme="light"
            logger={logger}
          />
        </div>

        <p className="mx-auto w-[315px] text-center text-xxs text-light-secondary">
          {t("SignInAccept")}&nbsp;
          <strong>
            <a href="https://baz.co/legal#terms" target="_blank" rel="noopener noreferrer">
              {t("TermsOfUse")}
            </a>
          </strong>
        </p>
        <p className="mx-auto text-center text-xxs text-light-secondary">
          &nbsp;{t("Acknowledge")}&nbsp;
          <strong>
            <a href="https://baz.co/legal#privacy" target="_blank" rel="noopener noreferrer">
              {t("PrivacyStatement")}
            </a>
          </strong>
          &nbsp;{t("And")}&nbsp;
          <strong>
            <a href="https://baz.co/legal#privacy" target="_blank" rel="noopener noreferrer">
              {t("CookiePolicy")}
            </a>
          </strong>
        </p>
      </section>
    </CenteredLayout>
  )
}
