import { Button } from "@/components/ui/Button"
import { useClickOutside } from "@/hooks/useClickOutside"

import { PlusIcon } from "@radix-ui/react-icons"
import { useRef } from "react"
import { useSelection } from "@/services/store/useSelection"
import { Side } from "@/models/FCC"

interface Props {
  onClick: () => void
  handleDropdownClose: () => void
  lineNumber: number | null
  side: Side
  filePath?: string
}

export const PlusButton = ({ onClick, handleDropdownClose, lineNumber, side, filePath }: Props) => {
  const ref = useRef<HTMLButtonElement>(null)
  const { selectedLines, firstSelectedSide, selectedTableFilePath, setSelection } = useSelection()

  const onButtonClick = () => {
    if (
      firstSelectedSide !== side.toLowerCase() ||
      selectedTableFilePath !== filePath ||
      (lineNumber && selectedLines.startLine && selectedLines.startLine > lineNumber) ||
      (lineNumber && selectedLines.endLine && selectedLines.endLine < lineNumber)
    ) {
      setSelection(filePath || "", { startLine: lineNumber, endLine: lineNumber }, side)
    }
    onClick()
  }

  useClickOutside(ref, handleDropdownClose)

  return (
    <Button
      data-id="plusButton"
      onClick={onButtonClick}
      onMouseDown={(e) => e.preventDefault()}
      ref={ref}
      className="absolute left-[-5px] top-0 size-5 bg-secondary p-0 text-background hover:bg-primary hover:text-background"
    >
      <PlusIcon className="size-5 p-1 text-background" />
    </Button>
  )
}
