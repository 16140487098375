import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { useLinkGitHubInstallation } from "@/hooks/queries/useLinkGitHubInstallation"
import { routes } from "@/lib/routes.ts"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"

export function GithubWebhookCallback() {
  const { t } = useTranslation("Integrations")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const installationId = searchParams.get("installation_id")

  const { isLoading, error, data } = useLinkGitHubInstallation({ installationId })

  useEffect(() => {
    // on success data is '' which is falsy value
    if (data === "") {
      navigate({ pathname: routes.settings.integrations({ provider: "github" }), search: "" })
    }
  }, [data, navigate])

  if (error) {
    return <div className="flex w-full justify-center">{t("IntegrationError")}</div>
  }

  return <div className="flex w-full justify-center">{isLoading ? <SpinnerLoader /> : null}</div>
}
