import { Button, Input } from "@/components"
import { CodeChangeStateBadge } from "@/components/designSystem/Badges/CodeChangeStateBadge"
import { ChangeRequest } from "@/models/Changes"
import { routes } from "@/lib/routes.ts"
import { ArrowLeft, SquarePen, Folders, X } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { useCallback, useState } from "react"
import { useUpdateChangeRequest } from "@/hooks/mutations/useUpdateChangeRequest"
import { useTranslation } from "react-i18next"

interface CRTitleProps {
  changeRequest: ChangeRequest
  minimalHeader: boolean
  isOpen: boolean
  onOpen: () => void
}

export const CRTitle = ({ changeRequest, minimalHeader, isOpen, onOpen }: CRTitleProps) => {
  const { mutateAsync, status } = useUpdateChangeRequest()
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [title, setTitle] = useState(changeRequest.title)
  const { t } = useTranslation("Common")

  const handleClick = useCallback(() => {
    isEditMode ? setIsEditMode(false) : navigate(routes.changes.root)
  }, [isEditMode, setIsEditMode, navigate])

  const handleSave = useCallback(() => {
    mutateAsync({
      changeId: changeRequest.id,
      title
    }).then(() => setIsEditMode(false))
  }, [title, changeRequest, setIsEditMode, mutateAsync])

  const isTitleEditable = changeRequest.state === "open" || changeRequest.state === "draft"
  return (
    <div
      className="mb-3 flex w-full cursor-default items-center gap-x-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button onClick={handleClick} className="size-8" size="icon" variant="outline">
        {isEditMode ? <X width={16} height={16} /> : <ArrowLeft width={16} height={16} />}
      </Button>
      {minimalHeader ? (
        <>
          {!isOpen ? (
            <Button className="size-8" size="icon" variant="outline" aria-label="Folder tree" onClick={onOpen}>
              <Folders width={16} height={16} />
            </Button>
          ) : null}

          <CodeChangeStateBadge
            is_draft={changeRequest.is_draft}
            state={changeRequest.state}
            isEditable={false}
            isLoading={false}
          />
        </>
      ) : null}

      <Input
        onChange={(e) => setTitle(e.target.value)}
        type="text"
        value={title}
        className={`w-3/6 border-0 border-b-2 border-dotted text-xs shadow-none outline-none focus-visible:border-b-2 focus-visible:ring-0 ${isEditMode ? "block" : "hidden"}`}
        onKeyDown={(event) => {
          event.key === "Enter" && handleSave()
        }}
      />

      <h3
        className={`
      ${minimalHeader ? "text-xs" : "2xl:text-xl"} 
      ${isEditMode ? "hidden" : "block"}
      max-w-[80%] font-semibold text-primary
      2xl:overflow-x-hidden
      `}
      >
        {`${changeRequest.title} #${changeRequest.pr_number}`}
      </h3>

      {isHovered && !isEditMode && isTitleEditable && (
        <Button className="size-5" size="icon" variant="outline" onClick={() => setIsEditMode(true)}>
          <SquarePen width={16} height={16} className="cursor-pointer justify-end text-primary " />
        </Button>
      )}
      {isEditMode && (
        <Button
          onClick={handleSave}
          disabled={status === "pending"}
          className="ml-2 h-6 p-0 px-2 text-xxs"
          variant="outline"
        >
          {t("Save")}
        </Button>
      )}
    </div>
  )
}
