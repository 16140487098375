import { EditorBubble } from "novel"
import { Fragment, type ReactNode } from "react"

interface GenerativeMenuSwitchProps {
  children: ReactNode
  open: boolean
  onOpenChange: (open: boolean) => void
}

const BubbleMenu = ({ children, open, onOpenChange }: GenerativeMenuSwitchProps) => {
  return (
    <EditorBubble
      tippyOptions={{
        placement: open ? "bottom-start" : "top",
        onHidden: () => {
          onOpenChange(false)
        }
      }}
      className="flex w-fit max-w-[90vw] overflow-hidden rounded-md border border-gray-300 bg-background shadow-xl"
    >
      {!open && <Fragment>{children}</Fragment>}
    </EditorBubble>
  )
}

export default BubbleMenu
