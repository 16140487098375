function ExpandDownSVG({ color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{ color: color }}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2 4h12M5.333 8.667L8 11.333l2.666-2.666"
      />
    </svg>
  )
}

export default ExpandDownSVG
