import { CodeChangeStateBadge } from "@/components/designSystem/Badges/CodeChangeStateBadge"
import {
  Avatar,
  Badge,
  CopyToClipboard,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { CommitterItem } from "@/components/designSystem/Topics/CommitterItem.tsx"
import { ClipboardCopy } from "lucide-react"
import { useTranslation } from "react-i18next"
import { formatName } from "@/lib/utils.ts"
import { ChangeRequest } from "@/models/Changes.ts"
import { MenuState } from "@/pages/crFileChanges/components/helpers.tsx"
import { useUpdateChangeRequest } from "@/hooks/mutations/useUpdateChangeRequest.tsx"
import { useCallback } from "react"
import { usePermissions } from "@/services/store/usePermissions.ts"
import { ChangeStatusMenu } from "@/pages/crFileChanges/components/CRHeader/CRActions/ChangeStatusMenu.tsx"

interface SubHeaderMetaDataProps {
  changeRequest: ChangeRequest
  minimalHeader: boolean
  foldersTreeOpen: boolean
}

const availableOptions: { [key: string]: Array<MenuState> } = {
  draft: ["open", "close"],
  open: ["draft", "close"],
  closed: ["open"]
}

export const SubHeaderMetaData = ({ changeRequest, foldersTreeOpen }: SubHeaderMetaDataProps) => {
  const { t } = useTranslation("Common")
  const { t: FccT } = useTranslation("FCC")
  const { mutateAsync, status } = useUpdateChangeRequest()
  const { user } = usePermissions()

  const currentState = changeRequest.is_draft && changeRequest.state === "open" ? "draft" : changeRequest.state
  const disabled = changeRequest.state === "merged" || status === "pending"
  const isLoading = status === "pending"
  const options = availableOptions[currentState]

  const handleClick = useCallback(
    (value: string) => {
      mutateAsync({ changeId: changeRequest.id, state: value })
    },
    [mutateAsync, changeRequest.id]
  )

  const myCR = user?.github_login === changeRequest.author_name
  const avatarUrl = changeRequest.author_user?.avatar_url || changeRequest.author_avatar_url
  const username = changeRequest.author_user?.display_name || formatName(changeRequest.author_name)
  const totalFiles = changeRequest.files_viewed.length
  const isChangeStateBadgeEditable = myCR && currentState !== "merged"

  let head = changeRequest.head_branch
  let base = changeRequest.base_branch
  if (changeRequest.head_repo_full_name !== changeRequest.base_repo_full_name) {
    head = `${changeRequest.head_repo_full_name}:${head}`
    base = `${changeRequest.base_repo_full_name}:${base}`
  }

  return (
    <div className="flex items-center gap-x-2">
      {myCR ? (
        <ChangeStatusMenu
          onClick={handleClick}
          disabled={disabled}
          options={options}
          isLoading={isLoading}
          state={currentState}
          isDraft={changeRequest.is_draft}
          isEditable={isChangeStateBadgeEditable}
        />
      ) : (
        <CodeChangeStateBadge
          is_draft={changeRequest.is_draft}
          state={changeRequest.state}
          isEditable={isChangeStateBadgeEditable}
          isLoading={isLoading}
        />
      )}
      <p className="flex items-center text-sm">
        <Avatar className="m-1 size-6 rounded-full" avatarURL={avatarUrl} username={username} />{" "}
        <p className="m-1 mr-2 text-sm text-primary">{username}</p>
        {t("WantsToMerge")}
        <Popover>
          <PopoverTrigger>
            <Badge variant="secondary" className="mx-2 cursor-pointer">
              {changeRequest.commits.length} {changeRequest.commits.length === 1 ? t("Commit") : t("Commits")}
            </Badge>
          </PopoverTrigger>
          <PopoverContent className="max-h-[542px] min-w-[350px] max-w-dialog overflow-y-scroll rounded-md border border-border bg-background p-4 text-xs text-primary dark:shadow-background">
            {changeRequest.commits.map((commit) => {
              const committer = { email: commit.committer, gh_login: commit.committer }
              const formatted = {
                commit_sha: commit.sha,
                date: commit.committed_at,
                githubUrl: `https://github.com/${changeRequest.head_repo_full_name}/commit/${commit.sha}`,
                message: commit.message,
                user: committer
              }
              return <CommitterItem committer={formatted} key={commit.sha} />
            })}
          </PopoverContent>
        </Popover>
        {t("Into")}
      </p>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              className={`
      ${foldersTreeOpen ? "max-w-20" : "max-w-36"}
      inline-block truncate break-keep px-2 2xl:max-w-[190px] 3xl:max-w-fit`}
              variant="outline"
            >
              {base}
            </Badge>
          </TooltipTrigger>
          <TooltipContent>{base}</TooltipContent>
        </Tooltip>

        <p className="text-sm">{t("From")}</p>

        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              className={`
      ${foldersTreeOpen ? "max-w-20" : "max-w-36"}
      inline-block truncate break-keep px-2 2xl:max-w-[190px] 3xl:max-w-fit`}
              variant="outline"
            >
              {head}
            </Badge>
          </TooltipTrigger>
          <TooltipContent>{head}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <CopyToClipboard text={head}>
        <ClipboardCopy width={16} height={16} className="cursor-pointer text-primary" />
      </CopyToClipboard>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Badge variant="secondary">{totalFiles}</Badge>
          </TooltipTrigger>
          <TooltipContent>{FccT("FilesChanged")}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
