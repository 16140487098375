import Typescript from "@/assets/icons/languages/Typescript.svg?react"
import NPM from "@/assets/icons/languages/NPM.svg?react"
import Markdown from "@/assets/icons/languages/Markdown.svg?react"
import Helm from "@/assets/icons/languages/Helm.svg?react"
import Rust from "@/assets/icons/languages/Rust.tsx"
import RustCargo from "@/assets/icons/languages/RustCargo.svg?react"
import DockerFile from "@/assets/icons/languages/DockerFile.svg?react"
import Javascript from "@/assets/icons/languages/Javascript.svg?react"
import Kubernetes from "@/assets/icons/languages/Kubernetes.svg?react"
import Redis from "@/assets/icons/languages/Redis.svg?react"
import Go from "@/assets/icons/languages/Go.svg?react"
import JSON from "@/assets/icons/languages/JSON.svg?react"
import Java from "@/assets/icons/languages/Java.svg?react"
import Python from "@/assets/icons/languages/Python.svg?react"
import GithubActions from "@/assets/icons/languages/GithubActions.svg?react"
import Terraform from "@/assets/icons/languages/Terraform.svg?react"
import SQL from "@/assets/icons/languages/SQL.svg?react"
import { File } from "lucide-react"

const mapper: { [key: string]: JSX.Element } = {
  docs: <Markdown />,
  markdown: <Markdown />,
  typescript: <Typescript />,
  npm: <NPM />,
  helm: <Helm />,
  rust: <Rust />,
  github_actions: <GithubActions />,
  javascript: <Javascript />,
  dockerfile: <DockerFile />,
  docker_compose: <DockerFile />,
  terraform: <Terraform />,
  cargo: <RustCargo />,
  kubernetes: <Kubernetes />,
  redis: <Redis />,
  go: <Go />,
  json: <JSON />,
  java: <Java />,
  python: <Python />,
  sql: <SQL width={26} height={16} />
} as const

export const languageIconsMapper = (language: string): JSX.Element => {
  return mapper[language] || <File width="16" />
}
