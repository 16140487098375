import Intercom from "@intercom/messenger-js-sdk"
import { useUser } from "@descope/react-sdk"

export default function Component() {
  const { user, isUserLoading } = useUser()
  if (isUserLoading) return <div></div>

  Intercom({
    app_id: "yignlbrw",
    user_id: user.userId,
    name: user.name,
    email: user.email,
    created_at: user.createTime
  })
}
