import { DirectoryContent, Entry } from "@/models/FC"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Folder, FolderClosed, File, ChevronDown, ChevronRight, LucideIcon } from "lucide-react"
import { useFetchDirContent } from "@/hooks/queries/useFetchDirContent"
import { getFilePathFromUrl, renderIconBasedOnStatus } from "../../../utils"
import { computeContentArgs } from "../../../utils"
import { errorHandler } from "@/services/api/helpers"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"

interface Props {
  entry: Entry
  depth: number
  branchName?: string
  repositoryId?: string
  forceOpen: boolean
  pathSegments: string[]
}

interface RenderTreeNodeArgs {
  data: DirectoryContent
  pathSegments: string[]
  branchName?: string
  repositoryId?: string
}

export const renderIconType = (objectType: string, isLoading: boolean, Entity: LucideIcon) => {
  return (
    <span className="mr-1 w-4 min-w-[16px]">
      {isLoading ? (
        <SpinnerLoader size="12px" />
      ) : objectType === "dir" ? (
        <Entity className="w-full min-w-[16px]" />
      ) : (
        <File className="w-full min-w-[16px]" />
      )}
    </span>
  )
}

export const renderTreeNodes = ({ branchName, data, pathSegments, repositoryId }: RenderTreeNodeArgs) => {
  return data.entries.map((entry) => {
    let forceOpen = false
    const currentSegment = pathSegments[0]

    if (currentSegment) {
      if (entry.path === currentSegment) {
        forceOpen = true
      }
    }

    return (
      <TreeNode
        pathSegments={pathSegments.slice(1)}
        forceOpen={forceOpen}
        depth={data.depth_level}
        entry={entry}
        key={entry.path_from_root}
        branchName={branchName}
        repositoryId={repositoryId}
      />
    )
  })
}

export const TreeNode = ({ entry, branchName, repositoryId, forceOpen, pathSegments, depth }: Props) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { org, repository, branch } = useParams()
  const { pathname } = useLocation()
  const path = useMemo(() => getFilePathFromUrl(pathname), [pathname])
  const Chevron = useMemo(() => renderIconBasedOnStatus(open, ChevronDown, ChevronRight), [open])
  const Entity = useMemo(() => renderIconBasedOnStatus(open, Folder, FolderClosed), [open])

  const computedFetchDirContentArgs = useMemo(
    () => computeContentArgs({ path: entry.path_from_root, branchName, repositoryId, type: "dir" }),
    [branchName, entry.path_from_root, repositoryId]
  )

  const { data, error, isLoading } = useFetchDirContent({
    fetchArgs: computedFetchDirContentArgs,
    objectType: entry.object_type,
    shouldFetch: open
  })

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), [setOpen])

  const handleNodeSelect = useCallback(() => {
    if (entry.object_type === "dir") {
      toggleOpen()
    }

    const encodedBranch = encodeURIComponent(branch || "")
    const computedPath = `/code/${org}/${repository}/${encodedBranch}/path/${entry.path_from_root}`

    navigate(computedPath)
  }, [toggleOpen, entry.object_type, navigate, org, repository, branch, entry.path_from_root])

  useEffect(() => {
    if (forceOpen) setOpen(true)
  }, [forceOpen])

  return (
    <div className={depth > 0 ? "ml-3" : ""} data-id={entry.path_from_root}>
      <li className={`mb-1 flex items-center hover:bg-darker-fill ${path === entry.path_from_root ? "bg-border" : ""}`}>
        <span className="mr-1 w-4 min-w-[16px]">
          {entry.object_type === "dir" ? <Chevron className="w-full cursor-pointer" onClick={toggleOpen} /> : null}
        </span>

        {renderIconType(entry.object_type, isLoading, Entity)}

        <span
          onClick={handleNodeSelect}
          className={`cursor-pointer ${path === entry.path_from_root ? "font-bold text-primary" : ""}`}
        >
          {entry.path}
        </span>
      </li>

      <div className={`${open ? "block" : "hidden"} ml-[7px] border-l-2 border-dotted border-border`}>
        {error ? errorHandler(error) : null}
        {data ? renderTreeNodes({ data, pathSegments, branchName, repositoryId }) : null}
      </div>
    </div>
  )
}
