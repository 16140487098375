import { useCallback } from "react"
import { useDiscussions } from "@/services/store/useDiscussions.ts"
import { v4 as uuidv4 } from "uuid"
import { useSelection } from "@/services/store/useSelection.ts"
import { useChangeRequest } from "@/services/store/useChangeRequest.ts"

export interface CommentCreationPlacementData {
  lineNumber: number
  filePath: string
}

export const useNewCommentBubble = () => {
  const { injectNewCommentBubbleToDiscussions, toggleManualRecalculationTrigger, setCurrentOpenedDiscussion } =
    useDiscussions()
  const { selectedTableFilePath, firstSelectedSide, selectedText, selectedLines, clearLines } = useSelection()
  const { endLine, startLine } = selectedLines
  const { breakingChangeItemToBeShown } = useChangeRequest()

  const handleAddCommentClick = useCallback(
    async (data?: CommentCreationPlacementData) => {
      const startLineNumber = data
        ? data.lineNumber
        : breakingChangeItemToBeShown?.codeLocations[0]
          ? breakingChangeItemToBeShown.codeLocations[0].lineNumber
          : startLine
      const endLineNumber = data
        ? data.lineNumber
        : breakingChangeItemToBeShown?.codeLocations[0]
          ? breakingChangeItemToBeShown.codeLocations[0].lineNumber
          : endLine
      const filePathValue = data
        ? data.filePath
        : breakingChangeItemToBeShown?.codeLocations[0]
          ? breakingChangeItemToBeShown?.codeLocations[0].fileName
          : selectedTableFilePath

      if (!startLineNumber && !endLineNumber) {
        console.error("Trying to add a comment bubble without any line numbers")
        return
      }

      if (!filePathValue) {
        console.error("Trying to add a comment bubble without file path")
        return
      }

      const newBubble = injectNewCommentBubbleToDiscussions({
        id: uuidv4(),
        type: "new comment",
        file: filePathValue,
        end_line: endLineNumber,
        start_line: startLineNumber,
        selectedText,
        selectedLines,
        side: firstSelectedSide || "right"
      })

      setCurrentOpenedDiscussion(newBubble)
      toggleManualRecalculationTrigger()
      clearLines()
    },
    [
      breakingChangeItemToBeShown,
      startLine,
      injectNewCommentBubbleToDiscussions,
      selectedTableFilePath,
      endLine,
      selectedText,
      selectedLines,
      firstSelectedSide,
      setCurrentOpenedDiscussion,
      toggleManualRecalculationTrigger,
      clearLines
    ]
  )

  return { handleAddCommentClick }
}
