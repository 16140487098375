import { Button } from "@/components"
import { GithubIntegrationsResponse } from "@/models/GithubIntegrations"
import { ExternalLink as ExternalLinkIcon } from "lucide-react"
import { RepoCard } from "./RepoCard"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface Props {
  data: GithubIntegrationsResponse
  githubUrl: string
}

export const RepositoriesContainer = ({ data: { installations }, githubUrl }: Props) => {
  const { t } = useTranslation("Integrations")

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-sm font-medium text-primary">{t("GithubIntegration")}</h1>
          <h3 className="text-sm text-primary">{t("Github")}</h3>
        </div>
        <Link to={githubUrl} target="_blank" rel="noopener noreferrer">
          <Button variant="secondary" size="sm" className="gap-1 text-xs">
            <ExternalLinkIcon size={14} /> {t("Configure")}
          </Button>
        </Link>
      </div>

      <div className="grid w-full grid-cols-2 gap-4">
        {installations.map((installation) => (
          <RepoCard key={installation.installationId} installation={installation} />
        ))}
      </div>
    </div>
  )
}
