import { Card, CardDescription, CardHeader, TabsContent, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { useTranslation } from "react-i18next"
import RedX from "@/assets/icons/RedX.svg?react"
import APIAddressSVG from "@/assets/icons/APIAddressSVG.tsx"
import { UniqueErrors, UniqueStatEntry } from "@/components/designSystem/Insights/helpers.tsx"

interface Props {
  stats: UniqueStatEntry[]
  runErrors: UniqueErrors
}

export const UsageTabContent = ({ stats, runErrors }: Props) => {
  const { t } = useTranslation("Insight")

  return (
    <TabsContent
      value="usage"
      className="max-h-80 overflow-auto rounded-md border shadow-md shadow-border dark:shadow-background"
    >
      <Card className="text-primary">
        {Object.entries(stats).map(([key, { method, avgTime, usageNum }]) => (
          <CardHeader key={key} className="h-full space-y-0 border-b p-0">
            <CardDescription className="border-b border-border p-2 text-xxs text-primary">
              <div className="flex items-center">
                <APIAddressSVG />
                <Tooltip>
                  <TooltipTrigger>
                    <p className="ml-2">{method}</p>
                  </TooltipTrigger>

                  <TooltipContent>
                    <p>{method}</p>
                  </TooltipContent>
                </Tooltip>
                <p className="ml-2 text-xxs leading-[13px]">
                  {usageNum} {usageNum && usageNum > 1 ? t("Invocations") : t("Invocation")}, {avgTime?.toFixed(2)}{" "}
                  {t("MsAvg")}{" "}
                  {Object.keys(runErrors).length > 0
                    ? ` ${Object.keys(runErrors).length} ${Object.keys(runErrors).length === 1 ? t("Error") : t("Errors")}`
                    : ""}
                </p>
              </div>
            </CardDescription>
          </CardHeader>
        ))}
        {Object.entries(runErrors).map(([errorMsg, { errorType, times }]) => (
          <CardHeader key={errorMsg} className="h-full space-y-0 border-b p-0">
            <CardDescription className="border-b border-border p-2 text-xxs text-primary">
              <div className="flex items-center">
                <RedX width={16} height={16} />
                <Tooltip>
                  <TooltipTrigger>
                    <p className="ml-2 max-w-dialog truncate">
                      <span className="capitalize">{errorType}:</span> {errorMsg}, {times}{" "}
                      {times > 1 ? t("Times") : t("Time")}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {errorType} : {errorMsg}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </CardDescription>
          </CardHeader>
        ))}
      </Card>
    </TabsContent>
  )
}
