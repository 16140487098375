import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

export type Themes = "dark" | "light"

interface State {
  selectedTheme: Themes
  setTheme: (theme: Themes) => void
  sidebarWidth: number
  setSidebarWidth: (width: number) => void
}

const getTheme = (): Themes => {
  const theme = localStorage.getItem("theme") as Themes
  if (theme) {
    return theme
  } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark"
  }
  return "light"
}

export const useSettings = create<State>()(
  immer((set) => ({
    selectedTheme: getTheme(),
    setTheme(theme: Themes) {
      set((state) => {
        state.selectedTheme = theme
        localStorage.setItem("theme", theme)
      })
    },
    sidebarWidth: 0,
    setSidebarWidth: (width) => {
      set((state) => {
        state.sidebarWidth = width
      })
    }
  }))
)
