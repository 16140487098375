import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { marked } from "marked"
import { generateJSON, JSONContent } from "@tiptap/core"
import Mention from "@tiptap/extension-mention"
import {
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorContent,
  EditorInstance,
  EditorRoot
} from "novel"
import { ImageResizer, StarterKit, TiptapLink } from "novel/extensions"
import { Separator } from "@/components"
import { slashCommand } from "@/components/designSystem/Discussions/components/RichEditor/SlashCommand"
import BubbleMenu from "@/components/designSystem/Discussions/components/RichEditor/BubbleMenu"
import { NodeSelector } from "@/components/designSystem/Discussions/components/RichEditor/selectors/NodeSelector"
import { ColorSelector } from "@/components/designSystem/Discussions/components/RichEditor/selectors/ColorSelector"
import { TextButtons } from "@/components/designSystem/Discussions/components/RichEditor/selectors/TextButtons"
import { LinkSelector } from "@/components/designSystem/Discussions/components/RichEditor/selectors/LinkSelector"
import suggestionItems from "@/components/designSystem/Discussions/components/RichEditor/suggestionItems"
import mentionConfig, {
  MentionNameAndLogin
} from "@/components/designSystem/Discussions/components/RichEditor/extensions/mentions/MentionConfiguration"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useFetchEligibleReviewers } from "@/hooks/queries/useFetchEligibleReviewers"
import { defaultExtensions, placeholderExtension } from "./extensions"

interface RichEditorProps {
  initialContent: string
  handleEditorChange: (content: string) => void
  handleEditorUpdate?: (editor: EditorInstance) => void
  parseMode: "full" | "inline"
  onCommandEnter?: () => void
  hint?: string
}

export const RichEditor = ({
  initialContent,
  handleEditorChange,
  handleEditorUpdate,
  parseMode,
  onCommandEnter,
  hint
}: RichEditorProps) => {
  const [openNode, setOpenNode] = useState(false)
  const [openColor, setOpenColor] = useState(false)
  const [openLink, setOpenLink] = useState(false)
  const [isBubbleMenuOpen, setIsBubbleMenuOpen] = useState(false)
  const [editCommentData, setEditCommentData] = useState<JSONContent | undefined>(undefined)
  const [isInitialized, setIsInitialized] = useState(false)

  const { changeRequest } = useChangeRequest()
  const { data: eligibleReviewers, isLoading: isReviewersLoading } = useFetchEligibleReviewers({
    changeId: changeRequest?.id || ""
  })
  const { t } = useTranslation("Discussions")

  const extensions = useMemo(() => {
    const baseExtensions = [...defaultExtensions, slashCommand, placeholderExtension(hint)]

    if (!isReviewersLoading && eligibleReviewers) {
      const mentionOptions: MentionNameAndLogin[] = eligibleReviewers
        .filter((reviewer) => reviewer.avatar_url)
        .map((reviewer) => {
          const lastSlashIndex = reviewer.login.lastIndexOf("/")
          const login = reviewer.login.substring(lastSlashIndex + 1)

          return {
            name: reviewer.name,
            login: login,
            avatarUrl: reviewer.avatar_url!
          }
        })

      if (mentionOptions.length > 0) {
        baseExtensions.push(
          Mention.configure({
            HTMLAttributes: {
              class: "text-link px-1"
            },
            suggestion: mentionConfig(mentionOptions, t("NoMatchingPersonFound"))
          })
        )
      }
    }

    return baseExtensions
  }, [hint, isReviewersLoading, eligibleReviewers, t])

  const update = (editor: EditorInstance) => {
    editor.commands.focus(null, { scrollIntoView: false })
    handleEditorChange(editor.getHTML())
    if (handleEditorUpdate) handleEditorUpdate(editor)
  }

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation()
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault()
        onCommandEnter?.()
      }
    },
    [onCommandEnter]
  )

  useEffect(() => {
    if (!initialContent) {
      setIsInitialized(true)
      return
    }

    const parseContent = async () => {
      try {
        const parsedContent = initialContent?.startsWith("<p>")
          ? initialContent
          : parseMode === "full"
            ? await marked.parse(initialContent)
            : await marked.parseInline(initialContent)

        const jsonContent = generateJSON(parsedContent, [
          StarterKit,
          TiptapLink.configure({
            openOnClick: false
          })
        ])
        setEditCommentData(jsonContent)
        setIsInitialized(true)
      } catch (error) {
        console.error("Error parsing content:", error)
        setEditCommentData(generateJSON("Error parsing content", [StarterKit]))
      }
    }

    parseContent()
  }, [initialContent, parseMode])

  if (!isInitialized) {
    return null
  }

  return (
    <div tabIndex={0} className="focus:outline-none" onKeyDown={handleKeyDown}>
      <EditorRoot>
        <EditorContent
          initialContent={editCommentData}
          extensions={extensions}
          editorProps={{
            attributes: {
              class: "text-sm focus:outline-none max-w-full"
            }
          }}
          onUpdate={({ editor }) => {
            update(editor)
          }}
          slotAfter={<ImageResizer />}
        >
          <EditorCommand
            className={`z-50 h-auto max-h-[330px] overflow-y-auto rounded-md border border-border bg-background px-1 py-2 shadow-md transition-all`}
          >
            <EditorCommandEmpty className="px-2 text-hint">No results</EditorCommandEmpty>
            <EditorCommandList>
              {suggestionItems.map((item) => (
                <EditorCommandItem
                  value={item.title}
                  onCommand={(val) => (item.command ? item.command(val) : null)}
                  className="flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm text-primary hover:bg-fill aria-selected:bg-fill"
                  key={item.title}
                >
                  <div className="flex size-10 items-center justify-center rounded-md border border-border bg-background">
                    {item.icon}
                  </div>
                  <div>
                    <p className="font-medium">{item.title}</p>
                    <p className="text-xs text-hint">{item.description}</p>
                  </div>
                </EditorCommandItem>
              ))}
            </EditorCommandList>
          </EditorCommand>
          <BubbleMenu open={isBubbleMenuOpen} onOpenChange={setIsBubbleMenuOpen}>
            <Separator orientation="vertical" />
            <NodeSelector open={openNode} onOpenChange={setOpenNode} />
            <Separator orientation="vertical" />
            <LinkSelector open={openLink} onOpenChange={setOpenLink} />
            <Separator orientation="vertical" />
            <TextButtons />
            <Separator orientation="vertical" />
            <ColorSelector open={openColor} onOpenChange={setOpenColor} />
          </BubbleMenu>
        </EditorContent>
      </EditorRoot>
    </div>
  )
}
