import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation } from "@tanstack/react-query"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "@/lib/routes"

export const useUpdateOnboardingReviewerStatus = () => {
  const navigate = useNavigate()

  const updateStatus = useCallback(async (wasStuck: boolean) => {
    await axiosClient.put(apiRoutes.onboarding.reviewer.put)
    return wasStuck
  }, [])

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["updateOnboardingReviewerStatus"],
    mutationFn: updateStatus,
    onSuccess: (wasStuck) => {
      navigate(routes.changes.root, {
        state: { fromStuckOnboarding: wasStuck }
      })
    }
  })

  return { data, mutateAsync, status }
}
