export const CODE_BLOCK_REGEX = /```([^`]*)```/g
export const SUGGESTION_LINES_REGEX = /Here are my suggestions for lines \d+-\d+/g

export function getMatchIterator(markdownText: string, regex: RegExp) {
  const iterator = markdownText.matchAll(regex)
  return Array.from(iterator)
}

export function getRawCodeFromRegexMatch(regexMatch: RegExpExecArray[]) {
  return regexMatch.map((match) => {
    const rawCode = match[1]
    const firstNewlineIndex = rawCode.indexOf("\n")
    return rawCode.slice(firstNewlineIndex).trim()
  })
}

export function getRawNumbersFromRegexMatch(regexMatch: RegExpExecArray[]) {
  return regexMatch
    .map((match) => {
      const [start, end] =
        match[0]
          ?.match(/\d+-\d+/)?.[0]
          ?.split("-")
          .map(Number) ?? []
      if (start && end) return [start, end] as [number, number]
      console.error("Could not extract line numbers for bazzy suggestion")
      return null
    })
    .filter((tuple): tuple is [number, number] => tuple !== null)
}

export function combineCodeBlockWithLineNumbers(lineNumbers: [number, number][], codeBlocks: string[]) {
  const combinedMap = new Map<string, [number, number]>()

  while (lineNumbers.length && codeBlocks.length) {
    combinedMap.set(codeBlocks.shift() as string, lineNumbers.shift() as [number, number])
  }

  return combinedMap
}
