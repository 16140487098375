export const Topics = {
  Topics: "Topics",
  Checks: "Checks",
  ChecksText: "View test logs and suggest fixes",
  Developers: "Developers",
  DevelopersText: "Invite recent contributors for review",
  Usage: "Usage",
  UsageText: "Code elements impacting APIs",
  Committed: "committed",
  Comment: "Comment",
  suggestAChange: "Suggest a change",
  goToFile: "Go to File",
  FailedCheck: "Failed check",
  BreakingChange: "Breaking change",
  reviewedTooltip: "Check this topic as reviewed",
  unReviewedTooltip: "Uncheck this topic",
  OutOfScope: "This error is out of the diff's scope",
  AskBazzy: "Ask Bazzy",
  Explain: "Explain"
}
