import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { FetchContentArgs, ContentMetadata } from "@/models/FC"

interface Options {
  fetchArgs: FetchContentArgs | null
  path: string | null
}

export const useFetchContentMetadata = ({ fetchArgs, path }: Options) => {
  const { error, isLoading, data } = useQuery({
    queryKey: ["contentMetadata", path],
    queryFn: async () => {
      const response = await axiosClient.post<ContentMetadata>(apiRoutes.contentMetadata, fetchArgs)

      return response.data
    },
    enabled: fetchArgs !== null
  })

  return { error, isLoading, data }
}
