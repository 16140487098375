import { MinimalSheet, MinimalSheetContent } from "@/components"
import { Discussion } from "@/models/Discussions"
import { Comment } from "../components/Comment"
import { formatDate } from "@/lib/dateFormats"
import { AddComment } from "../components/Comment/AddComment"
import { useTranslation } from "react-i18next"
import { X } from "lucide-react"
import { Suggestion } from "../components/SuggestionV2"
import { commentOnLinesFormat, splitCommentAndSuggestion } from "@/components/designSystem/Discussions/utils"

interface Props {
  discussion: Discussion
  open: boolean
  onClose: () => void
  CRId: string
  commitSha: string
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
}

export const DiscussionDrawer = ({
  discussion,
  open,
  onClose,
  CRId,
  commitSha,
  handleResolveToggle,
  isResolved
}: Props) => {
  const { t } = useTranslation("Discussions")

  const shouldShowDate = (currentIndex: number) =>
    currentIndex === 0 ||
    new Date(discussion.comments[currentIndex].created_at).toDateString() !==
      new Date(discussion.comments[currentIndex - 1].created_at).toDateString()

  return (
    <MinimalSheet open={open} modal={false}>
      <MinimalSheetContent
        onEscapeKeyDown={onClose}
        className="flex w-2/5 flex-col items-start overflow-y-auto border-border bg-fill font-inter shadow-xl sm:max-w-[40%]"
      >
        <div className="mb-4 flex items-center text-primary">
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
          <span className="ml-2 text-sm font-semibold">{t("Thread")}</span>
        </div>
        <div className="flex w-full grow flex-col">
          {discussion.comments.map((comment, index) => {
            const { suggestion, comment: commentText } = splitCommentAndSuggestion(comment.comment_body)
            const showDate = shouldShowDate(index)
            const header = `${index === 0 ? commentOnLinesFormat(discussion) : ""}${showDate ? " " + formatDate(discussion.created_at) : ""}`
            return suggestion ? (
              <Suggestion
                key={comment.id}
                comment={comment}
                CRId={CRId}
                commitSha={commitSha}
                isClickable={false}
                isResolved={isResolved}
                current={discussion}
                resolvable={index === 0}
                handleResolveToggle={handleResolveToggle}
              />
            ) : (
              <div key={comment.id}>
                {header && <p className="mb-4 text-xxs text-secondary">{header}</p>}
                <Comment
                  comment={{
                    ...comment,
                    comment_body: commentText || ""
                  }}
                  isFullComment
                  isClickable={false}
                  current={discussion}
                  resolvable={index === 0}
                  handleResolveToggle={handleResolveToggle}
                  isResolved={isResolved}
                />
              </div>
            )
          })}
        </div>

        <AddComment discussion={discussion} CRId={CRId} commitSha={commitSha} />
      </MinimalSheetContent>
    </MinimalSheet>
  )
}
