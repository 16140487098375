import { ChevronDown } from "lucide-react"
import { EditorBubbleItem, useEditor } from "novel"

import { Button, Popover, PopoverContent, PopoverTrigger } from "@/components"

export interface BubbleColorMenuItem {
  name: string
  color: string
}

const TEXT_COLORS: BubbleColorMenuItem[] = [
  {
    name: "Default",
    color: "var(--novel-black)"
  },
  {
    name: "Purple",
    color: "#9333EA"
  },
  {
    name: "Red",
    color: "#E00000"
  },
  {
    name: "Yellow",
    color: "#EAB308"
  },
  {
    name: "Blue",
    color: "#2563EB"
  },
  {
    name: "Green",
    color: "#008A00"
  },
  {
    name: "Orange",
    color: "#FFA500"
  },
  {
    name: "Pink",
    color: "#BA4081"
  },
  {
    name: "Gray",
    color: "#A8A29E"
  }
]

interface ColorSelectorProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const ColorSelector = ({ open, onOpenChange }: ColorSelectorProps) => {
  const { editor } = useEditor()

  if (!editor) return null
  const activeColorItem = TEXT_COLORS.find(({ color }) => editor.isActive("textStyle", { color }))

  return (
    <Popover modal={true} open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button type="button" size="sm" className="gap-2 rounded-none" variant="ghost">
          <span
            className="rounded-sm px-1"
            style={{
              color: activeColorItem?.color
            }}
          >
            A
          </span>
          <ChevronDown className="size-4" />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        sideOffset={5}
        className="my-1 flex max-h-80 w-48 flex-col overflow-hidden overflow-y-auto rounded border p-1 shadow-xl "
        align="start"
      >
        <div className="flex flex-col">
          <div className="my-1 px-2 text-sm font-semibold text-hint">Color</div>
          {TEXT_COLORS.map(({ name, color }) => (
            <EditorBubbleItem
              key={name}
              onSelect={() => {
                editor.commands.unsetColor()
                name !== "Default" &&
                  editor
                    .chain()
                    .focus()
                    .setColor(color || "")
                    .run()
              }}
              className="flex cursor-pointer items-center justify-between px-2 py-1 text-sm hover:bg-fill"
            >
              <div className="flex items-center gap-2">
                <div className="rounded-sm border px-2 py-px font-medium" style={{ color }}>
                  A
                </div>
                <span>{name}</span>
              </div>
            </EditorBubbleItem>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}
