import { Repository } from "@/models/Repository"

export interface BaseOption {
  value: string
  name: string
}

export const getRepositoryDefaultBranch = (repositoryId: string, repositories: Repository[]) => {
  return repositories.find((repo) => repo.id === repositoryId)?.defaultBranch
}

export const getRepositoryId = (repositoryName: string, repositories: Repository[]) => {
  return repositories.find((repo) => repo.fullName === repositoryName)?.id
}

export const getBranchValueFromOptions = (branchName: string, options: BaseOption[]) => {
  return options.find((option) => option.name === branchName)?.value
}
