import { useEffect, useMemo, useState } from "react"
import { getFullRepositoryName } from "../utils"
import { useParams } from "react-router-dom"
import { useFetchRepositories } from "@/hooks/queries/useFetchRepositories"
import { LocalStorageService } from "@/lib/localStorageService"

const localStorageService = new LocalStorageService("localStorage")

export const useRepositories = () => {
  const { org, repository } = useParams()
  const [currentRepositoryName, setCurrentRepositoryName] = useState(getFullRepositoryName({ org, repository }))
  const [currentRepositoryIcon, setCurrentRepositoryIcon] = useState("")
  const repositoryFromLocalStorage = localStorageService.getItem<string>("repositoryFullName") || undefined
  const { data: repositories, error: repositoriesError, isLoading: repositoriesLoading } = useFetchRepositories()

  const currentRepository = useMemo(
    () => repositories?.find((repo) => repo.fullName === currentRepositoryName),
    [currentRepositoryName, repositories]
  )

  // sync the avatar url in a state because we need to be able to set it on item click
  useEffect(() => {
    setCurrentRepositoryIcon(currentRepository?.avatarUrl || "")
  }, [currentRepository, setCurrentRepositoryIcon])

  const repositoryProps = useMemo(
    () => ({
      org,
      repository,
      currentRepository,
      repositories,
      repositoriesError,
      repositoriesLoading,
      currentRepositoryName,
      currentRepositoryIcon,
      setCurrentRepositoryName,
      setCurrentRepositoryIcon
    }),
    [
      org,
      repository,
      currentRepository,
      repositories,
      repositoriesError,
      repositoriesLoading,
      currentRepositoryName,
      currentRepositoryIcon,
      setCurrentRepositoryName,
      setCurrentRepositoryIcon
    ]
  )

  return {
    currentRepositoryName,
    repositoryFromLocalStorage,
    repositories,
    repositoriesError,
    repositoriesLoading,
    repositoryProps,
    currentRepository,
    setCurrentRepositoryName,
    setCurrentRepositoryIcon
  }
}
