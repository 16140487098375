import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useSession } from "@descope/react-sdk"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { LocalStorageService } from "@/lib/localStorageService"
import { useFetchOnboardingState } from "@/hooks/queries/useFetchOnboardingState"
import { routes } from "@/lib/routes"
import { isDev } from "@/services/api/helpers"

const localStorageService = new LocalStorageService("sessionStorage")

const PrivateRoute = () => {
  const { isAuthenticated, isSessionLoading } = useSession()
  const { data, isLoading } = useFetchOnboardingState(isAuthenticated, 0)
  const { pathname, search } = useLocation()

  localStorageService.setItem("from", pathname + search || "")

  if (isSessionLoading || isLoading) {
    return <SpinnerLoader className="pt-6" />
  }

  if (isAuthenticated && !data?.data.isDoneOnboarding && !isDev) return <Navigate to={routes.onboarding.root} />

  return isAuthenticated ? <Outlet /> : <Navigate to={routes.login} />
}

export default PrivateRoute
