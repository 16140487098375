import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { DirectoryContent, FetchContentArgs } from "@/models/FC"
import { useId } from "react"
interface Options {
  fetchArgs: FetchContentArgs | null
  objectType: string
  shouldFetch: boolean
  filePath?: string
}

export const useFetchDirContent = ({ fetchArgs, objectType, shouldFetch }: Options) => {
  const id = useId()

  const { error, isLoading, data } = useQuery({
    queryKey: ["dirContent", id],
    queryFn: async () => {
      const response = await axiosClient.post<DirectoryContent>(apiRoutes.dirContent, fetchArgs)

      // sorting the nodes based on object type
      if (response && response.data.entries.length > 0) {
        response.data.entries.sort((nodeA, nodeB) => {
          if (nodeA.object_type === "dir" && nodeB.object_type !== "dir") {
            return -1
          } else if (nodeA.object_type !== "dir" && nodeB.object_type === "dir") {
            return 1
          }
          return 0
        })

        return response.data
      }

      return response.data
    },
    enabled: !!fetchArgs && objectType === "dir" && shouldFetch
  })

  return { error, isLoading, data }
}
