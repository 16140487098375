import { Button, Form, FormControl, FormField, FormItem, FormMessage } from "@/components"
import { useCallback } from "react"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { useTranslation } from "react-i18next"
import { RichEditor } from "@/components/designSystem/Discussions/components/RichEditor"
import { UseFormReturn } from "react-hook-form"
import { useDiscussions } from "@/services/store/useDiscussions"
import { ChangeRequest } from "@/models/Changes"
import { useUpdateChangeRequest } from "@/hooks/mutations/useUpdateChangeRequest"

interface Props {
  changeRequest: ChangeRequest
  description: string
  form: UseFormReturn<{ body: string }>
  setEditMode: (value: boolean) => void
}

export const EditCRDescription = ({ changeRequest, description, form, setEditMode }: Props) => {
  const { mutateAsync } = useUpdateChangeRequest()
  const { t } = useTranslation("Common")
  const { toggleManualRecalculationTrigger } = useDiscussions()

  const handleCloseEdit = useCallback(() => {
    setEditMode(false)
    toggleManualRecalculationTrigger()
  }, [setEditMode, toggleManualRecalculationTrigger])

  const { setError, handleSubmit, control } = form

  const onSubmit = useCallback(
    async (values: { body: string }) => {
      try {
        await mutateAsync({
          changeId: changeRequest.id,
          description: values.body
        })
        handleCloseEdit()
      } catch (err) {
        setError("body", { message: ghWriteErrorHandler(err) })
      }
    },
    [mutateAsync, changeRequest.id, handleCloseEdit, setError]
  )

  return (
    <Form {...form}>
      <form
        id="edit-cr-description-form"
        className="w-full rounded-lg border border-secondary bg-background p-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          name="body"
          control={control}
          render={({ field }) => (
            <FormItem className="mb-3 items-baseline">
              <FormControl>
                <RichEditor
                  initialContent={description}
                  handleEditorChange={field.onChange}
                  parseMode="full"
                  onCommandEnter={handleSubmit(onSubmit)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex gap-2 py-2">
          <Button type="submit" variant="default" className="h-4 px-2 py-4 text-xs">
            {t("Save")}
          </Button>
          <Button onClick={handleCloseEdit} type="button" variant="outline" className="h-4 px-2 py-4 text-xs">
            {t("Cancel")}
          </Button>
        </div>
      </form>
    </Form>
  )
}
