import { BazzyContext } from "@/models/Bazzy.ts"
import { useTranslation } from "react-i18next"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { memo, useRef, useState } from "react"

export const LinesContextInfo = memo(({ filePath, lines, relevantInsight, insightType }: BazzyContext) => {
  const { t } = useTranslation("Bazzy")
  const isSingleLine = lines?.startLine === lines?.endLine
  const filePathRef = useRef<HTMLSpanElement>(null)
  const [isFilePathTooltipOpen, setIsFilePathTooltipOpen] = useState(false)

  const fileName = filePath?.split("/").pop()

  return (
    <TooltipProvider>
      <div className="mt-2 flex flex-wrap text-xs leading-5 text-secondary ">
        <Tooltip open={isFilePathTooltipOpen}>
          <TooltipTrigger
            onMouseEnter={() => setIsFilePathTooltipOpen(true)}
            onMouseLeave={() => setIsFilePathTooltipOpen(false)}
            asChild
          >
            <span className="mr-2 font-semibold italic" ref={filePathRef}>
              {fileName}
            </span>
          </TooltipTrigger>
          <TooltipContent align="start" className="flex h-full flex-wrap" role="tooltip">
            {filePath}
          </TooltipContent>
        </Tooltip>
        <span>
          {isSingleLine ? `${t("Line")} ${lines?.startLine}` : `${t("Lines")} ${lines?.startLine}-${lines?.endLine}`}
        </span>
        {insightType && <span className="ml-2">{insightType}</span>}
        {relevantInsight && <p className="font-menlo">{relevantInsight}</p>}
      </div>
    </TooltipProvider>
  )
})
