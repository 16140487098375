import { CIRun, CIRunWithErrors } from "@/models/Changes"
import { FileDetails, TopicInsights } from "@/models/Topics"
import { processBreakingChanges, processStats, UniqueStatEntry } from "@/components/designSystem/Insights/helpers.tsx"
import { BreakingChange, InsightType, InsightsPerRow, Stat } from "@/models/Insight.ts"
import { scrollToPosition, tBodyScrollOffset } from "@/pages/crFileChanges/components/helpers"

export type IconType = "usage" | "checks" | "developers" | "name" | "files" | "empty"

type Insight = {
  insights: { LogError?: { scm_provider_details?: { run_url: string }; err_description?: string } }[]
  line_number: number
}

export const getChecksWithExtraData = (
  checks: CIRun[],
  files: FileDetails[],
  insights: TopicInsights
): CIRunWithErrors[] => {
  return checks.reduce<CIRunWithErrors[]>((previousValue, currentValue) => {
    const currentCIRunWithErrors: CIRunWithErrors = {
      ...currentValue,
      errors: []
    }

    for (const file of files) {
      const relevantInsights = insights[file.path]?.filter((insight: Insight) =>
        insight.insights.some((i) => i.LogError?.scm_provider_details?.run_url === currentValue.link)
      )

      relevantInsights?.forEach((insight) => {
        insight.insights?.forEach((i) =>
          i.LogError
            ? currentCIRunWithErrors.errors.push({
                ...i.LogError,
                codeLocations: [
                  {
                    fileName: file.path,
                    lineNumber: insight.line_number
                  }
                ],
                lineNumber: insight.line_number,
                ci_name: currentValue.ci_name,
                name: currentValue.name
              })
            : null
        )
      })
    }

    return [...previousValue, currentCIRunWithErrors]
  }, [])
}

const getInsightsByType = <T extends Stat | BreakingChange>(
  extractedInsights: InsightType[],
  type: T extends Stat ? "Stat" : "BreakingChange"
): T[] => {
  return extractedInsights
    .flatMap((item) => (item[type] ? [item] : []))
    .flatMap((insight) => (insight[type] ?? []) as T[])
}

export const getInsightsData = (insights: TopicInsights) => {
  const extractedInsights = Object.values(insights)
    .flatMap((insightRow) => insightRow.flatMap((row: InsightsPerRow) => row.insights))
    .filter((insight) => insight.RunError || insight.Stat || insight.BreakingChange)

  const stats = getInsightsByType<Stat>(extractedInsights, "Stat")
  const breakingChanges = getInsightsByType<BreakingChange>(extractedInsights, "BreakingChange")

  const uniqueStats: UniqueStatEntry[] = processStats(stats, insights)
  const uniqueBreakingChanges = Object.values(processBreakingChanges(breakingChanges, insights, "title"))

  return {
    uniqueStats,
    uniqueBreakingChanges
  }
}

export const isLineInScope = (fileName: string, lineNumber: number | null) => {
  if (!fileName || !lineNumber) return false
  const file = document.querySelector(`table[data-file-path='${fileName}']`)

  if (!file) return false
  const lineElement = file.querySelector(`tr[data-right='${lineNumber}']`)

  return !!lineElement
}

export const scrollToLineOrBody = (fileName: string, lineNumber: number | null) => {
  if (!fileName) return

  const file = document.querySelector(`table[data-file-path='${fileName}']`)
  if (!file) return

  const lineElement = file.querySelector(`tr[data-right='${lineNumber}']`)
  if (lineElement) {
    const elementTop = lineElement.getBoundingClientRect().top
    scrollToPosition({ elementTop, offset: tBodyScrollOffset })
  } else {
    const body = file.querySelector("tbody")
    if (body) {
      const elementTop = body.getBoundingClientRect().top
      scrollToPosition({ elementTop, offset: tBodyScrollOffset })
    }
  }
}
