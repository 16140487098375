import { TableBody as BaseTableBody, TableCell, TableRow } from "@/components"

import { flexRender, Table } from "@tanstack/react-table"
import { FccRow, FileWithDiffs } from "@/models/FCC"
import { useSelection } from "@/services/store/useSelection"
import { isExpandableLine, setCellTextAlignment } from "@/components/designSystem/FCC/utils"
import { ExpanderRow } from "@/components/designSystem/FCC/Table/ExpanderRow"
import { useRef } from "react"

interface Props {
  table: Table<FccRow>
  file: FileWithDiffs
  columnsCount: number
}

const TableBody = ({ table, file, columnsCount }: Props) => {
  const { handleMouseUp, handleMouseDown, currentEditableTable, setCurrentEditableTable } = useSelection()
  const currentRow = useRef<HTMLTableRowElement>(null)

  return (
    <BaseTableBody className="w-full select-none border-b font-mono text-xs leading-[20px]">
      {table.getRowModel().rows.map((row, idx) =>
        isExpandableLine(row.original) && !row.getIsExpanded() ? (
          <ExpanderRow
            row={row}
            file={file}
            ref={currentRow}
            arrowDirection={row.original.arrowDirection || null}
            isExpanded={row.getIsExpanded()}
            key={idx}
            elements={table.options.meta?.elements || []}
            columnsCount={columnsCount}
            table={table}
          />
        ) : isExpandableLine(row.original) && row.getIsExpanded() ? (
          <div key={idx}></div>
        ) : (
          <TableRow
            key={idx}
            data-left={row.original.number}
            data-right={row.original.new_line_number}
            className="h-5 border-b-0 text-primary"
            onMouseDown={(event: React.MouseEvent<HTMLTableRowElement>) => {
              handleMouseDown(event)
              if (currentEditableTable === null) {
                setCurrentEditableTable(file.file_relative_path)
              }
            }}
            onMouseUp={handleMouseUp}
          >
            {row.getVisibleCells().map((cell) => {
              const textPlacement = setCellTextAlignment(cell.column.id)
              const isNumberColumn = cell.column.id === "expander" ? "border-r" : ""

              return (
                <TableCell className={`h-5 p-0 ${textPlacement} ${isNumberColumn}`} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              )
            })}
          </TableRow>
        )
      )}
    </BaseTableBody>
  )
}

export default TableBody
