import { useEffect, useState } from "react"
import { RowData, getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table"
import TableHeader from "./TableHeader"
import TableBody from "./TableBody"
import { SkeletonLoader, Table, TooltipProvider } from "@/components"
import { FileElement, FileWithDiffs } from "@/models/FCC"
import { computeIsEdge, getEdgeType } from "../utils"
import { addedViewColumns, deletedViewColumns, splitViewColumns, unifiedViewColumns } from "./columns"
import { InsightsPerRow } from "@/models/Insight"
import { EdgeFile } from "./EdgeComponents/EdgeFile"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useContent } from "@/hooks/useContent"
import { flattenLines } from "@/components/designSystem/FCC/utils/flattenLines"
import { SubRow } from "@/models/FCC"

declare module "@tanstack/react-table" {
  // eslint-disable-next-line
  interface TableMeta<TData extends RowData> {
    filePath: string
    insights: InsightsPerRow[]
    newDiff?: boolean
    elements: FileElement[]
  }
  interface TableOptions<TData extends RowData> {
    getSubRows?: (originalRow: TData) => SubRow[]
  }
}

interface Props {
  file: FileWithDiffs
  minimized: boolean
  newDiff?: boolean
}

const getTableColumns = (hasEdits: boolean, fileStatus: string | null) => {
  if (hasEdits) {
    return splitViewColumns
  }
  if (fileStatus === "added") {
    return addedViewColumns
  }
  if (fileStatus === "removed") {
    return deletedViewColumns
  }

  return unifiedViewColumns
}

export const FccTable = ({ file, minimized, newDiff }: Props) => {
  const [forceRender, setForceRender] = useState(false)
  const { toggleManualRecalculationTrigger } = useDiscussions()
  const { tables, setTables } = useChangeRequest()
  const isEdgeFile = computeIsEdge(file)
  const currentTable = tables[file.file_relative_path]
  const columnsCount = getTableColumns(file.has_edits, file.file_status)?.length

  const { content } = useContent({
    filePath: file.file_relative_path,
    shouldFetch: currentTable?.shouldFetchContent || false
  })

  const table = useReactTable({
    data: currentTable?.rows || [],
    columns: getTableColumns(file.has_edits, file.file_status),
    meta: { filePath: file.file_relative_path, insights: file.insights, newDiff, elements: file.elements },
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })

  useEffect(() => {
    const updatedFileContent = content ?? file.file_content
    const flattenedLines = flattenLines({ ...file, file_content: updatedFileContent })

    setTables({
      [file.file_relative_path]: {
        rows: flattenedLines,
        shouldFetchContent: false
      }
    })
    toggleManualRecalculationTrigger()
  }, [file, isEdgeFile, setTables, content, toggleManualRecalculationTrigger, forceRender])

  if (!currentTable?.rows) return <SkeletonLoader />

  if (isEdgeFile && !forceRender)
    return <EdgeFile fileType={getEdgeType(file)} minimized={minimized} setForceRender={() => setForceRender(true)} />

  return (
    <TooltipProvider delayDuration={100}>
      <Table data-file-path={file.file_relative_path} className={`table-fixed ${minimized ? "hidden" : "visible"}`}>
        <TableHeader table={table} />
        <TableBody table={table} file={file} columnsCount={columnsCount} />
      </Table>
    </TooltipProvider>
  )
}
