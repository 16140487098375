import { IconProps } from "@radix-ui/react-icons/dist/types"
import { LucideIcon } from "lucide-react"
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"

interface Props {
  title: string
  items: BlockerBoxItem[]
  filterButton?: string
  filterFunction?: () => void
}

export interface BlockerBoxItem {
  description: string
  subDescription?: string
  isLink: boolean
  Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> | LucideIcon
  link?: string
  iconColor?: string
  iconToolTip?: string
}

export const BlockerBoxItem = ({
  item: { Icon, description, isLink, link, subDescription, iconColor, iconToolTip }
}: {
  item: BlockerBoxItem
}) => {
  return (
    <div className="flex items-center rounded-sm bg-background p-2">
      {iconToolTip ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Icon width={16} height={16} className={`mr-2`} color={iconColor} />
            </TooltipTrigger>
            <TooltipContent sideOffset={10}>{iconToolTip}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <Icon width={16} height={16} className={`mr-2`} color={iconColor} />
      )}

      {isLink && link ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className="text-xs">
          {description}
        </a>
      ) : (
        <span className="text-xs">{description}</span>
      )}
      <span className="text-xxs text-fill">{subDescription}</span>
    </div>
  )
}

export const BlockerBox = ({ title, items, filterButton, filterFunction = () => {} }: Props) => {
  if (items.length === 0) return <></>

  return (
    <div className="flex h-fit flex-col rounded-sm bg-border p-1 text-primary">
      <div className="flex items-center">
        <p className="mx-3 h-8 content-center text-xs">{title}</p>
        {filterButton && (
          <Button onClick={filterFunction} size="sm" variant="ghost" className="h-6 text-xs hover:bg-background">
            {filterButton}
          </Button>
        )}
      </div>
      {items.map((item) => {
        return <BlockerBoxItem item={item} />
      })}
    </div>
  )
}
