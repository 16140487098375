import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { Topic } from "@/models/Topics"

export const useFetchTopics = (changeId?: string) => {
  const { error, isLoading, data } = useQuery<{ topics: Topic[] }, unknown>({
    queryKey: ["topics", changeId],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.changes.topics(changeId || ""))
      return response.data
    },
    enabled: !!changeId
  })

  return { error, isLoading, data: data?.topics || [] }
}
