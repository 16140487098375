import { WriteAccessDeniedReason } from "@/models/RepoWriteAccess"
import { useFetchRepoWriteAccess } from "@/hooks/queries/useFetchRepoWriteAccess"
import { ExternalLink } from "@/components"
import { useTranslation } from "react-i18next"
import { clientUrl } from "@/lib/urls"
import { usePermissions } from "@/services/store/usePermissions"
import { useMemo } from "react"
import { githubWriteUrl } from "@/models/GithubIntegrations.ts"

export const useGithubRepoWriteAccess = (repoFullName: string) => {
  const { t: writeAccessTranslation } = useTranslation("WriteAccessDialog")
  const { hasGithubWriteAccess } = usePermissions()
  const havePersonalToken = hasGithubWriteAccess()
  const { data: repoWriteAccess } = useFetchRepoWriteAccess(repoFullName, havePersonalToken)

  const calculateWriteAccess = useMemo(() => {
    if (!havePersonalToken)
      return {
        hasAccess: false,
        reason: WriteAccessDeniedReason.MISSING_USER_INSTALLATION
      }

    const { hasAccess = false, reason = "" } = repoWriteAccess ?? {}

    return {
      hasAccess,
      reason
    }
  }, [havePersonalToken, repoWriteAccess])

  const { hasAccess, reason } = calculateWriteAccess

  const link = reason === WriteAccessDeniedReason.MISSING_USER_INSTALLATION ? clientUrl(`/privilege`) : githubWriteUrl

  const linkText = hasAccess
    ? ""
    : reason === WriteAccessDeniedReason.MISSING_USER_INSTALLATION
      ? writeAccessTranslation("AuthorizeAccountOnGithub")
      : writeAccessTranslation("AuthorizeOrgOnGithub")

  const toolTipContent = (
    <div>
      <h5 className="mb-1 text-s">{writeAccessTranslation("WritePermissionsRequired")}</h5>
      <ExternalLink href={link} label={linkText} />
    </div>
  )

  return { toolTipContent, hasAccess }
}
