import { FileWithDiffs } from "@/models/FCC"
import { FileHeader } from "./FileHeader"
import { FccTable } from "./Table"
import { useCallback, useState } from "react"
import { ViewedDetails } from "@/models/Topics.ts"
import { useDiscussions } from "@/services/store/useDiscussions.ts"

interface Props {
  file: FileWithDiffs
  viewedDetails?: ViewedDetails
  newDiff?: boolean
}

export const FCC = ({ file, newDiff, viewedDetails }: Props) => {
  const { lines_deleted, lines_added, file_relative_path } = file
  const { toggleManualRecalculationTrigger } = useDiscussions()
  const [minimized, setMinimized] = useState(false)
  const toggleMinimized = useCallback(() => {
    toggleManualRecalculationTrigger()
    setMinimized((prev) => !prev)
  }, [setMinimized, toggleManualRecalculationTrigger])

  return (
    <div className="w-full">
      <div data-id="table-invisible-header" id={file_relative_path}></div>
      <FileHeader
        isFileViewed={viewedDetails?.viewed === "viewed"}
        newDiff={newDiff}
        diff={{ addition: lines_added, deletion: lines_deleted }}
        filePath={file_relative_path}
        oldFilePath={file.old_relative_path}
        onMinimizeToggle={toggleMinimized}
        minimized={minimized}
      />
      <FccTable file={file} minimized={minimized} newDiff={newDiff} />
    </div>
  )
}
