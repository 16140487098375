import { useCallback, useRef, useState } from "react"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { isEllipsisActive } from "@/lib/utils"
import { TreeNode } from "@/pages/crFileChanges/utils"
import { renderIcons } from "@/pages/crFileChanges/components/helpers"

interface Props {
  node: TreeNode
  onToggle: () => void
  onClick: (path: string) => void
  selectedPath: string | null
}

export const NodeComponent = ({ node, onToggle, onClick, selectedPath }: Props) => {
  const [expanded, setExpanded] = useState(true)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const isLast = !node.children
  const tooltipRef = useRef<null | HTMLButtonElement>(null)

  const toggleOpen = useCallback(() => {
    setExpanded((prev) => !prev)
  }, [setExpanded])

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (isEllipsisActive(tooltipRef)) {
        setTooltipOpen(open)
      }
    },
    [tooltipRef]
  )

  const handleClick = useCallback(
    () => (isLast && node.fullPath ? onClick(node.fullPath) : toggleOpen()),
    [onClick, isLast, node, toggleOpen]
  )

  return (
    <section className="max-w-full">
      <div
        className={`
          flex h-7 cursor-pointer items-center gap-2 rounded text-xs text-primary 
          hover:bg-darker-fill 
          ${node.fullPath === selectedPath ? "bg-border" : ""}
        `}
        onClick={handleClick}
      >
        {renderIcons({ isLast, expanded, node })}

        <Tooltip defaultOpen={false} open={tooltipOpen} onOpenChange={handleOpenChange}>
          <TooltipTrigger ref={tooltipRef} className="overflow-hidden text-ellipsis text-nowrap break-keep">
            {node.name}
          </TooltipTrigger>

          <TooltipContent>{node.name}</TooltipContent>
        </Tooltip>
      </div>

      <div className="ml-[7px] border-l-2 border-dotted border-border">
        <div className="ml-[2px]">
          {node.children && expanded
            ? node.children.map((childNode) => (
                <NodeComponent
                  node={childNode}
                  key={childNode.name}
                  onToggle={onToggle}
                  onClick={onClick}
                  selectedPath={selectedPath}
                />
              ))
            : null}
        </div>
      </div>
    </section>
  )
}
