import { useTranslation } from "react-i18next"
import { getChecksWithExtraData, getInsightsData } from "@/components/designSystem/Topics/helpers.ts"
import TopicBadge from "@/components/designSystem/Topics/TopicBadge.tsx"
import { useChangeRequest } from "@/services/store/useChangeRequest.ts"
import { motion } from "framer-motion"
import { useMemo } from "react"
import { PlusPopover } from "@/components/designSystem/Topics/PlusPopover"
import { Topic } from "@/models/Topics"

interface Props {
  minimized: boolean
}

const maxWidthToSliceTopics = 1980

function shouldShowPlusButton(minimized: boolean, topics: Topic[], computedTopics: Topic[]): boolean {
  const windowWidth = window.innerWidth

  return !(!minimized || topics.length <= computedTopics.length || windowWidth >= maxWidthToSliceTopics)
}

export const Topics = ({ minimized }: Props) => {
  const { t } = useTranslation("Topics")
  const { topics } = useChangeRequest()

  const computedTopics = useMemo(() => {
    return minimized && window.innerWidth < maxWidthToSliceTopics ? topics.slice(0, 4) : topics
  }, [topics, minimized])

  return (
    <motion.div
      key="topics"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
      className="flex"
    >
      <div className="mr-2 h-8 cursor-default content-center text-s text-primary">{t("Topics")}</div>

      <div className="mr-2 flex flex-wrap items-center gap-2">
        {[...computedTopics]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((topic) => (
            <div
              key={topic.id}
              className="w-fit break-normal rounded bg-background font-inter text-xxs leading-5 text-primary"
            >
              <TopicBadge
                topic={topic}
                getInsightsData={getInsightsData}
                getChecksWithExtraData={getChecksWithExtraData}
                minimized={minimized}
              />
            </div>
          ))}
      </div>

      {shouldShowPlusButton(minimized, topics, computedTopics) ? (
        <PlusPopover topics={topics} computedTopics={computedTopics} minimized={minimized} />
      ) : null}
    </motion.div>
  )
}
