export const Toolbar = {
  MergeBlockers: "Merge Blockers",
  Topics: "Topics",
  Discussions: "Discussions",
  PickAndEdit: "Pick and edit",
  ScrollToTop: "Scroll to Top",
  Previous: "Previous File",
  Next: "Next File",
  merge: "Merge",
  squash: "Squash & Merge",
  rebase: "Rebase & Merge",
  merged: "Merged",
  closed: "Closed",
  SuccessMerge: "Success: Your change was merged",
  FailedMerge: "Failure: Your merge failed: ",
  SuccessApprove: "Change approved",
  FailedApprove: "Failed to approve this change, try again: ",
  Approve: "Approve Change",
  Approved: "Approved",
  ApprovalsRequired: "Change must be approved before merging",
  ChecksNotSuccessfulTitle: "Some checks were not successful",
  FileConflictsTitle: "This branch has file conflicts that must be resolved",
  UnresolvedDiscussions: "Some discussions are unresolved",
  UnresolvedDiscussionsDescription: "Resolve all discussions to enable merging",
  ShowAll: "Show all",
  ShowLess: "Show less",
  Checks: "Checks",
  usage: "Usage",
  developers: "Developers",
  NoChecks: "No Checks for this change"
}
