import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components"
import { routes } from "@/lib/routes.ts"
import { useDescope } from "@descope/react-sdk"
import { useTranslation } from "react-i18next"
import { NavLink, useLocation } from "react-router-dom"
import { User, Settings2, LogOut } from "lucide-react"
import { SunIcon, MoonIcon } from "@radix-ui/react-icons"
import { datadogRum } from "@datadog/browser-rum"
import { usePermissions } from "@/services/store/usePermissions"
import { useCallback } from "react"
import { runtimeConfig } from "@/lib/config"
import { useSettings } from "@/services/store/useSettings.ts"
import { Organization } from "@/models/User"
import { OrganizationSelect } from "@/pages/root/TopHeader/OrganizationSelect"

interface Props {
  TriggerComponent: JSX.Element
  organizations: Organization[]
}

export const UserDropdownMenu = ({ TriggerComponent, organizations }: Props) => {
  const { logout } = useDescope()
  const { t } = useTranslation("UserDropdown")
  const { setUser } = usePermissions()
  const { setTheme, selectedTheme } = useSettings()
  const location = useLocation()

  const handleLogout = useCallback(() => {
    logout()
    setUser(null)

    if (runtimeConfig.VITE_ENABLE_TRACING === "true") {
      datadogRum.stopSession()
    }
  }, [logout, setUser])

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger>{TriggerComponent}</DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <OrganizationSelect data={organizations} />
        <DropdownMenuSeparator />
        <NavLink to={routes.settings.profile}>
          <DropdownMenuItem className="flex items-center gap-2">
            <User width={16} height={16} /> {t("ProfileItem")}
          </DropdownMenuItem>
        </NavLink>
        <NavLink to={location.pathname} onClick={() => setTheme(selectedTheme === "dark" ? "light" : "dark")}>
          <DropdownMenuItem className="flex items-center gap-2">
            {selectedTheme === "dark" ? <SunIcon className="size-4" /> : <MoonIcon className="size-4" />}
            {selectedTheme === "dark" ? t("LightMode") : t("DarkMode")}
          </DropdownMenuItem>
        </NavLink>
        <NavLink to={routes.settings.integrations({ provider: "github" })}>
          <DropdownMenuItem className="flex items-center gap-2">
            <Settings2 width={16} height={16} />
            {t("Settings")}
          </DropdownMenuItem>
        </NavLink>

        <DropdownMenuSeparator />
        <DropdownMenuItem className="flex items-center gap-2" onSelect={handleLogout}>
          <LogOut width={16} height={16} />
          {t("SignOut")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
