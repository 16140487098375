import { HorizontalRule, Placeholder, StarterKit, TaskItem, TaskList, TiptapImage, TiptapLink } from "novel/extensions"

import Focus from "@tiptap/extension-focus"

import { cx } from "class-variance-authority"
import { emojiExtension } from "@/components/designSystem/Discussions/components/RichEditor/extensions/emojis/EmojiConfiguration.ts"

export const placeholderExtension = (text?: string) =>
  Placeholder.configure({
    emptyNodeClass:
      "first:before:text-gray-400 before:absolute first:before:float-left first:before:content-[attr(data-placeholder)] first:before:pointer-events-none",
    ...(text ? { placeholder: text } : {})
  })

const focus = Focus.configure({
  className: "has-focus",
  mode: "deepest"
})

const tiptapLink = TiptapLink.configure({
  HTMLAttributes: {
    class: cx(
      "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer"
    )
  }
})

const taskList = TaskList.configure({
  HTMLAttributes: {
    class: cx("not-prose pl-2")
  }
})

const taskItem = TaskItem.configure({
  HTMLAttributes: {
    class: cx("flex gap-2 items-start my-4 leading-3")
  },
  nested: true
})

const horizontalRule = HorizontalRule.configure({
  HTMLAttributes: {
    class: cx("mt-4 mb-6 border-t border-muted-foreground")
  }
})

const starterKit = StarterKit.configure({
  bulletList: {
    HTMLAttributes: {
      class: cx("list-disc list-outside leading-3 my-2 ml-2"),
      dir: "ltr"
    }
  },
  orderedList: {
    HTMLAttributes: {
      class: cx("list-decimal list-outside ml-4 leading-4"),
      dir: "ltr"
    }
  },
  listItem: {
    HTMLAttributes: {
      class: cx("leading-normal mb-1")
    }
  },
  blockquote: {
    HTMLAttributes: {
      class: cx("border-l-4 m-1 border-secondary p-2 text-secondary")
    }
  },
  codeBlock: {
    HTMLAttributes: {
      class: cx("rounded-sm bg-darker-fill border p-5 font-mono font-medium")
    }
  },
  code: {
    HTMLAttributes: {
      class: cx("rounded-md bg-darker-fill px-1.5 py-1 font-mono font-medium"),
      spellcheck: "false"
    }
  },
  horizontalRule: false,
  dropcursor: {
    color: "#DBEAFE",
    width: 4
  },
  gapcursor: false
})

export const defaultExtensions = [
  starterKit,
  tiptapLink,
  TiptapImage,
  taskList,
  taskItem,
  horizontalRule,
  focus,
  emojiExtension
]
