import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger
} from "@/components"
import { useChangeOrganization } from "@/hooks/mutations/useChangeOrganization"
import { GetActiveOrganizationArgs, Organization } from "@/models/User"
import { useUser } from "@descope/react-sdk"
import { Building2, Check } from "lucide-react"
import { cn } from "@/lib/utils"

interface Props {
  data: Organization[]
}

const getActiveTenant = ({ customAttributes, data }: GetActiveOrganizationArgs): Organization | undefined => {
  if (customAttributes && customAttributes.activeTenant) {
    return data.find((org) => org.id === customAttributes.activeTenant)
  }
  if (data?.length > 0) {
    return data[0]
  }
}

export const OrganizationSelect = ({ data }: Props) => {
  const { user } = useUser()
  const { mutateAsync } = useChangeOrganization()
  const activeTenant = getActiveTenant({ customAttributes: user.customAttributes, data })

  if (data.length === 1) {
    return (
      <DropdownMenuLabel className="flex items-center gap-2 px-2 py-1.5 text-sm font-normal text-primary">
        <Building2 className="size-4" />
        {activeTenant?.name}
      </DropdownMenuLabel>
    )
  }

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger className="flex items-center gap-2">
        <Building2 className="size-4" />
        {activeTenant?.name}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {data?.map((tenant) => (
            <DropdownMenuItem
              key={tenant.id}
              onSelect={() => mutateAsync(tenant.id)}
              className={cn("flex items-center", activeTenant?.id === tenant.id && "bg-fill")}
            >
              <div className="w-6">
                {activeTenant?.id === tenant.id ? (
                  <Check className="size-4" />
                ) : (
                  <span className="inline-block size-4" />
                )}
              </div>
              {tenant.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}
