import { TableHead, TableHeader as BaseTableHeader, TableRow } from "@/components"
import { calculateHeaderSize, setCellTextAlignment } from "../utils"
import { Table, flexRender } from "@tanstack/react-table"
import { FccRow } from "@/models/FCC"

interface Props {
  table: Table<FccRow>
}
const TableHeader = ({ table }: Props) => {
  return (
    <BaseTableHeader>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const headerSize = header.getSize()
            const width: string = calculateHeaderSize(headerSize)
            const textPlacement = setCellTextAlignment(header.id)

            return (
              <TableHead className={`h-7 px-2 ${textPlacement}`} style={{ width }} key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHead>
            )
          })}
        </TableRow>
      ))}
    </BaseTableHeader>
  )
}

export default TableHeader
