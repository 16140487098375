import { CodeChangeBadge } from "@/components"
import { AggregatedEdits, LanguageDiffChange } from "@/models/Changes"

interface Props {
  aggregated_edits: AggregatedEdits
  lines_added: number
  lines_deleted: number
}

export const LanguagesChanges = ({ aggregated_edits, lines_added, lines_deleted }: Props) => {
  if (aggregated_edits) {
    return Object.values(aggregated_edits).map((language: LanguageDiffChange) => {
      return Object.keys(language).map((key) => {
        const { lines_added, lines_deleted } = language[key]

        return <CodeChangeBadge key={key} addition={lines_added} deletion={lines_deleted} language={key} />
      })
    })
  }
  return <CodeChangeBadge addition={lines_added} deletion={lines_deleted} language="Unknown" />
}
