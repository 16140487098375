import { errorHandler } from "@/services/api/helpers"
import { Filters } from "@/components"
import { authorsFilter, repositoriesFilter, statusFilter } from "@/components/designSystem/Filters/createFilters"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { useFetchAuthors } from "@/hooks/queries/useFetchAuthors"
import { useFetchRepositories } from "@/hooks/queries/useFetchRepositories"
import { useMemo } from "react"

interface Props {
  count: number
}

export const ChangeFilters = ({ count }: Props) => {
  const { data: authors, error: authorsError, isLoading: authorsLoading } = useFetchAuthors()

  const { data: repositories, error: repositoriesError, isLoading: repositoriesLoading } = useFetchRepositories()

  const filters = useMemo(
    () => [repositoriesFilter({ data: repositories }), statusFilter({}), authorsFilter({ data: authors })],
    [authors, repositories]
  )

  const isLoading = authorsLoading || repositoriesLoading
  const error = authorsError || repositoriesError

  if (isLoading) {
    return <SpinnerLoader className="py-4" />
  }

  if (error) {
    return <p>{errorHandler(error)}</p>
  }

  return (
    <Filters
      filters={filters}
      resultsName="Changes"
      resultsCount={count}
      isPaginated
      localStorageKey="changesFilters"
    />
  )
}
