import { Avatar } from "@/components"
import { Comment, Discussion } from "@/models/Discussions"
import { CommentCreationData } from "../Comment/CommentCreationData"
import { ResolveController } from "../Comment/ResolveController"
import { MouseEvent, useCallback, useState } from "react"
import { toast } from "@/hooks/useToast"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { useDeleteComment } from "@/hooks/mutations/useDeleteComment"
import { SuggestionView } from "./SuggestionView"
import { useDiscussions } from "@/services/store/useDiscussions"
import { RenderComment } from "@/components/designSystem/Discussions/components/Comment/RenderComment"
import { EditComment } from "@/components/designSystem/Discussions/components/Comment/EditComment"
import { useForm } from "react-hook-form"

interface SuggestionProps {
  comment: Comment
  onCommentClick?: () => void
  isClickable: boolean
  current: Discussion | null
  commitSha?: string
  CRId?: string
  resolvable?: boolean
  handleResolveToggle?: (resolvedState: boolean) => void
  isResolved?: boolean
}

export const Suggestion = ({
  comment,
  current,
  isClickable,
  handleResolveToggle,
  isResolved,
  onCommentClick,
  resolvable
}: SuggestionProps) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const { mutateAsync } = useDeleteComment()
  const { toggleManualRecalculationTrigger } = useDiscussions()

  const form = useForm({
    defaultValues: {
      body: comment.comment_body
    }
  })

  const handleToggleMenu = useCallback(
    (event: MouseEvent<Element> | CustomEvent<{ originalEvent: PointerEvent }>) => {
      event.stopPropagation()

      if (!comment.editable) {
        return
      }
      setDropDownOpen((prev) => !prev)
    },
    [setDropDownOpen, comment.editable]
  )

  const handleDelete = useCallback(
    async (event: MouseEvent<Element>) => {
      event.stopPropagation()
      try {
        await mutateAsync({ commentId: comment.id })
      } catch (err) {
        toast({
          variant: "destructive",
          description: ghWriteErrorHandler(err)
        })
      } finally {
        handleToggleMenu(event)
      }
    },
    [mutateAsync, comment.id, handleToggleMenu]
  )

  const handleEditClick = useCallback(
    (event: MouseEvent<Element>) => {
      handleToggleMenu(event)
      setIsEditMode(true)
      toggleManualRecalculationTrigger()
    },
    [handleToggleMenu, setIsEditMode, toggleManualRecalculationTrigger]
  )

  return (
    <div className="my-4 flex w-full flex-col text-xs">
      <div className="flex">
        <Avatar
          username={comment.author_user?.display_name || comment.author}
          avatarURL={comment.author_user?.avatar_url}
          className="mb-1 mr-4 size-8 cursor-default text-sm"
        />
        <div className="flex flex-col break-words">
          <CommentCreationData
            comment={comment}
            onCommentClick={onCommentClick}
            isClickable={isClickable}
            dropDownOpen={dropDownOpen}
            handleDelete={handleDelete}
            handleEditClick={handleEditClick}
            handleToggleMenu={handleToggleMenu}
          />
          <div className="w-full text-primary">
            {isEditMode && comment.comment_body !== "" ? (
              <EditComment comment={comment} form={form} setEditMode={setIsEditMode} />
            ) : (
              <RenderComment comment={comment} isFullComment={false} isClickable={false} current={null} />
            )}

            <SuggestionView
              discussion={current}
              comment={comment}
              isEditMode={isEditMode}
              setEditMode={setIsEditMode}
            />
          </div>
        </div>
      </div>

      {resolvable && current ? (
        <ResolveController discussion={current} handleResolveToggle={handleResolveToggle} isResolved={isResolved} />
      ) : null}
    </div>
  )
}
