import { createColumnHelper } from "@tanstack/react-table"
import { FccRow } from "@/models/FCC"
import { CodeCell } from "../Cells/CodeCell"
import { NumberCell } from "../Cells/NumberCell"
import { ExpandAllCell } from "../Cells/ExpandAllCell"

const columnHelper = createColumnHelper<FccRow>()

export const unifiedViewColumns = [
  columnHelper.accessor((row) => row, {
    id: "left-expander",
    header: (info) => <ExpandAllCell info={info} />,
    cell: (info) => <NumberCell columnSide="left" isSplitView={false} info={info} />,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "right-expander",
    header: () => <></>,
    cell: (info) => <NumberCell columnSide="right" isSplitView={false} info={info} />,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "code",
    header: () => <span />,
    cell: (info) => <CodeCell columnSide="right" tableSide="right" isSplitView={false} info={info} />
  })
]

export const addedViewColumns = [
  columnHelper.accessor((row) => row, {
    id: "right-expander",
    header: () => <></>,
    cell: (info) => <NumberCell columnSide="right" isSplitView={false} info={info} />,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "code",
    header: () => <span />,
    cell: (info) => <CodeCell columnSide="right" tableSide="right" isSplitView={false} info={info} />
  })
]

export const deletedViewColumns = [
  columnHelper.accessor((row) => row, {
    id: "left-expander",
    header: (info) => <ExpandAllCell info={info} />,
    cell: (info) => <NumberCell columnSide="left" isSplitView={false} info={info} />,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "code",
    header: () => <span />,
    cell: (info) => <CodeCell columnSide="right" tableSide="right" isSplitView={false} info={info} />
  })
]

export const splitViewColumns = [
  columnHelper.accessor((row) => row, {
    id: "left-expander",
    header: (info) => <ExpandAllCell info={info} />,
    cell: (info) => <NumberCell columnSide="left" isSplitView info={info} />,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "left-code",
    header: () => <span />,
    cell: (info) => <CodeCell columnSide="left" tableSide="left" isSplitView info={info} />
  }),
  columnHelper.accessor((row) => row, {
    id: "right-expander",
    header: () => <span />,
    cell: (info) => <NumberCell columnSide="right" isSplitView info={info} />,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "right-code",
    header: () => <span />,
    cell: (info) => <CodeCell columnSide="right" tableSide="right" isSplitView info={info} />
  })
]
