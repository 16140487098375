export const CreateCR = {
  SuccessMessage: "Successfully opened a CR",
  NewChange: "New Change",
  SelectBranches: "Choose two branches to see what’s changed or to start a new change request.",
  CRState: {
    WantsToMerge: "wants to merge",
    Into: "into",
    Commits: " commits"
  },
  CreateChange: "Create Change",
  OpenDraft: "Create Draft",
  OpenCR: "Create Change Request",
  WriteCRName: "Enter a Title for your change",
  Title: "Title",
  MergeFrom: "Merge from",
  MergeInto: "Merge into",
  ChangeExists: "Change already exists...",
  AlreadyOpenBranch: "There is already an open change for that branch, review or continue working on existing change."
}
