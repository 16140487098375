function APIAddressSVG({
  color = "var(--base-primary)",
  className: className
}: {
  color?: string
  className?: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 8a.667.667 0 100-1.334A.667.667 0 008 8z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 8a.667.667 0 100-1.334A.667.667 0 008 8z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.333 11.333a.667.667 0 111.334 0c0 .333-.227 2-.334 3a.333.333 0 11-.666 0c-.107-1-.334-2.667-.334-3z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.333 11.333a.667.667 0 111.334 0c0 .333-.227 2-.334 3a.333.333 0 11-.666 0c-.107-1-.334-2.667-.334-3z"
      ></path>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M5.333 9.333a3.333 3.333 0 115.334 0"></path>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M5.333 9.333a3.333 3.333 0 115.334 0"></path>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M11.333 12.333a6 6 0 10-6.666 0"></path>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M11.333 12.333a6 6 0 10-6.666 0"></path>
    </svg>
  )
}

export default APIAddressSVG
