import { Button, Form, FormControl, FormField, FormItem, FormMessage, Textarea } from "@/components"
import { Comment } from "@/models/Discussions"
import { useCallback } from "react"
import { useEditComment } from "@/hooks/mutations/useEditComment"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDiscussions } from "@/services/store/useDiscussions"

interface Props {
  comment: Comment
  form: UseFormReturn<{ body: string }>
  setEditMode: (value: boolean) => void
}

export const EditSuggestion = ({ comment, form, setEditMode }: Props) => {
  const { editCommentMutation } = useEditComment()
  const { t } = useTranslation("Common")
  const { toggleManualRecalculationTrigger } = useDiscussions()

  const handleCloseEdit = useCallback(() => {
    setEditMode(false)
    toggleManualRecalculationTrigger()
  }, [setEditMode, toggleManualRecalculationTrigger])

  const { setError, handleSubmit, control } = form

  const onSubmit = useCallback(
    async (values: { body: string }) => {
      try {
        await editCommentMutation({
          body: values.body,
          commentId: comment.id,
          body_content_type: "html"
        })
        handleCloseEdit()
      } catch (err) {
        setError("body", { message: ghWriteErrorHandler(err) })
      }
    },
    [editCommentMutation, setError, comment.id, handleCloseEdit]
  )

  return (
    <Form {...form}>
      <form id="edit-comment-form" className="w-full rounded-lg bg-background p-1" onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="body"
          control={control}
          render={({ field }) => (
            <FormItem className="mb-3 items-baseline">
              <FormControl>
                <Textarea rows={6} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex gap-2 py-2">
          <Button type="submit" variant="default" className="h-4 px-2 py-4 text-xs">
            {t("Save")}
          </Button>
          <Button onClick={handleCloseEdit} type="button" variant="outline" className="h-4 px-2 py-4 text-xs">
            {t("Cancel")}
          </Button>
        </div>
      </form>
    </Form>
  )
}
