import BazIconLight from "@/assets/icons/BazAvatar-light.svg?react"
import BazIconDark from "@/assets/icons/BazAvatar-dark.svg?react"
import { Button, SingleSelect } from "@/components"
import { TriggerElementProps } from "@/components/designSystem/SelectVariations/types"
import { Repository } from "@/models/Repository"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSettings } from "@/services/store/useSettings.ts"

const RepositorySelectorTriggerElement = ({ label, selectItemPlaceholder }: TriggerElementProps) => {
  const { selectedTheme } = useSettings()
  const BazIcon = selectedTheme === "light" ? BazIconLight : BazIconDark
  return (
    <div className="mt-1 flex cursor-pointer items-center gap-2" role="button">
      <Button className="size-8" size="icon" variant="outline">
        {label ? <BazIcon width={16} height={16} /> : ""}
      </Button>

      <p className="overflow-x-hidden text-ellipsis text-nowrap text-xs font-semibold text-primary">
        {label || selectItemPlaceholder}
      </p>
    </div>
  )
}

interface Props {
  repositories: Repository[]
}

export const RepositorySelector = ({ repositories }: Props) => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const { selectedRepositoryId, setSelectedRepositoryId, setFromBranch, setIntoBranch, setSelectedRepositoryName } =
    useCreateCR()

  const { t } = useTranslation("Code")

  const extractOptions = useMemo(() => {
    return repositories.map((repo) => ({
      value: repo.id,
      name: repo.fullName
    }))
  }, [repositories])

  const handleSelect = (value: string) => {
    const repo = repositories.find((repo) => repo.id === value)
    setFromBranch("")
    setIntoBranch("")
    setSelectedRepositoryId(value)
    setSelectedRepositoryName(repo?.fullName || "")
    setOpen(false)
  }

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [setOpen])

  return (
    <SingleSelect
      isOpen={open}
      onClick={handleSelect}
      openToggle={handleToggle}
      optionalParams={{
        CustomTriggerElement: RepositorySelectorTriggerElement,
        withSearch: true,
        selectItemPlaceholder: t("SearchRepository")
      }}
      options={extractOptions}
      searchValue={searchValue}
      selectedValue={selectedRepositoryId}
      setSearchValue={setSearchValue}
    />
  )
}
