import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"

interface Args {
  commentId: string
}

export const useDeleteComment = () => {
  const queryClient = useQueryClient()

  const deleteComment = useCallback(
    async ({ commentId }: Args) => await axiosClient.delete(apiRoutes.editComments(commentId)),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["deleteComment"],
    mutationFn: deleteComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["change"] })
      queryClient.invalidateQueries({ queryKey: ["discussions"] })
    }
  })

  return { data, mutateAsync, status }
}
