import { CellContext } from "@tanstack/react-table"
import { FccRow, Side } from "@/models/FCC"
import { extractCellNumber, generateCellBackgroundColor, isEmptyContent, isLine, isSubRow } from "../utils"
import { useMemo } from "react"
import { isCellMarked } from "../utils/codeCellComputations"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useBazzy } from "@/services/store/useBazzy"

interface Props {
  info: CellContext<FccRow, FccRow>
  columnSide: Side
  isSplitView: boolean
}

export const NumberCell = ({ info, columnSide, isSplitView }: Props) => {
  const line = info.getValue()
  const { currentContext, isOpen } = useBazzy()
  let cellNumber: number | null = null

  const { currentHoveredDiscussion, currentOpenedDiscussion } = useDiscussions()

  const marked = isCellMarked({
    discussion: currentOpenedDiscussion || currentHoveredDiscussion,
    bazzyContext: currentContext,
    isBazzyOpen: isOpen,
    columnSide,
    filePath: info.table.options.meta?.filePath,
    info,
    isSplitView
  })
  const subRow = isSubRow(line)

  const backgroundColor = useMemo(
    () => generateCellBackgroundColor(info.row, isSplitView, columnSide, marked, subRow),
    [info.row, isSplitView, columnSide, marked, subRow]
  )

  if (isLine(line) && !isEmptyContent(isSplitView, line.line_type, columnSide)) {
    cellNumber = extractCellNumber(line, isSplitView, columnSide)
  }

  if (columnSide === "right") {
    cellNumber = line.new_line_number
  }

  return (
    <div className={`table size-full items-center text-center text-[12px] ${backgroundColor} select-none`}>
      {cellNumber}
    </div>
  )
}
