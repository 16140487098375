import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { Reviewer } from "@/models/Changes"

interface Args {
  changeId: string
}

export const useFetchEligibleReviewers = ({ changeId }: Args) => {
  const { error, isLoading, data } = useQuery<Reviewer[], undefined>({
    queryKey: ["eligible-reviewers", changeId],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.changes.eligibleReviewers.get(changeId), {
        params: { changeId }
      })
      return response.data.reviewers
    }
  })

  return { error, isLoading, data }
}
