import {
  Button,
  Card,
  CardDescription,
  CardHeader,
  TabsContent,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from "@/components"
import { useTranslation } from "react-i18next"
import { LogError } from "@/models/Insight.ts"
import RedX from "@/assets/icons/RedX.svg?react"
import { ClipboardList, Sparkles } from "lucide-react"
import { relativeTime } from "@/lib/dateFormats.ts"
import { useCallback, useRef, useState } from "react"
import { useBazzyMessages } from "@/hooks/useBazzyMessages"
import { BazzyErrorType, BazzyInsightOptions, BazzyQuestionsCategories } from "@/models/Bazzy"
import { scrollToLineOrBody } from "@/components/designSystem/Topics/helpers"
import { Table } from "@tanstack/react-table"
import { FccRow, Side } from "@/models/FCC"
import { FileCodeLine } from "@/components/designSystem/FC/types"

interface Props {
  logErrors: LogError[]
  table: Table<FccRow> | Table<FileCodeLine>
  side: Side
  lineNumber: number | null
}

export const ChecksTabContent = ({ logErrors, table, lineNumber, side }: Props) => {
  const { t } = useTranslation("Insight")

  const [hoveredBreakingChangeIndex, setHoveredBreakingChangeIndex] = useState<number | null>(null)
  const { addUserQuestionToBazzy } = useBazzyMessages()

  const [tooltipStates, setTooltipStates] = useState<Record<string, boolean>>(() =>
    Object.fromEntries(
      logErrors.flatMap(({ id }) => [
        [`title-${id}`, false],
        [`error-${id}`, false]
      ])
    )
  )

  const jobNameRefs = useRef<Record<string, HTMLParagraphElement | null>>({})
  const errorDescriptionRefs = useRef<Record<string, HTMLParagraphElement | null>>({})

  const handleTooltipOpen = useCallback((errorId: string, isOpen: boolean) => {
    setTooltipStates((prev) => ({
      ...prev,
      [errorId]: isOpen
    }))
  }, [])

  const checkTruncation = useCallback((element: HTMLElement | null) => {
    if (element) {
      return element.scrollWidth > element.clientWidth
    }
    return false
  }, [])

  const handleOpenBazzy = useCallback(
    (error: LogError) => {
      const { err_description } = error

      const bazzyContext = {
        filePath: table.options.meta?.filePath || null,
        lines: {
          startLine: lineNumber || null,
          endLine: lineNumber || null
        },
        side: side || "right",
        type: "insight" as const,
        relevantInsight: err_description,
        insightType: BazzyErrorType.LOG_ERROR
      }

      addUserQuestionToBazzy(
        BazzyInsightOptions.EXPLAIN,
        t("Explain"),
        BazzyQuestionsCategories.INSIGHT,
        err_description,
        bazzyContext,
        BazzyErrorType.LOG_ERROR
      )
      requestAnimationFrame(() => {
        scrollToLineOrBody(side || "right", lineNumber)
      })
    },
    [addUserQuestionToBazzy, lineNumber, side, t, table]
  )

  return (
    <TabsContent
      value="checks"
      className="max-h-80 overflow-auto rounded-md border shadow-md shadow-border focus:outline-none dark:shadow-background"
    >
      <Card>
        {logErrors.map((error, index) => (
          <CardHeader key={error.id} className="space-y-0 border-b p-0">
            <CardDescription className="border-b border-border p-2 text-xxs text-primary">
              <Tooltip open={tooltipStates[`title-${error.id}`]}>
                <TooltipTrigger
                  className="flex w-full items-center"
                  onMouseEnter={() => {
                    if (checkTruncation(jobNameRefs.current[error.id])) {
                      handleTooltipOpen(`title-${error.id}`, true)
                    }
                  }}
                  onMouseLeave={() => handleTooltipOpen(`title-${error.id}`, false)}
                  disabled={!checkTruncation(jobNameRefs.current[error.id])}
                >
                  <ClipboardList width={16} className="text-primary" />
                  <p
                    ref={(jobNameElement) => (jobNameRefs.current[error.id] = jobNameElement)}
                    className="ml-2 truncate font-menlo"
                  >
                    {error.scm_provider_details.job_name}
                  </p>
                  <p className="ml-2 text-secondary">{relativeTime(error.created_at)}</p>
                </TooltipTrigger>
                <TooltipContent className="max-w-[400px]" align="start">
                  <p className="ml-2">{error.scm_provider_details.job_name}</p>
                </TooltipContent>
              </Tooltip>
            </CardDescription>
            <CardDescription
              className="group relative flex cursor-default items-center p-2 text-xxs text-primary"
              onMouseEnter={() => setHoveredBreakingChangeIndex(index)}
              onMouseLeave={() => setHoveredBreakingChangeIndex(null)}
            >
              <RedX className="shrink-0" />
              <p className="mx-2 shrink-0">{t("Error")}: </p>
              <Tooltip open={tooltipStates[`error-${error.id}`]}>
                <TooltipTrigger
                  className="flex w-full cursor-default items-center"
                  onMouseEnter={() => {
                    if (checkTruncation(errorDescriptionRefs.current[error.id])) {
                      handleTooltipOpen(`error-${error.id}`, true)
                    }
                  }}
                  onMouseLeave={() => handleTooltipOpen(`error-${error.id}`, false)}
                  disabled={!checkTruncation(errorDescriptionRefs.current[error.id])}
                >
                  <p
                    ref={(errorDescriptionElement) =>
                      (errorDescriptionRefs.current[error.id] = errorDescriptionElement)
                    }
                    className="max-w-dialog truncate"
                  >
                    {error.err_description}
                  </p>
                </TooltipTrigger>
                <TooltipContent className="max-w-[400px]" align="start">
                  <p>
                    {t("Error")}: {error.err_description}
                  </p>
                </TooltipContent>
              </Tooltip>
              {hoveredBreakingChangeIndex === index && (
                <Button
                  className="absolute right-2 top-1/2 ml-2 h-5 -translate-y-1/2 px-2 text-xs font-normal"
                  onClick={() => {
                    handleOpenBazzy(error)
                  }}
                >
                  <Sparkles width={14} height={24} className="mr-1" />
                  {t("AskBazzy")}
                </Button>
              )}
            </CardDescription>
          </CardHeader>
        ))}
      </Card>
    </TabsContent>
  )
}
