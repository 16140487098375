import { ClipboardCopy } from "lucide-react"
import { CopyToClipboard } from "@/components"
import { ContentMetadata } from "@/models/FC"
import { CommitHistory } from "@/components"

interface Props {
  contentMetadata: ContentMetadata
}

export const FileHeader = ({ contentMetadata }: Props) => {
  return (
    <header className="flex h-[72px] items-center justify-between">
      <div className="flex items-center">
        <span className="mr-2 text-sm">{contentMetadata.file_path}</span>
        <CopyToClipboard text={contentMetadata.file_path}>
          <ClipboardCopy width={16} height={16} className="cursor-pointer text-primary" />
        </CopyToClipboard>
      </div>
      <CommitHistory commits={contentMetadata.commits} />
    </header>
  )
}
