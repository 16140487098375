import { Badge, Button } from "@/components"
import { Download, Rocket } from "lucide-react"
import { useFetchSubscription } from "@/hooks/queries/useFetchSubscription.tsx"
import { useTranslation } from "react-i18next"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader.tsx"
import { useFetchUser } from "@/hooks/queries/useFetchUser.tsx"
import { TrialProgress } from "@/components/designSystem/TrialProgress"
import { useExportUsers } from "@/hooks/mutations/useExportUsers.tsx"
import DeleteAccountDialog from "@/pages/settings/DeleteAccountDialog.tsx"

export function Account() {
  const { data: subscriptionData, isLoading: isSubscriptionLoading } = useFetchSubscription()
  const { data: backendUser, isLoading } = useFetchUser()
  const { mutate: exportUsers, isPending: isExporting } = useExportUsers()
  const { t } = useTranslation("Subscription")
  document.title = "Settings - Account"

  const daysLeft = subscriptionData?.trialRemainingDays || 0
  const isInTrial = subscriptionData?.status === "trialing"

  const handleClick = () => {
    window.open(subscriptionData?.customerPortalUrl, "_blank")
  }

  return (
    <div>
      {isSubscriptionLoading || isLoading ? (
        <div>
          <SpinnerLoader />
        </div>
      ) : (
        <section>
          <h1 className="pb-4 text-base font-bold text-primary">{t("AccountSettings")}</h1>
          <div className="flex justify-between rounded-lg border border-solid  border-border p-6">
            {isInTrial && (
              <>
                <div className="flex w-full grow flex-col">
                  <h3 className="text-s font-medium text-primary">{t("RemainingDays")}</h3>
                  <div className="py-4 text-3xl font-semibold text-primary">{daysLeft}</div>
                  <TrialProgress className="h-2" trialRemainingDays={daysLeft} />
                </div>
                <div className="m-4 mx-12 w-px bg-border"></div>
              </>
            )}
            <div className="flex w-full grow flex-col">
              <h3 className="text-s font-medium text-primary">{t("NumberOfContributors")}</h3>
              <div className="py-4 text-3xl font-semibold text-primary">{subscriptionData?.quantity}</div>
              <button
                onClick={() => exportUsers()}
                className="flex cursor-pointer items-center text-secondary hover:text-primary disabled:cursor-not-allowed disabled:opacity-50"
              >
                <Download className="mr-1 size-4" />
                <span>{isExporting ? "Downloading..." : "Download the list of contributors"}</span>
              </button>
            </div>
          </div>
          {backendUser.is_owner && isInTrial && (
            <div className="mt-3 flex grow flex-col rounded-lg border  border-solid border-border p-6">
              <h3 className="text-s font-medium text-primary">{t("Account")}</h3>
              <div className="pb-9 text-xs font-normal text-secondary">{t("UpgradeAction")}</div>
              <div className="flex justify-between">
                <Button type="button" variant="purple" className="h-4 w-fit px-2 py-4 text-xs" onClick={handleClick}>
                  <Rocket className="size-4"></Rocket>
                  <span className="ml-2">{t("Upgrade")}</span>
                </Button>
                <DeleteAccountDialog />
              </div>
            </div>
          )}
          {backendUser.is_owner && !isInTrial && (
            <div className="mt-3 flex grow flex-col rounded-lg border  border-solid border-border p-6">
              <div className="mb-14 flex flex-row justify-between">
                <h3 className="text-s font-medium text-primary">{t("Account")}</h3>
                <Badge variant="purple" className="hover:bg-purple-light">
                  <Rocket className="size-4" />
                  <span className="ml-2">{t("Upgraded")}</span>
                </Badge>
              </div>
              <div className="flex flex-row justify-between">
                <Button type="button" variant="outline" className="h-4 w-fit px-2 py-4 text-xs" onClick={handleClick}>
                  <span>{t("Payment")}</span>
                </Button>
                <DeleteAccountDialog />
              </div>
            </div>
          )}
        </section>
      )}
    </div>
  )
}
