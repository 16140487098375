import { useFetchIntegrations } from "@/hooks/queries/useFetchIntegrations.tsx"
import { useFetchSlackDetails } from "@/hooks/queries/useFetchSlackDetails.tsx"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useToast } from "@/hooks/useToast.ts"
import { useNavigate } from "react-router-dom"
import { routes } from "@/lib/routes.ts"
import { ToastAction } from "@/components/ui/Toast.tsx"
import { cn } from "@/lib/utils.ts"
import { LocalStorageService } from "@/lib/localStorageService.ts"

const SLACK_LOCAL_STORAGE_KEY = "slackToasterDismissed"
const LOCAL_STORAGE_EXPIRATION = 7 * 24 * 60 * 60 * 1000
const localStorageService = new LocalStorageService("localStorage")

export const CheckSlackConnection = () => {
  const { t } = useTranslation("ConnectSlack")
  const { toast, dismiss } = useToast()
  const navigate = useNavigate()
  const { data: integrationsData } = useFetchIntegrations("slack")
  const { data: slackDetailsData, isLoading } = useFetchSlackDetails()

  const expiration = localStorage.getItem(SLACK_LOCAL_STORAGE_KEY)
  useEffect(() => {
    if (expiration) {
      const currentDate = new Date().getTime()
      if (currentDate <= Number(expiration)) {
        return
      }
    }
    localStorage.removeItem(SLACK_LOCAL_STORAGE_KEY)
    if (integrationsData && integrationsData.integrations?.length > 0 && !isLoading && !slackDetailsData) {
      localStorageService.setItem(SLACK_LOCAL_STORAGE_KEY, new Date().getTime() + LOCAL_STORAGE_EXPIRATION)
      toast({
        variant: "default",
        description: t("Action"),
        duration: 600000,
        action: (
          <ToastAction
            className={cn("w-36")}
            onClick={() => {
              navigate({ pathname: routes.slack })
              dismiss()
            }}
            altText={"connect"}
          >
            Connect
          </ToastAction>
        )
      })
    }
    // eslint-disable-next-line
  }, [integrationsData, slackDetailsData, toast, t, navigate, expiration, isLoading])
  return <></>
}
