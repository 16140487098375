import { ChangeRequest } from "@/models/Changes.ts"
import { ReviewersSection } from "src/pages/crFileChanges/components/CRHeader/ReviewersSection"
import { SubHeaderMetaData } from "@/pages/crFileChanges/components/CRHeader/CRSubHeader/SubHeaderMetaData.tsx"
import { motion } from "framer-motion"

interface Props {
  changeRequest: ChangeRequest
  minimalHeader: boolean
  foldersTreeOpen: boolean
}

export const CRSubHeader = ({ changeRequest, minimalHeader, foldersTreeOpen }: Props) => {
  return (
    <motion.div
      key="cr-subheader"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
      className="flex w-full items-center justify-between text-primary"
      data-id="sub-header"
    >
      <SubHeaderMetaData
        changeRequest={changeRequest}
        minimalHeader={minimalHeader}
        foldersTreeOpen={foldersTreeOpen}
      />
      <ReviewersSection changeRequest={changeRequest} />
    </motion.div>
  )
}
