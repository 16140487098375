import { useToast } from "@/hooks/useToast"
import { Descope, refresh } from "@descope/react-sdk"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useClearOAuthCache } from "@/hooks/mutations/useClearOAuthCache.tsx"
import { useQueryClient } from "@tanstack/react-query"

export function Privilege() {
  const { clearOAuthCacheMutation } = useClearOAuthCache()
  const navigate = useNavigate()
  const { toast } = useToast()
  const { t } = useTranslation("WriteAccessDialog")
  const queryClient = useQueryClient()

  const handleError = useCallback(() => {
    navigate("/")
    toast({ variant: "destructive", description: t("ErrorInPermission") })
  }, [navigate, toast, t])

  const handleSuccess = useCallback(
    async (event: { detail: { refreshJwt?: string } }) => {
      if (event.detail.refreshJwt) {
        await refresh(event.detail.refreshJwt)
        await clearOAuthCacheMutation()
        queryClient.invalidateQueries({ queryKey: ["user"] })
        navigate("/")
        toast({ variant: "default", description: t("SuccessfullyGranted") })
      } else {
        handleError()
      }
    },
    [navigate, toast, t, handleError, clearOAuthCacheMutation, queryClient]
  )

  return (
    <div>
      <Descope flowId={"privilege"} onSuccess={handleSuccess} onError={handleError} />
    </div>
  )
}
