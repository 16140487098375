import Emoji, { emojis } from "@tiptap-pro/extension-emoji"
import { ReactRenderer } from "@tiptap/react"
import tippy, { Instance as TippyInstance, Props } from "tippy.js"
import { SuggestionProps } from "@tiptap/suggestion"
import { EmojiList } from "@/components/designSystem/Discussions/components/RichEditor/extensions/emojis/EmojiList"
import { isListRef } from "@/components/designSystem/Discussions/components/RichEditor/extensions/base"

export interface EmojiText {
  emoji: string
  name: string
  isUnselectable?: boolean
}

const itemsToRender = async (query: string): Promise<EmojiText[]> => {
  return emojis
    .sort((a, b) => (a.name === "+1" ? -1 : a.name.localeCompare(b.name)))
    .filter((item) => item.name.toLowerCase().startsWith(query.toLowerCase()))
    .map((emoji) => ({ emoji: emoji.emoji || emoji.name, name: `:${emoji.name}:` }) as EmojiText)
    .concat(emojis.length === 0 ? [{ emoji: "No matching emoji", name: "", isUnselectable: true }] : [])
}

export const emojiExtension = Emoji.configure({
  enableEmoticons: true,
  HTMLAttributes: {
    class: "text-link px-1"
  },
  suggestion: {
    char: ":",
    command: ({ editor, range, props }) => {
      editor.chain().deleteRange(range).insertContent(props.id).run()
    },
    items: ({ query }: { query: string }) => itemsToRender(query),
    render: () => {
      let reactRenderer: ReactRenderer
      let popup: TippyInstance | null = null

      return {
        onStart: (props: SuggestionProps) => {
          reactRenderer = new ReactRenderer(EmojiList, {
            props: {
              ...props,
              onClose: () => {
                if (popup) {
                  popup.hide()
                }
              }
            },
            editor: props.editor
          })

          const tippyClientRect: Props["getReferenceClientRect"] = () => {
            const rect = props.clientRect?.()
            if (rect) {
              return rect
            }
            return new DOMRect(0, 0, 0, 0)
          }

          popup = tippy(document.body, {
            getReferenceClientRect: tippyClientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            onShow: () => {
              setTimeout(() => {
                if (isListRef(reactRenderer.ref)) {
                  reactRenderer.ref.focus()
                }
              }, 0)
            }
          })
        },

        onUpdate(props: SuggestionProps) {
          reactRenderer.updateProps({
            ...props,
            onClose: () => {
              if (popup) {
                popup.hide()
              }
            }
          })

          const tippyClientRect: Props["getReferenceClientRect"] = () => {
            const rect = props.clientRect?.()
            if (rect) {
              return rect
            }
            return new DOMRect(0, 0, 0, 0)
          }

          popup?.setProps({
            getReferenceClientRect: tippyClientRect
          })
        },

        onKeyDown: (props: { event: KeyboardEvent }) => {
          if (props.event.key === "Escape") {
            if (popup) {
              popup.hide()
            }
            return true
          }

          if (isListRef(reactRenderer.ref)) {
            return reactRenderer.ref.onKeyDown({ key: props.event.key })
          }

          return false
        },

        onExit() {
          if (popup) {
            popup.destroy()
            popup = null
          }
          reactRenderer.destroy()
        }
      }
    }
  }
})
