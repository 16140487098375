import { useRef, useMemo } from "react"
import { Minimize, Sparkles } from "lucide-react"
import {
  Badge,
  Button,
  DialogDescription,
  DialogTitle,
  MinimalSheet,
  MinimalSheetContent,
  Separator,
  Textarea
} from "@/components"
import { useTranslation } from "react-i18next"
import { useBazzyMessages } from "@/hooks/useBazzyMessages"
import { BazzyCodeOptions, BazzyInsightOptions, BazzyQuestionsCategories } from "@/models/Bazzy"
import { LinesContextInfo } from "@/components/designSystem/Discussions/components/Bazzy/LinesContextInfo"
import { Message } from "@/components/designSystem/Discussions/components/Bazzy/Message"
import { useBazzy } from "@/services/store/useBazzy"
import EraserSVG from "@/assets/icons/EraserSVG"
import { EmptyBazzy } from "./EmptyBazzy"

interface BazzyProps {
  scrollReachedTarget: boolean
}

export const Bazzy = ({ scrollReachedTarget }: BazzyProps) => {
  const { t } = useTranslation("Bazzy")
  const {
    chat,
    options,
    isOpen,
    toggleBazzy,
    setBazzyFreeText,
    clearBazzyContent,
    currentContext,
    currentBazzyOption
  } = useBazzy()
  const { addUserQuestionToBazzy, isLoading, error } = useBazzyMessages()
  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const bazzyContainerHeight = scrollReachedTarget ? "218px" : "265px"

  const optionsToShow = useMemo(() => {
    if (!currentContext) {
      return []
    }

    const usedQuestions =
      chat
        .find(
          (chatItem) =>
            chatItem.context?.filePath === currentContext.filePath && chatItem.context.lines === currentContext.lines
        )
        ?.messages.filter((message) => message.role === "user")
        .map((message) => message.content) || []

    // Get current options based on type
    const currentOptionsBasedOnType =
      currentContext.type === "code"
        ? options[BazzyQuestionsCategories.CODE]
        : options[BazzyQuestionsCategories.INSIGHT]

    return currentOptionsBasedOnType.filter(
      (option) => !usedQuestions.includes(option.label) && option.id !== currentBazzyOption && option.id !== "FREE_TEXT"
    )
  }, [currentContext, chat, options, currentBazzyOption])

  const handleBadgeClick = (questionId: BazzyCodeOptions | BazzyInsightOptions, questionLabel: string) => {
    if (!isLoading) {
      addUserQuestionToBazzy(questionId, questionLabel)
    }
  }

  return (
    <MinimalSheet open={isOpen} modal={false}>
      <MinimalSheetContent
        onEscapeKeyDown={() => toggleBazzy(false)}
        style={{
          top: `${bazzyContainerHeight}`,
          height: `calc(100% - ${bazzyContainerHeight})`,
          paddingRight: "48px"
        }}
        className={`fixed right-0 z-50 flex w-[500px] flex-col items-center ${chat.length > 0 ? "justify-between" : ""} bazzy-style rounded-none border-none bg-background p-6 text-primary [box-shadow:-4px_0_8px_-4px_rgba(0,0,0,0.1)]`}
      >
        <DialogTitle className="sr-only">{t("DialogTitle")}</DialogTitle>
        <DialogDescription className="sr-only">{t("DialogDescription")}</DialogDescription>
        <div className="flex w-full justify-between">
          <div className="flex w-full items-center">
            <Sparkles className="size-5 text-primary" />
            <header className="ml-4 text-sm font-medium">{t("Header")}</header>
          </div>
          <div className="mr-[-5px] flex">
            {chat.length > 0 && (
              <Button variant="ghost" size="icon" className="mr-1 size-6 p-1" onClick={clearBazzyContent}>
                <EraserSVG />
              </Button>
            )}
            <Button variant="ghost" size="icon" className="size-6 p-1" onClick={() => toggleBazzy(false)}>
              <Minimize className="text-primary" size={16} />
            </Button>
          </div>
        </div>

        {chat.length > 0 ? (
          <>
            <div
              ref={messagesContainerRef}
              className="mr-[6px] size-full overflow-y-hidden pr-2 hover:overflow-y-auto"
              style={{ scrollbarGutter: "stable" }}
            >
              {chat.map(({ context, messages }, index) => (
                <div key={context?.filePath} className={index === 0 ? "mt-4" : "mt-10"}>
                  {context && (
                    <>
                      <Separator className="h-px bg-secondary" />
                      <LinesContextInfo {...context} />
                    </>
                  )}
                  <div>
                    {messages.map((message, index) => {
                      const isLastMessage = index === messages.length - 1
                      const currentChatIndex = chat.findIndex((c) => c.messages === messages)
                      const isLastChat = currentChatIndex === chat.length - 1

                      return (
                        <Message
                          key={index}
                          {...message}
                          isLoading={!message.content || (isLastChat && isLastMessage && isLoading)}
                        />
                      )
                    })}
                    {messages.length === 1 && (isLoading || !error) && (
                      <Message
                        role="bazzy"
                        isLoading={isLoading || !error}
                        content={error ? "Try again" : ""}
                        timestamp={Date.now()}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full">
              <div className="flex w-full justify-start gap-2 bg-background">
                {optionsToShow.map((question) => (
                  <Badge
                    key={question.id}
                    onClick={() => handleBadgeClick(question.id, question.label)}
                    className={`flex h-6 rounded-md border border-border bg-background px-2 py-1 text-xxs text-primary ${
                      isLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                    }`}
                  >
                    {question.label}
                  </Badge>
                ))}
              </div>

              <Textarea
                shouldFocus
                placeholder={t("AskBazzy")}
                className="mt-4 flex h-12 items-center p-[13px] text-xs"
                onSend={(content) => {
                  setBazzyFreeText(content)
                  addUserQuestionToBazzy(BazzyCodeOptions.FREE_TEXT, content)
                }}
              />
              <p className="mt-4 text-xs text-hint">{t("Disclaimer")}</p>
            </div>
          </>
        ) : (
          <EmptyBazzy />
        )}
      </MinimalSheetContent>
    </MinimalSheet>
  )
}
