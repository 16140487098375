import { TableCell, TableRow, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { expanderDirection, FileWithDiffs, FccRow, FileElement } from "@/models/FCC"
import { useDiscussions } from "@/services/store/useDiscussions"
import { TFunction } from "i18next"
import ExpandUpSVG from "@/assets/icons/ExpandUpSVG"
import ExpandDownSVG from "@/assets/icons/ExpandDownSVG"
import ExpandAllSVG from "@/assets/icons/ExpandAllSVG"
import { useCallback, forwardRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useContent } from "@/hooks/useContent"
import { Row, Table } from "@tanstack/react-table"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { computeIsEdge, getEncapsulatedElements, isExpandableLine } from "@/components/designSystem/FCC/utils"
import { useIsExpanderEnabled } from "@/hooks/useIsExpanderEnabled"
import { EncapsulatedElements } from "../Cells/EncapsulatedElements"

interface Props {
  row: Row<FccRow>
  file: FileWithDiffs
  arrowDirection: expanderDirection | null
  isExpanded: boolean
  elements: FileElement[]
  columnsCount: number
  table: Table<FccRow>
}

const renderArrow = (expanderDirection: expanderDirection | null) => {
  if (!expanderDirection) return null

  return expanderDirection === "up" ? (
    <ExpandUpSVG color="var(--base-primary)" />
  ) : expanderDirection === "middle" ? (
    <ExpandAllSVG color="var(--base-primary)" />
  ) : (
    <ExpandDownSVG color="var(--base-primary)" />
  )
}

const renderText = (expanderDirection: expanderDirection | null, t: TFunction<"FCC", undefined>) => {
  if (expanderDirection === "up") return t("ExpandUp")
  if (expanderDirection === "middle") return t("Expand")
  if (expanderDirection === "down") return t("ExpandDown")
}

export const ExpanderRow = forwardRef<HTMLTableRowElement, Props>(
  ({ row, file, isExpanded, arrowDirection, elements, columnsCount, table }, ref) => {
    const { toggleManualRecalculationTrigger } = useDiscussions()
    const isExpanderEnabled = useIsExpanderEnabled()
    const { tables } = useChangeRequest()
    const filePath = file.file_relative_path
    const isEdgeFile = computeIsEdge(file)
    const currentTable = tables[filePath]
    const { refetch } = useContent({ filePath, shouldFetch: currentTable.shouldFetchContent && !isEdgeFile })
    const { t } = useTranslation("FCC")
    const currentRow = ref as React.RefObject<HTMLTableRowElement>
    const encapsulatedElements = useMemo(
      () => getEncapsulatedElements(row.original.nextLineNumber || -1, elements),
      [row, elements]
    )

    const handleClick = useCallback(async () => {
      const needsRefetch = currentTable.rows?.some((line) => isExpandableLine(line) && line.subRows)
      if (needsRefetch) {
        await refetch()
      }
      toggleManualRecalculationTrigger()
      row.toggleExpanded()
    }, [currentTable.rows, row, toggleManualRecalculationTrigger, refetch])

    return (
      <TableRow
        ref={currentRow}
        data-left="arrow"
        className="table-row h-7 cursor-pointer border-b-0 bg-fill text-primary"
      >
        <TableCell className="flex h-7 items-center justify-center">
          <Tooltip>
            <TooltipTrigger
              className={"disabled:cursor-not-allowed disabled:text-hint"}
              disabled={!isExpanderEnabled}
              onClick={handleClick}
              aria-expanded={isExpanded}
              aria-label={`Toggle ${arrowDirection}`}
            >
              {renderArrow(arrowDirection)}
            </TooltipTrigger>
            {isExpanderEnabled && <TooltipContent>{renderText(arrowDirection, t)}</TooltipContent>}
          </Tooltip>
        </TableCell>
        {columnsCount === 3 ? (
          <TableCell>
            {/* Placeholder for 3 columns table the number cell cannot be used for expand content */}
          </TableCell>
        ) : null}
        <TableCell colSpan={3} className="cursor-default pl-5 text-secondary">
          <EncapsulatedElements fileElements={encapsulatedElements} table={table} />
        </TableCell>
      </TableRow>
    )
  }
)
