import { ShadcnAvatar } from "@/components"
import { cn, extractInitials } from "@/lib/utils"
import { AvatarImage } from "@/components/ui/Avatar.tsx"

interface Props {
  username?: string
  className?: string
  fallbackClassName?: string
  avatarURL?: string
}
export const Avatar = ({ username, className, fallbackClassName, avatarURL }: Props) => {
  return (
    <ShadcnAvatar.Avatar className={className}>
      {avatarURL ? <AvatarImage src={avatarURL} /> : ""}
      <ShadcnAvatar.AvatarFallback className={cn(fallbackClassName)} data-testid="avatar">
        {extractInitials(username)}
      </ShadcnAvatar.AvatarFallback>
    </ShadcnAvatar.Avatar>
  )
}
