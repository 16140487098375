import { CommandItem } from "@/components"
import { useTranslation } from "react-i18next"
import { Undo2 } from "lucide-react"

interface Props {
  onClearFilter: () => void
}

export const ClearFilter = ({ onClearFilter }: Props) => {
  const { t } = useTranslation("Filters")
  return (
    // because of command item hover behavior I use placeholder value here
    <CommandItem key="#" value="#" onSelect={onClearFilter} className="mb-1 text-xs">
      <Undo2 width={16} height={16} className="mr-2" />
      {t("Reset")}
    </CommandItem>
  )
}
