import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  GithubWriteAccessButtonWrapper
} from "@/components"
import { useCreateNewCR } from "@/hooks/mutations/useCreateNewCR.tsx"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { ChevronDown } from "lucide-react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { LocalStorageService } from "@/lib/localStorageService"

interface Props {
  isDisabled: boolean
}

const localStorageService = new LocalStorageService()

export const CreateCRMenu = ({ isDisabled }: Props) => {
  const { createCRMutation, status } = useCreateNewCR()
  const { name, selectedRepositoryId, fromBranch, intoBranch, selectedRepositoryName } = useCreateCR()
  const { t } = useTranslation("CreateCR")
  const disabled = isDisabled || status === "pending" || name.length === 0
  const selectedRepository =
    selectedRepositoryName || localStorageService.getItem<string>("repositoryFullName", false) || ""

  const handleClick = useCallback(
    async (isDraft: boolean) => {
      if (!fromBranch || !intoBranch) {
        console.error("branch names were not provided")
        return
      }

      await createCRMutation({
        draft: isDraft,
        title: name,
        description: null,
        repository_id: selectedRepositoryId,
        branch_to: intoBranch,
        branch_from: fromBranch
      })
    },
    [intoBranch, fromBranch, createCRMutation, name, selectedRepositoryId]
  )

  const btn = (hasAccess: boolean) => (
    <Button disabled={disabled || !hasAccess} variant="default" size="sm" className="h-8 gap-1 py-0 text-xs">
      {t("CreateChange")} <ChevronDown width={16} height={16} />
    </Button>
  )

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled}>
        {disabled ? (
          btn(true)
        ) : (
          <GithubWriteAccessButtonWrapper repoFullName={selectedRepository} side="bottom">
            {(hasAccess) => btn(hasAccess)}
          </GithubWriteAccessButtonWrapper>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => handleClick(true)}>{t("OpenDraft")}</DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleClick(false)}>{t("OpenCR")}</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
