import { CheckSquare, Code, Heading1, Heading2, Heading3, ImageIcon, List, ListOrdered, TextQuote } from "lucide-react"

import { createSuggestionItems } from "novel/extensions"

const suggestionItems = createSuggestionItems([
  {
    title: "To-do List",
    description: "Track tasks with a to-do list.",
    searchTerms: ["todo", "task", "list", "check", "checkbox"],
    icon: <CheckSquare size={18} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run()
    }
  },
  {
    title: "Bullet List",
    description: "Create a simple bullet list.",
    searchTerms: ["unordered", "point"],
    icon: <List size={18} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run()
    }
  },
  {
    title: "Numbered List",
    description: "Create a list with numbering.",
    searchTerms: ["ordered"],
    icon: <ListOrdered size={18} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run()
    }
  },
  {
    title: "Heading 1",
    description: "Big section heading.",
    searchTerms: ["title", "big", "large"],
    icon: <Heading1 size={18} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode("heading", { level: 1 }).run()
    }
  },
  {
    title: "Heading 2",
    description: "Medium section heading.",
    searchTerms: ["subtitle", "medium"],
    icon: <Heading2 size={18} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode("heading", { level: 2 }).run()
    }
  },
  {
    title: "Heading 3",
    description: "Small section heading.",
    searchTerms: ["subtitle", "small"],
    icon: <Heading3 size={18} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode("heading", { level: 3 }).run()
    }
  },
  {
    title: "Quote",
    description: "Capture a quote.",
    searchTerms: ["blockquote"],
    icon: <TextQuote size={18} />,
    command: ({ editor, range }) =>
      editor.chain().focus().deleteRange(range).toggleNode("paragraph", "paragraph").toggleBlockquote().run()
  },
  {
    title: "Code",
    description: "Capture a code snippet.",
    searchTerms: ["codeblock"],
    icon: <Code size={18} />,
    command: ({ editor, range }) => editor.chain().focus().deleteRange(range).toggleCodeBlock().run()
  },
  {
    title: "Image",
    description: "Upload an image from your computer.",
    searchTerms: ["photo", "picture", "media"],
    icon: <ImageIcon size={18} />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).run()
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/*"

      input.onchange = async () => {
        if (input.files?.length) {
          const file = input.files[0]
          const reader = new FileReader()

          reader.onload = (event) => {
            const base64String = event.target?.result as string

            editor
              .chain()
              .focus()
              .setImage({
                src: base64String,
                alt: file.name,
                title: file.name
              })
              .run()

            document.body.removeChild(input)
          }

          reader.onerror = (error) => {
            console.error("Error reading file:", error)
            alert("An error occurred while reading the file. Please try again.")
            document.body.removeChild(input)
          }

          reader.onabort = () => {
            console.warn("File reading was aborted.")
            alert("File reading was aborted. Please try again.")
            document.body.removeChild(input)
          }

          reader.readAsDataURL(file)
        } else {
          alert("No file selected. Please select a file.")
          document.body.removeChild(input)
        }
      }
      input.click()
    }
  }
])

export default suggestionItems
