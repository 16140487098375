import { ContentMetadata } from "@/models/FC"
import { FileHeader } from "./FileHeader"
import { FileCodeTable } from "./Table"
import { extractFullFileLines } from "../FCC/utils"

interface Props {
  file: string | object
  contentMetadata: ContentMetadata
}

export const FC = ({ file, contentMetadata }: Props) => {
  const lines = extractFullFileLines(file)

  return (
    <div className="w-full">
      <FileHeader contentMetadata={contentMetadata} />
      <FileCodeTable lines={lines} contentMetadata={contentMetadata} />
    </div>
  )
}
