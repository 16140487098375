export function substringHighlighter(text?: string, substring?: string) {
  if (!text || !substring) {
    return
  }

  const index = text.toLocaleLowerCase().indexOf(substring.toLocaleLowerCase())

  if (index === -1) {
    return <span>{text}</span>
  }

  const textStart = text.substring(0, index)
  const textEnd = text.substring(index + substring.length, text.length)
  const highlighted = text.substring(index, index + substring.length)

  return (
    <span>
      <span>{textStart}</span>
      <span className="font-bold text-success-dark">{highlighted}</span>
      <span>{textEnd}</span>
    </span>
  )
}
