import { motion } from "framer-motion"
import { useEffect, useState } from "react"

const AnimatedCodeMarkingSvg = () => {
  const [sequence, setSequence] = useState(0)

  // Static rects that won't animate
  const staticRects = [
    { id: "top-dot", width: 10, height: 8, x: 0, y: 0.5 },
    { id: "block-1", width: 50, height: 8, x: 10, y: 11.5 },
    { id: "block-5", width: 50, height: 8, x: 10, y: 55.5 },
    { id: "bottom-dot", width: 10, height: 8, x: 0, y: 66.5 }
  ]

  // Animated middle blocks
  const animatedRects = [
    { id: "block-2", width: 50, height: 8, x: 35, y: 22.5 },
    { id: "block-3", width: 25, height: 8, x: 35, y: 33.5 },
    { id: "block-4", width: 37.5, height: 8, x: 35, y: 44.5 }
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      setSequence((prev) => (prev + 1) % 5) // 0-2 for marking, 3 for hold, 4 for reset
    }, 500)

    return () => clearInterval(timer)
  }, [])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="75" viewBox="0 0 85 75" fill="none">
      <g id="code illustration">
        {staticRects.map((rect) => (
          <rect
            key={rect.id}
            width={rect.width}
            height={rect.height}
            x={rect.x}
            y={rect.y}
            rx="1"
            fill="var(--base-border)"
          />
        ))}

        {animatedRects.map((rect, index) => (
          <motion.rect
            key={rect.id}
            width={rect.width}
            height={rect.height}
            x={rect.x}
            y={rect.y}
            rx="1"
            initial={{
              fill: "var(--base-border)",
              fillOpacity: 1
            }}
            animate={
              sequence >= index && sequence <= 3
                ? {
                    fill: "#5533FF",
                    fillOpacity: 0.2
                  }
                : {
                    fill: "var(--base-border)",
                    fillOpacity: 1
                  }
            }
            transition={{ duration: 0.2 }}
          />
        ))}
      </g>
    </svg>
  )
}

export default AnimatedCodeMarkingSvg
