import { BazzyContext, BazzyMessage, BazzyQuestionOptions } from "@/models/Bazzy.ts"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

interface StoreState {
  isOpen: boolean
  options: BazzyQuestionOptions
  currentContext?: BazzyContext
  currentMessageTimestamp: number
  currentBazzyOption: string
  freeText: string
  enableFetch: boolean
  chat: { context: BazzyContext; messages: BazzyMessage[] }[]
}

type Store = StoreState & StoreActions

type StoreActions = {
  clearBazzyContent: () => void
  toggleBazzy: (isOpen: boolean) => void
  setBazzyOptions: (options: BazzyQuestionOptions) => void
  updateContext: (context: BazzyContext, message?: BazzyMessage) => void
  addMessageToContext: (params: BazzyMessage) => void
  setBazzyFreeText: (text: string) => void
  setBazzyMessageTimestamp: (timestamp: number) => void
  setBazzyOption: (option: string) => void
  setEnableFetch: (enable: boolean) => void
}

const initialState = {
  isOpen: false,
  chat: [],
  options: {} as BazzyQuestionOptions,
  currentContext: undefined,
  currentMessageTimestamp: 0,
  currentBazzyOption: "",
  freeText: "",
  enableFetch: false
}

export const useBazzy = create<Store>()(
  immer((set) => ({
    ...initialState,
    updateContext: (context: BazzyContext, message?: BazzyMessage) =>
      set((state) => {
        if (!context.filePath) return

        const isNewContext = !(
          state.currentContext &&
          state.currentContext.filePath === context.filePath &&
          state.currentContext.lines.startLine === context.lines.startLine &&
          state.currentContext.lines.endLine === context.lines.endLine
        )

        if (isNewContext) {
          state.currentContext = context
          const newChat = {
            context,
            messages: message ? [message] : []
          }
          state.chat.push(newChat)
        } else if (message) {
          const lastChat = state.chat[state.chat.length - 1]
          if (lastChat) {
            const lastMessage = lastChat.messages[lastChat.messages.length - 1]
            if (lastMessage?.timestamp === message.timestamp) {
              lastMessage.content = message.content
            } else {
              lastChat.messages.push(message)
            }
          }
        }
      }),
    addMessageToContext: (message: BazzyMessage) =>
      set((state) => {
        const lastMessage =
          state.chat[state.chat.length - 1]?.messages[state.chat[state.chat.length - 1]?.messages.length - 1]
        if (lastMessage?.timestamp === message.timestamp) {
          lastMessage.content = message.content
          return
        }
        state.chat[state.chat.length - 1]?.messages.push(message)
      }),
    clearBazzyContent: () =>
      set((state) => {
        state.chat = []
        state.currentContext = undefined
      }),

    toggleBazzy: (isOpen) =>
      set((state) => {
        state.isOpen = isOpen
      }),

    setBazzyOptions: (options) =>
      set((state) => {
        state.options = options
      }),

    setBazzyFreeText: (text) =>
      set((state) => {
        state.freeText = text
      }),

    setBazzyMessageTimestamp: (timestamp) =>
      set((state) => {
        state.currentMessageTimestamp = timestamp
      }),

    setBazzyOption: (option) =>
      set((state) => {
        state.currentBazzyOption = option
      }),

    setEnableFetch: (enable) =>
      set((state) => {
        state.enableFetch = enable
      })
  }))
)
