import {
  Badges,
  Common,
  Filters,
  CommitHistory,
  WriteAccessDialog,
  ConnectSlack,
  Subscription,
  Reviewer
} from "./common"
import { FCC } from "./FCC"
import { UserDropdown, RepoSelector, TableDropdown } from "./Menus"
import { Integrations } from "./Integrations"
import { Settings } from "./Settings"
import { Discussions } from "./Discussions"
import { Code } from "./Code"
import { EmptyStates } from "./EmptyStates"
import { Review } from "./Review"
import { Insight } from "./Insight"
import { Toolbar } from "./Toolbar"
import { Topics } from "./Topics"
import { CreateCR } from "./CreateCR.ts"
import { OnBoarding } from "./OnBoarding.ts"
import { Bazzy } from "./Bazzy.ts"
import { Changes } from "@/services/i18n/locales/en/Changes"
import { Auth } from "./Auth.ts"
import { DisabledState } from "@/services/i18n/locales/en/DisabledState"

export const en = {
  Common,
  Subscription,
  Reviewer,
  Badges,
  FCC,
  UserDropdown,
  Integrations,
  Settings,
  Filters,
  Discussions,
  Code,
  CommitHistory,
  WriteAccessDialog,
  RepoSelector,
  EmptyStates,
  TableDropdown,
  Review,
  Insight,
  Toolbar,
  CreateCR,
  OnBoarding,
  Topics,
  Changes,
  ConnectSlack,
  Bazzy,
  Auth,
  DisabledState
} as const
