import { Hourglass, Rocket } from "lucide-react"
import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Button } from "@/components"
import { useFetchSubscription } from "@/hooks/queries/useFetchSubscription"
import { useTranslation } from "react-i18next"
import { TrialProgress } from "@/components/designSystem/TrialProgress"
import { NavLink } from "react-router-dom"
import { routes } from "@/lib/routes"

const TrialTooltip = ({ isOwner = false }) => {
  const { data: subscriptionData, isLoading: isSubscriptionLoading } = useFetchSubscription()
  const { t } = useTranslation("Subscription")
  const daysLeft = subscriptionData?.trialRemainingDays || 0

  if (isSubscriptionLoading || !subscriptionData) {
    return null
  }

  const handleUpgradeClick = () => {
    window.open(subscriptionData.customerPortalUrl, "_blank")
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Badge variant="purple">
            <Hourglass className="size-4" />
            <span className="ml-2">{daysLeft} days left</span>
          </Badge>
        </TooltipTrigger>
        <TooltipContent side="bottom" align="end" className="border border-border bg-background p-3">
          <div className="text-xs font-medium leading-5 text-primary">{daysLeft} days left on trial</div>
          <TrialProgress trialRemainingDays={daysLeft} />
          <div className="pb-3 text-xxs font-normal leading-3 text-secondary">
            {subscriptionData.quantity} Contributors
          </div>
          {isOwner && (
            <div className="flex gap-2">
              <Button type="button" variant="purple" className="h-4 px-2 py-4 text-xs" onClick={handleUpgradeClick}>
                <Rocket className="size-4"></Rocket>
                <span className="ml-2">{t("Upgrade")}</span>
              </Button>
              <NavLink to={routes.settings.accountSettings}>
                <Button type="button" variant="outline" className="h-4 px-2 py-4 text-xs">
                  {t("Manage")}
                </Button>
              </NavLink>
            </div>
          )}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default TrialTooltip
