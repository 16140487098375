export const apiRoutes = {
  changes: {
    get: "v1/changes",
    post: "v1/changes",
    updateChange: (changeId: string) => `v1/changes/${changeId}`,
    changeDiff: {
      get: (changeId: string) => `v1/changes/${changeId}/diff`
    },
    mergeStatus: (changeId: string) => `v1/changes/${changeId}/merge-status`,
    mergeChangeRequest: {
      post: (changeId: string) => `v1/changes/${changeId}/merge`
    },
    approve: {
      post: (changeId: string) => `v1/changes/${changeId}/approve`
    },
    authors: "v1/changes/authors",
    eligibleReviewers: {
      get: (changeId: string) => `v1/changes/${changeId}/eligible-reviewers`,
      put: (changeId: string) => `v1/changes/${changeId}/reviewers`
    },
    search: {
      post: "v1/changes/search"
    },
    topics: (changeId: string) => `v1/changes/${changeId}/topics`,
    discussions: (changeId: string) => `v1/changes/${changeId}/discussions`,
    viewed: {
      post: (fileId: string) => `v1/changes/file/${fileId}/viewed`,
      delete: (fileId: string) => `v1/changes/file/${fileId}/viewed`
    }
  },
  diff: {
    get: (repoId: string, fromRef: string, toRef: string) =>
      `v1/diff/${repoId}/branches/${encodeURIComponent(toRef)}/${encodeURIComponent(fromRef)}`
  },
  comments: "v1/comments",
  editComments: (id: string) => `v1/comments/${id}`,
  resolveComment: (discussionId: string) => `v1/discussions/${discussionId}`,
  githubWebhookCallback: "v1/github-webhook-callback",
  githubIntegrations: {
    get: "v1/github-integrations",
    patch: (installationId: string) => `v1/github-integrations/${installationId}`
  },
  integrations: {
    get: "v1/integrations",
    post: "v1/integrations"
  },
  slack: {
    get: "v1/integrations/slack",
    post: "v1/integrations/slack"
  },
  repositories: "v2/repositories",
  branches: "v1/browser/branches",
  dirContent: "v1/browser/dir-content",
  contentMetadata: "v1/browser/content-metadata",
  content: "v1/browser/content",
  auth: {
    base: "v1/auth",
    orgs: {
      patch: (orgId: string) => `v1/auth/orgs/${orgId}`
    },
    user: {
      get: "v1/auth/user"
    },
    org: {
      delete: "v1/auth/org"
    },
    cache: {
      oauth: {
        delete: "v1/auth/cache/oauth"
      }
    }
  },
  bazzy: {
    options: "v2/bazzy/options",
    ask: "v2/bazzy/ask",
    insight: "v2/bazzy/insight-query"
  },
  billing: {
    get: "v2/billing/subscription",
    exportUsers: "v2/billing/export-users"
  },
  reviewerConfig: {
    get: "v2/reviewer/config",
    finding: {
      put: "v2/reviewer/config/finding"
    },
    excluded: {
      put: "v2/reviewer/config/excluded"
    }
  },
  onboarding: {
    get: "v2/onboarding/status",
    reviewer: {
      put: "v2/onboarding/status/reviewer"
    }
  },
  installations: {
    get: "v2/installations",
    repoWriteAccess: {
      get: (repoName: string) => `v2/installations/write-access/${encodeURIComponent(repoName)}`
    }
  }
}
