import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "../useToast"
import { ghWriteErrorHandler } from "@/services/api/helpers"

interface Args {
  changeId: string
  reviewers: string[]
}

export const useUpdateReviewers = () => {
  const { toast } = useToast()

  const queryClient = useQueryClient()

  const changeReviewers = useCallback(
    async (options: Args) =>
      await axiosClient.put(apiRoutes.changes.eligibleReviewers.put(options.changeId), {
        users: options.reviewers
      }),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["updateReviewers"],
    mutationFn: changeReviewers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["eligible-reviewers"] })
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        description: ghWriteErrorHandler(err)
      })
    }
  })

  return { data, mutateAsync, status }
}
