import { Button } from "@/components"
import { SVGProps } from "react"
import { useTranslation } from "react-i18next"

export const EmptyFolder = () => {
  const { t } = useTranslation("EmptyStates")
  return (
    <div className="flex h-[80vh] flex-col items-center justify-center gap-4">
      <div className="rounded-full bg-border p-6">
        <FolderIcon className="size-12 text-primary" />
      </div>
      <div className="space-y-2 text-center">
        <h3 className="text-2xl font-semibold">{t("EmptyFolder")}</h3>
        <p className="text-primary">{t("CheckOtherFolders")}</p>
      </div>
    </div>
  )
}

export const NoReadme = () => {
  const { t } = useTranslation("EmptyStates")
  return (
    <div className="flex h-[80vh] flex-col items-center justify-center gap-4">
      <div className="rounded-full bg-border p-6">
        <FileIcon className="size-12 text-primary" />
      </div>
      <div className="space-y-2 text-center">
        <h3 className="text-2xl font-semibold">{t("AddReadme")}</h3>
        <p className="text-primary">{t("ProvideInformation")}</p>
        <div className="flex gap-2">
          <Button>{t("CreateReadme")}</Button>
          <Button variant="outline">{t("Skip")}</Button>
        </div>
      </div>
    </div>
  )
}

function FolderIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2Z" />
    </svg>
  )
}

function FileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
      <path d="M14 2v4a2 2 0 0 0 2 2h4" />
    </svg>
  )
}
