import { ExternalLink, SkeletonLoader } from "@/components"
import { ExternalLink as ExternalLinkIcon } from "lucide-react"
import { useFetchIntegrations } from "@/hooks/queries/useFetchIntegrations"
import { useTranslation } from "react-i18next"
import { errorHandler } from "@/services/api/helpers"
import { runtimeConfig } from "@/lib/config.ts"
import { useEffect } from "react"
import { routes } from "@/lib/routes.ts"
import { useNavigate, useSearchParams } from "react-router-dom"
import { IntegrationCard } from "@/pages/settings/integrations/slack/IntegrationCard.tsx"
import { SlackIntegration } from "@/models/Integration.ts"

export const slackUrl = `https://slack.com/oauth/v2/authorize?scope=chat:write,channels:join,im:write,users:read,users:read.email&client_id=${runtimeConfig.VITE_SLACK_CLIENT_ID}`

export const Slack = () => {
  const { t } = useTranslation("Integrations")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { error, isLoading, data } = useFetchIntegrations("slack")

  const code = searchParams.get("code")

  useEffect(() => {
    if (code) {
      navigate({
        pathname: routes.settings.slackCallback,
        search: `?code=${code}`
      })
    }
  }, [code, navigate])

  if (isLoading) {
    return (
      <div>
        <SkeletonLoader />
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <p>{errorHandler(error)}</p>
      </div>
    )
  }

  if (data && data.integrations?.length > 0) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium">{t("Slack")}</h1>
          <ExternalLink href={slackUrl} label={t("Install")} Icon={ExternalLinkIcon} />
        </div>
        <div className="grid w-full grid-cols-2 gap-4">
          {data.integrations.map((integration) => (
            <IntegrationCard key={integration.id} integration={integration as SlackIntegration} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div data-testid="slack-integration-description" className="text-primary">
        <p className="mb-4">{t("slack_description.first")}</p>
        <p className="mb-4">{t("slack_description.second")}</p>
        <ExternalLink href={slackUrl} label={t("Install")} Icon={ExternalLinkIcon} />
      </div>
    </div>
  )
}
