import { BazzyContext, StreamResponse } from "@/models/Bazzy"
import { BazzyMessage } from "@/models/Bazzy"
import { TFunction } from "i18next"

interface ProcessStreamDataProps {
  data: StreamResponse[] | undefined
  currentMessageTimestamp: number
  hasStartedToGetStream: boolean
  error: Error | null
  chat: { context: BazzyContext; messages: BazzyMessage[] }[]
  addMessageToContext: (message: BazzyMessage) => void
  setBazzyFreeText: (text: string) => void
  t: TFunction<"Bazzy", undefined>
}

export const processStreamData = ({
  data,
  currentMessageTimestamp,
  hasStartedToGetStream,
  error,
  chat,
  addMessageToContext,
  setBazzyFreeText,
  t
}: ProcessStreamDataProps) => {
  if (!data || currentMessageTimestamp === undefined || currentMessageTimestamp === null) return

  const streamEvents = data.filter(
    (event) => (event.event === "on_chat_model_stream" && event.data?.chunk?.kwargs?.content) ?? false
  )

  const result = hasStartedToGetStream ? streamEvents.map((event) => event.data.chunk.kwargs.content).join("") : ""

  if (
    (error || hasStartedToGetStream) &&
    (result || !chat[chat.length - 1]?.messages.some((m) => m.timestamp === currentMessageTimestamp))
  ) {
    addMessageToContext({
      role: "bazzy",
      content: error ? t("BazzyError") : result,
      timestamp: currentMessageTimestamp
    })
  }

  setBazzyFreeText("")
}
