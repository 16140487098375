import { Button, CommandSeparator } from "@/components"
import { Command, CommandEmpty, CommandGroup, CommandInput } from "@/components"
import { Popover, PopoverContent, PopoverTrigger } from "@/components"
import { Filter } from "../types"
import { displayMainLabel, filterByLabel } from "../utils"
import { useTranslation } from "react-i18next"
import { SelectOption } from "../components/SelectOption"
import { ClearFilter } from "../components/ClearFilter"
import { useFilter } from "../hooks/useFilter"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

interface Props {
  filter: Filter
  isPaginated: boolean
  localStorageKey?: string
}

export function SelectFilter({ filter, isPaginated, localStorageKey }: Props) {
  const { isSearch, options, urlKey, isSyncedWithLocalStorage, defaultValue } = filter
  const { t } = useTranslation("Filters")
  const [searchParams] = useSearchParams()
  const urlFilter = searchParams.get(urlKey)

  const { open, setOpen, handleSelect, handleClearFilter, syncDefaultValue } = useFilter({
    filter,
    isPaginated,
    isSyncedWithLocalStorage,
    localStorageKey,
    defaultValue
  })

  useEffect(() => {
    syncDefaultValue()
  }, [syncDefaultValue])

  const buttonClassName = `h-7 px-2 py-1 text-xs ${urlFilter ? "bg-border hover:bg-border" : ""}`

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className={buttonClassName}>
          {displayMainLabel(urlFilter, filter)}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-fit min-w-[200px] p-0" align="start">
        <Command filter={(value, search) => filterByLabel(value, search, options)}>
          {isSearch && <CommandInput placeholder={t("Search")} />}

          <CommandEmpty>{t("NoResults")}</CommandEmpty>

          <CommandGroup>
            {urlFilter && (
              <>
                <ClearFilter onClearFilter={handleClearFilter} />
                <CommandSeparator />
              </>
            )}

            {options.map((option) => (
              <SelectOption key={option.value} urlFilter={urlFilter} option={option} handleSelect={handleSelect} />
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
