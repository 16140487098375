import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button
} from "@/components"
import { useTranslation } from "react-i18next"
import { useDeleteOrg } from "@/hooks/mutations/useDeleteOrg"

export function DeleteAccountDialog() {
  const { t } = useTranslation("Subscription")
  const { mutateAsync } = useDeleteOrg()

  const handleDelete = () => mutateAsync()

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button type="button" variant="outline" className="h-4 w-fit px-2 py-4 text-xs">
          {t("Delete")}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("AreYouSure")}</AlertDialogTitle>
          <AlertDialogDescription className="my-4 text-left text-xs leading-5 text-primary">
            {t("Confirmation")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("Cancel")}</AlertDialogCancel>
          <AlertDialogAction className="hover:bg-error-mid/90 bg-error-mid text-fill" onClick={handleDelete}>
            {t("Ok")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteAccountDialog
