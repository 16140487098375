import { LocalStorageService } from "@/lib/localStorageService"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useFetchBranches } from "@/hooks/queries/useFetchBranches"
import { Repository } from "@/models/Repository"

interface Args {
  currentRepository?: Repository
}

const localStorageService = new LocalStorageService("localStorage")

export const useBranches = ({ currentRepository }: Args) => {
  const { branch } = useParams()

  const branchFromLocalStorage = localStorageService.getItem<string>("branch") || undefined

  const [currentBranch, setCurrentBranch] = useState(branch)

  const { data: branches, error: branchesError, isLoading: branchesLoading } = useFetchBranches(currentRepository?.id)

  const branchProps = useMemo(
    () => ({
      branch: currentBranch || branchFromLocalStorage,
      branches,
      branchesError,
      branchesLoading,
      setCurrentBranch
    }),
    [currentBranch, branchFromLocalStorage, branches, branchesError, branchesLoading, setCurrentBranch]
  )

  return {
    branchFromLocalStorage,
    currentBranch,
    setCurrentBranch,
    branches,
    branchesError,
    branchesLoading,
    branchProps
  }
}
