import { FccRow, FileElement } from "@/models/FCC"
import { isRowWithInsights } from "../../Insights/helpers"
import { InsightsLabel } from "../../Insights/InsightsLabel"
import { Table } from "@tanstack/react-table"

interface Props {
  fileElements: FileElement[]
  table: Table<FccRow>
}

export const EncapsulatedElements = ({ fileElements, table }: Props) => {
  if (fileElements.length === 0) {
    return <span>@@</span>
  }

  return fileElements.map((element) => {
    const hasInsights = isRowWithInsights(table, element.start_line)

    return (
      <div className="flex items-center gap-2" key={element.name}>
        <span>@@</span>
        <span>{element.line_content}</span>
        {hasInsights && <InsightsLabel table={table} lineNumber={element.start_line} side="right" />}
      </div>
    )
  })
}
