import { KeyboardEvent } from "react"

interface ListRef {
  onKeyDown: (event: { key: string }) => boolean
  focus: () => void
}

export function isListRef(ref: unknown): ref is ListRef {
  return (
    typeof ref === "object" &&
    ref !== null &&
    "onKeyDown" in ref &&
    typeof (ref as ListRef).onKeyDown === "function" &&
    "focus" in ref &&
    typeof (ref as ListRef).focus === "function"
  )
}

export interface ListHandlers {
  onKeyDown: (event: KeyboardEvent) => boolean
}

export interface ListProps<T> {
  items: T[]
  onClose: () => void
  command: (commandObject: { id: string }) => void
}
