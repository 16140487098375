export const Changes = {
  BuildStatus: {
    success: "All checks passed",
    failure: (num: number) => `${num} failed`,
    pending: (num: number) => `${num} still pending`
  },
  Reviews: {
    NoReviews: "Review pending",
    Approved: "approved",
    Reviewed: "reviewed"
  },
  EmptyOpenCRTitle: "There are no open change requests",
  EmptyOpenCRSubTitle: "You can take some time off 😊",
  EmptyCRTitle: "No change requests match your current filter",
  EmptyCRSubTitle: "Try looking for something else 🔍"
}
