import { Button } from "@/components"
import { useTranslation } from "react-i18next"

interface Props {
  onClick: () => void
  isChanged: boolean
}

export const ApproveButton = ({ onClick, isChanged }: Props) => {
  const { t } = useTranslation("Filters")

  return (
    <Button disabled={!isChanged} className="h-8 w-full cursor-pointer" size="sm" onClick={onClick}>
      {t("Apply")}
    </Button>
  )
}
