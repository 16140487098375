import StrangeInputLight from "@/assets/icons/StrangeInputLight.svg?react"
import StrangeInputDark from "@/assets/icons/StrangeInputDark.svg?react"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { useDebouncedCallback } from "use-debounce"
import { useSettings } from "@/services/store/useSettings.ts"

export const NameInput = () => {
  const { setName } = useCreateCR()
  const { selectedTheme } = useSettings()

  const onInputChange = useDebouncedCallback((value: string) => {
    setName(value)
  }, 500)

  const IconToUse = selectedTheme === "light" ? StrangeInputLight : StrangeInputDark

  return (
    <div className="mt-1 flex w-full text-primary">
      <IconToUse width={32} height={32} className="mr-2" />
      <input
        onChange={(event) => onInputChange(event.target.value)}
        type="text"
        className="w-full border-border bg-background px-1 text-xs outline-none focus:border"
      />
    </div>
  )
}
