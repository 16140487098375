export const Settings = {
  Settings: "Settings",
  Integrations: "Integrations",
  Security: "Security",
  Profile: "Profile",
  // Billing: "Billing and plans",
  Emails: "Emails",
  Password: "Password and authentication",
  Sessions: "Sessions",
  GitHub: "GitHub",
  Datadog: "Datadog",
  Slack: "Slack",
  "Account Settings": "Account Settings",
  Preferences: "Preferences",
  Account: "Account"
}
