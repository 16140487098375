import { Button, Popover, PopoverContent, PopoverTrigger } from "@/components"
import { Topic } from "@/models/Topics"
import { cn } from "@/lib/utils"
import TopicBadge from "@/components/designSystem/Topics/TopicBadge"
import { getChecksWithExtraData, getInsightsData } from "@/components/designSystem/Topics/helpers"
import { useState } from "react"

interface Props {
  topics: Topic[]
  computedTopics: Topic[]
  contentClassName?: string
  minimized: boolean
}

export const PlusPopover = ({ topics, contentClassName, minimized }: Props) => {
  const remainingTopics = topics.slice(4)
  const [popoverOpen, setPopoverOpen] = useState(false)

  return (
    <Popover open={popoverOpen}>
      <PopoverTrigger>
        <Button
          onClick={() => setPopoverOpen(true)}
          className="flex h-6 items-center rounded-sm bg-background px-[5px] font-medium text-primary"
          variant="outline"
        >
          +{remainingTopics.length}
        </Button>
      </PopoverTrigger>
      <PopoverContent onInteractOutside={() => setPopoverOpen(false)} align="start" className={cn(contentClassName)}>
        <div onClick={() => setPopoverOpen(false)} className="flex flex-col gap-2 p-2">
          {remainingTopics.map((topic, index) => (
            <TopicBadge
              key={index}
              topic={topic}
              getInsightsData={getInsightsData}
              getChecksWithExtraData={getChecksWithExtraData}
              minimized={minimized}
              withHover={false}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}
