import { useParams } from "react-router-dom"
import { Github } from "./github/Github"
import { Datadog } from "./datadog/Datadog.tsx"
import { Slack } from "./slack/Slack.tsx"

export function Integrations() {
  document.title = "Settings - Integrations"
  const { provider } = useParams()

  switch (provider) {
    case "github":
      return <Github />
    case "datadog":
      return <Datadog />
    case "slack":
      return <Slack />
  }

  return <div>Not found a provider</div>
}
