import { CodeChangeDiff } from "@/models/FCC"
import { CodeChangeBadge, CopyToClipboard } from "@/components"
import { ClipboardCopy, ChevronDown, ChevronRight } from "lucide-react"
import { useTranslation } from "react-i18next"
import Checkmark from "@/assets/icons/Checkmark"
import { BASE_HEADER_HEIGHT, useChangeRequest } from "@/services/store/useChangeRequest"

interface Props {
  isFileViewed: boolean
  diff: CodeChangeDiff
  filePath: string
  oldFilePath?: string
  newDiff?: boolean
  onMinimizeToggle: () => void
  minimized: boolean
}

const renderIcon = (minimized: boolean, onMinimizeToggle: () => void) => {
  return minimized ? (
    <ChevronRight onClick={onMinimizeToggle} width={16} height={16} className="mx-[6px] cursor-pointer text-primary" />
  ) : (
    <ChevronDown onClick={onMinimizeToggle} width={16} height={16} className="mx-[6px] cursor-pointer text-primary" />
  )
}

export const FileHeader = ({
  isFileViewed,
  diff,
  filePath,
  newDiff,
  onMinimizeToggle,
  minimized,
  oldFilePath
}: Props) => {
  const { addition, deletion } = diff
  const { t } = useTranslation("Review")
  const { selectedTopic } = useChangeRequest()

  let filePathText = filePath
  if (oldFilePath && oldFilePath !== filePath && oldFilePath.length > 0) {
    filePathText = `${oldFilePath} -> ${filePath}`
  }

  return (
    <header
      className="sticky z-8 flex h-[72px] border-b bg-background px-4 text-primary"
      style={newDiff ? undefined : { top: `${BASE_HEADER_HEIGHT}px` }}
    >
      <div className="flex w-full items-center gap-2">
        {renderIcon(minimized, onMinimizeToggle)}
        <CodeChangeBadge addition={addition} deletion={deletion} />
        <span className="max-w-[80%] overflow-x-hidden break-all text-sm">{filePathText}</span>
        <CopyToClipboard text={filePath}>
          <ClipboardCopy width={16} height={16} className="cursor-pointer text-primary" />
        </CopyToClipboard>
        {(selectedTopic?.isViewed || isFileViewed) && (
          <>
            <p className="ml-1 text-xxs text-secondary">{t("Reviewed")}</p>
            <Checkmark color="var(--green-success-dark)" size={12} />
          </>
        )}
      </div>
    </header>
  )
}
