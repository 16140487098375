import { Avatar } from "@/components/designSystem/Avatar"
import { ChangeRequest, Review, Reviewer } from "@/models/Changes"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { uppercaseString } from "@/lib/utils"
import { ReviewersDropdown } from "./ReviewersDropdown"
import { useFetchEligibleReviewers } from "@/hooks/queries/useFetchEligibleReviewers"
import { useEffect, useMemo, useState } from "react"
import ThreeHorizontalDots from "@/assets/icons/ThreeHorizontalDots.svg?react"
import WhiteCheckOnGreen from "@/assets/icons/WhiteCheckOnGreen.svg?react"

interface Props {
  changeRequest: ChangeRequest
}

const getAvatarColor = (status: string) => {
  return status === "approved" ? "bg-success-dark" : "bg-primary"
}

const disabledApprovalColor = "bg-secondary"

const getStatusIcon = (status: string, hasWriteAccess: boolean) => {
  switch (status) {
    case "pending":
      return null
    case "approved":
      return (
        <WhiteCheckOnGreen
          className={`absolute right-[-3px] top-[-3px] size-3 rounded-full ${hasWriteAccess ? "bg-highlight-dark" : disabledApprovalColor} text-white shadow`}
        />
      )
    case "reviewed":
      return (
        <ThreeHorizontalDots
          className={`absolute right-[-3px] top-[-3px] size-3 rounded-full ${hasWriteAccess ? "bg-yellow-500" : disabledApprovalColor} shadow`}
        />
      )
  }
}

const renderReviewers = (reviewersIds: string[], reviewers: Reviewer[] | undefined, reviews: Review[]) => {
  return reviewersIds.map((reviewerId) => {
    const reviewer = reviewers?.find((reviewer) => reviewer.id === reviewerId)
    const review = reviews.find((review) => review.assignee_id === reviewerId)
    const reviewState = review?.review_state ?? "pending"
    const reviewerWriteAccess = review?.has_write_access ?? false
    const bgColor = getAvatarColor(reviewState)
    return (
      <Tooltip key={reviewerId}>
        <TooltipTrigger className="cursor-default">
          <span className="relative rounded-full">
            <Avatar
              className="size-6 cursor-default select-none text-[10px] font-bold leading-[12px] text-secondary"
              username={review?.assignee ?? reviewer?.name}
              fallbackClassName={bgColor}
              avatarURL={review?.assignee_user?.avatar_url ?? reviewer?.avatar_url}
            />
            {getStatusIcon(reviewState, reviewerWriteAccess)}
          </span>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          {review?.assignee_user?.display_name ?? review?.assignee ?? "Unknown"}, {uppercaseString(reviewState)}
        </TooltipContent>
      </Tooltip>
    )
  })
}

export const Reviews = ({ changeRequest }: Props) => {
  const { reviews } = changeRequest
  const { data: eligibleReviewers } = useFetchEligibleReviewers({ changeId: changeRequest.id })
  const [localReviewersIds, setLocalReviewersIds] = useState<string[]>([])

  const sortedReviews = [...reviews].sort((a, b) => a.assignee.localeCompare(b.assignee))
  const approvedReviews = sortedReviews.filter((review) => review.review_state === "approved")

  const reviewersAvatars = useMemo(
    () => renderReviewers(localReviewersIds, eligibleReviewers, sortedReviews),
    [localReviewersIds, eligibleReviewers, sortedReviews]
  )

  useEffect(() => {
    setLocalReviewersIds(reviews.map((review) => review.assignee_id))
  }, [reviews])

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-2">
        <ReviewersDropdown
          changeId={changeRequest.id}
          allReviewersOptions={eligibleReviewers}
          currentReviewersIds={sortedReviews.map((review) => review.assignee_id)}
          setNewReviewersIds={(newReviewersIds: string[]) => setLocalReviewersIds(newReviewersIds)}
          approvedReviewers={approvedReviews.map((review) => review.assignee_id)}
        />
        {reviewersAvatars}
      </div>
    </div>
  )
}
