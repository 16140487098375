import { languageIconsMapper } from "@/lib/LanguageIconsMapper"
import { MagnitudeMeter } from "../MagnitudeMeter"
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from "@/components"

interface Props {
  addition: number
  deletion: number
  language?: string
}

export const CodeChangeBadge = ({ addition, deletion, language }: Props) => {
  let text = `+${addition} -${deletion}`
  if (addition === 0) text = `-${deletion}`
  if (deletion === 0) text = `+${addition}`

  return (
    <div className="flex min-w-fit cursor-default items-center rounded-md border border-border px-2 py-1">
      {language ? (
        <span className="flex items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="cursor-default">{languageIconsMapper(language)}</TooltipTrigger>
              <TooltipContent>{language}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </span>
      ) : null}

      <span className="mx-2 text-xs">{text}</span>
      <MagnitudeMeter addition={addition} deletion={deletion} />
    </div>
  )
}
