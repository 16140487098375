import { Card, CardContent } from "@/components"
import Slack from "@/assets/icons/slack.svg?react"
import { CheckCircle, AlertTriangle } from "lucide-react"
import { SlackIntegration, IntegrationStatus } from "@/models/Integration.ts"
import { Timestamp } from "@/components/ui/Timestamp"

interface Props {
  integration: SlackIntegration
}

const Icon = ({ status }: { status: IntegrationStatus }) => {
  return status === "success" ? (
    <CheckCircle width={12} height={12} className="ml-1 text-success-normal" />
  ) : (
    <AlertTriangle width={12} height={12} className="ml-1 text-error-normal" />
  )
}

export const IntegrationCard = ({ integration }: Props) => {
  return (
    <Card className="w-full rounded-md border shadow-none" data-testid="github-repo-card">
      <CardContent className="flex items-center p-4 text-fill">
        <Slack width={32} height={30} className="mr-4" />
        <div>
          <h1 className="flex items-center text-sm text-primary">
            {integration.metadata.team?.name} <Icon status={integration.status} />
          </h1>

          <Timestamp time={integration.updated_at} />
        </div>
      </CardContent>
    </Card>
  )
}
