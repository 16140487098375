import { ForwardedRef, MutableRefObject } from "react"
import { stickyScrollOffset } from "@/pages/crFileChanges/components/helpers"

export const topNavigationHeight = 64

function isProperRef(ref: ForwardedRef<HTMLElement | null>): ref is MutableRefObject<HTMLElement> {
  return !!(typeof ref !== "function" && ref && ref.current)
}

function getContentHeight(elementRef: ForwardedRef<HTMLElement | null>): number {
  return isProperRef(elementRef) ? elementRef.current.offsetHeight : document.body.offsetHeight
}

export function calculateScrollbarHeight(elementRef: ForwardedRef<HTMLElement | null>): number {
  // Get the total height of the content
  // If elementRef is valid, use its offsetHeight, otherwise fallback to document.body height
  const contentHeight = getContentHeight(elementRef)

  // Calculate scrollbar height using the formula:
  // (viewport height - top navigation) * (available height / total content height)
  // This creates a proportional scrollbar height based on how much content is visible
  return (window.innerHeight - topNavigationHeight) * ((window.innerHeight - topNavigationHeight) / contentHeight)
}

export function calculateScrollbarTopPosition(elementRef: ForwardedRef<HTMLElement | null>): number {
  // Get the total height of the content, similar to previous function
  const contentHeight = getContentHeight(elementRef)

  // Calculate the ratio between content height and available viewport height
  // This determines how much the scrollbar should move relative to content scroll
  const scrollRatio = contentHeight / (window.innerHeight - topNavigationHeight)

  // Return the scrollbar position by dividing current scroll position by the ratio
  // This ensures scrollbar position matches content scroll position proportionally
  return window.scrollY / scrollRatio
}

export function calculateLineMarkTop(elementRef: ForwardedRef<HTMLElement | null>, discussionTop?: number) {
  // Calculate the line marker position as a percentage of total scroll height
  // Otherwise return 0
  return isProperRef(elementRef) && discussionTop
    ? Math.floor((discussionTop / elementRef.current.scrollHeight) * 100)
    : 0
}

// Checks if content is tall enough to need a scrollbar by comparing
// the scrollbar height against the total content height
export function isVisibleScroll(elementRef: ForwardedRef<HTMLElement | null>, scrollbarHeight: number): boolean {
  const contentHeight = getContentHeight(elementRef)
  return scrollbarHeight < contentHeight
}

// Converts a percentage (0-100) to pixels based on a reference height
// For example: 50% of 200px = 100px
export function percentageToPixels(percentage: number, referenceHeight: number) {
  const decimalPercentage = percentage / 100
  const pixelValue = referenceHeight * decimalPercentage

  return Math.floor(pixelValue)
}

// Handles clicks on the custom scrollbar by converting the clicked position
// to an equivalent scroll position, accounting for the navigation bar height
// and any sticky element offset
export function navigateToClickedScrollbarLocation(positionY: number, elementRef: ForwardedRef<HTMLElement | null>) {
  const clickedPositionY = positionY - topNavigationHeight
  const contentHeight = getContentHeight(elementRef)

  const ratio = contentHeight / (window.innerHeight - topNavigationHeight)
  const scrollToTop = ratio * clickedPositionY

  scrollTo({ top: scrollToTop - stickyScrollOffset, behavior: "instant" })
}

// Converts the scrollbar thumb's position to the corresponding window scroll position
// Uses a ratio based on content height vs viewport height to ensure proportional scrolling
export function transformThumbTopToWindowScroll(elementRef: ForwardedRef<HTMLElement | null>, thumbTop: number) {
  const contentHeight = getContentHeight(elementRef)
  const scrollRatio = contentHeight / (window.innerHeight - topNavigationHeight)

  return thumbTop * scrollRatio
}

// Calculates how far down the scrollbar thumb can move before hitting the bottom
// Takes into account the thumb's height to prevent scrolling past the content
export function calculateScrollbarBottomBoundary(
  contentRef: ForwardedRef<HTMLElement | null>,
  scrollAreaRef: MutableRefObject<HTMLDivElement | null>
) {
  const thumbHeight = calculateScrollbarHeight(contentRef)
  const scrollAreaHeight = scrollAreaRef.current?.scrollHeight || Infinity

  return scrollAreaHeight - thumbHeight
}
