import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "@/components"
import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"

interface Props {
  total: number
  itemsPerPage?: number
  hasMore: boolean
}

const isActive = (currentPage: string | null, index: number) => {
  if (!currentPage && index === 0) {
    return true
  }
  return Number(currentPage) === index + 1
}

const isPrevDisabled = (currentPage: string | null) => {
  return currentPage === null || Number(currentPage) === 1
}

export const Paginator = ({ total, itemsPerPage = 20, hasMore }: Props) => {
  const pages = Math.ceil(total / itemsPerPage)
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = searchParams.get("page")

  const handleNext = useCallback(() => {
    if (!hasMore) return

    const nextPage = currentPage === null ? 2 : Number(currentPage) + 1

    setSearchParams((params) => {
      params.set("page", nextPage.toString())
      return params
    })
  }, [hasMore, currentPage, setSearchParams])

  const handlePrev = useCallback(() => {
    if (!currentPage || currentPage === "1") return

    const prevPage = Number(currentPage) - 1
    setSearchParams((params) => {
      params.set("page", prevPage.toString())
      return params
    })
  }, [currentPage, setSearchParams])

  const handlePageClick = useCallback(
    (page: string) => {
      setSearchParams((params) => {
        params.set("page", page)
        return params
      })
    },
    [setSearchParams]
  )

  if (total === 0) {
    return <div></div>
  }

  return (
    <Pagination>
      <PaginationPrevious disabled={isPrevDisabled(currentPage)} onClick={handlePrev} />
      <PaginationContent className="px-1">
        {Array.from({ length: pages }, (_, index) => (
          <PaginationItem
            onClick={() => handlePageClick(String(index + 1))}
            className="cursor-pointer"
            key={index + 1}
            value={index + 1}
          >
            <PaginationLink className="px-1" isActive={isActive(currentPage, index)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
      </PaginationContent>
      <PaginationNext disabled={!hasMore} onClick={handleNext} />
    </Pagination>
  )
}
