import Prism from "prismjs"
import { fileExtensionMapping, parseAndSanitizeComment } from "@/lib/utils"
import { Dispatch, FormEvent, SetStateAction, useMemo } from "react"
import { getFileExtension } from "../../utils/codeCellComputations"

interface SuggestionDiffPreviewProps {
  selectedTableFilePath: string | null
  suggestionValue: string
  newSuggestionValue: string
  setNewSuggestionValue: Dispatch<SetStateAction<string>>
}

interface RenderSuggestedCodeArgs {
  fileExtension: string | null
  suggestionValue: string
}

const renderSuggestedCode = ({ fileExtension, suggestionValue }: RenderSuggestedCodeArgs) => {
  if (fileExtension) {
    const languageGrammar = fileExtensionMapping[fileExtension].prismLanguage
    const languageName = fileExtensionMapping[fileExtension].displayName

    if (languageGrammar && languageName) {
      const highlightedCode = Prism.highlight(suggestionValue, languageGrammar, languageName)
      return parseAndSanitizeComment(highlightedCode)
    }
  }
  return ""
}

export const SuggestionDiffPreview = ({
  selectedTableFilePath,
  suggestionValue,
  setNewSuggestionValue,
  newSuggestionValue
}: SuggestionDiffPreviewProps) => {
  const fileExtension = useMemo(
    () => (selectedTableFilePath ? getFileExtension(selectedTableFilePath) : null),
    [selectedTableFilePath]
  )

  const onInput = (e: FormEvent<HTMLPreElement>) => {
    if (e.target instanceof HTMLElement) {
      setNewSuggestionValue(e.target.innerText)
    }
  }

  return (
    <div className="grid grid-rows-2 gap-4">
      <pre className="max-h-[calc((100vh-250px-250px)/2)] overflow-auto rounded-md bg-error-light px-4 py-1 text-xs">
        <code dangerouslySetInnerHTML={{ __html: renderSuggestedCode({ fileExtension, suggestionValue }) }}></code>
      </pre>
      <pre
        className="max-h-[calc((100vh-250px-250px)/2)] overflow-auto rounded-md bg-success-light px-4 py-1 text-xs"
        contentEditable={true}
        onInput={onInput}
        suppressContentEditableWarning={true}
      >
        <code
          dangerouslySetInnerHTML={{
            __html: renderSuggestedCode({ fileExtension, suggestionValue: newSuggestionValue || suggestionValue })
          }}
        ></code>
      </pre>
    </div>
  )
}
