import { LocalStorageService } from "@/lib/localStorageService"
import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { useGetFilterValue } from "@/components/designSystem/Filters/hooks/useGetFilterValue"
import { ChangesTimeSort } from "@/models/Changes"
import { useFetchChanges } from "@/hooks/queries/useFetchChanges"

const localStorageService = new LocalStorageService("localStorage")

export function useChangesFilters() {
  const [searchParams, setSearchParams] = useSearchParams()

  // Sync query params filters with localStorage
  useEffect(() => {
    const currentParams = Array.from(searchParams.entries())
    if (currentParams.length === 0) {
      const savedFiltersInLocalStorage = localStorageService.getItem("changesFilters", true)
      if (savedFiltersInLocalStorage) {
        setSearchParams((current) => {
          const newParams = new URLSearchParams(current)
          Object.entries(savedFiltersInLocalStorage).forEach(([key, value]) => {
            if (key !== "page") {
              newParams.set(key, Array.isArray(value) ? value.join(",") : value)
            }
          })
          return newParams
        })
      }
    } else {
      const filters = currentParams.reduce(
        (acc, [key, value]) => {
          if (key !== "page") {
            acc[key] = value.includes(",") ? value.split(",") : value
          }
          return acc
        },
        {} as Record<string, string | string[]>
      )
      localStorageService.setItem("changesFilters", filters)
    }
  }, [searchParams, setSearchParams])

  // Get all filter values
  const page = useGetFilterValue({ urlKey: "page", filterType: "single" })
  const authors = useGetFilterValue({ urlKey: "author", filterType: "multi" })
  const statuses = useGetFilterValue({ urlKey: "status", filterType: "multi" })
  const repositories = useGetFilterValue({ urlKey: "repository", filterType: "multi" })
  const rawTimeSort = useGetFilterValue({ urlKey: "sort", filterType: "single" })

  // Memoize filters to prevent unnecessary fetches
  const filters = useMemo(
    () => ({
      authors,
      statuses,
      page,
      repositories,
      sort: rawTimeSort as ChangesTimeSort | undefined
    }),
    [authors, statuses, page, repositories, rawTimeSort]
  )

  // Fetch data using the filters
  return useFetchChanges(filters)
}
