import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FindingConfig } from "@/models/ReviewerConfig"

export const useUpdateReviewerConfiguration = () => {
  const queryClient = useQueryClient()

  const { mutateAsync, status } = useMutation({
    mutationKey: ["updateReviewerConfiguration"],
    mutationFn: (config: FindingConfig) => axiosClient.put(apiRoutes.reviewerConfig.finding.put, config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reviewer-config"] })
    }
  })

  return { mutateAsync, status }
}
