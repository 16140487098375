import { CommandItem, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { BaseOption, OptionElementProps } from "./types"
import { substringHighlighter } from "@/lib/jsxUtils"
import { cn } from "@/lib/utils"
import { Check } from "lucide-react"

const renderContent = function <Option extends BaseOption>(searchValue: string, withTooltip: boolean, option: Option) {
  if (searchValue) return substringHighlighter(option.name, searchValue)

  if (withTooltip) {
    return (
      <Tooltip>
        <TooltipTrigger className="overflow-x-hidden text-ellipsis text-nowrap">{option.name}</TooltipTrigger>
        <TooltipContent>{option.name}</TooltipContent>
      </Tooltip>
    )
  }
  return <div className="overflow-x-hidden text-ellipsis text-nowrap">{option.name}</div>
}

export function BaseItemComponent<Option extends BaseOption>({
  onClick,
  option,
  searchValue,
  selectedValue,
  withTooltip = false
}: OptionElementProps<Option>) {
  return (
    <CommandItem value={option.value || option.name} onSelect={onClick}>
      <div>
        <Check
          className={cn(
            "mr-2 h-4 w-4 flex",
            selectedValue === option.value || selectedValue === option.name ? "opacity-100" : "opacity-0"
          )}
        />
      </div>

      {renderContent(searchValue, withTooltip, option)}
    </CommandItem>
  )
}
