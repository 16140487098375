import { Committer } from "@/models/Topics.ts"
import { useTranslation } from "react-i18next"
import { localeDateTime, relativeTime } from "@/lib/dateFormats.ts"
import { GitCommit } from "lucide-react"
import { useEffect, useRef, useState } from "react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"

export const CommitterItem = ({ committer }: { committer: Committer }) => {
  const { t } = useTranslation("Topics")
  const [isNameTextTruncated, setIsNameTextTruncated] = useState(false)
  const [isCommitTextTruncated, setIsCommitTextTruncated] = useState(false)
  const nameRef = useRef<HTMLSpanElement>(null)
  const commitRef = useRef<HTMLSpanElement>(null)
  const [isNameTooltipOpen, setIsNameTooltipOpen] = useState(false)
  const [isCommitTooltipOpen, setIsCommitTooltipOpen] = useState(false)

  useEffect(() => {
    if (nameRef.current) {
      setIsNameTextTruncated(nameRef.current.scrollWidth > nameRef.current.clientWidth)
    }
    if (commitRef.current) {
      setIsCommitTextTruncated(commitRef.current.scrollWidth > commitRef.current.clientWidth)
    }
  }, [])

  const userEmail = committer.user?.email.includes("@users.noreply.github.com")
    ? committer.user.gh_login || committer.user.email
    : committer.user?.email
  return (
    <div className="mt-2 flex items-center gap-2">
      <GitCommit width={14} height={14} />
      <TooltipProvider>
        <Tooltip open={isNameTooltipOpen}>
          <TooltipTrigger
            onMouseEnter={() => (isNameTextTruncated ? setIsNameTooltipOpen(true) : null)}
            onMouseLeave={() => setIsNameTooltipOpen(false)}
            disabled={!isNameTextTruncated}
            asChild
          >
            <span ref={nameRef} className="max-w-[33%] truncate font-bold">
              {userEmail}
            </span>
          </TooltipTrigger>
          <TooltipContent align="start">{committer.user?.name || userEmail}</TooltipContent>
        </Tooltip>

        <span>{t("Committed")}</span>

        <Tooltip open={isCommitTooltipOpen}>
          <TooltipTrigger
            onMouseEnter={() => (isCommitTextTruncated ? setIsCommitTooltipOpen(true) : null)}
            onMouseLeave={() => setIsCommitTooltipOpen(false)}
            disabled={!isCommitTextTruncated}
            asChild
          >
            <code
              ref={commitRef}
              role="button"
              tabIndex={0}
              aria-label="Open commit in github"
              className="inline-block max-w-[60%] cursor-pointer truncate font-menlo font-medium text-primary hover:bg-darker-fill"
              onClick={() => window.open(committer.githubUrl, "_blank")}
            >
              {committer.commit_sha.slice(0, 7)} {committer.message}
            </code>
          </TooltipTrigger>
          <TooltipContent align="start" className="max-w-80 overflow-auto">
            {committer.message}
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger className="cursor-default">
            <span className="text-xxs text-hint">{relativeTime(committer.date)}</span>
          </TooltipTrigger>
          <TooltipContent>{localeDateTime(committer.date)}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
