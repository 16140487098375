import { CRState } from "@/models/Changes"
import { getBackgroundColor, getChangeRequestStateIcon, getTextColor } from "./utils"
import { Badge } from "@/components/ui/Badge"
import { uppercaseString } from "@/lib/utils"
import { useTranslation } from "react-i18next"
import { ChevronDown } from "lucide-react"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"

interface Props {
  state: CRState
  is_draft: boolean
  isEditable: boolean
  isLoading: boolean
  color?: string
  bgColor?: string
}

export const CodeChangeStateBadge = ({ is_draft, state, bgColor, color, isEditable, isLoading }: Props) => {
  const { t } = useTranslation("Badges")
  const icon = getChangeRequestStateIcon({ is_draft, state, iconColor: getTextColor({ is_draft, state }) })

  const isDraftText = is_draft && state === "open"

  return (
    <Badge
      className={`flex h-[26px] gap-2 ${isEditable ? "cursor-pointer" : "cursor-default"} ${color ? color : getTextColor({ is_draft, state, textColor: color })}
      ${bgColor ? bgColor : getBackgroundColor({ is_draft, state, backgroundColor: bgColor })}`}
    >
      {icon}
      {isLoading ? (
        <SpinnerLoader size="14px" className="w-fit" color={getTextColor({ is_draft, state })} />
      ) : (
        <span>{uppercaseString(isDraftText ? t("Draft") : state)}</span>
      )}

      {isEditable && <ChevronDown className="border-l pl-2" height={14} width={24} />}
    </Badge>
  )
}
