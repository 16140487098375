import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"

type IntegrationOptions = DatadogIntegrationOptions | SlackIntegrationOptions

export interface DatadogIntegrationOptions {
  integration_type: "datadog"
  credentials: {
    api_key: string
    app_key: string
  }
  metadata: {
    org_name: string
    base_url: string
  }
}

export interface SlackIntegrationOptions {
  integration_type: "slack"
  credentials: {
    code: string
  }
  metadata: null
}

export const useCreateIntegration = () => {
  const queryClient = useQueryClient()

  const createIntegration = useCallback(
    async (options: IntegrationOptions) => await axiosClient.post(apiRoutes.integrations.post, options),
    []
  )

  const { data, mutateAsync, status, error } = useMutation({
    mutationKey: ["createIntegration"],
    mutationFn: createIntegration,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["integrations"] })
    },
    onError: (error) => {
      console.error("Failed to create Datadog integration:", error)
    }
  })

  return { data, mutateAsync, status, error }
}
