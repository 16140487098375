export const FCC = {
  Viewed: "Viewed",
  Services: "Services",
  Checks: "Checks",
  Data: "Data",
  ChangedFiles: "Changed Files",
  ExpandAll: "Expand All",
  CollapseAll: "Collapse All",
  ExpandUp: "Expand Up",
  ExpandDown: "Expand Down",
  Expand: "Expand",
  Binary: "Binary file not shown.",
  Lock: "Generated files are not rendered by default.",
  Empty: "Empty content.",
  Removed: "Removed file.",
  Renamed: "Renamed file.",
  ChangeStatus: "Change Status",
  SetAs: "Set as",
  LoadDiff: "Load diff",
  TooLong: "Very large diffs are not rendered by default.",
  Formatting: "Files with problematic formatting are not rendered.",
  FilesChanged: "Files Changed",
  ChangeDiffView: "Change Diff View"
}
