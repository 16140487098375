import { CellContext } from "@tanstack/react-table"
import { FileCodeLine } from "../../types"

interface Props {
  info: CellContext<FileCodeLine, FileCodeLine>
}

export const NumberCell = ({ info }: Props) => {
  const line = info.getValue()

  return <div className="flex size-full items-center justify-center border-r">{line.lineNumber}</div>
}
