import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { ReactElement } from "react"
import { useGithubRepoWriteAccess } from "@/hooks/useGithubRepoWriteAccess"

interface Props {
  children: (disabled: boolean) => ReactElement
  repoFullName: string
  className?: string
  side?: "top" | "bottom" | "left" | "right"
}

export const GithubWriteAccessButtonWrapper = ({
  children,
  repoFullName,
  side = "bottom",
  className = "bg-background shadow shadow-border p-2"
}: Props) => {
  const { toolTipContent, hasAccess } = useGithubRepoWriteAccess(repoFullName)

  const renderedChildren = children(hasAccess)

  if (hasAccess) {
    return renderedChildren
  }

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger className="cursor-not-allowed">
          {renderedChildren}
          <TooltipContent side={side} className={className}>
            {toolTipContent}
          </TooltipContent>
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  )
}
