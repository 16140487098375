import { CRState } from "@/models/Changes"
import { GitPullRequest, GitPullRequestClosed, GitPullRequestDraft, GitMerge } from "lucide-react"

export interface MinimalArgs {
  state: CRState
  is_draft: boolean
}

export interface GetChangeRequestStateIconArgs extends MinimalArgs {
  iconColor?: string
}

export interface GetTextColorArgs extends MinimalArgs {
  textColor?: string
}

export interface GetBackgroundColorArgs extends MinimalArgs {
  backgroundColor?: string
}

export const getChangeRequestStateIcon = ({ state, iconColor }: GetChangeRequestStateIconArgs) => {
  if (state === "draft")
    return <GitPullRequestDraft width={16} height={16} className={`${iconColor ? iconColor : "text-primary"}`} />

  if (state === "open")
    return <GitPullRequest width={16} height={16} className={`${iconColor ? iconColor : "text-success-dark"}`} />

  if (state === "merged")
    return <GitMerge width={16} height={16} className={`${iconColor ? iconColor : "text-link"}`} />

  if (state === "closed")
    return <GitPullRequestClosed width={16} height={16} className={`${iconColor ? iconColor : "text-error-dark"}`} />
}

export const getTextColor = ({ state, textColor }: GetTextColorArgs) => {
  if (state === "draft") return textColor ? textColor : "text-primary"
  if (state === "open") return textColor ? textColor : "text-background"
  if (state === "merged") return textColor ? textColor : "text-background"
  if (state === "closed") return textColor ? textColor : "text-background"
}

export const getBackgroundColor = ({ state, backgroundColor }: GetBackgroundColorArgs) => {
  if (state === "draft") return backgroundColor ? backgroundColor : "bg-fill"
  if (state === "open") return backgroundColor ? backgroundColor : "bg-success-dark"
  if (state === "merged") return backgroundColor ? backgroundColor : "bg-link"
  if (state === "closed") return backgroundColor ? backgroundColor : "bg-error-dark"
}
