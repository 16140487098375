import { useCallback, useEffect, useState } from "react"
import { CenteredLayout } from "@/pages/CenteredLayout"
import { runtimeConfig } from "@/lib/config"
import { datadogRum } from "@datadog/browser-rum"
import { usePermissions } from "@/services/store/usePermissions"
import { useDescope, useSession } from "@descope/react-sdk"
import { useFetchOnboardingState } from "@/hooks/queries/useFetchOnboardingState"
import { Navigate, useLocation } from "react-router-dom"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { LocalStorageService } from "@/lib/localStorageService"
import { routes } from "@/lib/routes"
import { useFetchGithubIntegrations } from "@/hooks/queries/useFetchGithubIntegrations"
import { OnboardingProgress } from "@/pages/onboarding/OnboardingProgress"
import { ReviewerWorkflow } from "@/pages/onboarding/ReviewerWorkflow"

const localStorageService = new LocalStorageService("sessionStorage")
const STUCK_THRESHOLD = 60000

export function Onboarding() {
  document.title = "Baz"
  const [showReviewer, setShowReviewer] = useState(false)
  const [pageLoadTime] = useState(Date.now())

  const { isAuthenticated, isSessionLoading } = useSession()
  const { data: onboardingData, error } = useFetchOnboardingState(isAuthenticated, 6000)
  const {
    data: githubIntegrationsData,
    error: githubIntegrationsError,
    isLoading: githubIntegrationsLoading
  } = useFetchGithubIntegrations({ page: null })

  const { setUser } = usePermissions()
  const { logout } = useDescope()

  const { pathname, search } = useLocation()

  const hasOnboardingError = onboardingData?.data?.error != null
  const hasQueryError = error != null
  const hasGithubError = githubIntegrationsError != null

  const isError = hasOnboardingError || hasQueryError || hasGithubError

  // Set up timer to show reviewer page after 60 seconds
  useEffect(() => {
    if (onboardingData?.data?.pullRequests !== "done") {
      const timer = setTimeout(() => {
        const timeOnPage = Date.now() - pageLoadTime
        if (!isError && timeOnPage >= STUCK_THRESHOLD) {
          setShowReviewer(true)
        }
      }, STUCK_THRESHOLD)

      return () => clearTimeout(timer)
    }
  }, [pageLoadTime, onboardingData?.data?.pullRequests, isError])

  const handleLogout = useCallback(() => {
    logout()
    setUser(null)

    if (runtimeConfig.VITE_ENABLE_TRACING === "true") {
      datadogRum.stopSession()
    }
  }, [logout, setUser])

  localStorageService.setItem("from", pathname + search || "")

  if (!isAuthenticated) {
    return <Navigate to={routes.login} />
  }

  if (isSessionLoading || githubIntegrationsLoading) {
    return <SpinnerLoader className="pt-6" />
  }

  if (onboardingData?.data?.isDoneOnboarding) {
    return <Navigate to={routes.root} />
  }

  if (githubIntegrationsData && githubIntegrationsData.installations.length === 0) {
    return <Navigate to={routes.onboarding.connect} />
  }

  return (
    <CenteredLayout>
      {onboardingData?.data?.pullRequests !== "done" && !showReviewer ? (
        <OnboardingProgress isError={isError} onboardingStatus={onboardingData?.data} onLogout={handleLogout} />
      ) : (
        <ReviewerWorkflow wasStuck={showReviewer} />
      )}
    </CenteredLayout>
  )
}
