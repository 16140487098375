import { useSettings } from "@/services/store/useSettings.ts"
import BazIconLight from "@/assets/icons/BazAvatar-light.svg?react"
import BazIconDark from "@/assets/icons/BazAvatar-dark.svg?react"

export default function AnimatedBazzyLoader() {
  const { selectedTheme } = useSettings()
  const BazIcon = selectedTheme === "light" ? BazIconLight : BazIconDark

  return (
    <div className="relative flex size-24 items-center justify-center">
      {/* Outer rotating rect */}
      <div className="absolute inset-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute size-full animate-spin"
          viewBox="0 0 34 34"
          style={{ animationDuration: "1.5s" }}
          fill="none"
        >
          <rect width="32.5" height="32.5" x="0.75" y="0.75" fill="var(--background)" rx="16.25" />
          <rect
            width="32.5"
            height="32.5"
            x="0.75"
            y="0.75"
            stroke="url(#outer_gradient)"
            strokeWidth="1.5"
            rx="16.25"
          />
          <defs>
            <linearGradient id="outer_gradient" x1="5.8" x2="28.2" y1="17" y2="17" gradientUnits="userSpaceOnUse">
              <stop stopColor="#53F" />
              <stop offset="1" stopColor="var(--background)" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {/* Inner rotating dotted circle */}
      <div className="absolute inset-[2px]">
        <svg
          className="absolute size-full animate-spin"
          viewBox="0 0 28 28"
          style={{ animationDuration: "1s", animationDirection: "reverse" }}
          fill="none"
        >
          <circle
            cx="14"
            cy="14.002"
            r="13.076"
            stroke="url(#inner_gradient)"
            strokeDasharray="1 2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <radialGradient
              id="inner_gradient"
              cx="0"
              cy="0"
              r="1"
              gradientTransform="rotate(-90 20.538 6.539)scale(26.1515)"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7E68EF" />
              <stop offset="0.47" stopColor="var(--background)" />
            </radialGradient>
          </defs>
        </svg>
      </div>

      {/* Centered background to ensure icon visibility */}
      <div className="absolute inset-[16px] rounded-full bg-background" />

      {/* Bazzy Icon positioned on top */}
      <div className="absolute z-10">
        <BazIcon width={12} height={18} />
      </div>
    </div>
  )
}
