import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components"
import { MergeStatus } from "@/models/Changes.ts"
import { MergeAction } from "./MergeAction.tsx"
import { BlockerBox } from "../../../BlockerBox.tsx"
import { MutationStatus } from "@/models/Common.ts"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  getFormattedCiRuns,
  getFormattedConflicts,
  getFormattedReviewsStatus,
  getUnresolvedDiscussionsBlocker
} from "@/pages/crFileChanges/utils.ts"

interface Props {
  onTriggerClick: (mergeStatus: MergeStatus) => void
  onClose: () => void
  open: boolean
  mergeStatus: MergeStatus
  crStatus: string
  status: MutationStatus
  isPrimary: boolean
}

export const BlockersDialog = ({ onClose, onTriggerClick, open, mergeStatus, crStatus, status, isPrimary }: Props) => {
  const [showAllCIChecks, setShowAllCIChecks] = useState(false)
  const { t } = useTranslation("Toolbar")

  const toggleCIChecks = useCallback(() => {
    setShowAllCIChecks((prev) => !prev)
  }, [])

  const isDisabled = crStatus === "merged"
  return (
    <Dialog open={open} modal={false}>
      <DialogTrigger disabled={isDisabled}>
        <MergeAction
          mergeStatus={mergeStatus}
          onTriggerClick={onTriggerClick}
          isDisabled={isDisabled}
          crStatus={crStatus}
          status={status}
          isPrimary={isPrimary}
        />
      </DialogTrigger>

      <DialogContent
        onPointerDownOutside={onClose}
        onEscapeKeyDown={onClose}
        className="max-w-dialog gap-[2px] border-0 bg-transparent p-0"
      >
        <DialogTitle className="sr-only">Blockers dialog</DialogTitle>
        <BlockerBox title={t("ApprovalsRequired")} items={getFormattedReviewsStatus(mergeStatus.review_status)} />
        <BlockerBox
          title={t("ChecksNotSuccessfulTitle")}
          items={getFormattedCiRuns(mergeStatus.ci_runs, showAllCIChecks)}
          filterButton={showAllCIChecks ? t("ShowLess") : t("ShowAll")}
          filterFunction={toggleCIChecks}
        />
        <BlockerBox title={t("FileConflictsTitle")} items={getFormattedConflicts(mergeStatus.conflicts)} />
        {mergeStatus.has_unresolved_discussions && (
          <BlockerBox
            title={t("UnresolvedDiscussions")}
            items={getUnresolvedDiscussionsBlocker(t("UnresolvedDiscussionsDescription"))}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
