import { useCallback, useState } from "react"
import { BlockersDialog } from "./BlockersDialog.tsx"
import { useFetchMergeBlockers } from "@/hooks/queries/useFetchMergeStatus.tsx"
import { MergeStatus, MergeStrategy, ChangeRequest } from "@/models/Changes.ts"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader.tsx"
import { errorHandler } from "@/services/api/helpers.ts"
import { MergeDropdown } from "./MergeDropdown.tsx"
import { useMergeChangeRequest } from "@/hooks/mutations/useMergeChangeRequest.tsx"

interface Props {
  changeRequest: ChangeRequest
  isPrimary: boolean
}

export const MergeButton = ({ changeRequest, isPrimary }: Props) => {
  const [blockersDialogOpen, setBlockersDialogOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { data, error, isLoading } = useFetchMergeBlockers(changeRequest.id)
  const { mutateAsync, status } = useMergeChangeRequest()

  const handleDialogTriggerClick = useCallback(
    (mergeStatus: MergeStatus) => {
      const { is_mergeable } = mergeStatus

      if (!is_mergeable) setBlockersDialogOpen((open) => !open)
    },
    [setBlockersDialogOpen]
  )

  const handleMergeBlockersClose = useCallback(() => {
    setBlockersDialogOpen(false)
  }, [setBlockersDialogOpen])

  const handleDropdownTriggerClick = useCallback(() => setDropdownOpen((open) => !open), [setDropdownOpen])
  const handleDropdownClose = useCallback(() => setDropdownOpen(false), [setDropdownOpen])

  const handleItemClick = useCallback(
    (value: MergeStrategy) => {
      mutateAsync({ changeId: changeRequest.id, mergeStrategy: value })
      handleDropdownClose()
    },
    [mutateAsync, handleDropdownClose, changeRequest.id]
  )

  if (isLoading || !data) {
    return (
      <div className="flex w-full justify-center">
        <SpinnerLoader size="16px" />
      </div>
    )
  }

  if (error) {
    return <div className="flex w-full justify-center">{errorHandler(error)}</div>
  }

  return (
    <div className="flex w-full gap-2">
      {data.is_mergeable ? (
        <MergeDropdown
          mergeStatus={data}
          open={dropdownOpen}
          onItemClick={handleItemClick}
          onTriggerClick={handleDropdownTriggerClick}
          onClose={handleDropdownClose}
          status={status}
          crStatus={changeRequest.state}
          isPrimary={isPrimary}
        />
      ) : (
        <BlockersDialog
          open={blockersDialogOpen}
          onClose={handleMergeBlockersClose}
          onTriggerClick={handleDialogTriggerClick}
          mergeStatus={data}
          crStatus={changeRequest.state}
          status={status}
          isPrimary={isPrimary}
        />
      )}
    </div>
  )
}
