import { Dispatch, SetStateAction } from "react"
import { clearFilterFromLocalStorage, setFilterValueToLocalStorage } from "../utils"
import { NavigateFunction } from "react-router-dom"

interface MultiApplyValueArgs {
  urlFilter?: string | null
  searchParams: URLSearchParams
  urlKey: string
  setIsChanged: Dispatch<SetStateAction<boolean>>
  setOpen: Dispatch<SetStateAction<boolean>>
  navigate: NavigateFunction
  isSyncedWithLocalStorage?: boolean
  localStorageKey?: string
  selectedValuesArray: string[]
  pathname: string
  computedValue: string | null
  applyCase: string
}

interface SyncMultiValueWithLocalStorageArgs {
  isSyncedWithLocalStorage?: boolean
  localStorageKey?: string
  selectedValuesArray: string[]
  urlKey: string
}

export const multiApplyValue = ({
  navigate,
  searchParams,
  setIsChanged,
  setOpen,
  urlKey,
  isSyncedWithLocalStorage,
  localStorageKey,
  selectedValuesArray,
  pathname,
  computedValue,
  urlFilter,
  applyCase
}: MultiApplyValueArgs) => {
  if (applyCase === "delete") {
    searchParams.delete(urlKey)
  } else {
    !urlFilter
      ? searchParams.append(urlKey, computedValue as string)
      : searchParams.set(urlKey, computedValue as string)
  }

  setIsChanged(false)
  setOpen(false)
  syncMultiValueWithLocalStorage({ selectedValuesArray, urlKey, isSyncedWithLocalStorage, localStorageKey })

  navigate({
    pathname,
    search: `?${searchParams.toString()}`
  })
}

export const syncMultiValueWithLocalStorage = ({
  isSyncedWithLocalStorage,
  localStorageKey,
  selectedValuesArray,
  urlKey
}: SyncMultiValueWithLocalStorageArgs) => {
  if (isSyncedWithLocalStorage && localStorageKey) {
    // if the user selected apply without filters lets remove the filter key
    if (selectedValuesArray.length === 0) {
      clearFilterFromLocalStorage(localStorageKey, urlKey)
    } else {
      setFilterValueToLocalStorage(selectedValuesArray, localStorageKey, urlKey)
    }
  }
}

export const checkSetsEquality = (set1: Set<unknown>, set2: Set<unknown>) => {
  if (set1.size !== set2.size) {
    return false
  }

  for (const item of set1) {
    if (!set2.has(item)) {
      return false
    }
  }

  return true
}
