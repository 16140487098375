import { FileWithDiffs, SelectedLines, FccRow, Side } from "@/models/FCC.ts"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

interface SetSuggestionsProps {
  selectedFile: string | null
  selectedLines: SelectedLines
  selectedSide: Side | null
}

interface State {
  isModalOpen: boolean
  suggestionValue: string
  newSuggestionValue: string
  setSuggestionModalOpen: () => void
  setSuggestionModalClose: () => void
  diffData: FileWithDiffs[]
  setDiffData: (diffData: FileWithDiffs[]) => void
  setSuggestionValue: (setSuggestionsProps: SetSuggestionsProps) => void
  setNewSuggestionValue: (newSuggestionValue: string) => void
  clearNewSuggestionValue: () => void
  getLineContent(side: Side, lineNumber: number, lines: FccRow[] | undefined): string
}

export const useSuggestion = create<State>()(
  immer((set, get) => ({
    isModalOpen: false,
    diffData: [],
    suggestionValue: "",
    newSuggestionValue: "",
    setSuggestionModalOpen() {
      set((state) => {
        state.isModalOpen = true
      })
    },
    setSuggestionModalClose() {
      set((state) => {
        state.isModalOpen = false
        state.suggestionValue = ""
        state.newSuggestionValue = ""
      })
    },
    setDiffData(diffData) {
      set((state) => {
        state.diffData = diffData
      })
    },
    setSuggestionValue({ selectedFile, selectedLines, selectedSide }) {
      const file = get().diffData.find((file) => file.file_relative_path === selectedFile)
      const extractedLines = file?.chunks.flatMap((chunk) => [
        ...chunk.before_lines,
        ...chunk.lines,
        ...chunk.after_lines
      ])
      let result: string = ""

      if ((!selectedLines.endLine && !selectedLines.startLine) || !selectedSide) {
        console.error("Cannot set suggestion content, No selected lines or side")
        return
      }

      if (selectedLines.startLine === selectedLines.endLine && selectedLines.endLine) {
        // if lines are the same only one line is selected
        result = get().getLineContent(selectedSide, selectedLines.endLine, extractedLines || [])
      } else if (selectedLines.endLine && selectedLines.startLine) {
        for (let i = selectedLines.startLine; i <= selectedLines.endLine; i++) {
          result += get().getLineContent(selectedSide, i, extractedLines) + "\n"
        }
      }
      set((state) => {
        state.suggestionValue = result
      })
    },
    getLineContent(side: Side, lineNumber: number, lines: FccRow[] | undefined): string {
      if (side === "right") {
        return lines?.find((line) => line.new_line_number === lineNumber)?.new_content || ""
      }
      return lines?.find((line) => line.number === lineNumber)?.content || ""
    },
    setNewSuggestionValue: (newSuggestionValue: string) => {
      set((state) => {
        state.newSuggestionValue = newSuggestionValue
      })
    },
    clearNewSuggestionValue: () =>
      set((state) => {
        state.newSuggestionValue = ""
      })
  }))
)
