import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { User } from "@/models/User"

export const useFetchUser = () => {
  const { error, isLoading, data } = useQuery<User, unknown>({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.auth.user.get)

      return response.data
    }
  })

  return { error, isLoading, data: data! }
}
