import { Skeleton } from "@/components"
import { cn } from "@/lib/utils"

interface Props {
  className?: string
}

export const SkeletonLoader = ({ className }: Props) => {
  return (
    <div data-testid="skeleton-loader" className={cn("flex flex-col w-full h-full gap-8 min-w-full p-2", className)}>
      <div className="flex w-full items-center space-x-4">
        <Skeleton className="size-12 rounded-full" />
        <div className="w-full space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-[90%]" />
        </div>
      </div>

      <div className="flex w-full items-center space-x-4">
        <Skeleton className="size-12 rounded-full" />
        <div className="w-full space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-[90%]" />
        </div>
      </div>

      <div className="flex w-full items-center space-x-4">
        <Skeleton className="size-12 rounded-full" />
        <div className="w-full space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-[90%]" />
        </div>
      </div>
    </div>
  )
}
