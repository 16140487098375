import { Button } from "@/components"
import { MergeStatus, MergeStrategy } from "@/models/Changes.ts"
import { MutationStatus } from "@/models/Common.ts"
import { CrossCircledIcon, CheckCircledIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

interface Props {
  mergeStatus: MergeStatus
  onTriggerClick: (mergeStatus: MergeStatus) => void
  isDisabled: boolean
  crStatus: string
  status: MutationStatus
  isPrimary: boolean
}

interface Args {
  crStatus: string
  is_mergeable: boolean
  default_merge_strategy: MergeStrategy
  t: TFunction<"Toolbar", undefined>
  status: MutationStatus
  isPrimary: boolean
}

const getButtonUi = ({ default_merge_strategy, is_mergeable, crStatus, t, status, isPrimary }: Args) => {
  if (crStatus === "merged" || status === "success") {
    return <span className={`w-[120px] ${isPrimary ? "text-white" : "text-primary"}`}>{t("merged")}</span>
  }

  return is_mergeable ? (
    <>
      <CheckCircledIcon className="text-green-400" width={16} height={16} />
      <span className="text-xs">{t(default_merge_strategy)}</span>
      <ChevronDownIcon width={16} height={16} />
    </>
  ) : (
    <>
      <CrossCircledIcon className="text-red-400" width={16} height={16} />
      <span className="text-xs">{t("MergeBlockers")}</span>
    </>
  )
}

export const MergeAction = ({ mergeStatus, onTriggerClick, isDisabled, crStatus, status, isPrimary }: Props) => {
  const { is_mergeable, default_merge_strategy } = mergeStatus
  const { t } = useTranslation("Toolbar")

  return (
    <div className="w-full">
      <Button
        onClick={() => onTriggerClick(mergeStatus)}
        variant={isPrimary ? "default" : "outline"}
        className="flex h-8 w-fit items-center gap-1 p-0 px-2"
        disabled={isDisabled}
      >
        {getButtonUi({ default_merge_strategy, is_mergeable, crStatus, t, status, isPrimary })}
      </Button>
    </div>
  )
}
