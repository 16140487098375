import { formatName } from "@/lib/utils"
import { formatTime } from "@/lib/dateFormats"
import { DropDown } from "@/components/designSystem/Discussions/components/Comment/Dropdown"
import { useCallback, MouseEvent } from "react"
import { MoreHorizontal } from "lucide-react"
import { Comment as CommentInterface } from "@/models/Discussions"

interface Props {
  dropDownOpen: boolean
  handleEditClick: (event: MouseEvent<Element>) => void
  handleToggleMenu: (
    event:
      | MouseEvent<Element>
      | CustomEvent<{
          originalEvent: PointerEvent
        }>
  ) => void
  handleDelete: (event: MouseEvent<Element>) => Promise<void>
  comment: CommentInterface
  onCommentClick?: () => void
  isClickable: boolean
}

export const CommentCreationData = ({
  dropDownOpen,
  comment,
  handleEditClick,
  onCommentClick,
  isClickable,
  handleToggleMenu,
  handleDelete
}: Props) => {
  const handleIconClick = useCallback((event: MouseEvent<Element>) => {
    event.stopPropagation()
  }, [])

  return (
    <div
      className={`mb-1 flex items-center ${isClickable ? "cursor-pointer" : "cursor-default"}`}
      role="button"
      onClick={onCommentClick}
    >
      <div className="flex flex-col">
        <div>
          <span className="mr-1 text-xs text-primary">{formatName(comment.author)}</span>
          <span className="mr-1 text-xxs text-secondary">{formatTime(comment.created_at)}</span>
        </div>
      </div>

      {comment.editable ? (
        <MoreHorizontal
          className="block cursor-pointer text-primary transition"
          width={16}
          height={16}
          onClick={handleToggleMenu}
        />
      ) : null}

      <DropDown
        open={dropDownOpen}
        onToggle={handleToggleMenu}
        onIconClick={handleIconClick}
        onEditClick={handleEditClick}
        onDelete={handleDelete}
      />
    </div>
  )
}
