import { Separator } from "@/components"
import BazLogoLight from "@/assets/icons/BazLogo-light.tsx"
import BazLogoDark from "@/assets/icons/BazLogo-dark.tsx"
import { useSettings } from "@/services/store/useSettings.ts"
import { show } from "@intercom/messenger-js-sdk"
import { useEffect, useState } from "react"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useLocation } from "react-router-dom"

const FOOTER_OFFSET = 80

const buttons = [
  {
    title: "Help",
    action: () => {
      window.open("https://docs.baz.co/", "_blank")
    }
  },
  {
    title: "Contact",
    action: () => {
      show()
    }
  },
  {
    title: "About",
    action: () => {
      window.open("https://baz.co/about", "_blank")
    }
  }
]

export const Footer = () => {
  const { selectedTheme, sidebarWidth } = useSettings()
  const { discussionsWithTop, manualRecalculationTrigger } = useDiscussions()
  const Icon = selectedTheme === "light" ? BazLogoLight : BazLogoDark
  const currentYear = new Date().getFullYear()
  const [discussionOverflow, setDiscussionOverflow] = useState(0)
  const { pathname, search } = useLocation()

  useEffect(() => {
    const contentHeight = document.querySelector<HTMLElement>("div[data-id='content-height-wrapper']")?.clientHeight
    const lastDiscussion = document.querySelector<HTMLElement>(
      `div[data-discussion-number='${discussionsWithTop.length - 1}']`
    )

    if (!contentHeight || !lastDiscussion) {
      setDiscussionOverflow(0)
      return
    }

    const lastDiscussionBottom = lastDiscussion.offsetTop + lastDiscussion.offsetHeight

    if (lastDiscussionBottom + FOOTER_OFFSET >= contentHeight) {
      setDiscussionOverflow(lastDiscussionBottom - contentHeight + FOOTER_OFFSET)
    } else {
      setDiscussionOverflow(0)
    }
  }, [discussionsWithTop, manualRecalculationTrigger, pathname, search])

  return (
    <footer
      style={{ width: `calc(100% - ${sidebarWidth}px)`, left: `${sidebarWidth}px`, bottom: -discussionOverflow }}
      className="absolute flex w-full flex-col px-6 pb-6 pt-0"
    >
      <Separator orientation="horizontal" />
      <div className="flex w-full flex-row items-center pt-6">
        <div className="flex grow flex-row items-center">
          <div onClick={() => window.open("https://baz.co/", "_blank")} className="cursor-pointer">
            {Icon ? <Icon width="86" height="32" /> : null}
          </div>
          {buttons.map((button) => (
            <div
              key={button.title}
              onClick={button.action}
              className="cursor-pointer pr-4 text-xs leading-5 text-secondary hover:underline"
            >
              {button.title}
            </div>
          ))}
        </div>
        <div className="text-xs leading-5 text-hint">Copyright © {currentYear} Baz. All rights reserved.</div>
      </div>
    </footer>
  )
}
