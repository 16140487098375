export interface RepoWriteAccess {
  hasAccess: boolean
  reason: WriteAccessDeniedReason
}

export enum WriteAccessDeniedReason {
  MISSING_USER_INSTALLATION = "missing-user-installation",
  MISSING_ORG_INSTALLATION = "missing-org-installation",
  REPO_NOT_CONFIGURED = "repo-not-configured"
}
