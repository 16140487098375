export const Insight = {
  Invocations: "invocations",
  Invocation: "invocation",
  MsAvg: "ms avg (last 24h)",
  Errors: "Errors",
  BreakingChanges: "Breaking changes",
  BreakingChange: "Breaking change",
  Error: "Error",
  FailedChecks: "Failed checks",
  Times: "times",
  Time: "time",
  InternalCodeCall: "Internal Code Call",
  Usage: "Usage",
  Checks: "Checks",
  checksHeader: "Test logs and suggested fixes",
  developersHeader: "Last contributors and commits",
  usageHeader: "Code elements impacting APls",
  filesHeader: "Files associated with the topic",
  Explain: "Explain",
  AskBazzy: "Ask Bazzy"
}
