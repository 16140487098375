export const Bazzy = {
  Header: "Chat with Bazzy about this change",
  MessageBazzy: "Message Bazzy",
  Disclaimer: "This conversation is private and will not be saved.",
  Line: "line",
  Lines: "lines",
  AskBazzy: "Ask Bazzy",
  FailedToGetBazzysAnswer: "Failed to get Bazzy's answer. please try again later.",
  ClosedBazzyTooltip: "Get answers and suggestions powered by AI on your codebase",
  EmptyBazzyHeader: "Mark the code, and ask me anything!",
  EmptyBazzyMessage: "Bazzy can explain and improve this change request.",
  DialogTitle: "Bazzy AI Assistant",
  DialogDescription: "AI assistant panel for code analysis and questions",
  BazzyError: "Hmm, I couldn't find an answer this time. Try rephrasing your question or providing more context",
  CreateSuggestion: "Create suggestion"
}
