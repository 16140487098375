export const DEFAULT_SIZE = 150
export const CENTERED_TEXT_CELL_TYPES = ["number"]

export const calculateHeaderSize = (size: number) => {
  return size === DEFAULT_SIZE ? "100%" : size.toString() + "px"
}

export const setCellTextAlignment = (cellType: string) => {
  return CENTERED_TEXT_CELL_TYPES.includes(cellType) ? "text-center" : "text-left"
}
