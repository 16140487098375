import type { Components } from "react-markdown"
import { RenderCodeBlock } from "@/lib/mdTransformer/RenderCodeBlock.tsx"
import { MdTransformerOptions } from "./types"
import { Themes } from "@/services/store/useSettings.ts"

export const createMarkdownComponents = (theme: Themes, options?: MdTransformerOptions): Components => ({
  ol: ({ children, ...props }) => (
    <ol className="my-0 list-decimal pl-6" {...props}>
      {children}
    </ol>
  ),
  ul: ({ children, ...props }) => (
    <ul className="my-0 list-disc pl-6" {...props}>
      {children}
    </ul>
  ),
  li: ({ children, ...props }) => {
    if (Array.isArray(children)) {
      const processedChildren = children.map((child) => {
        if (typeof child === "object" && child?.type === "p") {
          return child.props.children
        }
        return child
      })
      return (
        <li className="leading-normal marker:mr-2 marker:inline-block" {...props}>
          {processedChildren}
        </li>
      )
    }
    return (
      <li className="leading-normal marker:mr-2 marker:inline-block" {...props}>
        {children}
      </li>
    )
  },
  code: (props) => {
    return RenderCodeBlock({ props, theme, options })
  },
  p: ({ children, ...props }) => {
    return (
      <p className="inline-block" {...props}>
        {children}
      </p>
    )
  },
  table: ({ children, ...rest }) => (
    <table className="my-2" {...rest}>
      {children}
    </table>
  ),
  td: ({ children, ...rest }) => (
    <td className="border p-1" {...rest}>
      {children}
    </td>
  ),
  th: ({ children, ...rest }) => (
    <th className="border" {...rest}>
      {children}
    </th>
  ),
  a: ({ children, ...rest }) => (
    <a className="border-b border-link text-link" {...rest}>
      {children}
    </a>
  ),
  img: ({ src, alt }) => (
    <a className="border-b border-link text-link" href={src} target="_blank">
      {alt || "Image"}
    </a>
  ),
  blockquote: ({ children }) => (
    <div>
      <blockquote className="my-4 border-s-4 border-primary bg-border p-2">{children}</blockquote>
    </div>
  )
})
