import { useCallback, useState } from "react"
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { Command, CommandGroup } from "@/components"
import { Popover, PopoverContent, PopoverTrigger } from "@/components"
import { Sorter } from "../types"
import { useSearchParams } from "react-router-dom"
import { displayMainLabel } from "../utils"
import { SelectOption } from "../components/SelectOption"
import { useTranslation } from "react-i18next"

interface Props {
  sorter: Sorter
  align?: "center" | "end" | "start"
  Icon?: JSX.Element
  withTooltip: boolean
  mainTooltip?: string
}

export function SorterFilter({ sorter, align = "start", Icon, withTooltip, mainTooltip }: Props) {
  const [open, setOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation("Common")
  const urlFilter = searchParams.get(sorter.urlKey) || sorter.defaultValue

  const handleSelect = useCallback(
    (value: string) => {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params)
        newParams.set(sorter.urlKey, value)
        return newParams
      })
      setOpen(false)
    },
    [setSearchParams, sorter.urlKey]
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <TooltipProvider>
        <PopoverTrigger>
          <Tooltip>
            <TooltipTrigger>
              <Button variant="secondary" role="combobox" aria-expanded={open} className="h-7 px-2 py-1">
                {Icon || null}
                <p className="min-w-16">{displayMainLabel(urlFilter, sorter)}</p>
              </Button>
            </TooltipTrigger>
            {withTooltip && <TooltipContent>{mainTooltip ? mainTooltip : t("NoTooltipContent")}</TooltipContent>}
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent className="w-[150px]" align={align}>
          <Command>
            <CommandGroup>
              {sorter.options.map((option) => (
                <SelectOption
                  key={option.value}
                  withTooltip={withTooltip}
                  urlFilter={urlFilter}
                  option={option}
                  handleSelect={handleSelect}
                />
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </TooltipProvider>
    </Popover>
  )
}
