const Checkmark = ({ size = 14, color = "var(--base-hint)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={Math.round((size * 10) / 14)}
      viewBox="0 0 14 10"
      fill="none"
    >
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12.334 1L5 8.333 1.667 5" />
    </svg>
  )
}

export default Checkmark
