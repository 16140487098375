import { useMemo } from "react"
import { BazzyMessage } from "@/models/Bazzy.ts"
import { cn } from "@/lib/utils.ts"
import { Avatar, Badge } from "@/components"
import { mdTransformer } from "@/lib/mdTransformer"
import { useSettings } from "@/services/store/useSettings.ts"
import {
  CODE_BLOCK_REGEX,
  combineCodeBlockWithLineNumbers,
  getMatchIterator,
  getRawCodeFromRegexMatch,
  getRawNumbersFromRegexMatch,
  SUGGESTION_LINES_REGEX
} from "@/components/designSystem/Discussions/components/Bazzy/bazzySuggestionUtils.ts"
import BazIconLight from "@/assets/icons/BazAvatar-light.svg?react"
import BazIconDark from "@/assets/icons/BazAvatar-dark.svg?react"
import AnimatedBazzyLoader from "@/assets/icons/AnimatedBazzyLoader.tsx"
import { useUser } from "@descope/react-sdk"
import { useFetchUser } from "@/hooks/queries/useFetchUser"

interface TextProps {
  content: string
}

const UserText = ({ content }: TextProps) => {
  const { user } = useUser()
  const { data } = useFetchUser()

  if (!content) return null

  return (
    <div className="flex flex-row-reverse">
      <div className="ml-4 flex size-8 items-center justify-center rounded-full border-[1.5px] border-hint p-[5px]">
        <Avatar className="size-6 rounded-full" username={user?.name} avatarURL={data?.avatar_url} />
      </div>
      <Badge className="flex rounded-md bg-primary py-[1.25px] text-xxs text-background">{content}</Badge>
    </div>
  )
}

const BazzyIcon = ({ isLoading }: { isLoading: boolean }) => {
  const { selectedTheme } = useSettings()
  const BazIcon = selectedTheme === "light" ? BazIconLight : BazIconDark

  return (
    <div className="relative mr-4 flex size-8 items-center justify-center">
      {isLoading ? (
        <AnimatedBazzyLoader />
      ) : (
        <div className="flex size-8 items-center justify-center rounded-full border-[1.5px] border-hint p-[5px]">
          <BazIcon width={12} height={18} />
        </div>
      )}
    </div>
  )
}

const BazzyContent = ({ content, isLoading }: TextProps & { isLoading: boolean }) => {
  const codeBlocksIterator = getMatchIterator(content, CODE_BLOCK_REGEX)
  const linesIterator = getMatchIterator(content, SUGGESTION_LINES_REGEX)
  const { selectedTheme } = useSettings()
  const lineNumbers = useMemo(() => getRawNumbersFromRegexMatch(linesIterator), [linesIterator])
  const codeBlocks = useMemo(() => getRawCodeFromRegexMatch(codeBlocksIterator), [codeBlocksIterator])
  const codeWithLinesMap = useMemo(
    () => combineCodeBlockWithLineNumbers(lineNumbers, codeBlocks),
    [lineNumbers, codeBlocks]
  )

  return (
    <div className="flex min-h-[1em] break-words">
      <BazzyIcon isLoading={isLoading || !content} />
      {!isLoading && content && (
        <div className="bazzy-style whitespace-pre-wrap text-sm text-primary">
          {mdTransformer(content, selectedTheme, {
            isBazzy: true,
            codeBlocksWithLines: codeWithLinesMap
          })}
        </div>
      )}
    </div>
  )
}

export const Message = ({ role, content, isLoading = true }: BazzyMessage) => {
  return (
    <div className={cn("mt-4 flex w-full", role === "user" ? "justify-end" : "justify-start")}>
      {role === "user" ? <UserText content={content} /> : <BazzyContent content={content} isLoading={isLoading} />}
    </div>
  )
}
