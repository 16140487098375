import { runtimeConfig } from "@/lib/config"

export const githubUrl = `https://github.com/apps/${runtimeConfig.VITE_GITHUB_APP_NAME}/installations/new`
export const githubWriteUrl = `https://github.com/apps/${runtimeConfig.VITE_GITHUB_WRITE_APP_NAME}/installations/new`

export interface Installation {
  installationId: number
  account: string
  accountType: string
  createdAt: string
  lastUpdatedAt: string
  status: InstallationStatus
  repoSelection: string
  writeAppConfigured: boolean
  writeAppMissingRepos: boolean
}

export type InstallationStatus = "Active" | "Limited" | "Disabled"
export type StatusLabel = "Reconfigure" | "AddRepos" | "ConfigureWriteAccess"

export interface GithubIntegrationsResponse {
  installations: Installation[]
}

export const getActionsNeeded = (installation: Installation) => {
  const menuOptions: Array<{ label: StatusLabel; link: string }> = []

  if (installation.status === "Disabled") {
    menuOptions.push({
      label: "Reconfigure",
      link: githubUrl
    })
  }

  if (!installation.writeAppConfigured || installation.writeAppMissingRepos) {
    menuOptions.push({
      label: "ConfigureWriteAccess",
      link: githubWriteUrl
    })
  }

  if (installation.status === "Limited") {
    menuOptions.push({
      label: "AddRepos",
      link: githubUrl
    })
  }

  return menuOptions
}
