import { UserProfile } from "@descope/react-sdk"
import { useSettings } from "@/services/store/useSettings.ts"

export function Profile() {
  document.title = "Settings - Profile"
  const { selectedTheme } = useSettings()
  return (
    <div>
      <UserProfile widgetId="user-profile-widget" theme={selectedTheme} />
    </div>
  )
}
