import { Button } from "@/components"
import { useNavigate } from "react-router-dom"
import { routes } from "../../lib/routes.ts"
import { useTranslation } from "react-i18next"

export const CreateCRButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("CreateCR")

  return (
    <Button onClick={() => navigate(routes.changes.createNewCR)} className="h-8 gap-1 py-0 text-xs" size="sm">
      {t("NewChange")}
    </Button>
  )
}
0
