import { FCC } from "@/components"
import { FileWithDiffs } from "@/models/FCC"
import { FileDetails } from "@/models/Topics.ts"

interface Props {
  data: FileWithDiffs[]
  filesViewed?: FileDetails[]
}

export const CRContentSection = ({ data, filesViewed }: Props) => {
  return (
    <section data-id="pr-content-section" className="mb-[80px] flex w-full flex-col border-r">
      {data?.map((file, index) => (
        <FCC
          key={`${file.file_relative_path}-${index}`}
          file={file}
          viewedDetails={filesViewed?.find((v) => v.path === file.file_relative_path)?.viewed}
        />
      ))}
    </section>
  )
}
