import { useCallback, useMemo } from "react"
import { File, Sparkles } from "lucide-react"
import { useTranslation } from "react-i18next"
import { getFileLanguageFromPath, splitPath } from "@/lib/utils"
import { BreakingChangeItem } from "@/models/Insight"
import { CodeLocation } from "@/models/Changes"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import {
  Badge,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { useNewCommentBubble } from "@/hooks/useNewCommentbubble"
import { Link } from "@/components/designSystem/Link"
import { isLineInScope, scrollToLineOrBody } from "./helpers"
import { useDiscussions } from "@/services/store/useDiscussions"
import { Side } from "@/models/FCC.ts"
import { BazzyErrorType, BazzyInsightOptions, BazzyQuestionsCategories } from "@/models/Bazzy"
import { useBazzyMessages } from "@/hooks/useBazzyMessages"

interface Props {
  breakingItem: BreakingChangeItem
  onFileClick: (path: string) => void
  handleClose: (forceClose: boolean) => void
}

const BreakingChangeContent = ({ breakingItem, onFileClick, handleClose }: Props) => {
  const { tables } = useChangeRequest()
  const { expandDiscussionsContainer } = useDiscussions()
  const { description, codeLocations } = breakingItem
  const { handleAddCommentClick } = useNewCommentBubble()
  const { t } = useTranslation("Topics")
  const { addUserQuestionToBazzy } = useBazzyMessages()

  const firstCodeLocation = useMemo(() => codeLocations[0], [codeLocations])

  const isBreakingChangeInScope = useMemo(
    () => isLineInScope(firstCodeLocation.fileName, firstCodeLocation.lineNumber),
    [firstCodeLocation]
  )

  const bazzyContext = useMemo(
    () => ({
      filePath: firstCodeLocation.fileName,
      lines: {
        startLine: firstCodeLocation.lineNumber,
        endLine: firstCodeLocation.lineNumber
      },
      side: firstCodeLocation.side || "right",
      type: "insight" as const,
      relevantInsight: description,
      insightType: BazzyErrorType.BREAKING_CHANGE
    }),
    [firstCodeLocation, description]
  )

  const handleGoToFile = useCallback(
    (fileName: string) => {
      onFileClick(fileName)
      handleClose(true)
    },
    [onFileClick, handleClose]
  )

  const handleCommentClick = useCallback(async () => {
    try {
      expandDiscussionsContainer()
      await handleAddCommentClick()
      scrollToLineOrBody(firstCodeLocation.fileName, firstCodeLocation.lineNumber)
      handleClose(true)
    } catch (error) {
      console.error("Error handling comment click:", error)
    }
  }, [expandDiscussionsContainer, handleAddCommentClick, firstCodeLocation, handleClose])

  const handleOpenBazzy = useCallback(() => {
    handleClose(true)

    addUserQuestionToBazzy(
      BazzyInsightOptions.EXPLAIN,
      t("Explain"),
      BazzyQuestionsCategories.INSIGHT,
      description,
      bazzyContext,
      BazzyErrorType.BREAKING_CHANGE
    )
    requestAnimationFrame(() => {
      scrollToLineOrBody(firstCodeLocation.fileName, firstCodeLocation.lineNumber)
    })
  }, [
    handleClose,
    addUserQuestionToBazzy,
    t,
    description,
    bazzyContext,
    firstCodeLocation.fileName,
    firstCodeLocation.lineNumber
  ])

  const getLocationRows = useCallback(
    (fileName: string, line_number: number, side: Side = "right") => {
      if (!tables[fileName]?.rows) return []

      return tables[fileName].rows
        .map((row) => ({
          line_number: side === "right" ? row.new_line_number : row.number,
          content: side === "right" ? row.new_content : row.content,
          side
        }))
        .filter((row) => row.line_number && [line_number - 1, line_number, line_number + 1].includes(row.line_number))
    },
    [tables]
  )

  const renderCodeLocation = useCallback(
    (fileName: string, line_number: number, side: Side = "right") => {
      const { fileName: shortName, path } = splitPath(fileName)
      const rows = getLocationRows(fileName, line_number, side)

      return (
        <section
          className="my-2 flex flex-col rounded-md border border-border bg-background px-0"
          key={`${fileName}-${line_number}-${side}`}
        >
          <div className="flex w-full items-center justify-between pr-1">
            <div className="flex items-center pl-2">
              <File width={16} height={16} />
              <Link onClick={() => handleGoToFile(fileName)} className="px-2">
                <p>{shortName}</p>
              </Link>
              <p className="mr-2 text-xxs font-normal text-secondary">/{path}/</p>
            </div>

            <Badge className="h-6 text-xs font-medium">{getFileLanguageFromPath(fileName)}</Badge>
          </div>
          <Table className="overflow-scroll rounded-md border-b-0 border-t bg-background font-menlo text-[12px] font-normal">
            <TableBody className="flex max-w-dialog flex-col">
              {rows.map(({ line_number, content }) => (
                <TableRow key={line_number} className="border-b-0">
                  <TableCell className="h-7 w-5 border-r px-4 py-1">{line_number}</TableCell>
                  <TableCell className="h-7 whitespace-pre py-0 pl-4">{content}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>
      )
    },
    [handleGoToFile, getLocationRows]
  )

  const renderedCodeLocations = useMemo(
    () =>
      codeLocations.map((codeLocation: CodeLocation) =>
        renderCodeLocation(codeLocation.fileName, codeLocation.lineNumber, codeLocation.side)
      ),
    [codeLocations, renderCodeLocation]
  )

  return (
    <div className="max-h-[500px] max-w-dialog gap-[2px] overflow-auto rounded-md font-bold">
      <p className="mb-4 mt-2 w-full text-xs font-normal">{description}</p>

      {renderedCodeLocations}

      <TooltipProvider>
        <Tooltip>
          {isBreakingChangeInScope ? (
            <div className="flex items-center">
              <Button className="my-2 h-6 px-2 text-xs font-normal" onClick={handleCommentClick}>
                {t("Comment")}
              </Button>
              <Button className="my-2 ml-2 h-6 px-2 text-xs font-normal" onClick={handleOpenBazzy}>
                <Sparkles width={14} className="mr-1" />
                {t("AskBazzy")}
              </Button>
            </div>
          ) : (
            <div className="flex items-center">
              <TooltipTrigger className="cursor-not-allowed">
                <Button disabled className="my-2 h-6 px-2 text-xs font-normal">
                  {t("Comment")}
                </Button>
              </TooltipTrigger>
              <TooltipTrigger className="cursor-not-allowed">
                <Button disabled className="my-2 ml-2 h-6 px-2 text-xs font-normal">
                  <Sparkles width={14} className="mr-1" />
                  {t("AskBazzy")}
                </Button>
              </TooltipTrigger>
            </div>
          )}
          <TooltipContent className="font-inter text-xxs font-normal">{t("OutOfScope")}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}

export default BreakingChangeContent
