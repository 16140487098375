import { BreakingChange, InsightsPerRow, InsightType, RunError, Stat } from "@/models/Insight"
import { FileCodeLine } from "@/components/designSystem/FC/types.ts"
import { Table } from "@tanstack/react-table"
import { FccRow, Side } from "@/models/FCC.ts"
import { TopicInsights } from "@/models/Topics.ts"
import { CodeLocation } from "@/models/Changes.ts"

export interface UniqueStatEntry {
  method: string
  avgTime: number | null
  usageNum: number | null
  fileName?: string
  lineNumber?: number
}

export interface UniqueStats {
  [key: string]: UniqueStatEntry
}

export interface BreakingChangeDetails {
  title: string
  description: string
  breakingType: string
  times: number
  codeLocations: CodeLocation[]
}

export interface ErrorDetails {
  errorType: string
  times: number
  errorMessage: string
}

export interface UniqueErrors {
  [errorMsg: string]: ErrorDetails
}

export const processStats = (stats: Stat[], insights?: TopicInsights): UniqueStatEntry[] => {
  const uniques = stats.reduce<UniqueStats>((acc, stat) => {
    const key = stat.entrypoint

    if (!acc[key]) {
      acc[key] = { method: key, avgTime: null, usageNum: null }

      //  injecting file path and line number to the stat object to add scrolling ability to usage
      insights &&
        Object.entries(insights).forEach(([fileName, insightsPerRow]) => {
          insightsPerRow.forEach((insight) => {
            if (
              insight.insights.some(
                (statInsight) => statInsight.Stat && statInsight.Stat.entrypoint === stat.entrypoint
              )
            ) {
              acc[key].fileName = fileName
              acc[key].lineNumber = insight.line_number
            }
          })
        })
    }

    if (stat.stat_type === "avg_duration_ns") {
      acc[key].avgTime = stat.stat_value / 1000000 // Convert nanoseconds to milliseconds
    } else if (stat.stat_type === "invocations") {
      acc[key].usageNum = stat.stat_value
    }
    return acc
  }, {})
  return Object.values(uniques).sort((a, b) => (b.usageNum ?? 0) - (a.usageNum ?? 0))
}

export const processRunErrors = (errors: RunError[], processBy?: "error_type"): UniqueErrors =>
  errors.reduce<UniqueErrors>((acc, error) => {
    let uniqueBy: string | null = error.error_msg

    if (processBy) {
      uniqueBy = error[processBy]
    }

    if (!uniqueBy) return acc
    acc[uniqueBy] = acc[uniqueBy] || {
      errorType: error.error_type ?? "N/A",
      times: 0,
      errorMessage: error.error_msg
    }
    acc[uniqueBy].times++
    return acc
  }, {})

export interface UniqueBreakingChanges {
  [key: string]: BreakingChangeDetails
}

export const normalizeInsights = (insights: InsightsPerRow[]): InsightsPerRow[] => {
  return insights.flatMap((insightRow) => {
    if (insightRow.line_number) {
      return [insightRow]
    }

    const breakingChanges: BreakingChange[] = []
    const otherInsights: InsightType[] = []

    insightRow.insights.forEach((insight) => {
      if (insight.BreakingChange) {
        breakingChanges.push(insight.BreakingChange)
      } else {
        otherInsights.push(insight)
      }
    })

    if (breakingChanges.length === 0) {
      return [insightRow]
    }

    const results: InsightsPerRow[] = []

    if (otherInsights.length > 0) {
      results.push({
        ...insightRow,
        insights: otherInsights
      })
    }

    const breakingChangeRows = breakingChanges.map((breakingChange) => ({
      ...insightRow,
      line_number: breakingChange.head_line_number ? breakingChange.head_line_number : breakingChange.base_line_number,
      is_base: !breakingChange.head_line_number,
      insights: [
        {
          BreakingChange: breakingChange
        }
      ]
    }))

    results.push(...breakingChangeRows)
    return results
  })
}

export const processBreakingChanges = (
  breakingChanges: BreakingChange[],
  topicInsights: TopicInsights,
  processBy: "title"
): UniqueBreakingChanges => {
  return breakingChanges.reduce<UniqueBreakingChanges>((acc, breakingChange) => {
    const uniqueBy: string = breakingChange[processBy] || "Unknown"
    if (!acc[uniqueBy]) {
      // Find all corresponding insights for this breaking change
      const codeLocations: CodeLocation[] = []

      Object.entries(topicInsights).forEach(([fileName, insightsPerRow]) => {
        insightsPerRow.forEach((insight) => {
          if (
            insight.insights.some((item) => item.BreakingChange && item.BreakingChange.title === breakingChange.title)
          ) {
            // added this for backward compatibility for breaking changes that don't have the new lines format
            if (!breakingChange.head_line_number && !breakingChange.base_line_number) {
              codeLocations.push({
                fileName,
                lineNumber: insight.line_number,
                side: "right"
              })
            } else if (breakingChange.head_line_number) {
              codeLocations.push({
                fileName,
                lineNumber: breakingChange.head_line_number,
                side: "right"
              })
            } else {
              codeLocations.push({
                fileName,
                lineNumber: breakingChange.base_line_number,
                side: "left"
              })
            }
          }
        })
      })

      acc[uniqueBy] = {
        title: breakingChange.title,
        description: breakingChange.description,
        breakingType: breakingChange.breaking_type || "N/A",
        times: 0,
        codeLocations
      }
    }

    acc[uniqueBy].times++
    return acc
  }, {})
}

type InsightsMap = Map<string, Map<string, Map<number, InsightsPerRow[]>>>

const transformInsightsToMap = (insights: InsightsPerRow[]): InsightsMap => {
  const map = new Map<string, Map<string, Map<number, InsightsPerRow[]>>>()
  insights.forEach((insight) => {
    const { file_name, line_number, is_base } = insight
    if (!map.has(file_name)) {
      const fileInsights = new Map<string, Map<number, InsightsPerRow[]>>()
      fileInsights.set("left", new Map<number, InsightsPerRow[]>())
      fileInsights.set("right", new Map<number, InsightsPerRow[]>())
      map.set(file_name, fileInsights)
    }
    const fileInsights = map.get(file_name)!
    const lineMap = is_base ? fileInsights.get("left")! : fileInsights.get("right")!
    if (!lineMap.has(line_number)) {
      lineMap.set(line_number, [])
    }
    lineMap.get(line_number)!.push(insight)
  })
  return map
}

export const getTableInsights = (
  table: Table<FccRow> | Table<FileCodeLine>,
  lineNumber: number | string | null,
  side: Side = "right"
) => {
  const allInsights = normalizeInsights(table.options.meta?.insights || [])
  const insightsMap = transformInsightsToMap(allInsights)
  const filePath = table.options.meta?.filePath

  const relevantDataPerRowArr =
    typeof lineNumber === "number" && filePath
      ? insightsMap
          .get(filePath)
          ?.get(side || "right")
          ?.get(lineNumber) || []
      : []

  const stats = relevantDataPerRowArr.flatMap((insight) =>
    insight.insights.flatMap((item) => (item.Stat ? [item.Stat] : []))
  )

  const runErrors = relevantDataPerRowArr.flatMap((insight) =>
    insight.insights.flatMap((item) => (item.RunError ? [item.RunError] : []))
  )

  const logErrors = relevantDataPerRowArr.flatMap((insight) =>
    insight.insights.flatMap((item) => (item.LogError ? [item.LogError] : []))
  )

  const breakingChanges = relevantDataPerRowArr.flatMap((insight) =>
    insight.insights.flatMap((item) => (item.BreakingChange ? [item.BreakingChange] : []))
  )

  return { stats, runErrors, logErrors, breakingChanges }
}

export const isRowWithInsights = (
  table: Table<FccRow> | Table<FileCodeLine>,
  lineNumber: number | null,
  side: Side = "right"
): boolean => {
  if (!lineNumber) return false
  const { stats, runErrors, logErrors, breakingChanges } = getTableInsights(table, lineNumber, side)
  return stats.length > 0 || runErrors.length > 0 || logErrors.length > 0 || breakingChanges.length > 0
}
