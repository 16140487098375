import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { BranchInfo } from "@/models/Repository"

export const useFetchBranches = (repositoryId?: string | null) => {
  const { error, isLoading, data } = useQuery<{ branches: BranchInfo[] }, unknown>({
    queryKey: ["branches", repositoryId],
    queryFn: async () => {
      if (repositoryId) {
        const response = await axiosClient.get(apiRoutes.branches, { params: { repository_id: repositoryId } })
        return response.data
      }
    },
    enabled: !!repositoryId
  })

  return { error, isLoading, data: data?.branches || [] }
}
