import { LocalStorageService } from "@/lib/localStorageService"
import { Filter, Option, Sorter, SavedFilter } from "./types"

export const compareParsedUrlToValue = (valueFromUrl: string | null, value: string) => {
  return decodeURI(valueFromUrl || "") === value
}

export const displayMainLabel = (urlFilter: string | null, filter: Filter | Sorter) => {
  let computedLabel = ""

  if (!urlFilter) {
    return filter.label
  }
  const currentFilter = filter.options.find((option) => compareParsedUrlToValue(urlFilter, option.value))

  if (currentFilter) {
    computedLabel = currentFilter.label
  }

  if (filter.isLabelPrefix) {
    computedLabel = `${filter.label}: ${computedLabel}`
  }

  return computedLabel
}

export const setInitialMultiSelectedValue = (URLFilter: string | null) => {
  if (!URLFilter) {
    return new Set<string>()
  }

  const initialSet = new Set<string>()
  const decodedUri = decodeURI(URLFilter || "")
  const isMultiSelected = decodedUri.includes(",")

  if (isMultiSelected) {
    const values = decodedUri.split(",")
    values.forEach((val) => initialSet.add(val))
  } else {
    initialSet.add(decodedUri)
  }

  return initialSet
}

export const extractCommaStringFromSet = (selectedValues: Set<string>): string | null => {
  if (selectedValues.size === 0) {
    return null
  }

  let computed = ""

  for (const value of selectedValues.values()) {
    if (computed === "") {
      computed = value
    } else {
      computed = computed + "," + value
    }
  }

  return computed
}

export const filterByLabel = (value: string, search: string, options: Option[]): number => {
  const label = options.find((item) => item.value.toLowerCase() === value.toLowerCase())?.label.toLowerCase()
  return label?.includes(search.toLowerCase()) ? 1 : 0
}

export const setFilterValueToLocalStorage = (
  value: string | string[] | null,
  localStorageKey: string,
  urlKey: string
) => {
  const localStorageService = new LocalStorageService()
  const currentValue = localStorageService.getItem<SavedFilter>(localStorageKey, true)

  if (!currentValue) {
    localStorageService.setItem(localStorageKey, { [urlKey]: value })
  } else {
    localStorageService.setItem(localStorageKey, { ...currentValue, [urlKey]: value })
  }
}

export const clearAllFiltersFromLocalStorage = (localStorageKey: string) => {
  const localStorageService = new LocalStorageService()
  localStorageService.setItem(localStorageKey, null)
}

export const clearFilterFromLocalStorage = (localStorageKey: string, urlKey: string) => {
  const localStorageService = new LocalStorageService()
  const currentValue = localStorageService.getItem<SavedFilter>(localStorageKey, true)

  if (currentValue) {
    delete currentValue[urlKey]
    // if we delete the last key the localStorage value will be set to null in order to support default value checks
    if (Object.keys(currentValue).length === 0) {
      localStorageService.setItem(localStorageKey, null)
    } else {
      localStorageService.setItem(localStorageKey, currentValue)
    }
  }
}
