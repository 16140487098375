import { Button } from "@/components"
import { diffSorter } from "@/components/designSystem/Filters/createFilters"
import { SorterFilter } from "@/components/designSystem/Filters/variations/SorterFilter"
import { Folders } from "lucide-react"
import { Topics } from "src/components/designSystem/Topics"
import { SectionIcon } from "@radix-ui/react-icons"
import { useTranslation } from "react-i18next"

interface Props {
  onOpen: () => void
  isFoldersTreeOpen: boolean
}

const sorters = [diffSorter({})]

export const CRFilters = ({ isFoldersTreeOpen, onOpen }: Props) => {
  const { t } = useTranslation("FCC")

  return (
    <div data-id="cr-filters" className="flex gap-2 border-y border-border bg-background py-5">
      {!isFoldersTreeOpen && (
        <Button className="size-8" size="icon" variant="outline" onClick={onOpen}>
          <Folders width={16} height={16} />
        </Button>
      )}
      <Topics minimized={false} />

      <div className="ml-auto flex h-fit gap-2">
        {sorters.map((sorter) => (
          <SorterFilter
            key={sorter.label}
            sorter={sorter}
            align="end"
            Icon={<SectionIcon className="mr-1" />}
            withTooltip={true}
            mainTooltip={t("ChangeDiffView")}
          />
        ))}
      </div>
    </div>
  )
}
