import { useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import z from "zod"
import { useTranslation } from "react-i18next"
import { ArrowRight } from "lucide-react"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Button
} from "@/components"
import { RichEditor } from "@/components/designSystem/Discussions/components/RichEditor"
import { useCreateComment } from "@/hooks/mutations"
import { zodResolver } from "@hookform/resolvers/zod"
import { Discussion } from "@/models/Discussions"
import { EditorInstance } from "novel"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useGithubRepoWriteAccess } from "@/hooks/useGithubRepoWriteAccess"
interface Props {
  discussion?: Discussion
  CRId: string
  commitSha: string
}

export const AddComment = ({ discussion, CRId, commitSha }: Props) => {
  const { t } = useTranslation("Discussions")
  const [currentEditor, setCurrentEditor] = useState<EditorInstance | null>(null)
  const { mutateAsync: createComment, status } = useCreateComment()
  const { changeRequest } = useChangeRequest()

  const { toolTipContent: noWriteAccessToolTipContent, hasAccess } = useGithubRepoWriteAccess(
    changeRequest?.base_repo_full_name || ""
  )

  const formSchema = useMemo(
    () =>
      z.object({
        body: z.string().min(1, {
          message: t("AddCommentErrorMessage")
        })
      }),
    [t]
  )

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      body: ""
    }
  })

  const { setValue, setError, handleSubmit, control, formState } = form

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      try {
        if (!hasAccess) return

        if (discussion) {
          await createComment({
            discussion_id: discussion.id,
            body: values.body,
            body_content_type: "html",
            code_change_id: CRId
          })
        } else {
          await createComment({
            body: values.body,
            body_content_type: "html",
            commit_sha: commitSha,
            commented_code: values.body,
            discussion_type: "review",
            code_change_id: CRId,
            side: "right",
            end_column: null,
            end_line: null,
            file: null,
            start_column: null,
            start_line: null,
            start_side: null
          })
        }
        if (currentEditor) {
          setTimeout(() => {
            currentEditor.commands.setContent("")
            currentEditor.commands.focus()
          }, 1000)
        }
        setValue("body", "")
      } catch (err) {
        setError("body", { message: ghWriteErrorHandler(err) })
      }
    },
    [setValue, currentEditor, createComment, discussion, CRId, commitSha, setError, hasAccess]
  )

  return (
    <TooltipProvider>
      <Form {...form}>
        <form className="w-full rounded-lg border border-border bg-background p-3" data-id="rich-editor-form">
          <FormField
            control={control}
            name="body"
            render={({ field }) => {
              return (
                <FormItem className="mb-3 items-baseline">
                  <FormControl>
                    <RichEditor
                      initialContent=""
                      handleEditorChange={field.onChange}
                      handleEditorUpdate={(editor: EditorInstance) => {
                        setCurrentEditor(editor)
                      }}
                      parseMode="inline"
                      onCommandEnter={handleSubmit(onSubmit)}
                      hint={t("Reply")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

          <div className="flex justify-end">
            {status === "pending" || formState.isSubmitting ? (
              <SpinnerLoader size="16px" className="justify-end" />
            ) : (
              <Tooltip delayDuration={hasAccess ? 700 : 0}>
                <TooltipTrigger
                  onClick={handleSubmit(onSubmit)}
                  className={hasAccess ? "cursor-pointer disabled:cursor-default" : "cursor-not-allowed"}
                >
                  <Button className="size-8" size="icon" variant="outline" disabled={!hasAccess}>
                    <ArrowRight width={16} height={16} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className={hasAccess ? "" : "bg-background p-2 shadow shadow-border"}>
                  {hasAccess ? t("Send") : noWriteAccessToolTipContent}
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        </form>
      </Form>
    </TooltipProvider>
  )
}
