import { ReactRenderer } from "@tiptap/react"
import tippy, { Instance as TippyInstance, Props } from "tippy.js"
import { MentionList } from "./MentionList.tsx"
import { SuggestionProps } from "@tiptap/suggestion"
import { isListRef } from "@/components/designSystem/Discussions/components/RichEditor/extensions/base.ts"

export interface MentionNameAndLogin {
  name: string
  login: string
  avatarUrl: string
  isUnselectable?: boolean
}

export const mentionConfig = (mentionOptions: MentionNameAndLogin[], noMatchingPersonFound: string) => {
  const itemsToRender = async (query: string): Promise<MentionNameAndLogin[]> => {
    const foundItems = mentionOptions
      .sort((a, b) => a.login.localeCompare(b.login))
      .filter(
        (item) =>
          item.login.toLowerCase().startsWith(query.toLowerCase()) ||
          item.name.toLowerCase().startsWith(query.toLowerCase())
      )

    return foundItems.length > 0
      ? foundItems
      : [{ name: noMatchingPersonFound, login: "", avatarUrl: "", isUnselectable: true }]
  }

  return {
    items: ({ query }: { query: string }) => itemsToRender(query),
    render: () => {
      let reactRenderer: ReactRenderer
      let popup: TippyInstance | null = null

      return {
        onStart: (props: SuggestionProps) => {
          reactRenderer = new ReactRenderer(MentionList, {
            props: {
              ...props,
              onClose: () => {
                if (popup) {
                  popup.hide()
                }
              }
            },
            editor: props.editor
          })

          const tippyClientRect: Props["getReferenceClientRect"] = () => {
            const rect = props.clientRect?.()
            if (rect) {
              return rect
            }
            return new DOMRect(0, 0, 0, 0)
          }

          popup = tippy(document.body, {
            getReferenceClientRect: tippyClientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            onShow: () => {
              setTimeout(() => {
                if (isListRef(reactRenderer.ref)) {
                  reactRenderer.ref.focus()
                }
              }, 0)
            }
          })
        },

        onUpdate(props: SuggestionProps) {
          reactRenderer.updateProps({
            ...props,
            onClose: () => {
              if (popup) {
                popup.hide()
              }
            }
          })

          const tippyClientRect: Props["getReferenceClientRect"] = () => {
            const rect = props.clientRect?.()
            if (rect) {
              return rect
            }
            return new DOMRect(0, 0, 0, 0)
          }

          popup?.setProps({
            getReferenceClientRect: tippyClientRect
          })
        },

        onKeyDown: (props: { event: KeyboardEvent }) => {
          if (props.event.key === "Escape") {
            if (popup) {
              popup.hide()
            }
            return true
          }

          if (isListRef(reactRenderer.ref)) {
            return reactRenderer.ref.onKeyDown({ key: props.event.key })
          }

          return false
        },

        onExit() {
          if (popup) {
            popup.destroy()
            popup = null
          }
          reactRenderer.destroy()
        }
      }
    }
  }
}

export default mentionConfig
