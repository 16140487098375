interface Props {
  title: string
  children: React.ReactNode
}

export const HeaderBox = ({ title, children }: Props) => {
  return (
    <div className="w-full bg-background px-6 py-4">
      <p className="text-xs text-secondary">{title}</p>
      {children}
    </div>
  )
}
