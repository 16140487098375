import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "../useToast"
import { errorHandler } from "@/services/api/helpers"

interface Args {
  changeId: string
  state?: string
  branch_to?: string
  title?: string
  description?: string | null
}

export const useUpdateChangeRequest = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const updateChangeRequest = useCallback(async ({ changeId, state, branch_to, description, title }: Args) => {
    const computed = state === "close" ? "closed" : state
    return await axiosClient.patch(apiRoutes.changes.updateChange(changeId), {
      state: computed || null,
      branch_to: branch_to || null,
      title: title || null,
      description: description || null
    })
  }, [])

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["useUpdateChangeRequest"],
    mutationFn: updateChangeRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["change"] })
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        description: errorHandler(err)
      })
    }
  })

  return { data, mutateAsync, status }
}
