import { Row } from "@tanstack/react-table"
import { EditAction, FccRow, FileElement, FileWithDiffs, IsNumberBetweenArgs, Side } from "@/models/FCC"
import { EdgeFile } from "@/components/designSystem/FCC/Table/EdgeComponents/EdgeFile.tsx"

export const DEFAULT_SIZE = 150
const LINE_LENGTH_LIMIT = 10_000
export const CENTERED_TEXT_CELL_TYPES = ["expander", "status"]
export const statusMap = {
  Added: "+",
  Deleted: "-",
  Unchanged: "",
  Changed: ""
}
export const BREAKING_POINT_FOR_MINIMIZING_HEADER = 136

// helper to check if the file is JSON/JS object
const objectConstructor = {}.constructor

export const calculateHeaderSize = (size: number) => {
  // default cell size is 150 so if no size passed make it 100%
  return size === DEFAULT_SIZE ? "100%" : size.toString() + "px"
}

export const setCellTextAlignment = (cellType: string) => {
  return CENTERED_TEXT_CELL_TYPES.includes(cellType) ? "text-center" : "text-left"
}

/* each cell in the table can have background color 
  the function generates the color based on a given row 
  depending on split view and column side
*/
export const generateCellBackgroundColor = (
  row: Row<FccRow>,
  isSplitView: boolean,
  columnSide: Side,
  marked: boolean,
  subRow: boolean
) => {
  if (!isLine(row.original)) {
    return ""
  }
  const { line_type } = row.original

  switch (true) {
    case marked:
      return "bg-highlight-light"
    case subRow:
      return "bg-background"
    case !isSplitView:
      switch (line_type) {
        case "Added":
          return "bg-success-light"
        case "Deleted":
          return "bg-error-light"
        case "Unchanged":
          return "bg-background"
        default:
          break
      }
      break

    case isSplitView && columnSide === "left":
      switch (line_type) {
        case "Added":
          return "bg-fill"
        case "Unchanged":
          return "bg-background"
        case "Changed":
        case "Deleted":
          return "bg-error-light"
        default:
          break
      }
      break

    case isSplitView && columnSide === "right":
      switch (line_type) {
        case "Added":
        case "Changed":
          return "bg-success-light"
        case "Deleted":
          return "bg-fill"
        case "Unchanged":
          return "bg-background"
        default:
          break
      }
      break

    default:
      break
  }

  return "bg-background"
}

// extracting cell number based on side
export const extractCellNumber = (line: FccRow, isSplitView: boolean, columnSide: Side): number | null => {
  if (!isSplitView) {
    return columnSide === "left" ? line.number : line.new_line_number
  }

  if (columnSide === "left") {
    return line.number
  }
  if (columnSide === "right") {
    return line.new_line_number
  }

  return null
}

/*
type checking helpers
*/
export const isLine = (row: FccRow): boolean => {
  return "content" in row || "new_content" in row
}

export const isExpandableLine = (row: FccRow): boolean => {
  return "subRows" in row
}

export const isSubRow = (row: FccRow): boolean => {
  return "isExpanded" in row
}

// Get the content of the line
export const getLineContent = (element: HTMLElement | undefined) => {
  const contentElement = element?.querySelector('[data-content="true"]')
  return contentElement?.textContent?.replace(/(\r\n|\n|\r)/gm, "") || null
}

// getting the current selected text of the user
export const getSelectedText = () => {
  const selection = window.getSelection()
  const section = document.querySelector("section[data-id=pr-content-section]")

  // checking if the selected text is part of the pr content if not ignore the selection
  if (selection?.rangeCount && selection?.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    if (section?.contains(range.commonAncestorContainer)) {
      return selection?.toString().replace(/(\r\n|\n|\r)/gm, "") || null
    }
    return null
  }

  return null
}

export const getExtendedParent = (
  node: Node | null | undefined,
  desiredAttribute: string,
  maxParent?: number
): HTMLElement | undefined => {
  let counter = 1
  let currentNode = node
  const maxPossibleParent = maxParent || 6

  while (currentNode && counter <= maxPossibleParent) {
    if (
      currentNode.parentNode instanceof HTMLElement &&
      typeof currentNode.parentNode.getAttribute === "function" &&
      currentNode.parentNode.getAttribute(desiredAttribute)
    ) {
      currentNode = currentNode.parentNode

      return currentNode as HTMLElement | undefined
    }
    counter++
    currentNode = currentNode.parentNode
  }
}

//  helper function to check if a content of a cell should be empty
export const isEmptyContent = (isSplitView: boolean, editAction: EditAction, columnSide: Side) => {
  if (isSplitView) {
    if (editAction === "Added" && columnSide === "left") {
      return true
    }
    if (editAction === "Deleted" && columnSide === "right") {
      return true
    }
  }
  return false
}

export const extractFullFileLines = (file: string | object): string[] => {
  if (typeof file === "string") {
    return file?.split("\n")
  }
  if (typeof file === "object") {
    if (file?.constructor === objectConstructor) {
      return JSON.stringify(file, null, 2).split("\n")
    }
  }
  return []
}

export const getSelectedLineNumber = (lineStart: number, lineEnd: number, type: "start" | "end"): number | null => {
  if (lineStart == null || lineEnd == null) return null

  return type === "start" ? Math.min(lineStart, lineEnd) : Math.max(lineStart, lineEnd)
}

export const isLockfileType = (filePath: string): boolean => {
  const ending = filePath.split(".").pop()
  const fileName = filePath.split("/").pop()

  return ending === "lock" || fileName === "package-lock.json"
}

export const getEdgeType = (file: FileWithDiffs): EdgeFile | null => {
  switch (true) {
    case isLockfileType(file.file_relative_path):
      return "Lock"
    case file.is_binary:
      return "Binary"
    case file.chunks.length === 0:
      if (file.old_relative_path) {
        return "Renamed"
      }
      return "Empty"
    case file.file_status === "removed":
      return "Removed"
    case file.lines_added + file.lines_deleted > 1000:
      return "TooLong"
    case file.chunks.some((chunk) => chunk.lines.some((line) => isLongLine(line))):
      return "Formatting"
    default:
      return null
  }
}

const isLongLine = ({ content, new_content }: { content: string | null; new_content: string | null }) => {
  return (content || "").length > LINE_LENGTH_LIMIT || (new_content || "").length > LINE_LENGTH_LIMIT
}

export const canLoad = (fileType: EdgeFile | null): boolean =>
  fileType === "TooLong" || fileType === "Lock" || fileType === "Removed"

export const computeIsEdge = (file: FileWithDiffs): boolean => getEdgeType(file) !== null

export const isNumberBetween = ({ number, startNumber, endNumber }: IsNumberBetweenArgs): boolean => {
  if (!number || !endNumber) return false

  if (!startNumber) {
    return number === endNumber
  }

  return number <= endNumber && number >= startNumber
}

export const checkLineStatus = (line: FccRow, isSplitView: boolean, columnSide: Side) => {
  const lineType = line.line_type

  if (isEmptyContent(isSplitView, lineType, columnSide)) {
    return ""
  }

  return statusMap[lineType] || null
}

export const getEncapsulatedElements = (lineNumber: number, elements: FileElement[]): FileElement[] => {
  const result: FileElement[] = []

  for (const element of elements) {
    if (element.start_line < lineNumber && element.end_line > lineNumber) {
      if (result.length <= 0) {
        result.push(element)
        continue
      }

      // sorting the array in an order of the closest element to the line (the first encapsulation) will be the first element
      result[0].start_line > element.start_line ? result.unshift(element) : result.push(element)
    }
  }

  return result.map((res) => ({ ...res, line_content: res.line_content.replace(/ /g, "\u00A0") }))
}
