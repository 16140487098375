import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkEmoji from "@baz-scm/remark-emoji"
import rehypeRaw from "rehype-raw"
import { Themes } from "@/services/store/useSettings.ts"
import { createMarkdownComponents } from "./markdownComponents"
import type { MdTransformerOptions } from "./types"

export const mdTransformer = (markdown: string, theme: Themes, options?: MdTransformerOptions) => {
  const components = createMarkdownComponents(theme, options)

  return (
    <Markdown
      className="markdown"
      remarkPlugins={[remarkGfm, [remarkEmoji, { emoticon: true }]]}
      rehypePlugins={[rehypeRaw]}
      components={components}
    >
      {markdown}
    </Markdown>
  )
}
