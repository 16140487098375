import { ChangeRequest } from "@/models/Changes"
import { Reviews } from "./Reviews"
import { TooltipProvider } from "@/components"

interface Props {
  changeRequest: ChangeRequest
}

export const ReviewersSection = ({ changeRequest }: Props) => {
  return (
    <TooltipProvider>
      <Reviews changeRequest={changeRequest} />
    </TooltipProvider>
  )
}
