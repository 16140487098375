import { useQuery } from "@tanstack/react-query"
import { OnboardingResponse } from "@/models/Onboarding.ts"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { isDev } from "@/services/api/helpers"

export type OnboardingStateResponse = OnboardingResponse & {
  isDoneOnboarding: boolean
}

type ApiResponse = {
  data: OnboardingStateResponse
}

const mockResponse: ApiResponse = {
  data: {
    error: null,
    pullRequests: "in_progress",
    repositories: "in_progress",
    reviewer: "in_progress",
    users: "in_progress",
    isDoneOnboarding: false
  }
}

export const useFetchOnboardingState = (isAuthenticated: boolean, intervalTime: number) => {
  return useQuery<ApiResponse>({
    queryKey: ["onboarding"],
    queryFn: async () => {
      if (isDev) {
        return mockResponse
      }
      const response = await axiosClient.get<OnboardingResponse>(apiRoutes.onboarding.get)
      return {
        data: {
          ...response.data,
          isDoneOnboarding: response.data.reviewer === "done"
        }
      }
    },
    enabled: isAuthenticated,
    refetchInterval: intervalTime
  })
}
