import { CIRunWithErrors } from "@/models/Changes"
import { Cross2Icon } from "@radix-ui/react-icons"

export const LogErrorItem = ({ check: { ci_name, name } }: { check: CIRunWithErrors }) => {
  return (
    <div className="my-2 flex items-center pl-0 pr-1 hover:bg-inherit">
      <Cross2Icon width={16} height={16} className="mr-2 text-error-dark" />
      {ci_name} {name}
    </div>
  )
}
