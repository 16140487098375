const FailedCheckSVG = ({ primaryColor = "var(--base-primary)", secondaryColor = "var(--base-primary)", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14 5.333h-4A.667.667 0 009.333 6v1.333c0 .368.299.667.667.667h4a.667.667 0 00.667-.667V6A.667.667 0 0014 5.333z"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14 5.333h-4A.667.667 0 009.333 6v1.333c0 .368.299.667.667.667h4a.667.667 0 00.667-.667V6A.667.667 0 0014 5.333z"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.667 6.667H16A1.333 1.333 0 0117.333 8v9.333A1.333 1.333 0 0116 18.667H8a1.333 1.333 0 01-1.333-1.334V8A1.333 1.333 0 018 6.667h1.333"
      />
      <path stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round" d="M14 11.333l-4 4" />
      <path stroke={secondaryColor} strokeLinecap="round" strokeLinejoin="round" d="M14 11.333l-4 4" />
      <path stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round" d="M10 11.333l4 4" />
      <path stroke={secondaryColor} strokeLinecap="round" strokeLinejoin="round" d="M10 11.333l4 4" />
    </svg>
  )
}

export default FailedCheckSVG
