import { CommandItem, Tooltip, TooltipContent, TooltipTrigger, Avatar } from "@/components"
import { BaseOption } from "@/components/designSystem/SelectVariations/types"
import { formatDate } from "@/lib/dateFormats"
import { substringHighlighter } from "@/lib/jsxUtils"
import { Repository } from "@/models/Repository"
import { GitCompare, Globe2, Lock } from "lucide-react"
import { useTranslation } from "react-i18next"

interface Props {
  option: BaseOption & Repository
  onClick: (name: string) => void
  searchValue: string
  selectedRepositoryName?: string
}

export function RepositoryItem({ option: repo, onClick, searchValue, selectedRepositoryName }: Props) {
  const { t } = useTranslation("RepoSelector")

  return (
    <CommandItem value={repo.fullName} onSelect={onClick} className="m-0 w-full p-0">
      <div
        role="button"
        onClick={() => onClick(repo.fullName)}
        className="flex h-10 w-full items-center gap-2 p-2 font-inter text-xs"
      >
        <Avatar avatarURL={repo.avatarUrl} className="size-4 rounded-full" username={repo.fullName} />
        <div className="flex w-[460px] items-center gap-2">
          <span className={`text-nowrap text-primary ${repo.fullName === selectedRepositoryName ? "font-bold" : ""}`}>
            {searchValue ? substringHighlighter(repo.fullName, searchValue) : repo.fullName}
          </span>
          <Tooltip>
            <TooltipTrigger className="overflow-x-hidden text-ellipsis text-nowrap text-xxs text-secondary">
              {repo.description ?? ""}
            </TooltipTrigger>

            <TooltipContent>{repo.description ?? ""}</TooltipContent>
          </Tooltip>
        </div>

        <div className="ml-6 flex w-[160px] items-center justify-between text-xxs text-primary">
          <span className="flex gap-2">
            {repo.openChanges}
            <Tooltip>
              <TooltipTrigger>
                <GitCompare className="text-primary" width={16} height={16} />
              </TooltipTrigger>
              <TooltipContent>{repo.openChanges + " " + t("Changes")}</TooltipContent>
            </Tooltip>
          </span>

          <Tooltip>
            <TooltipTrigger>
              {repo.isPrivate ? (
                <Lock className="text-primary" width={16} height={16} />
              ) : (
                <Globe2 className="text-primary" width={16} height={16} />
              )}
            </TooltipTrigger>
            <TooltipContent>{repo.isPrivate ? t("Private") : t("Public")}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger className="w-20 text-start text-xxs text-primary">
              {formatDate(repo.updatedAt)}
            </TooltipTrigger>
            <TooltipContent>{t("LastUpdatedOn") + " " + formatDate(repo.updatedAt)}</TooltipContent>
          </Tooltip>
        </div>
      </div>

      <div className="border-b"></div>
    </CommandItem>
  )
}
