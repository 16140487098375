import { Avatar } from "@/components"

interface NumberChipProps {
  number: number
  className?: string
}

export const NumberChip = ({ number, className }: NumberChipProps) => {
  return (
    <Avatar
      username={String(number)}
      className={className}
      fallbackClassName="bg-secondary text-[10px] font-bold text-white"
    />
  )
}
