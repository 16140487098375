import { Comment, Discussion } from "@/models/Discussions"
import { Dispatch, SetStateAction } from "react"
import { useForm } from "react-hook-form"
import { EditSuggestion } from "./EditSuggestion"
import { useTranslation } from "react-i18next"
import { splitCommentAndSuggestion } from "@/components/designSystem/Discussions/utils"

interface Props {
  discussion: Discussion | null
  comment: Comment
  isEditMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
}

const extractCommentedCode = (code: string): string[] => {
  const result: string[] = []

  code.split("\n").forEach((codeRow) => {
    if (codeRow.startsWith("+") || codeRow.startsWith("-")) {
      result.push(codeRow.slice(1))
    } else {
      result.push(codeRow)
    }
  })

  return result
}

const extractOriginalLine = (discussion: Discussion, incrementor: number) => {
  if (discussion.original_start_line) {
    return discussion.original_start_line + incrementor
  }

  if (discussion.original_end_line) {
    return discussion.original_end_line + incrementor
  }

  // in first time the discussion is created it needs to have the regular start/end line
  if (discussion.start_line) {
    return discussion.start_line + incrementor
  }

  if (discussion.end_line) {
    return discussion.end_line + incrementor
  }
}

export const SuggestionView = ({ discussion, comment, isEditMode, setEditMode }: Props) => {
  const { suggestion } = splitCommentAndSuggestion(comment.comment_body)
  const { t } = useTranslation("Discussions")

  const form = useForm({
    defaultValues: {
      body: suggestion || ""
    }
  })

  if (!discussion) return <></>

  const originalCode = extractCommentedCode(discussion?.commented_code || "")

  return (
    <div>
      <div className="mb-4">
        <p className="mb-2 text-xs text-secondary">{t("CurrentCode")}:</p>
        <table className="mb-1">
          {originalCode.map((codeRow, index) => (
            <tr key={codeRow + index} className="bg-error-light">
              <td className="px-1 align-top">{extractOriginalLine(discussion, index)}</td>
              <td className="px-1 align-top">{"-"}</td>
              <td className="whitespace-pre-wrap px-1 align-top">{codeRow}</td>
            </tr>
          ))}
        </table>
      </div>

      <div className="mb-4">
        <p className="mb-2 text-xs text-secondary">{t("ProposedCode")}:</p>
        {isEditMode ? (
          <EditSuggestion comment={comment} form={form} setEditMode={setEditMode} />
        ) : (
          suggestion && (
            <table className="mb-1">
              {suggestion.split("\n").map((codeRow, index) => (
                <tr key={codeRow + index} className="bg-success-light">
                  <td className="px-1 align-top">{extractOriginalLine(discussion, index)}</td>
                  <td className="px-1 align-top">{"+"}</td>
                  <td className="whitespace-pre-wrap px-1 align-top">{codeRow}</td>
                </tr>
              ))}
            </table>
          )
        )}
      </div>
    </div>
  )
}
