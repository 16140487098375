import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components"
import { MergeAction } from "./MergeAction.tsx"
import { MergeStatus, MergeStrategy } from "@/models/Changes.ts"
import { useTranslation } from "react-i18next"
import { MutationStatus } from "@/models/Common.ts"

interface Props {
  mergeStatus: MergeStatus
  onTriggerClick: (mergeStatus: MergeStatus) => void
  onItemClick: (value: MergeStrategy) => void
  onClose: () => void
  open: boolean
  status: MutationStatus
  crStatus: string
  isPrimary: boolean
}

export const MergeDropdown = ({
  mergeStatus,
  onTriggerClick,
  open,
  onItemClick,
  onClose,
  status,
  crStatus,
  isPrimary
}: Props) => {
  const { t } = useTranslation("Toolbar")
  const isDisabled = status === "pending" || crStatus === "merged" || status === "success"

  return (
    <DropdownMenu open={open} modal={false}>
      <DropdownMenuTrigger disabled={isDisabled}>
        <MergeAction
          mergeStatus={mergeStatus}
          onTriggerClick={onTriggerClick}
          isDisabled={isDisabled}
          crStatus={crStatus}
          status={status}
          isPrimary={isPrimary}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent onPointerDownOutside={onClose}>
        {mergeStatus.allowed_merge_strategies.map((strategy) => (
          <DropdownMenuItem
            className="w-[150px] text-left text-xs"
            key={strategy}
            onClick={() => onItemClick(strategy)}
          >
            {t(strategy)}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
