import { NavLink } from "react-router-dom"
import Github from "@/assets/icons/Github.svg?react"
import Datadog from "@/assets/icons/datadog.svg?react"
import Slack from "@/assets/icons/slack.svg?react"
import { Activity, Settings2, SettingsIcon } from "lucide-react"
import { routes } from "../../lib/routes.ts"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { Settings } from "@/services/i18n/locales/en/Settings"
import { useSettings } from "@/services/store/useSettings.ts"
import { useEffect } from "react"

const sections = [
  {
    title: "Integrations",
    links: [
      {
        label: "GitHub",
        href: routes.settings.integrations({ provider: "github" }),
        Icon: <Github />
      },
      {
        label: "Datadog",
        href: routes.settings.integrations({ provider: "datadog" }),
        Icon: <Datadog />
      },
      {
        label: "Slack",
        href: routes.settings.integrations({ provider: "slack" }),
        Icon: <Slack />
      }
    ]
  },
  {
    title: "Account",
    links: [
      {
        label: "Account Settings",
        href: "/settings/account",
        Icon: <SettingsIcon size={16} />
      },
      {
        label: "Preferences",
        href: "/settings/reviewer",
        Icon: <Settings2 size={16} />
      },
      {
        label: "Profile",
        href: "/settings/profile",
        Icon: <Activity size={16} />
      }
    ]
  }
] as const

const renderLink = (
  link: { label: keyof typeof Settings; href: string; Icon: JSX.Element },
  t: TFunction<"Settings", undefined>
) => (
  <NavLink to={link.href} key={link.href}>
    {({ isActive }) => (
      <p className={`flex h-9 items-center gap-2 px-2 hover:bg-darker-fill ${isActive ? "bg-darker-fill" : null}`}>
        {link.Icon && link.Icon}
        {t(link.label)}
      </p>
    )}
  </NavLink>
)

export const SettingsSidebar = () => {
  const { t } = useTranslation("Settings")
  const { setSidebarWidth } = useSettings()

  useEffect(() => {
    setSidebarWidth(384)
  }, [setSidebarWidth])

  return (
    <div data-testid="settings-sidebar" className="flex min-w-[384px] border-r border-border bg-fill pl-12">
      <div className="flex w-full flex-col py-6">
        {sections.map((section) => (
          <div key={section.title} className="max-w-[95%] text-xs">
            <p className="p-2 text-hint">{t(section.title)}</p>
            {section.links.map((link) => renderLink(link, t))}
            <div className="my-4 border-b border-border"></div>
          </div>
        ))}
      </div>
    </div>
  )
}
