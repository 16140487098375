import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation } from "@tanstack/react-query"
import { useCallback } from "react"

export const useUpdateSlackId = () => {
  const updateSlackId = useCallback(async () => await axiosClient.post(apiRoutes.slack.post), [])

  const {
    data,
    mutateAsync: updateSlackIdMutation,
    status
  } = useMutation({
    mutationKey: ["updateSlackId"],
    mutationFn: updateSlackId
  })

  return { data, updateSlackIdMutation, status }
}
