import {
  calculateBubbleTopBasedOnCurrentDiscussions,
  isDiscussion
} from "@/components/designSystem/Discussions/utils.ts"
import { Discussion, NewCommentBubble } from "@/models/Discussions.ts"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

export interface DiscussionWithTop extends Discussion {
  topPosition?: number
}

interface State {
  discussions: Array<Discussion | NewCommentBubble>
  discussionsWithTop: DiscussionWithTop[]
  manualRecalculationTrigger: boolean
  discussionsExpanded: boolean
  expandedStateBeforeComment: boolean
  toggleExpandDiscussionsContainer: () => void
  expandDiscussionsContainer: () => void
  minimizeDiscussionsContainer: () => void
  toggleManualRecalculationTrigger: () => void
  setDiscussions: (discussions: Discussion[]) => void
  setDiscussionsWithTop: () => void
  injectNewCommentBubbleToDiscussions: (newCommentBubble: NewCommentBubble) => NewCommentBubble
  removeNewCommentBubbleFromDiscussions: (newCommentBubble: Discussion | NewCommentBubble) => void
  currentHoveredDiscussion: Discussion | null
  setCurrentHoveredDiscussion: (discussion: Discussion) => void
  clearCurrentHoveredDiscussion: () => void
  currentOpenedDiscussion: Discussion | NewCommentBubble | null
  setCurrentOpenedDiscussion: (discussion: Discussion | NewCommentBubble) => void
  clearCurrentOpenedDiscussion: () => void
  updateDiscussionWithTop: (discussionId: string, top: number) => void
}

export const useDiscussions = create<State>()(
  immer((set, get) => ({
    discussions: [],
    discussionsWithTop: [],
    manualRecalculationTrigger: false,
    discussionsExpanded: false,
    expandedStateBeforeComment: false,
    toggleExpandDiscussionsContainer: () => {
      set((state) => {
        state.discussionsExpanded = !state.discussionsExpanded
        state.expandedStateBeforeComment = !state.expandedStateBeforeComment
      })
    },
    expandDiscussionsContainer: () => {
      set((state) => {
        state.discussionsExpanded = true
      })
    },
    minimizeDiscussionsContainer: () => {
      set((state) => {
        state.discussionsExpanded = false
      })
    },
    currentHoveredDiscussion: null,
    currentOpenedDiscussion: null,
    toggleManualRecalculationTrigger: () => {
      const status = get().manualRecalculationTrigger
      set((state) => {
        state.manualRecalculationTrigger = !status
      })
    },
    setDiscussions: (discussions) => {
      set((state) => {
        state.discussions = discussions
      })
    },
    setDiscussionsWithTop: () => {
      set((state) => {
        state.discussionsWithTop = get().discussions.filter((discussion) => isDiscussion(discussion))
      })
    },
    injectNewCommentBubbleToDiscussions: (newCommentBubble) => {
      const discussions = get().discussions
      const { bubbleIndex } = calculateBubbleTopBasedOnCurrentDiscussions(discussions, newCommentBubble)

      set((state) => {
        state.discussions = [...discussions.slice(0, bubbleIndex), newCommentBubble, ...discussions.slice(bubbleIndex)]
      })

      return newCommentBubble
    },
    removeNewCommentBubbleFromDiscussions: (newCommentBubble) => {
      const discussions = get().discussions
      const filtered = discussions.filter((discussion) => discussion.id !== newCommentBubble.id)

      set((state) => {
        state.discussions = filtered
      })
    },
    setCurrentHoveredDiscussion: (discussion: Discussion) => {
      set((state) => {
        state.currentHoveredDiscussion = discussion
      })
    },
    clearCurrentHoveredDiscussion: () => {
      set((state) => {
        state.currentHoveredDiscussion = null
      })
    },
    setCurrentOpenedDiscussion: (discussion: Discussion | NewCommentBubble) => {
      set((state) => {
        state.currentOpenedDiscussion = discussion
      })
    },
    clearCurrentOpenedDiscussion: () => {
      set((state) => {
        state.currentOpenedDiscussion = null
      })
    },
    updateDiscussionWithTop: (discussionId, top) => {
      set((state) => {
        state.discussionsWithTop = get().discussionsWithTop.map((discussion) =>
          discussion.id === discussionId ? { ...discussion, topPosition: top } : discussion
        )
      })
    }
  }))
)
