import {
  Button,
  Card,
  CardDescription,
  CardHeader,
  TabsContent,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from "@/components"
import { useTranslation } from "react-i18next"
import { BreakingChange } from "@/models/Insight.ts"
import RedX from "@/assets/icons/RedX.svg?react"
import { ClipboardList, Sparkles } from "lucide-react"
import { useCallback, useRef, useState } from "react"
import { BazzyErrorType, BazzyInsightOptions, BazzyQuestionsCategories } from "@/models/Bazzy"
import { scrollToLineOrBody } from "@/components/designSystem/Topics/helpers"
import { Table } from "@tanstack/react-table"
import { FccRow, Side } from "@/models/FCC"
import { FileCodeLine } from "@/components/designSystem/FC/types"
import { useBazzyMessages } from "@/hooks/useBazzyMessages"

interface Props {
  breakingChanges: BreakingChange[]
  table: Table<FccRow> | Table<FileCodeLine>
  side: Side
  lineNumber: number | null
}

export const BreakingChangesContent = ({ breakingChanges, table, side, lineNumber }: Props) => {
  const { t } = useTranslation("Insight")
  const [askBazzyIndexToShow, setAskBazzyIndexToShow] = useState<number | null>(null)
  const { addUserQuestionToBazzy } = useBazzyMessages()

  const [tooltipStates, setTooltipStates] = useState<Record<string, boolean>>(() =>
    Object.fromEntries(
      breakingChanges.flatMap(({ id }) => [
        [`title-${id}`, false],
        [`error-${id}`, false]
      ])
    )
  )

  const titleRefs = useRef<Record<string, HTMLParagraphElement | null>>({})
  const descriptionRefs = useRef<Record<string, HTMLParagraphElement | null>>({})

  const handleTooltipOpen = useCallback((changeId: string, isOpen: boolean) => {
    setTooltipStates((prev) => ({
      ...prev,
      [changeId]: isOpen
    }))
  }, [])

  const checkTruncation = useCallback((element: HTMLElement | null) => {
    if (element) {
      return element.scrollWidth > element.clientWidth
    }
    return false
  }, [])

  const handleOpenBazzy = useCallback(
    (breakingChange: BreakingChange) => {
      const { description, base_line_number, head_line_number } = breakingChange

      const breakingChangeLine = head_line_number ?? base_line_number

      const bazzyContext = {
        filePath: table.options.meta?.filePath || null,
        lines: {
          startLine: breakingChangeLine ?? lineNumber,
          endLine: breakingChangeLine ?? lineNumber
        },
        side: side,
        type: "insight" as const,
        relevantInsight: description,
        insightType: BazzyErrorType.BREAKING_CHANGE
      }

      addUserQuestionToBazzy(
        BazzyInsightOptions.EXPLAIN,
        t("Explain"),
        BazzyQuestionsCategories.INSIGHT,
        description,
        bazzyContext,
        BazzyErrorType.BREAKING_CHANGE
      )
      requestAnimationFrame(() => {
        scrollToLineOrBody(side, breakingChangeLine ?? lineNumber)
      })
    },
    [addUserQuestionToBazzy, lineNumber, side, t, table.options.meta?.filePath]
  )

  return (
    <TabsContent
      value="checks"
      className="max-h-80 overflow-auto rounded-md border shadow-md shadow-border dark:shadow-background"
    >
      <Card>
        {breakingChanges.map((breakingChange, index) => (
          <CardHeader key={breakingChange.id} className="space-y-0 border-b p-0">
            <CardDescription className="border-b border-border p-2 text-xxs text-primary">
              <Tooltip open={tooltipStates[`title-${breakingChange.id}`]}>
                <TooltipTrigger
                  className="flex w-full items-center"
                  onMouseEnter={() => {
                    if (checkTruncation(titleRefs.current[breakingChange.id])) {
                      handleTooltipOpen(`title-${breakingChange.id}`, true)
                    }
                  }}
                  onMouseLeave={() => handleTooltipOpen(`title-${breakingChange.id}`, false)}
                  disabled={!checkTruncation(titleRefs.current[breakingChange.id])}
                >
                  <ClipboardList width={16} className="text-primary" />
                  <p className="ml-2">{breakingChange.title}</p>
                </TooltipTrigger>
                <TooltipContent className="max-w-[400px]" align="start">
                  <p className="ml-2">{breakingChange.title}</p>
                </TooltipContent>
              </Tooltip>
            </CardDescription>
            <CardDescription
              className="group relative flex cursor-default items-center p-2 text-xxs text-primary"
              onMouseEnter={() => setAskBazzyIndexToShow(index)}
              onMouseLeave={() => setAskBazzyIndexToShow(null)}
            >
              <RedX className="shrink-0" />
              <div className="flex w-[500px] items-center ">
                <p className="mx-2 shrink-0">{t("Error")}: </p>
                <Tooltip open={tooltipStates[`error-${breakingChange.id}`]}>
                  <TooltipTrigger
                    className="flex max-w-dialog truncate"
                    onMouseEnter={() => {
                      if (checkTruncation(descriptionRefs.current[breakingChange.id])) {
                        handleTooltipOpen(`error-${breakingChange.id}`, true)
                      }
                    }}
                    onMouseLeave={() => handleTooltipOpen(`error-${breakingChange.id}`, false)}
                    disabled={!checkTruncation(descriptionRefs.current[breakingChange.id])}
                  >
                    <p
                      className="max-w-[340px] overflow-x-hidden text-ellipsis text-nowrap"
                      ref={(el) => (descriptionRefs.current[breakingChange.id] = el)}
                    >
                      {breakingChange.description}
                    </p>
                    <Button
                      className={`ml-2 h-5 px-2 text-xs font-normal ${askBazzyIndexToShow === index ? "flex" : "hidden"}`}
                      onClick={() => handleOpenBazzy(breakingChange)}
                    >
                      <Sparkles width={14} height={24} className="mr-1" />
                      {t("AskBazzy")}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-[400px]" align="start">
                    <p>
                      {t("Error")}: {breakingChange.description}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </CardDescription>
          </CardHeader>
        ))}
      </Card>
    </TabsContent>
  )
}
