import { ChangeRequest } from "@/models/Changes"
import { CRTitle } from "./CRTitle.tsx"
import { ApproveButton } from "@/pages/crFileChanges/components/CRHeader/CRActions/ApproveButton.tsx"
import { usePermissions } from "@/services/store/usePermissions"
import { MergeButton } from "@/pages/crFileChanges/components/CRHeader/CRActions/MergeButton"
import { InfoActions } from "@/pages/crFileChanges/components/CRHeader/CRActions/InfoActions"
import { Topics } from "@/components/designSystem/Topics"
import { CRSubHeader } from "@/pages/crFileChanges/components/CRHeader/CRSubHeader"
import { AnimatePresence } from "framer-motion"

interface Props {
  changeRequest: ChangeRequest
  minimalHeader: boolean
  isOpen: boolean
  onOpen: () => void
  foldersTreeOpen: boolean
}

export const CRHeader = ({ changeRequest, minimalHeader, isOpen, onOpen, foldersTreeOpen }: Props) => {
  const { user } = usePermissions()
  const myCR = user?.github_login === changeRequest.author_name
  const currentState = changeRequest.is_draft && changeRequest.state === "open" ? "draft" : changeRequest.state
  const isMerged = currentState === "merged"

  return (
    <div
      className={`sticky top-16 z-50 flex flex-col bg-background ${minimalHeader ? "border-b" : "border-b-0"} h-[116px] w-full justify-center`}
    >
      <div className={`flex w-full justify-between ${minimalHeader ? "mb-4 border-b border-border pb-1" : ""}`}>
        <CRTitle changeRequest={changeRequest} minimalHeader={minimalHeader} isOpen={isOpen} onOpen={onOpen} />

        <div className={`flex items-center ${!isMerged ? "gap-3" : ""}`}>
          <InfoActions changeRequest={changeRequest} isMerged={isMerged} />
          <div className="flex items-center gap-2">
            {!isMerged ? <MergeButton changeRequest={changeRequest} isPrimary={myCR} /> : null}
            {!myCR && !isMerged ? <ApproveButton changeRequest={changeRequest} /> : null}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {minimalHeader ? (
          <Topics minimized={minimalHeader} />
        ) : (
          <CRSubHeader foldersTreeOpen={foldersTreeOpen} changeRequest={changeRequest} minimalHeader={minimalHeader} />
        )}
      </AnimatePresence>
    </div>
  )
}
