const SortIcon = ({ color = "var(--base-primary)", className }: { color?: string; className: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g id="icon(shadcn)" strokeLinecap="round" strokeLinejoin="round">
      <g id="Vector">
        <path stroke={color} d="M7.333 3.333H14"></path>
        <path stroke={color} d="M7.333 3.333H14"></path>
      </g>
      <g id="Vector_2">
        <path stroke={color} d="M7.333 6H12"></path>
        <path stroke={color} d="M7.333 6H12"></path>
      </g>
      <g id="Vector_3">
        <path stroke={color} d="M7.333 8.667H10"></path>
        <path stroke={color} d="M7.333 8.667H10"></path>
      </g>
      <g id="Vector_4">
        <path stroke={color} d="m2 11.333 2 2 2-2"></path>
        <path stroke={color} d="m2 11.333 2 2 2-2"></path>
      </g>
      <g id="Vector_5">
        <path stroke={color} d="M4 12V2.667"></path>
        <path stroke={color} d="M4 12V2.667"></path>
      </g>
    </g>
  </svg>
)

export default SortIcon
