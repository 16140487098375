import { Discussion } from "@/models/Discussions"
import { marginInPixels } from "../../Inline/useSyncElementPosition"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/Tooltip"
import { ReadOnlyComment } from "./ReadOnlyComment"
import { useDiscussions } from "@/services/store/useDiscussions"
import { CombinedAvatarChip } from "@/components/designSystem/Avatar/CombinedAvatarChip.tsx"
import { useMemo } from "react"

interface Props {
  current: Discussion
  discussionNumber: number
  isResolved: boolean
  handleOpen: () => void
  topPosition: number | null
}

export interface CommentingUser {
  name: string
  imageSrc: string | undefined
}

function removeDuplicateUsers(users: CommentingUser[]) {
  return Array.from(new Map(users.map((user) => [user.name, user])).values())
}

export const DiscussionChip = ({ current, discussionNumber, isResolved, handleOpen, topPosition }: Props) => {
  const lastComment = current.comments[current.comments.length - 1]
  const remainingComments = current.comments.length - 1
  const { setCurrentHoveredDiscussion, clearCurrentHoveredDiscussion } = useDiscussions()

  const commentingUsers: CommentingUser[] = useMemo(() => {
    return current.comments.map((comment) => ({
      name: comment.author_user?.display_name ?? comment.author,
      imageSrc: comment.author_user?.avatar_url
    }))
  }, [current.comments])

  const avatarColor = isResolved ? "green" : current.outdated ? "gray" : "yellow"

  // marginInPixels is the top assigned to the discussion when it has no placement on the page
  const isDiscussionVisible = useMemo(() => topPosition && topPosition > marginInPixels, [topPosition])

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger
          onClick={handleOpen}
          onMouseEnter={() => setCurrentHoveredDiscussion(current)}
          onMouseLeave={() => clearCurrentHoveredDiscussion()}
        >
          <div
            data-discussion-number={discussionNumber}
            style={{ top: topPosition || 0 }}
            className={`left-3 flex w-20 cursor-pointer ${isDiscussionVisible ? "absolute" : "hidden"}`}
          >
            <CombinedAvatarChip color={avatarColor} avatars={removeDuplicateUsers(commentingUsers)} />
          </div>
        </TooltipTrigger>
        <TooltipContent
          style={{ top: (topPosition && topPosition - 20) || 0 }}
          className="absolute left-[-420px] min-h-[80px] w-[420px] shadow-md"
        >
          <ReadOnlyComment comment={lastComment} remainingComments={remainingComments} />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
