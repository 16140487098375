export const Discussions = {
  MoreReplies: "replies, last update ",
  OneMoreReply: "1 reply",
  New: "New",
  Thread: "Thread",
  AddCommentErrorMessage: "Oops! Comments must be at least 1 character long.",
  Reply: "Reply",
  Edit: "Edit",
  Delete: "Delete",
  Approve: "Approve",
  Send: "Send",
  NoDiscussions: "Looks like there aren't any discussions for this PR",
  NoDescription: "No description available",
  InReviewFor: "Change in review for ",
  ModifyCodeInline: "Modify code in-line...",
  CreateAThread: "Create a thread",
  Resolve: "Resolve",
  Resolved: "Resolved",
  Unresolve: "Unresolve",
  NoMatchingPersonFound: "No matching person found",
  ResolvedBy: "Resolved by",
  SuggestAChange: "Suggest a change",
  Outdated: "Outdated",
  RemainingComments: (commentsNumber: number) => `
  There ${commentsNumber > 1 ? "are" : "is"} ${commentsNumber} more 
  ${commentsNumber > 1 ? "comments" : "comment"}
  in this discussion`,
  CurrentCode: "Current code",
  ProposedCode: "Proposed code"
}
