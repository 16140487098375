import { formatDate } from "@/lib/dateFormats"
import { Comment as CommentI, Discussion } from "@/models/Discussions"
import { Comment } from "../Comment"
import { DiscussionSeparator } from "./DiscussionSeparator"
import { useTranslation } from "react-i18next"
import { useDiscussions } from "@/services/store/useDiscussions"
import { commentOnLinesFormat, isCommentSuggestion } from "../../utils"
import { Suggestion } from "../SuggestionV2"

interface Props {
  commentsToDisplay: CommentI[]
  remainingComments: number
  discussion: Discussion
  onCommentClick: () => void
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
}

export const DiscussionBody = ({
  commentsToDisplay,
  discussion,
  remainingComments,
  onCommentClick,
  handleResolveToggle,
  isResolved
}: Props) => {
  const { t } = useTranslation("Discussions")
  const { clearCurrentHoveredDiscussion, setCurrentHoveredDiscussion } = useDiscussions()
  const minimumLengthForSeparator = 2

  return (
    <div
      className="overflow-x-hidden text-left"
      onMouseEnter={() => setCurrentHoveredDiscussion(discussion)}
      onMouseLeave={() => clearCurrentHoveredDiscussion()}
    >
      <p className="mb-4 text-xxs leading-[13px] text-secondary">
        {commentOnLinesFormat(discussion)}
        {formatDate(discussion.created_at)}
      </p>
      {commentsToDisplay[0] ? (
        isCommentSuggestion(commentsToDisplay[0]) ? (
          <Suggestion
            key={commentsToDisplay[0].id}
            onCommentClick={onCommentClick}
            comment={commentsToDisplay[0]}
            isClickable
            current={discussion}
            resolvable={true}
            handleResolveToggle={handleResolveToggle}
            isResolved={isResolved}
          />
        ) : (
          <Comment
            key={commentsToDisplay[0].id}
            onCommentClick={onCommentClick}
            isFullComment={false}
            comment={commentsToDisplay[0]}
            isClickable
            current={discussion}
            resolvable={true}
            handleResolveToggle={handleResolveToggle}
            isResolved={isResolved}
          />
        )
      ) : null}
      {remainingComments > 0 ? (
        <p className="mb-4 text-xxs leading-[13px] text-fill">
          {remainingComments} {remainingComments === 1 ? t("OneMoreReply") : t("MoreReplies")}{" "}
          {commentsToDisplay[1] ? formatDate(commentsToDisplay[1].created_at) : null}
        </p>
      ) : null}
      {commentsToDisplay.length >= minimumLengthForSeparator ? <DiscussionSeparator /> : null}
      {commentsToDisplay[1] ? (
        isCommentSuggestion(commentsToDisplay[1]) ? (
          <Suggestion
            key={commentsToDisplay[1].id}
            onCommentClick={onCommentClick}
            comment={commentsToDisplay[1]}
            isClickable
            current={discussion}
          />
        ) : (
          <Comment
            key={commentsToDisplay[1].id}
            onCommentClick={onCommentClick}
            isFullComment={false}
            comment={commentsToDisplay[1]}
            isClickable
            current={discussion}
          />
        )
      ) : null}
    </div>
  )
}
