import { useCallback, useEffect, useState } from "react"
import { clearFilterFromLocalStorage, extractCommaStringFromSet, setInitialMultiSelectedValue } from "../utils"
import { Filter } from "../types"
import { useLocation, useNavigate } from "react-router-dom"
import { checkSetsEquality, multiApplyValue, syncMultiValueWithLocalStorage } from "./helpers"
import { LocalStorageService } from "@/lib/localStorageService"

interface Args {
  urlFilter: string | null
  isPaginated: boolean
  searchParams: URLSearchParams
  localStorageKey?: string
  filter: Filter
  defaultValue?: string | string[]
}

const localStorageService = new LocalStorageService()

export const useMultiFilter = ({
  urlFilter,
  filter: { urlKey, isSyncedWithLocalStorage, defaultValue },
  isPaginated,
  searchParams,
  localStorageKey
}: Args) => {
  const [open, setOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set())
  const [prevSelectedValues, setPrevSelectedValues] = useState<Set<string>>(new Set())
  const [isChanged, setIsChanged] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setPrevSelectedValues(setInitialMultiSelectedValue(urlFilter))
  }, [urlFilter, setPrevSelectedValues])

  useEffect(() => {
    setSelectedValues(setInitialMultiSelectedValue(urlFilter))
  }, [urlFilter, setSelectedValues])

  const handleOptionSelect = useCallback(
    (value: string) => {
      const stateCopy = new Set(selectedValues)

      if (stateCopy.has(value)) {
        stateCopy.delete(value)
        setSelectedValues(stateCopy)
      } else {
        stateCopy.add(value)
        setSelectedValues(stateCopy)
      }

      if (checkSetsEquality(stateCopy, prevSelectedValues)) {
        setIsChanged(false)
      } else {
        setIsChanged(true)
      }
    },
    [selectedValues, prevSelectedValues, setSelectedValues, setIsChanged]
  )

  const handleApplyFilters = useCallback(() => {
    if (isPaginated) {
      searchParams.delete("page")
    }

    const selectedValuesArray = Array.from(selectedValues)
    const computedValue = extractCommaStringFromSet(selectedValues)

    if (!computedValue) {
      multiApplyValue({
        navigate,
        isSyncedWithLocalStorage,
        pathname,
        searchParams,
        selectedValuesArray,
        setIsChanged,
        setOpen,
        urlKey,
        localStorageKey,
        computedValue: null,
        applyCase: "delete"
      })

      // early return from the function because we are deleting when there is no value
      return
    }

    multiApplyValue({
      computedValue,
      navigate,
      pathname,
      searchParams,
      selectedValuesArray,
      setIsChanged,
      setOpen,
      urlKey,
      isSyncedWithLocalStorage,
      localStorageKey,
      urlFilter,
      applyCase: "set"
    })
  }, [
    selectedValues,
    urlFilter,
    isPaginated,
    navigate,
    pathname,
    searchParams,
    urlKey,
    setOpen,
    isSyncedWithLocalStorage,
    localStorageKey
  ])

  const handleClearFilter = useCallback(() => {
    if (isPaginated) {
      searchParams.delete("page")
    }

    searchParams.delete(urlKey)

    const stateCopy = selectedValues
    stateCopy.clear()

    setSelectedValues(stateCopy)
    setIsChanged(false)
    setOpen(false)

    if (isSyncedWithLocalStorage && localStorageKey) {
      clearFilterFromLocalStorage(localStorageKey, urlKey)
    }

    navigate({
      pathname: pathname,
      search: `?${searchParams.toString()}`
    })
  }, [
    searchParams,
    urlKey,
    navigate,
    pathname,
    isPaginated,
    selectedValues,
    setOpen,
    isSyncedWithLocalStorage,
    localStorageKey
  ])

  const syncDefaultValue = useCallback(() => {
    const earlyExit = !defaultValue || (localStorageKey && localStorageService.hasItem(localStorageKey))

    // Early exit if defaultValue is not provided or if a value is already set in localStorage
    if (earlyExit) return

    // checking in multi filter if the default value is actually proper type of string array
    if (Array.isArray(defaultValue)) {
      const defaultValuesSet = new Set(defaultValue)
      const computedValue = extractCommaStringFromSet(defaultValuesSet)
      searchParams.append(urlKey, computedValue as string)

      syncMultiValueWithLocalStorage({
        selectedValuesArray: defaultValue,
        urlKey,
        localStorageKey,
        isSyncedWithLocalStorage
      })

      return navigate({
        pathname: pathname,
        search: `?${searchParams.toString()}`
      })
    }
  }, [defaultValue, localStorageKey, searchParams, navigate, pathname, urlKey, isSyncedWithLocalStorage])

  return {
    handleApplyFilters,
    handleClearFilter,
    handleOptionSelect,
    open,
    isChanged,
    setOpen,
    setIsChanged,
    selectedValues,
    syncDefaultValue
  }
}
