import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader.tsx"
import { useFetchUser } from "@/hooks/queries/useFetchUser.tsx"
import { useFetchReviewerConfig } from "@/hooks/queries/useFetchReviewerConfig.tsx"
import { REVIEWER_TYPES } from "@/pages/settings/reviewer/ReviewerTypes.tsx"
import {
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenu,
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { AlertCircle, MoreHorizontal } from "lucide-react"
import { useUpdateReviewerConfiguration } from "@/hooks/mutations/useUpdateReviewerConfiguration"
import { FindingConfig } from "@/models/ReviewerConfig.ts"

export function Preferences() {
  const { data: backendUser, isLoading } = useFetchUser()
  const { data: reviewerConfig, isLoading: isReviewerConfigLoading } = useFetchReviewerConfig()
  const { mutateAsync: updateReviewerConfig } = useUpdateReviewerConfiguration()
  const { t } = useTranslation("Reviewer")
  document.title = "Settings - Preferences"

  const mergedReviewerTypes = useMemo(() => {
    const findingConfig = reviewerConfig?.findingConfig || []
    return REVIEWER_TYPES.map((reviewerType) => {
      const config = findingConfig.find((config: FindingConfig) => config.findingType === reviewerType.findingType)

      return {
        ...reviewerType,
        enabled: config?.enabled ?? true
      }
    })
  }, [reviewerConfig])

  const handleClick = (findingType: FindingConfig) => {
    const newConfig = {
      findingType: findingType.findingType,
      enabled: !findingType.enabled
    }
    updateReviewerConfig(newConfig)
  }

  if (isLoading || isReviewerConfigLoading) {
    return <SpinnerLoader className="py-4" />
  }

  return (
    <section>
      <div className="flex justify-between pb-4">
        <h1 className="flex self-center text-s font-medium text-primary">{t("ReviewerWorkflow")}</h1>
        <div className="flex h-fit flex-row rounded-md border border-border bg-fill px-4 py-1.5  text-s font-normal text-secondary">
          <AlertCircle className="mr-3 flex size-4 self-center" />
          {t("ConfigurationRestriction")}
        </div>
      </div>
      <div className="flex flex-wrap gap-4 pb-2.5">
        {mergedReviewerTypes.map((item) => (
          <div
            className={`w-[calc(50%-8px)] rounded-md border border-border p-4 ${item.isConfigurable && backendUser.is_owner ? "" : "bg-fill"}`}
          >
            <div className="mb-4 flex flex-row justify-between ">
              <div
                className={`flex size-8 items-center justify-center rounded bg-link ${item.isConfigurable && backendUser.is_owner ? "" : "opacity-65"}`}
              >
                <item.icon className="size-4 text-white" />
              </div>
              {item.enabled ? (
                <div className="flex items-center justify-between">
                  {item.isConfigurable ? (
                    <div className="ml-2">
                      <TooltipProvider delayDuration={0}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div>
                              <DropdownMenu modal={false} open={backendUser.is_owner ? undefined : false}>
                                <DropdownMenuTrigger
                                  className={`p-1 ${!backendUser.is_owner ? "cursor-not-allowed opacity-50" : ""}`}
                                  disabled={!backendUser.is_owner}
                                >
                                  <MoreHorizontal className="size-4 hover:text-secondary" />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                  <DropdownMenuItem
                                    onClick={() => handleClick(item)}
                                    className="flex items-center gap-2 text-error-dark"
                                  >
                                    {t("Deactivate")}
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                          </TooltipTrigger>
                          {!backendUser.is_owner && (
                            <TooltipContent align="end" className="-translate-x-1 text-xs">
                              <p>{t("ConfigurationRestriction")}</p>
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  ) : null}
                </div>
              ) : (
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span>
                        <Button
                          disabled={!backendUser.is_owner}
                          variant="outline"
                          className="h-[32px] px-2.5 text-xs font-normal"
                          onClick={() => handleClick(item)}
                        >
                          {t("Activate")}
                        </Button>
                      </span>
                    </TooltipTrigger>
                    {!backendUser.is_owner && (
                      <TooltipContent align="end" className="text-xs" sideOffset={5}>
                        <p>{t("ConfigurationRestriction")}</p>
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <h5 className="text-s font-medium text-primary">{item.display}</h5>
            <div className="text-xs font-normal text-secondary">{item.description}</div>
          </div>
        ))}
      </div>
    </section>
  )
}
