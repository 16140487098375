import { Badge, Button, PopoverTrigger } from "@/components"
import { Option } from "../types"
import { useTranslation } from "react-i18next"

interface Props {
  label: string
  selectedValues: Set<string>
  options: Option[]
  onClick: () => void
}

const generateLabel = (label: string, selectedValues: Set<string>) => {
  return `${label}${selectedValues.size > 0 ? ":" : ""}`
}

export const MultiSelectAnchor = ({ label, options, selectedValues, onClick }: Props) => {
  const { t } = useTranslation("Filters")

  return (
    <PopoverTrigger asChild>
      <Button
        onClick={onClick}
        variant="outline"
        size="sm"
        className={`h-7 px-2 py-0 text-xs ${selectedValues.size > 0 ? "bg-border hover:bg-border" : ""}`}
      >
        {generateLabel(label, selectedValues)}
        {selectedValues?.size > 0 && (
          <>
            <Badge variant="secondary" className="rounded-sm bg-border px-1 hover:bg-border lg:hidden">
              {selectedValues.size}
            </Badge>
            <div className="hidden space-x-1 lg:flex">
              {selectedValues.size > 1 ? (
                <Badge variant="secondary" className="cursor-pointer rounded-sm bg-border px-1 hover:bg-border">
                  {selectedValues.size} {t("Selected")}
                </Badge>
              ) : (
                options
                  .filter((option) => selectedValues.has(option.value))
                  .map((option) => (
                    <Badge
                      variant="secondary"
                      key={option.value}
                      className="cursor-pointer rounded-sm bg-border px-1 hover:bg-border"
                    >
                      {option.label}
                    </Badge>
                  ))
              )}
            </div>
          </>
        )}
      </Button>
    </PopoverTrigger>
  )
}
