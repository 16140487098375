import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { router } from "./pages"
import { AuthProvider } from "@descope/react-sdk"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { datadogRum } from "@datadog/browser-rum"
import { datadogLogs } from "@datadog/browser-logs"

import "./index.css"
import "./services/i18n"
import { Toaster } from "./components/ui/toaster"
import { backendBaseUrlAbsolute } from "./lib/urls"
import { runtimeConfig } from "./lib/config"

const descopeProjectId = runtimeConfig.VITE_DESCOPE_PROJECT_ID
const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1, refetchOnWindowFocus: false } } })

if (runtimeConfig.VITE_ENABLE_TRACING === "true") {
  datadogRum.init({
    applicationId: runtimeConfig.VITE_DD_APP_ID,
    clientToken: runtimeConfig.VITE_DD_CLIENT_TOKEN,
    site: runtimeConfig.VITE_DD_SITE,
    service: "baz-frontend",
    env: runtimeConfig.VITE_APP_ENV,
    version: runtimeConfig.VITE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "allow",
    allowedTracingUrls: [backendBaseUrlAbsolute()],
    telemetrySampleRate: 0
  })
  datadogLogs.init({
    clientToken: runtimeConfig.VITE_DD_CLIENT_TOKEN,
    site: runtimeConfig.VITE_DD_SITE,
    service: "baz-frontend",
    env: runtimeConfig.VITE_APP_ENV,
    version: runtimeConfig.VITE_APP_VERSION,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["info", "warn", "error"],
    sessionSampleRate: 100,
    telemetrySampleRate: 0
  })
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={queryClient}>
    <Toaster />
    <AuthProvider projectId={descopeProjectId}>
      <RouterProvider router={router} />
    </AuthProvider>
  </QueryClientProvider>
)
