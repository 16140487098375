import { ChangeCard } from "./ChangeCard"
import { Change } from "@/models/Changes"
import { Paginator, SkeletonLoader } from "@/components"
import { errorHandler } from "@/services/api/helpers"
import { ChangeFilters } from "./ChangeFilters"
import { ContentContainer } from "@/components/designSystem/ContentContainer"
import { CreateCRButton } from "@/pages/changes/CreateCRButton"
import { SorterFilter } from "@/components/designSystem/Filters/variations/SorterFilter"
import { timeSorter } from "@/components/designSystem/Filters/createFilters"
import SortIcon from "@/assets/icons/SortIcon.tsx"
import { useChangesFilters } from "@/components/designSystem/Filters/hooks/useChangesFilters.ts"
import { useSettings } from "@/services/store/useSettings.ts"
import { useEffect } from "react"
import NoCRFound from "@/components/designSystem/FCC/NoCRFound"
import { useToast } from "@/hooks/useToast"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

const sorter = timeSorter({})

export function Changes() {
  const { data, error, isLoading } = useChangesFilters()
  document.title = "Changes"
  const searchParams = new URLSearchParams(window.location.search)
  const statusParam = searchParams.get("status")
  const isOpenFilter = statusParam === "open"
  const { setSidebarWidth } = useSettings()
  const { toast } = useToast()
  const location = useLocation()
  const { t } = useTranslation("OnBoarding")

  useEffect(() => {
    const fromStuckOnboarding = location.state?.fromStuckOnboarding

    if (fromStuckOnboarding) {
      toast({
        title: t("OnboardingStatus"),
        description: t("OnboardingInProgress"),
        duration: 6000,
        variant: "default"
      })
    }
  }, [location.state?.fromStuckOnboarding, t, toast])

  useEffect(() => {
    setSidebarWidth(0)
  }, [setSidebarWidth])

  if (isLoading) {
    return (
      <ContentContainer>
        <SkeletonLoader className="pt-6" />
      </ContentContainer>
    )
  }

  if (error) {
    return (
      <ContentContainer>
        <p>{errorHandler(error)}</p>
      </ContentContainer>
    )
  }

  if (!data) return null

  return (
    <ContentContainer>
      <div className="sticky top-16 z-10 flex justify-between bg-background pb-4 pt-6">
        <ChangeFilters count={data.total} />
        <div className="flex items-center gap-4">
          <SorterFilter
            key={sorter.label}
            sorter={sorter}
            align="end"
            Icon={<SortIcon className="mr-1" />}
            withTooltip={false}
          />
          <CreateCRButton />
        </div>
      </div>

      <div className="mb-2 flex flex-col gap-2">
        {data.changes.map((codeChange: Change) => (
          <ChangeCard key={codeChange.id} codeChange={codeChange} />
        ))}
        {data.total <= 0 && <NoCRFound isOpenCRFilter={isOpenFilter} />}
      </div>

      <Paginator hasMore={data.has_more} total={data.total} itemsPerPage={data.per_page} />
    </ContentContainer>
  )
}
