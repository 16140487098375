import { useTranslation } from "react-i18next"
import { OnboardingFieldStatus, OnboardingResponse } from "@/models/Onboarding.ts"

interface ListItemProps {
  text: string
  status: OnboardingFieldStatus
}

interface ProgressListProps {
  onboardingStatus?: OnboardingResponse
}

function ListItem({ status, text }: ListItemProps): JSX.Element {
  return (
    <li className="mb-4 flex w-full items-center">
      <span className="relative mr-4 flex size-3">
        <span
          className={`
        ${status === "in_progress" ? "animate-ping" : ""}
        ${status === "pending" ? "opacity-10" : "opacity-75"}
         absolute inline-flex size-full rounded-full bg-link`}
        />
        <span
          className={`
          ${status === "pending" ? "opacity-10" : "opacity-75"}
           relative inline-flex size-3 rounded-full bg-link`}
        />
      </span>
      <span
        className={`text-sm 
      ${status === "pending" ? "text-hint" : "text-primary "}
      ${status === "in_progress" && "font-bold"}
      `}
      >
        {text}
      </span>
    </li>
  )
}

export default function ProgressList({ onboardingStatus }: ProgressListProps) {
  const { t } = useTranslation("OnBoarding")

  return (
    <ul className="mt-8 min-h-24">
      {onboardingStatus ? (
        <>
          <ListItem text={t("OnboardUsers")} status={onboardingStatus["users"]} />
          <ListItem text={t("OnboardRepositories")} status={onboardingStatus["repositories"]} />
          <ListItem text={t("AnalyzeChanges")} status={onboardingStatus["pullRequests"]} />
        </>
      ) : null}
    </ul>
  )
}
