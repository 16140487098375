import { ClipLoader } from "react-spinners"
import { cn } from "@/lib/utils"
interface Props {
  className?: string
  size?: string
  color?: string
}

export const SpinnerLoader = ({ className, size = "30px", color }: Props) => {
  return (
    <div data-testid="spinner" className={cn("flex w-full h-full items-center justify-center", className)}>
      <ClipLoader size={size} color={color || "var(--tint)"} />
    </div>
  )
}
