const SearchCRsWithFilters = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="172" height="97" fill="none" viewBox="0 0 172 97">
    <g filter="url(#filter0_dd_8990_253647)">
      <rect width="108" height="38" x="32" y="35" fill="url(#paint0_linear_8990_253647)" rx="8"></rect>
      <rect width="107" height="37" x="32.5" y="35.5" stroke="url(#paint1_linear_8990_253647)" rx="7.5"></rect>
    </g>
    <g filter="url(#filter1_dd_8990_253647)">
      <rect width="126" height="45" x="23" y="20" fill="url(#paint2_linear_8990_253647)" rx="8"></rect>
      <rect width="125" height="44" x="23.5" y="20.5" stroke="url(#paint3_linear_8990_253647)" rx="7.5"></rect>
    </g>
    <g filter="url(#filter2_dd_8990_253647)">
      <rect width="142" height="51" x="15" y="6" fill="url(#paint4_linear_8990_253647)" rx="8"></rect>
      <rect width="141" height="50" x="15.5" y="6.5" stroke="url(#paint5_linear_8990_253647)" rx="7.5"></rect>
    </g>
    <rect width="81" height="5" x="24" y="42" fill="#94A3B8" opacity="0.2" rx="2.5"></rect>
    <rect width="118" height="5" x="24" y="33" fill="#94A3B8" opacity="0.2" rx="2.5"></rect>
    <rect width="95" height="7" x="47" y="16" fill="#94A3B8" opacity="0.23" rx="2"></rect>
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M39.666 14H26.333l5.333 6.307v4.36L34.333 26v-5.693z"
    ></path>
    <path
      stroke="#C7CDD9"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M39.666 14H26.333l5.333 6.307v4.36L34.333 26v-5.693z"
    ></path>
    <defs>
      <linearGradient id="paint0_linear_8990_253647" x1="86" x2="86" y1="35" y2="73" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F3F5F7"></stop>
        <stop offset="0.75" stopColor="#fff"></stop>
      </linearGradient>
      <linearGradient id="paint1_linear_8990_253647" x1="86" x2="86" y1="35" y2="73" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E2E7EF"></stop>
        <stop offset="1" stopColor="#EFF2F6"></stop>
      </linearGradient>
      <linearGradient id="paint2_linear_8990_253647" x1="86" x2="86" y1="20" y2="65" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F3F5F7"></stop>
        <stop offset="0.75" stopColor="#fff"></stop>
      </linearGradient>
      <linearGradient id="paint3_linear_8990_253647" x1="86" x2="86" y1="20" y2="65" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E2E7EF"></stop>
        <stop offset="1" stopColor="#EFF2F6"></stop>
      </linearGradient>
      <linearGradient id="paint4_linear_8990_253647" x1="86" x2="86" y1="6" y2="57" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F3F5F7"></stop>
        <stop offset="0.75" stopColor="#fff"></stop>
      </linearGradient>
      <linearGradient id="paint5_linear_8990_253647" x1="86" x2="86" y1="6" y2="57" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E2E7EF"></stop>
        <stop offset="1" stopColor="#EFF2F6"></stop>
      </linearGradient>
      <filter
        id="filter0_dd_8990_253647"
        width="136.8"
        height="66.8"
        x="17.6"
        y="29.6"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology in="SourceAlpha" radius="1" result="effect1_dropShadow_8990_253647"></feMorphology>
        <feOffset dy="9"></feOffset>
        <feGaussianBlur stdDeviation="7.7"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 0.03 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8990_253647"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology in="SourceAlpha" radius="2" result="effect2_dropShadow_8990_253647"></feMorphology>
        <feOffset dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2.85"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 0.05 0"></feColorMatrix>
        <feBlend in2="effect1_dropShadow_8990_253647" result="effect2_dropShadow_8990_253647"></feBlend>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_8990_253647" result="shape"></feBlend>
      </filter>
      <filter
        id="filter1_dd_8990_253647"
        width="154.8"
        height="73.8"
        x="8.6"
        y="14.6"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology in="SourceAlpha" radius="1" result="effect1_dropShadow_8990_253647"></feMorphology>
        <feOffset dy="9"></feOffset>
        <feGaussianBlur stdDeviation="7.7"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 0.03 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8990_253647"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology in="SourceAlpha" radius="2" result="effect2_dropShadow_8990_253647"></feMorphology>
        <feOffset dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2.85"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 0.05 0"></feColorMatrix>
        <feBlend in2="effect1_dropShadow_8990_253647" result="effect2_dropShadow_8990_253647"></feBlend>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_8990_253647" result="shape"></feBlend>
      </filter>
      <filter
        id="filter2_dd_8990_253647"
        width="170.8"
        height="79.8"
        x="0.6"
        y="0.6"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology in="SourceAlpha" radius="1" result="effect1_dropShadow_8990_253647"></feMorphology>
        <feOffset dy="9"></feOffset>
        <feGaussianBlur stdDeviation="7.7"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 0.03 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8990_253647"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology in="SourceAlpha" radius="2" result="effect2_dropShadow_8990_253647"></feMorphology>
        <feOffset dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2.85"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.254902 0 0 0 0 0.333333 0 0 0 0.05 0"></feColorMatrix>
        <feBlend in2="effect1_dropShadow_8990_253647" result="effect2_dropShadow_8990_253647"></feBlend>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_8990_253647" result="shape"></feBlend>
      </filter>
    </defs>
  </svg>
)

export default SearchCRsWithFilters
