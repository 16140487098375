import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Tabs, TabsList, TabsTrigger, TooltipProvider } from "@/components"
import { LogError, RunError, Stat, BreakingChange } from "@/models/Insight.ts"
import { UniqueErrors, UniqueStatEntry, processStats, processRunErrors } from "./helpers"
import { ChecksTabContent } from "@/components/designSystem/Insights/ChecksTabContent.tsx"
import { UsageTabContent } from "@/components/designSystem/Insights/UsageTabContent.tsx"
import { BreakingChangesContent } from "@/components/designSystem/Insights/BreakingChangesContent.tsx"
import { Table } from "@tanstack/react-table"
import { FccRow, Side } from "@/models/FCC"
import { FileCodeLine } from "@/components/designSystem/FC/types"

interface Props {
  stats: Stat[]
  runErrors: RunError[]
  logErrors: LogError[]
  breakingChanges: BreakingChange[]
  table: Table<FccRow> | Table<FileCodeLine>
  side: Side
  lineNumber: number | null
}

export function Insights({ stats, runErrors, logErrors, breakingChanges, table, side, lineNumber }: Props) {
  const { t } = useTranslation("Insight")

  const uniqueStats: UniqueStatEntry[] = useMemo(() => processStats(stats), [stats])
  const uniqueRunErrors: UniqueErrors = useMemo(() => processRunErrors(runErrors), [runErrors])

  const haveStatData = uniqueStats.length > 0
  const haveRunErrors = Object.keys(uniqueRunErrors).length > 0
  const haveLogErrors = logErrors.length > 0

  const defaultValue = haveStatData || haveRunErrors ? "usage" : "checks"

  return (
    <TooltipProvider>
      <Tabs defaultValue={defaultValue} className="max-w-[1000px]">
        {(uniqueStats.length > 0 || Object.keys(uniqueRunErrors).length > 0) && logErrors.length > 0 && (
          <TabsList className="grid w-[145px] grid-cols-2 bg-darker-fill">
            <TabsTrigger value="usage" disabled={!haveRunErrors && !haveStatData}>
              {t("Usage")}
            </TabsTrigger>
            <TabsTrigger value="checks" disabled={!haveLogErrors}>
              {t("Checks")}
            </TabsTrigger>
          </TabsList>
        )}
        <UsageTabContent stats={uniqueStats} runErrors={uniqueRunErrors} />
        <ChecksTabContent logErrors={logErrors} table={table} side={side} lineNumber={lineNumber} />
        <BreakingChangesContent breakingChanges={breakingChanges} table={table} side={side} lineNumber={lineNumber} />
      </Tabs>
    </TooltipProvider>
  )
}
