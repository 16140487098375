import { Avatar } from "@/components"
import { MouseEvent, useCallback, useState } from "react"
import { EditComment } from "./EditComment"
import { useForm } from "react-hook-form"
import { CommentCreationData } from "@/components/designSystem/Discussions/components/Comment/CommentCreationData"
import { RenderComment } from "@/components/designSystem/Discussions/components/Comment/RenderComment"
import { Comment as CommentInterface, Discussion, NewCommentBubble } from "@/models/Discussions"
import { ResolveController } from "./ResolveController"
import { isDiscussion } from "../../utils"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useToast } from "@/hooks/useToast"
import { useDeleteComment } from "@/hooks/mutations/useDeleteComment"
import { ghWriteErrorHandler } from "@/services/api/helpers"

export interface CommentProps {
  comment: CommentInterface
  isFullComment: boolean
  onCommentClick?: () => void
  isClickable: boolean
  current: NewCommentBubble | Discussion | null
  CRId?: string
  resolvable?: boolean
  handleResolveToggle?: (resolvedState: boolean) => void
  isResolved?: boolean
}

export const Comment = ({
  comment,
  isFullComment = false,
  onCommentClick,
  isClickable = false,
  current,
  CRId,
  resolvable,
  handleResolveToggle,
  isResolved
}: CommentProps) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const { toast } = useToast()
  const { mutateAsync } = useDeleteComment()
  const [editMode, setEditMode] = useState(false)
  const isResolvable = resolvable && current && isDiscussion(current)

  const { toggleManualRecalculationTrigger } = useDiscussions()

  const handleToggleMenu = useCallback(
    (event: MouseEvent<Element> | CustomEvent<{ originalEvent: PointerEvent }>) => {
      event.stopPropagation()

      if (!comment.editable) {
        return
      }
      setDropDownOpen((prev) => !prev)
    },
    [setDropDownOpen, comment.editable]
  )

  const handleDelete = useCallback(
    async (event: MouseEvent<Element>) => {
      event.stopPropagation()
      try {
        await mutateAsync({ commentId: comment.id })
      } catch (err) {
        toast({
          variant: "destructive",
          description: ghWriteErrorHandler(err)
        })
      } finally {
        handleToggleMenu(event)
      }
    },
    [mutateAsync, comment.id, toast, handleToggleMenu]
  )

  const handleEditClick = useCallback(
    (event: MouseEvent<Element>) => {
      handleToggleMenu(event)
      toggleManualRecalculationTrigger()
      setEditMode(!editMode)
    },
    [editMode, setEditMode, handleToggleMenu, toggleManualRecalculationTrigger]
  )

  const form = useForm({
    defaultValues: {
      body: comment.comment_body
    }
  })
  return (
    <div className="my-4 flex w-full flex-col">
      <div className="flex">
        <Avatar
          username={comment.author_user?.display_name || comment.author}
          avatarURL={comment.author_user?.avatar_url}
          className="mb-1 mr-4 size-8 cursor-default text-sm"
        />
        <div className="flex w-full flex-col break-words">
          <CommentCreationData
            comment={comment}
            dropDownOpen={dropDownOpen}
            handleToggleMenu={handleToggleMenu}
            handleDelete={handleDelete}
            handleEditClick={handleEditClick}
            onCommentClick={onCommentClick}
            isClickable={isClickable}
          />
          <div className="w-full text-primary">
            {editMode ? (
              <EditComment comment={comment} form={form} setEditMode={setEditMode} />
            ) : (
              <RenderComment
                comment={comment}
                isFullComment={isFullComment}
                isClickable={isClickable}
                onCommentClick={onCommentClick}
                current={current}
                CRId={CRId}
                handleResolveToggle={handleResolveToggle}
              />
            )}
          </div>
        </div>
      </div>
      {isResolvable ? (
        <ResolveController discussion={current} handleResolveToggle={handleResolveToggle} isResolved={isResolved} />
      ) : null}
    </div>
  )
}
