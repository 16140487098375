import { Discussion } from "@/models/Discussions"
import { useMemo } from "react"
import { getMinimumComments } from "../../utils"
import { marginInPixels } from "../../Inline/useSyncElementPosition"
import { DiscussionBody } from "./DiscussionBody"

interface Props {
  current: Discussion
  discussionNumber: number
  handleOpen: () => void
  isResolved: boolean
  handleResolveToggle: (resolveState: boolean) => void
  topPosition: number | null
}

export const ExpandedDiscussion = ({
  current,
  discussionNumber,
  handleOpen,
  isResolved,
  handleResolveToggle,
  topPosition
}: Props) => {
  const remainingComments = current.comments.length - 2
  const commentsToDisplay = useMemo(() => getMinimumComments(current.comments), [current.comments])

  // marginInPixels is the top assigned to the discussion when it has no placement on the page
  const isDiscussionVisible = useMemo(() => topPosition && topPosition > marginInPixels, [topPosition])

  return (
    <div
      data-discussion-number={discussionNumber}
      style={{ top: topPosition || 0 }}
      className={`${isDiscussionVisible ? "absolute" : "hidden"} left-5 z-10 w-laptop-discussion-width rounded-r-xl rounded-bl-xl border border-border bg-fill p-6 pb-0 pt-4 shadow-md shadow-border dark:shadow-background 2xl:w-desktop-discussion-width`}
    >
      <DiscussionBody
        commentsToDisplay={commentsToDisplay}
        discussion={current}
        remainingComments={remainingComments}
        onCommentClick={handleOpen}
        handleResolveToggle={handleResolveToggle}
        isResolved={isResolved}
      />
    </div>
  )
}
