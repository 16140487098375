import { RadixAccordion } from "@/components"
import { CIRunWithErrors } from "@/models/Changes"
import { BreakingChangeDetails } from "../Insights/helpers"

interface Props {
  TriggerElement: React.ReactNode
  ContentElement: React.ReactNode
  onClick: (check: CIRunWithErrors | BreakingChangeDetails) => void
  check: CIRunWithErrors | BreakingChangeDetails
}

export const ErrorAccordion = ({ TriggerElement, ContentElement, onClick, check }: Props) => {
  return (
    <div>
      <RadixAccordion.Accordion type="single" collapsible>
        <RadixAccordion.AccordionItem value="item-1" className="last:border-none">
          <RadixAccordion.AccordionTrigger
            className="w-full"
            onClick={(event) => {
              event.stopPropagation()
              onClick(check)
            }}
          >
            {TriggerElement}
          </RadixAccordion.AccordionTrigger>
          <RadixAccordion.AccordionContent className="bg-background">{ContentElement}</RadixAccordion.AccordionContent>
        </RadixAccordion.AccordionItem>
      </RadixAccordion.Accordion>
    </div>
  )
}
