import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { TopHeader } from "./TopHeader"
import { useEffect } from "react"
import { routes } from "@/lib/routes.ts"
import { useSettings } from "@/services/store/useSettings"
import Intercom from "@/components/ui/intercom"
import { CheckSlackConnection } from "@/pages/root/CheckSlackConnection"
import { Footer } from "@/pages/root/Footer"

export const Root = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { selectedTheme } = useSettings()

  useEffect(() => {
    if (pathname === routes.root) navigate(routes.changes.root)
  }, [pathname, navigate])

  useEffect(() => {
    const body = document.querySelector("body")

    if (selectedTheme === "dark") {
      body?.classList.add("dark")
    } else {
      body?.classList.remove("dark")
    }
  }, [selectedTheme])

  useEffect(() => {
    const changesRoute = location.pathname.startsWith("/changes/")

    if (changesRoute) {
      document.body.classList.add("invisible-scrollbar")
    } else {
      document.body.classList.remove("invisible-scrollbar")
    }

    return () => {
      document.body.classList.remove("invisible-scrollbar")
    }
  }, [pathname])

  return (
    <main className="relative flex min-h-screen w-full flex-col">
      <TopHeader />
      <section className="relative flex w-full grow flex-col bg-background">
        <Outlet />
        <CheckSlackConnection />
        <Intercom />
        <Footer />
      </section>
    </main>
  )
}
