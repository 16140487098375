import { convertToPercentage } from "@/lib/utils"

interface Props {
  addition: number
  deletion: number
}

const renderMagnitudeBasedOnPercentage = (additionPercentage: number, deletionPercentage: number) => {
  const diff = additionPercentage - deletionPercentage
  const dynamicColor = diff > 0 ? "#1AD598" : "#F3654A"
  const emptyColor = "#D1D1D1"
  const absoluteValue = Math.abs(diff)

  switch (true) {
    case absoluteValue <= 25:
      return (
        <>
          <rect width="4" height="8" fill="#1AD598" />
          <rect x="5" width="4" height="8" fill="#1AD598" />
          <rect x="10" width="4" height="8" fill="#F3654A" />
          <rect x="15" width="4" height="8" fill="#F3654A" />
          <rect x="20" width="4" height="8" fill={emptyColor} />
        </>
      )

    case absoluteValue <= 50:
      return (
        <>
          <rect width="4" height="8" fill="#1AD598" />
          <rect x="5" width="4" height="8" fill="#1AD598" />
          <rect x="10" width="4" height="8" fill={dynamicColor} />
          <rect x="15" width="4" height="8" fill="#F3654A" />
          <rect x="20" width="4" height="8" fill="#F3654A" />
        </>
      )

    // only addition or deletion case
    case absoluteValue === 100:
      return (
        <>
          <rect width="4" height="8" fill={dynamicColor} />
          <rect x="5" width="4" height="8" fill={dynamicColor} />
          <rect x="10" width="4" height="8" fill={dynamicColor} />
          <rect x="15" width="4" height="8" fill={dynamicColor} />
          <rect x="20" width="4" height="8" fill={emptyColor} />
        </>
      )

    // default case is above 50% difference
    default:
      return (
        <>
          <rect width="4" height="8" fill="#1AD598" />
          <rect x="5" width="4" height="8" fill={dynamicColor} />
          <rect x="10" width="4" height="8" fill={dynamicColor} />
          <rect x="15" width="4" height="8" fill={dynamicColor} />
          <rect x="20" width="4" height="8" fill="#F3654A" />
        </>
      )
  }
}

export const MagnitudeMeter = ({ addition, deletion }: Props) => {
  const [additionPercentage, deletionPercentage] = convertToPercentage(addition, deletion)

  renderMagnitudeBasedOnPercentage(additionPercentage, deletionPercentage)

  return (
    <svg width="24" height="8" viewBox="0 0 24 8" fill="none">
      {renderMagnitudeBasedOnPercentage(additionPercentage, deletionPercentage)}
    </svg>
  )
}
