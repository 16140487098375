import { isWordTooLong } from "../Comment/utils"
import { Comment } from "@/models/Discussions"
import { formatName, parseAndSanitizeComment } from "@/lib/utils"
import { formatTime } from "@/lib/dateFormats"
import { Avatar } from "@/components"
import { useTranslation } from "react-i18next"

interface Props {
  comment: Comment
  remainingComments: number
}

export const ReadOnlyComment = ({ comment, remainingComments }: Props) => {
  const { t } = useTranslation("Discussions")

  return (
    <section className="py-2">
      {remainingComments > 0 ? <p className="mb-2">{t("RemainingComments")(remainingComments)}</p> : null}
      <div className="flex">
        <Avatar
          username={comment.author_user?.display_name || comment.author}
          avatarURL={comment.author_user?.avatar_url}
          className="mb-1 mr-4 size-8 cursor-default text-sm"
        />
        <div className="flex flex-col">
          <div>
            <span className="mr-1 text-xs text-primary">{formatName(comment.author)}</span>
            <span className="mr-1 text-xxs text-secondary">{formatTime(comment.created_at)}</span>
          </div>
          <div
            className={`cursor-default ${isWordTooLong(comment.comment_body) ? "break-all" : "break-words"} text-xs`}
          >
            <div dangerouslySetInnerHTML={{ __html: parseAndSanitizeComment(comment.comment_body) as string }}></div>
          </div>
        </div>
      </div>
    </section>
  )
}
