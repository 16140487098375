import { createColumnHelper } from "@tanstack/react-table"
import { FileCodeLine } from "../types"
import { CodeCell } from "./cells/CodeCell"
import { NumberCell } from "./cells/NumberCell"

const columnHelper = createColumnHelper<FileCodeLine>()

export const columns = [
  columnHelper.accessor((row) => row, {
    id: "number",
    header: () => <></>,
    cell: (info) => <NumberCell info={info} />,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "expander",
    header: () => <></>,
    cell: () => <div></div>,
    size: 60
  }),
  columnHelper.accessor((row) => row, {
    id: "code",
    header: () => <></>,
    cell: (info) => <CodeCell info={info} />
  })
]
