import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components"
import { MouseEvent } from "react"
import { useTranslation } from "react-i18next"
import { Pencil, Trash2 } from "lucide-react"

interface Props {
  open: boolean
  onIconClick: (event: MouseEvent<Element>) => void
  onToggle: (event: CustomEvent<{ originalEvent: PointerEvent }>) => void
  onEditClick: (event: MouseEvent<Element>) => void
  onDelete: (event: MouseEvent<Element>) => void
}

export const DropDown = ({ open, onToggle, onEditClick, onDelete }: Props) => {
  const { t } = useTranslation("Discussions")

  return (
    <DropdownMenu open={open} modal={false}>
      <DropdownMenuTrigger />
      <DropdownMenuContent onPointerDownOutside={onToggle} align="start" className="p-1">
        <DropdownMenuItem className="text-primary" onClick={onEditClick}>
          <Pencil className="mr-2 text-primary" width={14} height={14} />
          {t("Edit")}
        </DropdownMenuItem>
        <DropdownMenuItem className="text-error-dark focus:text-error-dark" onClick={onDelete}>
          <Trash2 className="mr-2 text-error-dark" width={14} height={14} />
          {t("Delete")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
