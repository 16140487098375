import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { Subscription } from "@/models/Subscription"

export const useFetchSubscription = () => {
  const { error, isLoading, data } = useQuery<Subscription, unknown>({
    queryKey: ["billing"],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.billing.get)
      return response.data
    }
  })

  return { error, isLoading, data }
}
