import { Button, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { useCallback, useState } from "react"
import { ClipboardList } from "lucide-react"
import { useTranslation } from "react-i18next"
import { CIRun } from "@/models/Changes.ts"
import { ChecksDialog } from "./ChecksDialog.tsx"
import { TooltipPortal } from "@radix-ui/react-tooltip"

interface Props {
  ciChecks: CIRun[]
}

export const ChecksController = ({ ciChecks }: Props) => {
  const { t } = useTranslation("Toolbar")

  const [checksModalOpen, setChecksModalOpen] = useState(false)

  const disabled = ciChecks.length <= 0

  const handleChecksTriggerClick = useCallback(() => {
    if (disabled) {
      return false
    }
    setChecksModalOpen((prev) => !prev)
  }, [setChecksModalOpen, disabled])

  const handleChecksClose = useCallback(() => {
    setChecksModalOpen(false)
  }, [setChecksModalOpen])

  return (
    <Tooltip>
      <TooltipTrigger>
        <ChecksDialog
          open={checksModalOpen}
          onClose={handleChecksClose}
          onTriggerClick={handleChecksTriggerClick}
          ciChecks={ciChecks}
        >
          <Button disabled={disabled} variant="outline" className="size-8 p-0 hover:bg-darker-fill">
            <ClipboardList width={16} height={16} className="text-primary" />
          </Button>
        </ChecksDialog>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent sideOffset={10}>{disabled ? t("NoChecks") : t("Checks")}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}
