import { ChangeReview, CIRun } from "@/models/Changes.ts"
import { TFunction } from "i18next"
import { formatName } from "@/lib/utils.ts"
export const extractCIStatus = (runs?: CIRun[]) => {
  if (!runs || runs.length === 0) {
    return null
  }
  if (runs.some((run) => run.status === "failure" || run.status === "cancelled")) {
    return "failure"
  }
  if (runs.every((run) => run.status === "success" || run.status === "skipped")) {
    return "success"
  }
  if (runs.some((run) => run.status === "pending")) {
    return "pending"
  }
  return null
}

export const extractReviewStatus = (
  t: TFunction<"Changes", undefined>,
  reviews: ChangeReview[]
): { summary: string; details?: string } => {
  const writeAccessReviews = reviews.filter((review) => review.has_write_access)
  const approvals = writeAccessReviews.filter((review) => review.state === "approved")
  const reviewed = writeAccessReviews.filter((review) => review.state === "reviewed")
  if (approvals.length + reviewed.length === 0) {
    return { summary: t("Reviews.NoReviews") }
  }
  let summary = ""
  let details = ""
  if (approvals.length > 0) {
    summary += `${approvals.length} ${t("Reviews.Approved")}`
    details = approvals.map((review) => `${formatName(review.reviewer)} approved`).join("\n")
  }

  if (reviewed.length > 0) {
    summary += `${summary ? ", " : ""}${reviewed.length} ${t("Reviews.Reviewed")}`
    if (details) {
      details += "\n"
    }
    details += reviewed.map((review) => `${formatName(review.reviewer)} reviewed`).join("\n")
  }
  return { summary, details }
}
