import { TooltipProvider } from "@/components"
import { ChangeRequest } from "@/models/Changes.ts"
import { DiscussionController } from "./DiscussionController.tsx"
import { ChecksController } from "./ChecksController.tsx"

interface Props {
  changeRequest: ChangeRequest
  isMerged: boolean
}

export const InfoActions = ({ changeRequest, isMerged }: Props) => {
  return (
    <div className={`flex items-center gap-2 ${!isMerged ? "border-r border-border pr-3" : ""}`}>
      <TooltipProvider>
        <DiscussionController changeRequest={changeRequest} />
        <ChecksController ciChecks={changeRequest.runs} />
      </TooltipProvider>
    </div>
  )
}
