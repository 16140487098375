import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { BazzyContext, StreamResponse } from "@/models/Bazzy.ts"
import { useState } from "react"
import { useBazzy } from "@/services/store/useBazzy"

interface BaseBazzyProps {
  enabled?: boolean
  bazzyOption: string
  context?: BazzyContext
  commitSha: string
  fullRepoName: string
  timestamp: number
  freeText?: string
  insight?: string
}

type BazzyFetchType = "ask" | "insight"

const removeLeadingSlash = (path: string | null | undefined) => {
  return path?.startsWith("/") ? path.slice(1) : path
}

const createRequestBody = (props: BaseBazzyProps, type: BazzyFetchType) => {
  const { fullRepoName, context, commitSha, bazzyOption, freeText } = props

  const baseBody = {
    fullRepoName: fullRepoName,
    repoRelativePath: removeLeadingSlash(context?.filePath),
    commitSha: commitSha,
    bazzyOption: bazzyOption,
    startLine: context?.lines?.startLine,
    endLine: context?.lines?.endLine,
    freeText: freeText
  }

  if (type === "insight") {
    return { ...baseBody, insight: context?.relevantInsight }
  }

  return baseBody
}

export const useFetchBazzy = (type: BazzyFetchType, props: BaseBazzyProps) => {
  const [streamData, setStreamData] = useState<StreamResponse[]>([])
  const [hasStartedToGetStream, setHasStartedToGetStream] = useState(false)
  const { setEnableFetch } = useBazzy()

  const endpoint = type === "ask" ? apiRoutes.bazzy.ask : apiRoutes.bazzy.insight
  const requestBody = createRequestBody(props, type)

  const { error, isSuccess, data } = useQuery({
    queryKey: ["bazzy", type, props.bazzyOption, props.timestamp],
    queryFn: async ({ signal }) => {
      const resp = await axiosClient.post(endpoint, requestBody, {
        responseType: "text",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/event-stream"
        },
        signal,
        onDownloadProgress: (progressEvent) => {
          const data = progressEvent.event.target.response
          const lines = data
            .split("\n")
            .filter((line: string) => line.trim().startsWith("data: "))
            .map((line: string) => {
              try {
                return JSON.parse(line.slice(5))
              } catch {
                return null
              }
            })
            .filter(Boolean)

          if (lines.length > 0 && !hasStartedToGetStream) {
            setHasStartedToGetStream(true)
          }

          if (progressEvent.loaded === progressEvent.total) {
            setHasStartedToGetStream(false)
            setEnableFetch(false)
          }

          setStreamData(lines)
        }
      })
      return resp.data
    },
    enabled: props.enabled && !!props.context && !!props.commitSha && !!props.bazzyOption,
    retry: false,
    refetchOnWindowFocus: false
  })

  return {
    error,
    isLoading: !isSuccess && !error,
    data,
    streamData,
    hasStartedToGetStream
  }
}
