import { useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { computeContentArgs, getFilePathFromUrl } from "../utils"
import { Repository } from "@/models/Repository"
import { useFetchContentMetadata } from "@/hooks/queries/useFetchContentMetadata"
import { useFetchContent } from "@/hooks/queries/useFetchContent"

interface Args {
  currentRepository?: Repository
}

export const useContent = ({ currentRepository }: Args) => {
  const { branch, pathStart } = useParams()
  const { pathname } = useLocation()

  const path = useMemo(() => (pathStart ? getFilePathFromUrl(pathname) : null), [pathname, pathStart])

  const computedContentMetadataArgs = useMemo(
    () => computeContentArgs({ path, branchName: branch, repositoryId: currentRepository?.id, type: "dir" }),
    [branch, currentRepository, path]
  )

  const {
    data: contentMetadata,
    error: contentMetadataError,
    isLoading: contentMetadataLoading
  } = useFetchContentMetadata({ fetchArgs: computedContentMetadataArgs, path: pathname })

  const computedContentArgs = useMemo(
    () =>
      computeContentArgs({
        path: contentMetadata?.file_path || null,
        branchName: branch,
        repositoryId: currentRepository?.id,
        type: "file"
      }),
    [branch, currentRepository, contentMetadata?.file_path]
  )

  const {
    data: content,
    error: contentError,
    isLoading: contentLoading
  } = useFetchContent({ fetchArgs: computedContentArgs, path: pathname })

  const fileLoading = contentMetadataLoading || contentLoading
  const fileError = contentMetadataError || contentError

  return { fileLoading, fileError, content, contentMetadata }
}
