import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { MergeStatus } from "@/models/Changes"

export const useFetchMergeBlockers = (changeId: string) => {
  const { error, isLoading, data } = useQuery<MergeStatus, unknown>({
    queryKey: ["mergeStatus", changeId],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.changes.mergeStatus(changeId))
      return response.data
    }
  })

  return { error, isLoading, data }
}
