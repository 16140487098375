import { useSearchParams } from "react-router-dom"

interface Args {
  urlKey: string
  filterType: "single" | "multi"
}

type ReturnType<T extends Args> = T["filterType"] extends "multi" ? Multi : Single
type Multi = string[] | null
type Single = string | null

export const useGetFilterValue = <T extends Args>({ filterType, urlKey }: T): ReturnType<T> => {
  const [searchParams] = useSearchParams()
  const urlFilter = searchParams.get(urlKey)

  if (urlFilter && filterType === "multi") {
    return urlFilter.split(",") as ReturnType<T>
  }

  return urlFilter as ReturnType<T>
}
