import { useTranslation } from "react-i18next"
import AnimatedCodeMarkingSvg from "@/assets/icons/AnimatedCodeMarkingSvg.tsx"

export const EmptyBazzy = () => {
  const { t } = useTranslation("Bazzy")

  return (
    <div className="size-full flex-col items-center justify-center">
      <div className="mt-40 flex justify-center">
        <AnimatedCodeMarkingSvg />
      </div>
      <div className="mt-6 w-full flex-col">
        <p className="flex justify-center text-s font-medium italic leading-[22px] text-primary">
          {t("EmptyBazzyHeader")}
        </p>
        <p className="flex justify-center text-xs leading-5 text-secondary">{t("EmptyBazzyMessage")}</p>
      </div>
    </div>
  )
}
