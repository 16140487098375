import { Navigate, Outlet } from "react-router-dom"
import { useSession } from "@descope/react-sdk"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader.tsx"
import { LocalStorageService } from "@/lib/localStorageService.ts"
import { routes } from "@/lib/routes"

const localStorageService = new LocalStorageService("sessionStorage")

const PublicRoute = () => {
  const { isAuthenticated, isSessionLoading } = useSession()
  const from = localStorageService.getItem("from")

  if (isSessionLoading) {
    return <SpinnerLoader className="pt-6" />
  }

  return !isAuthenticated ? <Outlet /> : <Navigate to={from ? from : routes.changes.root} />
}

export default PublicRoute
