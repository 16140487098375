import { Popover, PopoverContent, PopoverTrigger } from "@/components"
import { formatDate } from "@/lib/dateFormats"
import { Commit as CommitI } from "@/models/FC"
import { GitCommitHorizontal } from "lucide-react"
import { useTranslation } from "react-i18next"

interface Props {
  commits: CommitI[]
}

interface CommitProps {
  commit: CommitI
}

const showNumberOfCommits = (commits?: CommitI[]) => {
  return commits?.length ? `(${commits.length})` : null
}

const Commit = ({ commit }: CommitProps) => {
  const { t } = useTranslation("Common")
  const { t: historyT } = useTranslation("CommitHistory")
  return (
    <>
      <div className="flex items-center gap-1 p-2 font-inter text-xs hover:bg-fill">
        <GitCommitHorizontal width={16} height={16} />
        <span className="text-primary">{commit.message}</span>
        <span className="text-xxs text-secondary">
          {historyT("CommittedOn")} {formatDate(commit.date)} {t("By")} {commit.author.split(" ")[0]}
        </span>
      </div>
      <div className="border-b"></div>
    </>
  )
}

export const CommitHistory = ({ commits }: Props) => {
  const { t } = useTranslation("CommitHistory")

  return (
    <Popover>
      <PopoverTrigger>
        <p className="text-xs text-link">
          {t("CommitHistory")} {showNumberOfCommits(commits)}
        </p>
      </PopoverTrigger>
      <PopoverContent className="w-fit min-w-[650px] p-0 text-xs" align="end">
        {commits?.map((commit) => <Commit commit={commit} key={commit.sha} />)}
      </PopoverContent>
    </Popover>
  )
}
