import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "../useToast"
import { errorHandler } from "@/services/api/helpers"

export const useChangeOrganization = () => {
  const { toast } = useToast()

  const changeOrganization = useCallback(
    async (orgId: string) =>
      await axiosClient.patch(apiRoutes.auth.orgs.patch(orgId), {
        is_active: true
      }),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["changeTenant"],
    mutationFn: changeOrganization,
    onSuccess: (data) => {
      if (data.status === 201) {
        window.location.reload()
      }
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        description: errorHandler(err)
      })
    }
  })

  return { data, mutateAsync, status }
}
