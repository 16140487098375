import { useState, forwardRef, useImperativeHandle, KeyboardEvent, useRef, useEffect, useCallback } from "react"
import { Button } from "@/components"
import { EmojiText } from "@/components/designSystem/Discussions/components/RichEditor/extensions/emojis/EmojiConfiguration"
import { ListHandlers, ListProps } from "@/components/designSystem/Discussions/components/RichEditor/extensions/base"

export const EmojiList = forwardRef<ListHandlers, ListProps<EmojiText>>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelectedIndex(0)
    setTimeout(focus, 0)
  }, [props.items])

  const selectItem = useCallback(
    (index: number) => {
      const item = props.items[index]
      if (item && !item.isUnselectable) {
        props.command({ id: item.emoji })
        props.onClose() // Close the list after selection
      }
    },
    [props]
  )

  const upHandler = () => {
    setSelectedIndex((prevIndex) => (prevIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % props.items.length)
  }

  const enterHandler = () => selectItem(selectedIndex)

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") {
      upHandler()
      event.preventDefault()
    } else if (event.key === "ArrowDown") {
      downHandler()
      event.preventDefault()
    } else if (event.key === "Enter") {
      enterHandler()
      event.preventDefault()
    } else if (event.key === "Escape") {
      props.onClose()
      event.preventDefault()
    }
  }

  useImperativeHandle(ref, () => ({
    onKeyDown: (event: { key: string }) => {
      if (event.key === "ArrowUp") {
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : props.items.length - 1))
        return true
      }
      if (event.key === "ArrowDown") {
        setSelectedIndex((prev) => (prev < props.items.length - 1 ? prev + 1 : 0))
        return true
      }
      if (event.key === "Enter") {
        selectItem(selectedIndex)
        return true
      }
      return false
    },
    focus: () => {
      containerRef.current?.focus()
    }
  }))

  return (
    <div
      ref={containerRef}
      className="flex flex-col border border-border bg-background p-2 shadow-md shadow-border outline-none dark:shadow-background"
      onKeyDown={handleKeyDown}
    >
      {props.items.map((item, index) => (
        <Button
          tabIndex={0}
          variant="ghost"
          className={`justify-start p-2 text-xs font-medium leading-5 ${index === selectedIndex ? "bg-fill" : ""} ${item.isUnselectable ? "cursor-default hover:bg-fill" : "cursor-pointer"}`}
          key={index}
          onClick={() => selectItem(index)}
        >
          <span className="ml-2 text-xxs text-secondary">{item.emoji}</span>
          <span className="ml-2 text-xs text-secondary">{item.name}</span>
        </Button>
      ))}
    </div>
  )
})

EmojiList.displayName = "EmojiList"
