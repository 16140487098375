import { useEffect, useState } from "react"

interface AnimatedSparklesProps {
  className?: string
  size?: number
  width?: number
  height?: number
}

interface AnimationState {
  topRight: {
    scale: number
    opacity: number
  }
  bottomLeft: {
    scale: number
    opacity: number
  }
}

const defaultProps = {
  size: 14,
  className: ""
}

const AnimatedSparkles = ({
  className = defaultProps.className,
  size = defaultProps.size,
  width = size,
  height = size
}: AnimatedSparklesProps) => {
  const [state, setState] = useState<AnimationState>({
    topRight: { scale: 1, opacity: 1 },
    bottomLeft: { scale: 0.6, opacity: 1 }
  })

  useEffect(() => {
    let cycleCount = 0

    const animate = () => {
      if (cycleCount < 4) {
        const isFirstState = cycleCount % 2 === 0

        // Hide and transform
        setState({
          topRight: { scale: isFirstState ? 0.6 : 1, opacity: 0 },
          bottomLeft: { scale: isFirstState ? 1 : 0.6, opacity: 0 }
        })

        // Show after transition
        setTimeout(() => {
          setState((prevState) => ({
            topRight: { ...prevState.topRight, opacity: 1 },
            bottomLeft: { ...prevState.bottomLeft, opacity: 1 }
          }))
        }, 400)

        cycleCount++
        setTimeout(animate, 800)
      } else {
        cycleCount = 0
      }
    }

    // Initial run and setup interval
    animate()
    const intervalId = setInterval(() => {
      cycleCount = 0
      animate()
    }, 7600) // 3200ms (4 cycles) + 4400ms pause

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div
      className={className}
      style={{
        width,
        height
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        {/* Main star */}
        <path d="M9.937 15.5A2 2 0 0 0 8.5 14.063l-6.135-1.582a.5.5 0 0 1 0-.962L8.5 9.936A2 2 0 0 0 9.937 8.5l1.582-6.135a.5.5 0 0 1 .963 0L14.063 8.5A2 2 0 0 0 15.5 9.937l6.135 1.581a.5.5 0 0 1 0 .964L15.5 14.063a2 2 0 0 0-1.437 1.437l-1.582 6.135a.5.5 0 0 1-.963 0z" />

        {/* Top right plus */}
        <g
          style={{
            transform: `scale(${state.topRight.scale})`,
            opacity: state.topRight.opacity,
            transformOrigin: "21px 2px",
            transition: "transform 0.4s ease-in-out, opacity 0.4s ease-in-out"
          }}
        >
          <path d="M20 0v4M22 2h-4" />
        </g>

        {/* Bottom left plus */}
        <g
          style={{
            transform: `scale(${state.bottomLeft.scale})`,
            opacity: state.bottomLeft.opacity,
            transformOrigin: "4px 21px",
            transition: "transform 0.4s ease-in-out, opacity 0.4s ease-in-out"
          }}
        >
          <path d="M3 19v4M5 21H1" />
        </g>
      </svg>
    </div>
  )
}

export default AnimatedSparkles
