import { Edit } from "@/models/FCC"
import { Token } from "prismjs"
import { Action, ComputedToken } from "./highlighTypes"

export const applyEditsToTokens = (tokens: ComputedToken[], edits: Edit[], action: Action): ComputedToken[] => {
  let editIndex = 0
  let tokenIndex = 0
  let columnIndex = 0
  const updatedTokens: ComputedToken[] = []
  const backgroundColor = action === "Deleted" ? "bg-error-normal" : "bg-success-normal"
  /* while loop that runs on the tokens with 3 pointers index count tokens count and current column position */
  while (tokenIndex < tokens.length) {
    const token = tokens[tokenIndex]
    const edit = edits[editIndex]

    // if edit index is higher than the edits length there are no more edits that means we push the remaining tokens
    if (editIndex >= edits.length) {
      // pushing the rest of the current token
      updatedTokens.push({
        ...token,
        content: token.content.slice(columnIndex - token.start),
        start: columnIndex
      })

      // pushing the rest of the tokens
      for (let i = tokenIndex + 1; i < tokens.length; i++) {
        updatedTokens.push(tokens[i])
      }
      break
    }

    // if the edit action is not relevant for this row we skip and increment edit index
    if (edit.edit_action !== action) {
      editIndex += 1
      continue
    }

    // the beginning of the row before the edits appear, in this case the just push the token and set the column
    // pointer to the end of the token
    if (edit.start >= token.end) {
      updatedTokens.push({
        ...token,
        content: token.content.slice(columnIndex - token.start, edit.start - token.start),
        start: columnIndex
      })

      tokenIndex += 1
      columnIndex = token.end

      continue
    }

    /* this is where the slicing of the tokens begin */

    // if the token start is smaller than the edit start that means there is pre content of the edit we need to push
    if (columnIndex < edit.start) {
      const preToken = token.content.slice(columnIndex - token.start, edit.start - token.start)
      const endOffset = columnIndex + preToken.length

      updatedTokens.push({
        content: preToken,
        start: columnIndex,
        end: endOffset,
        type: token.type
      })
    }

    if (token.end >= edit.end) {
      // the edited token with the background color
      const editedToken: ComputedToken = {
        content: edit.content !== null ? edit.content : "",
        start: edit.start,
        end: edit.end,
        type: token.type,
        backgroundColor
      }

      updatedTokens.push(editedToken)

      // if the edit reached the end of the token we increment the token index to get to the next one
      if (edit.end === token.end) {
        tokenIndex += 1
      }
      // we finished with this edit and set the column pointer to the end of the edit
      editIndex += 1
      columnIndex = edit.end

      continue
    }
    tokenIndex += 1
  }

  return updatedTokens
}

export const transformTokenToComputed = (token: Token | string): ComputedToken | ComputedToken[] => {
  if (typeof token === "string") {
    return { content: token, start: 0, end: 0 }
  }

  if (typeof token.content === "string") {
    if (typeof token.alias === "string") {
      // if we have alias we need to set the token type to the alias to display the class properly
      token.type = token.alias
    }
    return {
      content: token.content,
      type: token.type,
      start: 0,
      end: 0
    }
  }

  if (token.content instanceof Token) {
    return transformTokenToComputed(token.content)
  }

  if (Array.isArray(token.content)) {
    return token.content.flatMap((token) => transformTokenToComputed(token))
  }

  console.error("token was not computed in transformTokenToComputed, Token: ", String(token))

  return []
}

export const flattenTokenArray = (tokens: (string | Token)[]): ComputedToken[] =>
  tokens.flatMap((token) => transformTokenToComputed(token))

export const addRangeToFlattenedTokens = (tokens: ComputedToken[]): ComputedToken[] => {
  let currentIndex = 0
  const tokensWithRange = tokens

  return tokensWithRange.map((token) => {
    const currentStart = currentIndex
    const currentEnd = currentIndex + token.content.length
    currentIndex = currentEnd

    return {
      ...token,
      start: currentStart,
      end: currentEnd
    }
  })
}
