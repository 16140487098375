import { useCallback, useMemo, useState, MouseEvent } from "react"
import { useForm } from "react-hook-form"
import { MoreHorizontal, Pencil, X } from "lucide-react"
import { useTranslation } from "react-i18next"
import { extractComments } from "@/pages/crFileChanges/components/CRHeader/ReviewersSection/utils.tsx"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components"
import { EditCRDescription } from "@/components/designSystem/Discussions/components/EditCRDescription.tsx"
import { Comment } from "@/components/designSystem/Discussions/components/Comment"
import { AddComment } from "@/components/designSystem/Discussions/components/Comment/AddComment.tsx"
import { ChangeRequest } from "@/models/Changes.ts"
import { formatDate } from "@/lib/dateFormats.ts"
import { mdTransformer } from "@/lib/mdTransformer"
import { useDiscussions } from "@/services/store/useDiscussions.ts"
import { useSettings } from "@/services/store/useSettings.ts"

interface Props {
  onTriggerClick: () => void
  onClose: () => void
  open: boolean
  children: React.ReactElement
  changeRequest: ChangeRequest
}

export const DiscussionDialog = ({ onClose, onTriggerClick, open, children, changeRequest }: Props) => {
  const { t: discussionsT } = useTranslation("Discussions")
  const { toggleManualRecalculationTrigger } = useDiscussions()
  const { selectedTheme } = useSettings()
  const [isEditMode, setIsEditMode] = useState(false)

  const comments = useMemo(() => extractComments(changeRequest.change_level_discussions), [changeRequest])
  const lastDiscussion = changeRequest.change_level_discussions.slice(-1)[0]
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const form = useForm({
    defaultValues: {
      body: changeRequest.description
    }
  })

  const handleToggleMenu = useCallback(
    (event: MouseEvent<Element> | CustomEvent<{ originalEvent: PointerEvent }>) => {
      event.stopPropagation()
      setIsMenuOpen((prev) => !prev)
    },
    [setIsMenuOpen]
  )

  const handleEditClick = useCallback(
    (event: MouseEvent<Element>) => {
      event.stopPropagation()
      handleToggleMenu(event)
      toggleManualRecalculationTrigger()
      setIsEditMode(!isEditMode)
    },
    [handleToggleMenu, isEditMode, toggleManualRecalculationTrigger]
  )
  return (
    <Dialog open={open} modal={false}>
      <DialogTrigger onClick={onTriggerClick}>{children}</DialogTrigger>

      <DialogContent
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
        className="flex h-fit max-h-[60%] w-full max-w-[70%] flex-col gap-[2px] overflow-y-auto rounded-sm border-0 bg-darker-fill p-2 text-primary"
      >
        <DialogTitle className="mb-1 flex w-full items-center justify-between">
          <p className="h-8 content-center px-2 py-1 text-xs text-primary">{changeRequest.title}</p>
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
        </DialogTitle>

        <div className="relative flex flex-col items-center rounded-sm bg-background p-4 px-6 text-xs">
          <div className="mb-2 flex w-full">
            <span className="mr-1 text-xxs text-secondary">{formatDate(changeRequest.created_at)}</span>
            {changeRequest.state !== "merged" && changeRequest.state !== "closed" ? (
              <MoreHorizontal
                className="relative ml-2 block cursor-pointer text-primary transition"
                width={16}
                height={16}
                onClick={handleToggleMenu}
              />
            ) : null}
            <DropdownMenu open={isMenuOpen} modal={false}>
              <DropdownMenuTrigger />
              <DropdownMenuContent onPointerDownOutside={handleToggleMenu} className="justify-start p-1">
                <DropdownMenuItem className="text-primary" onClick={handleEditClick}>
                  <Pencil className="mr-2" width={14} height={14} /> {discussionsT("Edit")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {isEditMode ? (
            <EditCRDescription
              changeRequest={changeRequest}
              description={changeRequest.description}
              form={form}
              setEditMode={setIsEditMode}
            />
          ) : (
            mdTransformer(changeRequest.description, selectedTheme) || discussionsT("NoDescription")
          )}

          {comments.map((comment) => (
            <Comment comment={comment} key={comment.id} isFullComment isClickable={false} current={null} />
          ))}
          <AddComment discussion={lastDiscussion} CRId={changeRequest.id} commitSha={changeRequest.commits[0]?.sha} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
