import { useCallback, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ArrowRight } from "lucide-react"
import { z } from "zod"
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from "@/components"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { RichEditor } from "@/components/designSystem/Discussions/components/RichEditor"
import { getSchema, getServerFormat } from "@/components/designSystem/Discussions/components/Comment/utils"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { useCreateComment } from "@/hooks/mutations"
import { zodResolver } from "@hookform/resolvers/zod"
import { NewCommentBubble as NewCommentBubbleI } from "@/models/Discussions"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useGithubRepoWriteAccess } from "@/hooks/useGithubRepoWriteAccess"

interface Props {
  current: NewCommentBubbleI
  CRId?: string
  commitSha?: string
}

export const NewCommentBubbleForm = ({ current, CRId, commitSha }: Props) => {
  const { t } = useTranslation("Discussions")
  const { mutateAsync: createComment, status } = useCreateComment()
  const { expandedStateBeforeComment, minimizeDiscussionsContainer } = useDiscussions()
  const { changeRequest } = useChangeRequest()

  const { toolTipContent: noWriteAccessToolTipContent, hasAccess } = useGithubRepoWriteAccess(
    changeRequest?.base_repo_full_name || ""
  )

  const formSchema = useMemo(() => getSchema(t), [t])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      body: ""
    }
  })

  const { setValue, setError, handleSubmit, control, formState } = form

  const onSubmit = useCallback(
    async ({ body }: z.infer<typeof formSchema>) => {
      try {
        if (!hasAccess) return

        const args = getServerFormat({ current, body, commitSha, CRId })
        args ? await createComment(args) : null
        setValue("body", "")

        if (expandedStateBeforeComment === false) {
          minimizeDiscussionsContainer()
        }
      } catch (err) {
        setError("body", { message: ghWriteErrorHandler(err) })
      }
    },
    [
      setValue,
      createComment,
      setError,
      CRId,
      commitSha,
      current,
      expandedStateBeforeComment,
      minimizeDiscussionsContainer,
      hasAccess
    ]
  )

  return (
    <Form {...form}>
      <form className="w-4/5 px-0 py-2">
        <FormField
          control={control}
          name="body"
          render={({ field }) => {
            return (
              <FormItem>
                <FormControl>
                  <RichEditor
                    initialContent=""
                    handleEditorChange={field.onChange}
                    parseMode="inline"
                    onCommandEnter={handleSubmit(onSubmit)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
      </form>
      <div className="my-4 flex justify-end pr-4">
        {status === "pending" || formState.isSubmitting ? (
          <SpinnerLoader size="16px" className="justify-end" />
        ) : (
          <Tooltip delayDuration={hasAccess ? 700 : 0}>
            <TooltipTrigger
              onClick={handleSubmit(onSubmit)}
              className={hasAccess ? "cursor-pointer disabled:cursor-default" : "cursor-not-allowed"}
            >
              <Button className="size-8" size="icon" variant="outline" disabled={!hasAccess}>
                <ArrowRight width={16} height={16} />
              </Button>
            </TooltipTrigger>
            <TooltipContent className={hasAccess ? "" : "bg-background p-2 shadow shadow-border"}>
              {hasAccess ? t("Send") : noWriteAccessToolTipContent}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </Form>
  )
}
