import { User } from "@/models/User.ts"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

interface State {
  user: User | null
  setUser: (user: User | null) => void
  hasGithubWriteAccess: () => boolean
}

export const usePermissions = create<State>()(
  immer((set, get) => ({
    user: null,
    setUser: (user) => {
      set((state) => {
        if (user) state.user = user
      })
    },
    hasGithubWriteAccess: () => {
      const user = get().user

      return user?.provider_access["github_rw"] === true
    }
  }))
)
