import { ClearAllFilters } from "@/components/designSystem/Filters/components/ClearAllFilters"
import { useTranslation } from "react-i18next"
import SearchCRsWithStatusOpen from "@/assets/icons/SearchCRsWithStatusOpen"
import SearchCRsWithFilters from "@/assets/icons/SearchCRsWithFilters"

interface NoCRFoundProps {
  isOpenCRFilter?: boolean
}

export default function NoCRFound({ isOpenCRFilter = false }: NoCRFoundProps) {
  const { t: changesT } = useTranslation("Changes")

  const Icon = isOpenCRFilter ? SearchCRsWithStatusOpen : SearchCRsWithFilters
  const title = isOpenCRFilter ? "EmptyOpenCRTitle" : "EmptyCRTitle"
  const subtitle = isOpenCRFilter ? "EmptyOpenCRSubTitle" : "EmptyCRSubTitle"

  return (
    <div className="mt-48 flex flex-col items-center justify-center text-center">
      <div className="text-base font-semibold leading-7 text-primary">
        <div className="flex justify-center">
          <Icon />
        </div>
        {changesT(title)}
        <div className="mt-1 text-sm font-normal leading-5 text-secondary">{changesT(subtitle)}</div>
        <div className="mt-5 flex justify-center">
          <ClearAllFilters localStorageKey="changesFilters" variant="outline" />
        </div>
      </div>
    </div>
  )
}
