import * as React from "react"
import * as SheetPrimitive from "@radix-ui/react-dialog"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const MinimalSheet = SheetPrimitive.Root

const MinimalSheetTrigger = SheetPrimitive.Trigger

const MinimalSheetClose = SheetPrimitive.Close

const MinimalSheetPortal = SheetPrimitive.Portal

const MinimalSheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay className={cn("fixed inset-0 z-90", className)} {...props} ref={ref} />
))
MinimalSheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva("fixed z-90 gap-4 rounded-lg bg-background p-6", {
  variants: {
    side: {
      top: "inset-x-10 top-10",
      bottom: "inset-x-10 bottom-10",
      left: "inset-y-0 left-0",
      right: "inset-y-10 right-8 top-[90px]"
    }
  },
  defaultVariants: {
    side: "right"
  }
})

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const MinimalSheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
  ({ side = "right", className, children, ...props }, ref) => {
    return (
      <MinimalSheetPortal>
        <MinimalSheetOverlay />
        <SheetPrimitive.Content
          ref={ref}
          className={cn(sheetVariants({ side }), `w-[434px] shadow-lg border`, className)}
          {...props}
        >
          {children}
        </SheetPrimitive.Content>
      </MinimalSheetPortal>
    )
  }
)
MinimalSheetContent.displayName = SheetPrimitive.Content.displayName

const MinimalSheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col space-y-2 text-center sm:text-left p-4", className)} {...props} />
)
MinimalSheetHeader.displayName = "SheetHeader"

const MinimalSheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 p-4", className)} {...props} />
)
MinimalSheetFooter.displayName = "SheetFooter"

const MinimalSheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title ref={ref} className={cn("text-lg font-semibold text-primary", className)} {...props} />
))
MinimalSheetTitle.displayName = SheetPrimitive.Title.displayName

const MinimalSheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description ref={ref} className={cn("text-sm text-hint", className)} {...props} />
))
MinimalSheetDescription.displayName = SheetPrimitive.Description.displayName

export {
  MinimalSheet,
  MinimalSheetPortal,
  MinimalSheetOverlay,
  MinimalSheetTrigger,
  MinimalSheetClose,
  MinimalSheetContent,
  MinimalSheetHeader,
  MinimalSheetFooter,
  MinimalSheetTitle,
  MinimalSheetDescription
}
