import { LucideIcon } from "lucide-react"

interface Props {
  href: string
  label: string
  Icon?: LucideIcon
}

export const ExternalLink = ({ href, label, Icon }: Props) => {
  return (
    <a role="link" className="flex gap-1 text-link" target="_blank" rel="noopener noreferrer" href={href}>
      {Icon && <Icon size={16} />}
      <span>{label}</span>
    </a>
  )
}
