import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { GithubIntegrationsResponse } from "@/models/GithubIntegrations"

interface Args {
  page: string | null
}
export const useFetchGithubIntegrations = ({ page }: Args) => {
  const { error, isLoading, data } = useQuery<GithubIntegrationsResponse, unknown>({
    queryKey: ["github-integrations", page],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.installations.get)

      return response.data
    }
  })

  return { error, isLoading, data }
}
