interface CrFileChangesRoute {
  repoName: string
  prNumber: string
  organization: string
}
interface CrFileChangesLink {
  prNumber: string
  repoFullName: string
}

type PrFileChangesArgs = CrFileChangesRoute | CrFileChangesLink

export const routes = {
  root: "/",
  login: "/login",
  changes: {
    root: "/changes",
    withStatusOpen: "/changes?status=open",
    crFileChanges: (args: PrFileChangesArgs) => {
      if ("repoFullName" in args) {
        const { prNumber, repoFullName } = args
        return `/changes/${repoFullName}/${prNumber}?tool=ast`
      }
      const { organization, prNumber, repoName } = args
      return `/changes/${repoName}/${organization}/${prNumber}`
    },
    createNewCR: "/changes/new"
  },
  code: {
    root: "/code",
    filledWithBranch: "/code/:org/:repository/:branch",
    filledWithPath: "/code/:org/:repository/:branch/:pathStart/*"
  },
  notFound: "/not-found",
  settings: {
    root: "/settings",
    integrations: ({ provider }: { provider: string }) => `/settings/integrations/${provider}`,
    profile: "/settings/profile",
    githubWebhookCallback: "/settings/github-webhook-callback",
    slackCallback: "/settings/slack-callback",
    accountSettings: "/settings/account",
    preferences: "/settings/reviewer"
  },
  privilege: "/privilege",
  slack: "/settings/connect-slack",
  onboarding: {
    root: "/onboarding",
    connect: "/onboarding/connect"
  },
  disabled: "/disabled"
} as const
