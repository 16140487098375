import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { Badge } from "@/components/ui/Badge"
import { useResolveComment } from "@/hooks/mutations/useResolveComment"
import { Discussion } from "@/models/Discussions"
import { UserCheck } from "lucide-react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { TooltipContent, Tooltip, TooltipProvider, TooltipTrigger } from "@/components"
import { CalendarClock } from "lucide-react"
import { DiscussionStateChip } from "@/components/designSystem/Discussions/components/ExpandedDiscussion/DiscussionStateChip"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useGithubRepoWriteAccess } from "@/hooks/useGithubRepoWriteAccess"

interface Props {
  discussion: Discussion
  handleResolveToggle?: (resolvedState: boolean) => void
  isResolved?: boolean
}

export const ResolveController = ({ discussion, handleResolveToggle, isResolved }: Props) => {
  const { t } = useTranslation("Discussions")

  const { changeRequest } = useChangeRequest()

  const { toolTipContent, hasAccess } = useGithubRepoWriteAccess(changeRequest?.base_repo_full_name || "")

  const { resolveCommentMutation, status } = useResolveComment()
  const isLoading = status === "pending"

  const handleResolve = useCallback(async () => {
    if (typeof handleResolveToggle !== "function" || isLoading) return

    if (!hasAccess) return

    await resolveCommentMutation({ id: discussion.id, state: isResolved ? "pending" : "resolved" })
    handleResolveToggle(!!isResolved)
  }, [discussion, handleResolveToggle, hasAccess, isResolved, isLoading, resolveCommentMutation])

  const BadgeComponent = (
    <Badge
      onClick={handleResolve}
      variant={isResolved ? "default" : "outline"}
      className={`flex gap-1 ${hasAccess ? "cursor-pointer" : "cursor-not-allowed opacity-50"}`}
    >
      {isLoading ? <SpinnerLoader size="16px" /> : <UserCheck width={16} height={16} className="text-primary" />}
      {isResolved ? t("Unresolve") : t("Resolve")}
    </Badge>
  )

  return (
    <div className="mt-3 flex items-center gap-1 text-xs">
      {discussion.discussion_state === "outdated" && (
        <DiscussionStateChip discussion={discussion} Icon={CalendarClock} tooltipContent={t("Outdated")} />
      )}
      {!hasAccess ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>{BadgeComponent}</TooltipTrigger>
            <TooltipContent>{toolTipContent}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        BadgeComponent
      )}
    </div>
  )
}
