function ExpandUpSVG({ color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{ color: color }}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2 11.333h12M5.333 6.667L8 4l2.666 2.667"
      />
    </svg>
  )
}

export default ExpandUpSVG
