import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "../useToast"
import { useNavigate } from "react-router-dom"
import { routes } from "@/lib/routes.ts"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { useTranslation } from "react-i18next"

export interface CreateCRArgs {
  repository_id: string
  branch_from: string
  branch_to: string
  title: string
  description: string | null
  draft: boolean
}

export const useCreateNewCR = () => {
  const createCR = useCallback(async (args: CreateCRArgs) => await axiosClient.post(apiRoutes.changes.post, args), [])

  const { toast } = useToast()
  const { resetAll } = useCreateCR()
  const { t } = useTranslation("CreateCR")
  const navigate = useNavigate()

  const {
    data,
    mutateAsync: createCRMutation,
    status
  } = useMutation({
    mutationKey: ["createCR"],
    mutationFn: createCR,
    onSuccess: () => {
      resetAll()
      navigate(routes.changes.root)
      toast({
        variant: "default",
        description: t("SuccessMessage")
      })
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: ghWriteErrorHandler(error)
      })
    }
  })

  return { data, createCRMutation, status }
}
