import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "../useToast"
import { ghWriteErrorHandler } from "@/services/api/helpers"
import { useTranslation } from "react-i18next"

interface Args {
  changeId: string
  mergeStrategy: string
}

export const useMergeChangeRequest = () => {
  const { toast } = useToast()
  const { t } = useTranslation("Toolbar")

  const mergeChangeRequest = useCallback(
    async ({ changeId, mergeStrategy }: Args) =>
      await axiosClient.post(apiRoutes.changes.mergeChangeRequest.post(changeId), {
        merge_strategy: mergeStrategy
      }),
    []
  )

  const { data, mutateAsync, status } = useMutation({
    mutationKey: ["mergeChangeRequest"],
    mutationFn: mergeChangeRequest,
    onSuccess: () => {
      toast({
        variant: "default",
        description: t("SuccessMerge")
      })
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: t("FailedMerge") + ghWriteErrorHandler(error)
      })
    }
  })

  return { data, mutateAsync, status }
}
