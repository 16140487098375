import { useCallback, useEffect, useState } from "react"

interface ScrollState {
  reachedTarget: boolean
  reachedEnd: boolean
}

export const useScrollListener = (scrollTarget: number, endThreshold = 50) => {
  const [scrollState, setScrollState] = useState<ScrollState>({
    reachedTarget: false,
    reachedEnd: false
  })

  const handleScroll = useCallback(() => {
    const reachedTarget = window.scrollY >= scrollTarget

    let reachedEnd = false

    // Wait for next frame to ensure DOM is updated
    requestAnimationFrame(() => {
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      const windowHeight = window.innerHeight
      const scrollPosition = window.scrollY
      const distanceFromBottom = documentHeight - (scrollPosition + windowHeight)

      // Only set reachedEnd if document is actually taller than viewport
      if (documentHeight > windowHeight) {
        reachedEnd = distanceFromBottom <= endThreshold
        setScrollState((prevState) => {
          if (prevState.reachedTarget !== reachedTarget || prevState.reachedEnd !== reachedEnd) {
            return { reachedTarget, reachedEnd }
          }
          return prevState
        })
      }
    })
  }, [scrollTarget, endThreshold])

  const resetScrollState = useCallback(() => {
    setScrollState({ reachedTarget: false, reachedEnd: false })
  }, [])

  useEffect(() => {
    // Let the component fully mount and render
    const timeoutId = setTimeout(() => {
      handleScroll()
      window.addEventListener("scroll", handleScroll)
    }, 100)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  return { ...scrollState, resetScrollState }
}
