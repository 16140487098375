import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation } from "@tanstack/react-query"
import { useCallback } from "react"

export const useClearOAuthCache = () => {
  const clearOAuthCache = useCallback(async () => await axiosClient.delete(apiRoutes.auth.cache.oauth.delete), [])

  const {
    data,
    mutateAsync: clearOAuthCacheMutation,
    status
  } = useMutation({
    mutationKey: ["clearOAuthCache"],
    mutationFn: clearOAuthCache
  })

  return { data, clearOAuthCacheMutation, status }
}
