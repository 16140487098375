import { Chunk, FileWithDiffs, MissingSubRowsOptions, FccRow } from "@/models/FCC"
import { extractFullFileLines } from "."

const MAX_ROWS = 20

// return flattened lines array with the main chunks and their closest 20 rows
export const flattenLines = (file: FileWithDiffs) => {
  const { file_content, chunks } = file
  const flattenedLines: FccRow[] = []
  const fullFileRowsArray: string[] = extractFullFileLines(file_content)

  // go over the chunks and add the lines, before_lines and after_lines to the flattenedLines array
  for (let i = 0; i < chunks.length; i++) {
    const isFirstChunk = i === 0
    const isLastChunk = i === chunks.length - 1
    const currentChunk = chunks[i]
    const nextChunk = chunks[i + 1]

    const currentChunkFirstLine = getChunkLineNumber(currentChunk, "up")
    const currentChunkLastLine = getChunkLineNumber(currentChunk, "down")
    const nextChunkFirstLine = getChunkLineNumber(nextChunk || null, "up")

    /* starting from the first chunk, from its first line till
    we reach the beginning of the file or up to 20 rows*/
    if (isFirstChunk && currentChunkFirstLine && currentChunkFirstLine > 1) {
      const index = currentChunkFirstLine - 1
      const nextIndex = Math.max(0, index - MAX_ROWS)

      createExpanderRow({
        flattenedLines,
        subRows: fullFileRowsArray.slice(nextIndex, index),
        fromIndex: nextIndex,
        expanderDirection: "up",
        nextLineNumber: currentChunkFirstLine
      })
    }

    // Adding the chunk current lines, before and after
    flattenedLines.push(...currentChunk.before_lines, ...currentChunk.lines, ...currentChunk.after_lines)

    // If between the current line and the next chunk we have more than 1 row - add subRows
    if (nextChunkFirstLine && currentChunkLastLine && nextChunkFirstLine - currentChunkLastLine > 1) {
      middleChunkSubRowsProcessLogic(flattenedLines, nextChunkFirstLine, currentChunkLastLine, fullFileRowsArray)
    }

    // adding last sub rows
    if (currentChunkLastLine && file.total_file_lines > currentChunkLastLine + 1 && isLastChunk) {
      const index = currentChunkLastLine
      const nextIndex = Math.max(index + MAX_ROWS, file.total_file_lines)

      createExpanderRow({
        flattenedLines,
        subRows: fullFileRowsArray.slice(index, nextIndex),
        fromIndex: index,
        expanderDirection: "down"
      })
    }
  }

  return removeDuplicatedLines(flattenedLines)
}

const middleChunkSubRowsProcessLogic = (
  flattenedLines: FccRow[],
  nextChunkFirstLine: number,
  currentChunkLastLine: number,
  extractedFullFile: string[] | null
) => {
  const index = currentChunkLastLine
  const nextIndex = Math.min(index + MAX_ROWS, nextChunkFirstLine - 1)

  createExpanderRow({
    flattenedLines,
    subRows: extractedFullFile?.slice(index, nextIndex) || null,
    fromIndex: index,
    expanderDirection: "middle",
    nextLineNumber: nextChunkFirstLine
  })
}

export const createExpanderRow = ({
  flattenedLines,
  subRows,
  fromIndex,
  expanderDirection,
  nextLineNumber
}: MissingSubRowsOptions) => {
  let calculatedIndex = 0

  if (typeof fromIndex === "number") {
    calculatedIndex = Math.floor(fromIndex)
  }

  const subRowsToBeAdded =
    subRows?.map((row, index) => ({
      new_line_number: index + calculatedIndex + 1,
      new_content: row,
      isExpanded: false
    })) || []

  // TODO: extract the expander type to it's separate type
  flattenedLines.push({
    ...flattenedLines[calculatedIndex],
    subRows: subRowsToBeAdded,
    arrowDirection: expanderDirection,
    nextLineNumber,
    number: null,
    new_line_number: null
  })
}

const getChunkLineNumber = (chunk: Chunk | null, direction: "up" | "down"): number | null =>
  chunk
    ? direction === "up"
      ? (chunk.before_lines[0]?.new_line_number ?? chunk.before_lines[0]?.number)
      : (chunk.after_lines[chunk.after_lines.length - 1]?.new_line_number ??
        chunk.after_lines[chunk.after_lines.length - 1]?.number)
    : null

const removeDuplicatedLines = (lines: FccRow[]): FccRow[] => {
  const linesMap = new Set<string>()

  return lines.filter((line) => {
    if (!line.new_line_number) {
      return true // Keep lines without new_line_number
    }

    const lineNumber = line.new_line_number.toString()
    if (linesMap.has(lineNumber)) {
      return false // Skip duplicates
    }

    linesMap.add(lineNumber)
    return true
  })
}
