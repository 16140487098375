import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components"
import { GitPullRequest, GitPullRequestClosed, GitPullRequestDraft } from "lucide-react"
import { useTranslation } from "react-i18next"
import { MenuState } from "../../helpers.tsx"
import { uppercaseString } from "@/lib/utils.ts"
import { CodeChangeStateBadge } from "@/components/designSystem/Badges/CodeChangeStateBadge"
import { CRState } from "@/models/Changes.ts"

interface Props {
  onClick: (value: string) => void
  disabled: boolean
  options: MenuState[]
  isLoading: boolean
  state: CRState
  isDraft: boolean
  isEditable: boolean
  color?: string
  bgColor?: string
}

const menuIconMap = {
  draft: <GitPullRequestDraft width={14} height={14} className="text-primary" />,
  open: <GitPullRequest width={14} height={14} className="text-success-dark" />,
  close: <GitPullRequestClosed width={14} height={14} className="text-error-dark" />
}

export const ChangeStatusMenu = ({ disabled, onClick, options, state, isDraft, isEditable, isLoading }: Props) => {
  const { t } = useTranslation("FCC")

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger disabled={disabled}>
        <CodeChangeStateBadge state={state} is_draft={isDraft} isEditable={isEditable} isLoading={isLoading} />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {options?.map((option) => (
          <DropdownMenuItem key={option} className="flex gap-2" onClick={() => onClick(option)}>
            {menuIconMap[option]}
            {t("SetAs") + " " + uppercaseString(option)}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
