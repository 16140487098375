import { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Filter } from "../types"
import { clearFilterFromLocalStorage, setFilterValueToLocalStorage } from "../utils"
import { LocalStorageService } from "@/lib/localStorageService"

interface useFilterProps {
  filter: Filter
  isPaginated: boolean
  localStorageKey?: string
  isSyncedWithLocalStorage?: boolean
  defaultValue?: string | string[]
}

const localStorageService = new LocalStorageService()

export const useFilter = ({
  filter: { urlKey },
  isPaginated,
  isSyncedWithLocalStorage,
  localStorageKey,
  defaultValue
}: useFilterProps) => {
  const [open, setOpen] = useState(false)
  const [, setSearchParams] = useSearchParams()

  const handleSelect = useCallback(
    (value: string) => {
      setSearchParams((params) => {
        if (isPaginated) {
          params.delete("page")
        }
        params.set(urlKey, value)

        if (isSyncedWithLocalStorage && localStorageKey) {
          setFilterValueToLocalStorage(value, localStorageKey, urlKey)
        }

        return params
      })
    },
    [urlKey, isPaginated, isSyncedWithLocalStorage, localStorageKey, setSearchParams]
  )

  const handleClearFilter = useCallback(() => {
    setSearchParams((params) => {
      if (isPaginated) {
        params.delete("page")
      }
      params.delete(urlKey)

      if (isSyncedWithLocalStorage && localStorageKey) {
        clearFilterFromLocalStorage(localStorageKey, urlKey)
      }

      setOpen(false)
      return params
    })
  }, [urlKey, isPaginated, isSyncedWithLocalStorage, localStorageKey, setSearchParams])

  const syncDefaultValue = useCallback(() => {
    // Early exit if defaultValue is not provided or if a value is already set in localStorage
    if (!defaultValue || (localStorageKey && localStorageService.hasItem(localStorageKey))) {
      return
    }

    // checking in single filter if the default value is actually a string
    if (typeof defaultValue === "string") {
      setSearchParams((params) => {
        params.set(urlKey, defaultValue)

        if (isSyncedWithLocalStorage && localStorageKey) {
          setFilterValueToLocalStorage(defaultValue, localStorageKey, urlKey)
        }

        return params
      })
    }
  }, [urlKey, defaultValue, isSyncedWithLocalStorage, localStorageKey, setSearchParams])

  return { handleSelect, handleClearFilter, open, setOpen, syncDefaultValue }
}
