import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { okaidia, oneLight } from "react-syntax-highlighter/dist/esm/styles/prism"
import { ClassAttributes, HTMLAttributes, useEffect, useState } from "react"
import { ExtraProps } from "react-markdown"
import { Themes } from "@/services/store/useSettings"
import { MdTransformerOptions } from "@/lib/mdTransformer/types"
import { SuggestionButton } from "@/lib/mdTransformer/SuggestionButton"

interface RenderCodeBlockPrtops {
  props: ClassAttributes<HTMLElement> & HTMLAttributes<HTMLElement> & ExtraProps
  theme: Themes
  options?: MdTransformerOptions
}

export interface RelatedSuggestion {
  lines: [number, number]
  text: string
}

export const RenderCodeBlock = ({ props, theme, options }: RenderCodeBlockPrtops) => {
  const { children, className } = props
  const [relatedSuggestionInfo, setRelatedSuggestionInfo] = useState<RelatedSuggestion | null>(null)

  useEffect(() => {
    if (options?.isBazzy) {
      for (const key of options.codeBlocksWithLines.keys()) {
        if (typeof children === "string" && key.replace(/\n/g, "") === children.replace(/\n/g, "")) {
          // we found the lines needed for the code block by taking the child from the props and comparing it to the keys in the Map
          const lines = options.codeBlocksWithLines.get(key) as [number, number]

          setRelatedSuggestionInfo({
            lines,
            text: key
          })
        }
      }
    }
  }, [options, children])

  const match = /language-(\w+)/.exec(className || "")
  return match ? (
    <div className="py-4">
      <SyntaxHighlighter
        PreTag="div"
        children={String(children).replace(/\n$/, "")}
        language={match[1]}
        style={theme === "light" ? oneLight : okaidia}
        lineProps={{ style: { wordBreak: "break-word", whiteSpace: "pre-wrap" } }}
        wrapLines
      />
      {relatedSuggestionInfo ? <SuggestionButton relatedSuggestionInfo={relatedSuggestionInfo} /> : null}
    </div>
  ) : (
    <SyntaxHighlighter
      PreTag="span"
      children={String(children).replace(/\n$/, "")}
      language="bash"
      style={theme === "light" ? oneLight : okaidia}
      customStyle={{ padding: "4px" }}
      lineProps={{ style: { wordBreak: "break-word", whiteSpace: "pre-wrap" } }}
      wrapLines
    />
  )
}
