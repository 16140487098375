import { Token } from "prismjs"
import { RenderHighlightedSpanArgs } from "./highlighTypes"

export const transformTokenToSpan = (token: Token | string): JSX.Element => {
  if (typeof token === "string") {
    return renderHighlightedSpan({ text: token })
  }

  if (typeof token.content === "string") {
    if (typeof token.alias === "string") {
      token.type = token.alias
    }
    return renderHighlightedSpan({ text: token.content, type: token.type })
  }

  if (token.content instanceof Token) {
    return transformTokenToSpan(token.content)
  }

  if (Array.isArray(token.content)) {
    return (
      <span className="inline-flex flex-wrap">
        {token.content.map((tokenString, index) => (
          <span key={index} className="inline-block">
            {transformTokenToSpan(tokenString)}
          </span>
        ))}
      </span>
    )
  }

  console.error("Unexpected token format:", token)
  return <span>{JSON.stringify(token)}</span>
}

export const renderHighlightedSpan = ({ text, backgroundColor, type }: RenderHighlightedSpanArgs) => {
  return (
    <span className={`token inline-block ${type || ""} ${backgroundColor || ""} whitespace-pre-wrap break-all`}>
      {text}
    </span>
  )
}
