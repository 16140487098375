import { useTranslation } from "react-i18next"
import { useFetchIntegrations } from "@/hooks/queries/useFetchIntegrations.tsx"
import { Button, SkeletonLoader } from "@/components"
import { errorHandler } from "@/services/api/helpers.ts"
import { useState } from "react"
import { InstallationCard } from "@/pages/settings/integrations/datadog/InstallationCard"
import { DatadogIntegrationModal } from "@/pages/settings/integrations/datadog/DatadogIntegrationModal"
import { DataDogIntegration } from "@/models/Integration.ts"

export const Datadog = () => {
  const { t } = useTranslation("Integrations")
  const { data, error, isLoading } = useFetchIntegrations("datadog")
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (isLoading) {
    return (
      <div>
        <SkeletonLoader />
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <p>{errorHandler(error)}</p>
      </div>
    )
  }

  if (data && data.integrations?.length > 0) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium">{t("Datadog")}</h1>
          <Button variant="secondary" size="sm" className="gap-1 text-xs" onClick={() => setIsModalOpen(true)}>
            {t("Configure")}
          </Button>
        </div>
        <div className="grid w-full grid-cols-2 gap-4">
          {data.integrations.map((installation) => (
            <InstallationCard key={installation.id} installation={installation as DataDogIntegration} />
          ))}
        </div>
        <DatadogIntegrationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </div>
    )
  }

  return (
    <div>
      <div data-testid="github-integration-description" className="text-primary">
        <p className="mb-4">{t("dd_description.first")}</p>
        <p className="mb-4">{t("dd_description.second")}</p>

        <Button variant="secondary" size="sm" className="gap-1 text-xs" onClick={() => setIsModalOpen(true)}>
          {t("Configure")}
        </Button>
      </div>
      <DatadogIntegrationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  )
}
