import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { useTranslation } from "react-i18next"
import { useCallback, useState } from "react"

interface Props {
  children: JSX.Element
  text: string
}

export const CopyToClipboard = ({ children, text }: Props) => {
  const { t } = useTranslation("Common")
  const [open, setOpen] = useState(false)

  const onClick = useCallback(() => {
    setOpen(true)

    setTimeout(() => {
      setOpen(false)
    }, 1000)
  }, [setOpen])

  return (
    <TooltipProvider>
      <Tooltip open={open}>
        <TooltipTrigger onClick={onClick}>
          <ReactCopyToClipboard text={text}>{children}</ReactCopyToClipboard>
        </TooltipTrigger>
        <TooltipContent>{t("Copied")}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
