import { useToast } from "@/hooks/useToast"
import { Descope, refresh } from "@descope/react-sdk"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useClearOAuthCache } from "@/hooks/mutations/useClearOAuthCache.tsx"
import { useUpdateSlackId } from "@/hooks/mutations/useUpdateSlackId.tsx"

export function Slack() {
  const { clearOAuthCacheMutation } = useClearOAuthCache()
  const { updateSlackIdMutation } = useUpdateSlackId()
  const navigate = useNavigate()
  const { toast } = useToast()
  const { t } = useTranslation("ConnectSlack")

  const handleError = useCallback(() => {
    navigate("/")
    toast({ variant: "destructive", description: t("Error") })
  }, [navigate, toast, t])

  const handleSuccess = useCallback(
    async (event: { detail: { refreshJwt?: string } }) => {
      if (event.detail.refreshJwt) {
        await refresh(event.detail.refreshJwt)
        await updateSlackIdMutation()
        await clearOAuthCacheMutation()
        navigate("/")
        toast({ variant: "default", description: t("Success") })
      } else {
        handleError()
      }
    },
    [navigate, toast, t, handleError, clearOAuthCacheMutation, updateSlackIdMutation]
  )

  return (
    <div>
      <Descope flowId={"connect-slack"} onSuccess={handleSuccess} onError={handleError} />
    </div>
  )
}
