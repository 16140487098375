import { Button, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { DiscussionDialog } from "./DiscussionDialog.tsx"
import { useCallback, useState } from "react"
import { ReaderIcon } from "@radix-ui/react-icons"
import { useTranslation } from "react-i18next"
import { ChangeRequest } from "@/models/Changes.ts"
import { TooltipPortal } from "@radix-ui/react-tooltip"

interface Props {
  changeRequest: ChangeRequest
}

export const DiscussionController = ({ changeRequest }: Props) => {
  const { t } = useTranslation("Toolbar")

  const [discussionModalOpen, setDiscussionModalOpen] = useState(false)

  const handleDiscussionTriggerClick = useCallback(() => {
    setDiscussionModalOpen((prev) => !prev)
  }, [setDiscussionModalOpen])

  const handleDiscussionClose = useCallback(() => {
    setDiscussionModalOpen(false)
  }, [setDiscussionModalOpen])

  return (
    <Tooltip>
      <TooltipTrigger>
        <DiscussionDialog
          open={discussionModalOpen}
          onClose={handleDiscussionClose}
          onTriggerClick={handleDiscussionTriggerClick}
          changeRequest={changeRequest}
        >
          <Button variant="outline" className="size-8 p-0 hover:bg-darker-fill">
            <ReaderIcon width={16} height={16} className="text-primary" />
          </Button>
        </DiscussionDialog>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent sideOffset={10}>{t("Discussions")}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}
