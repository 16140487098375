import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { RepoWriteAccess } from "@/models/RepoWriteAccess"

export const useFetchRepoWriteAccess = (repoName: string, havePersonalToken: boolean = true) => {
  const { error, isLoading, data } = useQuery<RepoWriteAccess, unknown>({
    queryKey: ["repo-write-access", repoName],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.installations.repoWriteAccess.get(repoName))

      return response.data
    },
    enabled: havePersonalToken
  })

  return { error, isLoading, data }
}
