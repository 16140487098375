import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent
} from "@/components"
import { Filter } from "../types"
import { useEffect } from "react"
import { filterByLabel } from "../utils"
import { useTranslation } from "react-i18next"
import { MultiSelectOption } from "../components/MultiSelectOption"
import { MultiSelectAnchor } from "../components/MultiSelectAnchor"
import { ClearFilter } from "../components/ClearFilter"
import { ApproveButton } from "../components/ApproveButton"
import { useMultiFilter } from "../hooks/useMultiFilter"
import { useSearchParams } from "react-router-dom"

interface Props {
  filter: Filter
  isPaginated: boolean
  localStorageKey?: string
}

export const MultiSelect = ({ filter, isPaginated, localStorageKey }: Props) => {
  const { options, urlKey, isSearch, label, defaultValue } = filter
  const { t } = useTranslation("Filters")
  const [searchParams] = useSearchParams()

  const {
    handleApplyFilters,
    handleClearFilter,
    handleOptionSelect,
    isChanged,
    open,
    selectedValues,
    setOpen,
    syncDefaultValue
  } = useMultiFilter({
    filter,
    isPaginated,
    searchParams,
    urlFilter: searchParams.get(urlKey),
    localStorageKey,
    defaultValue
  })

  useEffect(() => {
    syncDefaultValue()
  }, [syncDefaultValue])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <MultiSelectAnchor
        onClick={() => setOpen(!open)}
        label={label}
        options={options}
        selectedValues={selectedValues}
      />

      <PopoverContent
        onPointerDownOutside={handleApplyFilters}
        className="w-fit min-w-[220px] p-0 shadow-md shadow-border dark:shadow-background"
        align="start"
      >
        <Command filter={(value, search) => filterByLabel(value, search, options)}>
          {isSearch && <CommandInput placeholder={t("Search")} />}

          <CommandList>
            <CommandEmpty>{t("NoResults")}</CommandEmpty>
            <CommandGroup>
              {selectedValues.size > 0 && (
                <>
                  <ClearFilter onClearFilter={handleClearFilter} />
                  <CommandSeparator className="mb-1" />
                </>
              )}

              {options.map((option) => (
                <MultiSelectOption
                  key={option.value}
                  option={option}
                  selectedValues={selectedValues}
                  handleOptionSelect={handleOptionSelect}
                />
              ))}
            </CommandGroup>
          </CommandList>

          <footer className="cursor-auto p-[5px] aria-selected:bg-transparent">
            <CommandSeparator className="mb-1" />
            <ApproveButton isChanged={isChanged} onClick={handleApplyFilters} />
          </footer>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
