import { LocalStorageService } from "@/lib/localStorageService"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

interface State {
  selectedRepositoryId: string
  setSelectedRepositoryId: (repo: string) => void
  fromBranch: string
  setFromBranch: (branch: string) => void
  intoBranch: string
  setIntoBranch: (branch: string) => void
  name: string
  setName: (name: string) => void
  resetAll: () => void
  selectedRepositoryName: string
  setSelectedRepositoryName: (repo: string) => void
}

const localStorageService = new LocalStorageService("localStorage")

export const useCreateCR = create<State>()(
  immer((set) => ({
    selectedRepositoryId: "",
    name: "",
    fromBranch: "",
    intoBranch: "",
    selectedRepositoryName: "",
    setName: (name) => {
      set((state) => {
        state.name = name
      })
    },
    setFromBranch: (branch) => {
      set((state) => {
        state.fromBranch = branch
      })
    },
    setIntoBranch: (branch) => {
      set((state) => {
        state.intoBranch = branch
      })
    },
    setSelectedRepositoryId: (repo) => {
      set((state) => {
        state.selectedRepositoryId = repo
      })
    },
    setSelectedRepositoryName: (repo) => {
      localStorageService.setItem("repositoryFullName", repo)
      set((state) => {
        state.selectedRepositoryName = repo
      })
    },
    resetAll: () => {
      set((state) => {
        state.name = ""
        state.fromBranch = ""
        state.intoBranch = ""
        state.selectedRepositoryId = ""
        state.selectedRepositoryName = ""
      })
    }
  }))
)
