import { Outlet } from "react-router-dom"
import { Content } from "./Content"
import { SettingsSidebar } from "./SettingsSidebar"

export function Settings() {
  return (
    <div className="flex w-full grow">
      <SettingsSidebar />
      <Content>
        <Outlet />
      </Content>
    </div>
  )
}
