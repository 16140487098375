export interface StreamResponse {
  event: string
  data: {
    chunk: {
      kwargs: {
        content: string
        additional_kwargs?: {
          id?: string
          type?: string
          role?: string
          model?: string
          stop_reason?: string
        }
      }
    }
  }
}

export enum BazzyQuestionsCategories {
  CODE = "code",
  INSIGHT = "insight"
}

export enum BazzyCodeOptions {
  EXPLAIN = "EXPLAIN",
  IMPROVE = "IMPROVE",
  FREE_TEXT = "FREE_TEXT"
}

export enum BazzyInsightOptions {
  EXPLAIN = "EXPLAIN",
  SUGGEST_A_FIX = "SUGGEST_A_FIX",
  FREE_TEXT = "FREE_TEXT"
}

export enum BazzyErrorType {
  BREAKING_CHANGE = "Breaking change",
  LOG_ERROR = "Log error"
}

export interface BazzyMessage {
  role: "user" | "bazzy"
  content: string
  timestamp: number
  isLoading?: boolean
}

export interface BazzyContext {
  filePath: string | null
  lines: { startLine: number | null; endLine: number | null }
  side: "right" | "left" | null
  type: "code" | "insight"
  relevantInsight?: string
  insightType?: BazzyErrorType
}

export interface BazzyOption<T> {
  id: T
  label: string
}

export interface BazzyQuestionOptions {
  [BazzyQuestionsCategories.CODE]: BazzyOption<BazzyCodeOptions>[]
  [BazzyQuestionsCategories.INSIGHT]: BazzyOption<BazzyInsightOptions>[]
}
