import { CollaborationUser } from "@/models/CollaborationUser"
import { CreateFilterBase, CreateFilterWithApiData, CreateSorter, Filter, Sorter } from "./types"
import { Repository } from "@/models/Repository"
import { formatName } from "@/lib/utils"

export const statusFilter = ({
  isLabelPrefix = true,
  isMulti = true,
  isSearch = false,
  isSyncedWithLocalStorage = true,
  defaultValue = ["open"]
}: CreateFilterBase): Filter => ({
  label: "Status",
  urlKey: "status",
  isLabelPrefix,
  isSearch,
  isMulti,
  isSyncedWithLocalStorage,
  defaultValue,
  options: [
    {
      label: "Open",
      value: "open"
    },
    { label: "Draft", value: "draft" },
    { label: "Closed", value: "closed" },
    { label: "Merged", value: "merged" }
  ]
})

export const authorsFilter = ({
  data,
  isLabelPrefix = true,
  isMulti = true,
  isSearch = true,
  isSyncedWithLocalStorage = true
}: CreateFilterWithApiData<CollaborationUser>): Filter => {
  const computedData =
    data?.map((item) => ({
      label: item.display_name || formatName(item.gh_login),
      value: item.gh_login || item.id,
      icon: item.avatar_url
    })) || []
  return {
    label: "Author",
    urlKey: "author",
    isLabelPrefix,
    isSearch,
    isMulti,
    options: computedData,
    isSyncedWithLocalStorage
  }
}

export const repositoriesFilter = ({
  data,
  isLabelPrefix = true,
  isMulti = true,
  isSearch = true,
  isSyncedWithLocalStorage = true
}: CreateFilterWithApiData<Repository>): Filter => {
  const computedData = data?.map((item) => ({ label: item.fullName, value: item.id, icon: item.avatarUrl })) || []

  return {
    label: "Repository",
    urlKey: "repository",
    isLabelPrefix,
    isSearch,
    isMulti,
    options: computedData,
    isSyncedWithLocalStorage
  }
}

export const diffSorter = ({ isLabelPrefix = true, defaultValue = "ast" }: CreateSorter): Sorter => ({
  label: "Diff",
  urlKey: "tool",
  isLabelPrefix,
  defaultValue,
  options: [
    {
      label: "AST",
      value: "ast",
      tooltipContent: "View Semantic Diff"
    },
    {
      label: "Git",
      value: "git",
      tooltipContent: "View Git Classic Diff"
    }
  ]
})

export const timeSorter = ({ isLabelPrefix = true, defaultValue = "recently_updated" }: CreateSorter): Sorter => ({
  label: "Sort",
  urlKey: "sort",
  isLabelPrefix,
  defaultValue,
  options: [
    {
      label: "Newest",
      value: "newest"
    },
    {
      label: "Oldest",
      value: "oldest"
    },
    {
      label: "Recently updated",
      value: "recently_updated"
    },
    {
      label: "Oldest updated",
      value: "least_recently_updated"
    }
  ]
})
