function EraserSVG({ color = "var(--base-primary)" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g id="icon(shadcn)" stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path
          id="Vector"
          d="M4.666 14 1.8 11.133c-.667-.666-.667-1.666 0-2.266l6.4-6.4c.666-.667 1.666-.667 2.267 0L14.2 6.2c.667.667.667 1.667 0 2.267L8.666 14"
        ></path>
        <path id="Vector_2" d="M14.667 14h-10"></path>
        <path id="Vector_3" d="m3.334 7.334 6 6"></path>
      </g>
    </svg>
  )
}

export default EraserSVG
