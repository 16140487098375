import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { CollaborationUser } from "@/models/CollaborationUser"

export const useFetchAuthors = () => {
  const { error, isLoading, data } = useQuery<{ authors: CollaborationUser[] }, unknown>({
    queryKey: ["authors"],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.changes.authors)
      return response.data
    }
  })

  return { error, isLoading, data: data?.authors || [] }
}
