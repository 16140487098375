export const Integrations = {
  gh_description: {
    first: "Set up the GitHub integration to configure Baz and enhance your team's code review process.",
    second:
      "You will be redirected to GitHub. Select a GitHub organization to configure, review the requested permissions, and choose whether to grant access to all repositories or only selected ones. Press 'Save' to proceed. Next, select the repositories to be scanned by Baz from the visible repositories."
  },
  dd_description: {
    first: "Set up the Datadog integration to configure Baz and enhance your team's code review process.",
    second: "Integrate Baz with your datadog organization by adding an api key and an app key."
  },
  slack_description: {
    first: "Connect Baz to Slack to get live updates on the progress of your reviews",
    second:
      "You will be redirected to Slack. Select the Slack workspace to configure, Review the requested permissions. Press 'Allow' to proceed."
  },
  datadog: {
    title: "Create a new Datadog integration",
    subtitle: "To create a new Datadog integration please fill in the following details:",
    success: "Datadog integration created successfully",
    failure: "Invalid Datadog credentials, please verify them and try again",
    org_name: "Datadog Org Name",
    org_name_required: "Organization name is required.",
    api_key: "API key",
    api_key_required: "API Key is required.",
    app_key: "App key",
    app_key_required: "App Key is required.",
    site: "Datadog site URL",
    more_info: "How to create Datadog App and API keys"
  },
  Configure: "Configure",
  Install: "Install",
  successToast: {
    title: "Success",
    description: "You have connected successfully"
  },
  errorToast: {
    title: "Error",
    description: "Could not connect"
  },
  Repositories: "Repositories",
  DisconnectedRepositories: "Disconnected Repositories",
  IntegrationError:
    "There was an error with the installation. Please delete the previous installation if exists and configure again.",
  NoInstallationId: "No installation id provided",
  NoCode: "No code provided",
  RetryingConnection: "Retrying connection...",
  UserInstallationNotAllowed:
    "It looks like you connected a personal GitHub account. Baz is all about teamwork, so we only support organization accounts. Give it a go with an organization account instead!",
  Reconfigure: "Reconfigure",
  AddRepos: "Add Repos",
  Github: "Integrate your GitHub organization by selecting 'Configure' and installing Baz in GitHub.",
  Datadog: "Integrate your Datadog organizations by selecting 'Configure' and filling in the required fields.",
  Slack: "Integrate your Slack by selecting 'Install' and installing Baz bot in Slack",
  None: "",
  ConfigureWriteAccess: "Configure Write Access",
  GithubIntegration: "Github Integration"
}
