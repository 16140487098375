import { useState, useRef, useEffect } from "react"
import APIAddressSVG from "@/assets/icons/APIAddressSVG.tsx"
import { Tooltip } from "@radix-ui/react-tooltip"
import { TooltipContent, TooltipTrigger } from "@/components/ui/Tooltip"
import { useTranslation } from "react-i18next"
import { UniqueStatEntry } from "../Insights/helpers"
import { scrollToLineOrBody } from "./helpers"

interface Props {
  insight: UniqueStatEntry
  handleClose: (forceClose: boolean) => void
}

export const StatItem = ({ insight, handleClose }: Props) => {
  const { t } = useTranslation("Insight")
  const { avgTime, method, usageNum, fileName, lineNumber } = insight
  const [isTextTruncated, setIsTextTruncated] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      setIsTextTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
    }
  }, [])

  const handleStatClick = () => {
    fileName && scrollToLineOrBody(fileName, lineNumber || null)
    handleClose(true)
  }

  return (
    <div className="my-4 flex items-center">
      <APIAddressSVG />
      <Tooltip open={isTooltipOpen}>
        <TooltipTrigger
          className="cursor-pointer"
          onMouseEnter={() => isTextTruncated && setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          onClick={handleStatClick}
        >
          <p ref={textRef} className="ml-2 max-w-56 truncate">
            {method}
          </p>
        </TooltipTrigger>

        <TooltipContent className="max-h-20 max-w-80 overflow-auto">
          <p>{method}</p>
        </TooltipContent>
      </Tooltip>
      <p className="ml-2 text-xxs leading-[13px] text-hint">
        {usageNum} {usageNum && usageNum > 1 ? t("Invocations") : t("Invocation")}, {avgTime?.toFixed(2)} {t("MsAvg")}
      </p>
    </div>
  )
}
