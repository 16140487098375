const Icon = ({ primaryColor = "var(--base-primary)", secondaryColor = "var(--base-secondary)", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill={secondaryColor}
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.333 15.333a.667.667 0 111.334 0c0 .334-.227 2-.334 3a.333.333 0 11-.666 0c-.107-1-.334-2.666-.334-3z"
      />
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.333 13.333a3.333 3.333 0 115.334 0"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.333 13.333a3.333 3.333 0 115.334 0"
      />
      <path stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round" d="M15.333 16.333a6 6 0 10-6.666 0" />
      <path stroke={secondaryColor} strokeLinecap="round" strokeLinejoin="round" d="M15.333 16.333a6 6 0 10-6.666 0" />
    </svg>
  )
}

export default Icon
