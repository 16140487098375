import { Avatar } from "@/components"
import { useUser } from "@descope/react-sdk"
import { UserDropdownMenu } from "./UserDropdown"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { useFetchUser } from "@/hooks/queries/useFetchUser"
import { Navbar } from "./Navbar"
import { datadogRum } from "@datadog/browser-rum"
import { useEffect } from "react"
import { usePermissions } from "@/services/store/usePermissions"
import { runtimeConfig } from "@/lib/config"
import TrialTooltip from "@/pages/root/TopHeader/TrialTooltip.tsx"

export const TopHeader = () => {
  const { user, isUserLoading } = useUser()
  const { data: backendUser, isLoading } = useFetchUser()
  const { setUser } = usePermissions()

  if (runtimeConfig.VITE_ENABLE_TRACING === "true") {
    datadogRum.setUser({
      id: user?.userId,
      name: user?.name,
      email: user?.email
    })
  }

  useEffect(() => {
    setUser(backendUser)
  }, [backendUser, setUser])

  return (
    <header className="sticky top-0 z-100 flex h-16 w-full items-center justify-center border-b bg-background shadow-sm">
      <div className="flex h-16 w-full items-center justify-between px-12">
        <Navbar />
        <div>
          {isUserLoading || isLoading ? (
            <div>
              <SpinnerLoader />
            </div>
          ) : (
            <div className="flex gap-4">
              {backendUser.subscription_status === "trialing" && <TrialTooltip isOwner={backendUser.is_owner} />}
              <UserDropdownMenu
                organizations={backendUser.orgs}
                TriggerComponent={
                  <Avatar
                    className="size-7 cursor-pointer bg-primary text-xxs font-bold text-background hover:opacity-90"
                    username={user?.name}
                    avatarURL={backendUser?.avatar_url}
                  />
                }
              />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
