import { Change } from "@/models/Changes"
import { ChangeCard } from "@/pages/changes/ChangeCard"
import { useTranslation } from "react-i18next"

interface Props {
  existingChange: Change
}

export const ExistingCR = ({ existingChange }: Props) => {
  const { t } = useTranslation("CreateCR")
  return (
    <div className="my-8">
      <h1 className="mb-2 text-2xl">{t("ChangeExists")}</h1>
      <p className="mb-8 text-sm">{t("AlreadyOpenBranch")}</p>

      <ChangeCard codeChange={existingChange} />
    </div>
  )
}
