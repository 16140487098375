import { errorHandler } from "@/services/api/helpers"
import { FCC, SkeletonLoader } from "@/components"
import { NewDiffResponse } from "@/hooks/queries/useFetchNewCRDiff.tsx"

interface Props {
  diff?: NewDiffResponse
  diffError: unknown
  diffLoading: boolean
}

export const DiffContent = ({ diffError, diffLoading, diff }: Props) => {
  if (diffError) {
    return <div className="py-4">{errorHandler(diffError)}</div>
  }

  if (diffLoading) {
    return (
      <div className="py-4">
        <SkeletonLoader />
      </div>
    )
  }

  return (
    <section data-id="pr-content-section" className="mb-[80px] flex w-full flex-col border-r">
      {diff?.files?.map((file) => <FCC key={file.file_relative_path} file={file} newDiff />)}
    </section>
  )
}
