import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { ChangeRequest } from "@/models/Changes"

interface Args {
  codeBase: string
  number: string
}

export const useFetchChange = ({ codeBase, number }: Args) => {
  const { error, isLoading, data } = useQuery<ChangeRequest, unknown>({
    queryKey: ["change", codeBase, number],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.changes.get, {
        params: { codebase_name: codeBase, number }
      })
      return response.data
    },
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
    gcTime: 0,
    staleTime: 0
  })

  return { error, isLoading, data }
}
