import { useMemo, useState } from "react"

import {
  Avatar,
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Input,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from "@/components"
import { Reviewer } from "@/models/Changes.ts"
import { useTranslation } from "react-i18next"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { useUpdateReviewers } from "@/hooks/mutations/useUpdateReviewers"
import { formatName } from "@/lib/utils"

interface Props {
  changeId: string
  currentReviewersIds: string[]
  allReviewersOptions: Reviewer[] | undefined
  setNewReviewersIds: (newReviews: string[]) => void
  approvedReviewers?: string[]
}

export const ReviewersDropdown = ({
  changeId,
  allReviewersOptions,
  currentReviewersIds,
  setNewReviewersIds,
  approvedReviewers
}: Props) => {
  const [chosenReviewersIds, setChosenReviewersIds] = useState<string[]>(currentReviewersIds)
  const [tempReviewersIds, setTempReviewersIds] = useState<string[]>(currentReviewersIds)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const { t } = useTranslation("Review")
  const { mutateAsync } = useUpdateReviewers()

  const closeDropdown = () => {
    setIsDropdownOpen(false)
    setSearchTerm("")
  }

  const hasSameStrings = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return false
    arr1.sort()
    arr2.sort()
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false
    }
    return true
  }

  const updateChosenReviewers = (userToReview: Reviewer) => {
    // Check if we have that user checked
    const indexOfUser = chosenReviewersIds.findIndex((chosenReviewerId) => chosenReviewerId === userToReview.id)
    // Update chosen users by removing or adding that checked user
    setChosenReviewersIds((prevEligibleReviewersNames) => {
      if (indexOfUser > -1) {
        return prevEligibleReviewersNames.filter((_, index) => index !== indexOfUser)
      }
      return [...prevEligibleReviewersNames, userToReview.id]
    })
  }

  const handleSetReviewers = () => {
    closeDropdown()
    mutateAsync({
      changeId,
      reviewers: chosenReviewersIds
    })
    setNewReviewersIds(chosenReviewersIds)
    setTempReviewersIds(chosenReviewersIds)
  }

  const filteredReviewers = useMemo(() => {
    const filtered = allReviewersOptions?.filter((reviewer) => {
      const normalizedName = formatName(reviewer.name || reviewer.login).toLowerCase()
      return normalizedName.includes(searchTerm.toLowerCase())
    })
    if (filtered) {
      return filtered.sort((a, b) => a.name.localeCompare(b.name))
    }
  }, [allReviewersOptions, searchTerm])

  return (
    <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen} modal={false}>
      <Tooltip>
        <TooltipTrigger className="flex items-center overflow-x-hidden text-ellipsis text-nowrap">
          <DropdownMenuTrigger className="outline-none" asChild>
            <DotsHorizontalIcon width={24} height={24} className=" rounded-md px-1 py-0.5 text-primary hover:bg-fill" />
          </DropdownMenuTrigger>
          <TooltipContent side="bottom">{t("SetReviewers")}</TooltipContent>
        </TooltipTrigger>
      </Tooltip>

      <DropdownMenuContent align="start" onInteractOutside={closeDropdown} className="mt-2 translate-x-[-50px]">
        <Input
          onKeyDown={(e) => e.stopPropagation()}
          autoFocus={true}
          type="text"
          placeholder={t("SearchReviewers")}
          value={searchTerm}
          className="border-none text-xs font-medium leading-5 shadow-none focus-visible:ring-0"
          onChange={(event) => setSearchTerm(event.target.value)}
        />

        <DropdownMenuSeparator />

        <section className="max-h-72 overflow-y-scroll">
          {filteredReviewers && filteredReviewers.length > 0 ? (
            filteredReviewers.map((reviewerOption) => {
              return (
                <DropdownMenuCheckboxItem
                  className="text-xs font-medium leading-5"
                  tabIndex={0}
                  onSelect={(event) => {
                    event.preventDefault()
                  }}
                  key={reviewerOption.id}
                  checked={chosenReviewersIds.some((chosenId) => chosenId === reviewerOption.id)}
                  onCheckedChange={() => updateChosenReviewers(reviewerOption)}
                  disabled={approvedReviewers?.includes(reviewerOption.id)}
                >
                  <Avatar
                    className="mr-2 size-6 bg-primary text-xxs font-bold text-background hover:opacity-90"
                    username={reviewerOption?.name || formatName(reviewerOption.login)}
                    avatarURL={reviewerOption?.avatar_url}
                  />
                  {reviewerOption.name || formatName(reviewerOption.login)}{" "}
                  {reviewerOption.group_members_count && ` - ${reviewerOption.group_members_count} ${t("Members")}`}
                </DropdownMenuCheckboxItem>
              )
            })
          ) : (
            <div className="p-3">{t("EmptyReviewers")}</div>
          )}
        </section>

        <DropdownMenuSeparator />

        <Button
          className="w-full"
          variant="default"
          onClick={handleSetReviewers}
          disabled={hasSameStrings(tempReviewersIds, chosenReviewersIds)}
        >
          {t("SetReviewers")}
        </Button>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
