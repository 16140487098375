interface Props {
  width?: string
  height?: string
}

export default function ({ height, width }: Props) {
  return (
    <svg
      width={width || "64"}
      height={height || "32"}
      viewBox="0 0 64 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_8993_2526)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.8197 19.0397C42.8197 19.4333 42.7307 19.808 42.5701 20.1484C42.5661 20.1568 42.5621 20.1651 42.558 20.1735C42.4359 20.4254 42.2743 20.6581 42.0803 20.8651C41.5084 21.4754 40.6551 21.8628 39.7018 21.8628C37.9799 21.8628 36.584 20.5988 36.584 19.0397C36.584 17.4805 37.9799 16.2166 39.7018 16.2166C40.6617 16.2166 41.5202 16.6093 42.0921 17.227C42.5463 17.7174 42.8197 18.3496 42.8197 19.0397ZM42.079 18.9891C42.079 19.6259 41.5239 20.1484 40.666 20.1484C39.8249 20.1484 39.253 19.6259 39.253 18.9891C39.253 18.336 39.8249 17.8135 40.666 17.8135C41.5239 17.8135 42.079 18.336 42.079 18.9891Z"
          fill="#334155"
        />
        <path
          d="M39.6399 15.1428C40.9856 15.1428 42.079 15.9849 42.079 16.8665V17.2094L42.0921 17.227C42.5463 17.7174 42.8197 18.3496 42.8197 19.0397C42.8197 19.4333 42.7307 19.808 42.5701 20.1484H42.7943L42.079 21.6995H44.8041V16.8665C44.8041 14.1725 42.5836 13.0459 39.9931 13.0459C38.2605 13.0459 37.2176 13.601 36.8643 13.797V15.9849C37.2176 15.7563 38.1596 15.1428 39.6399 15.1428Z"
          fill="#334155"
        />
        <path
          d="M45.4605 13.2021V15.4064V15.5621C46.9594 15.5621 49.2274 15.2166 50.4632 14.6713V14.8843L45.418 19.4883L45.418 21.6925H45.8246H53.1984V19.4883L53.2033 19.0327C52.0925 19.0327 49.5119 19.1889 48.1587 20.1733V19.9604L53.204 15.3564L53.204 13.2021H52.8088H45.4605Z"
          fill="#334155"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.9336 21.7038H29.6587L29.3889 20.4816L29.2083 19.6629H29.4335C29.6383 20.3976 30.4889 21.8671 32.252 21.8671C34.4244 21.8671 36.1854 19.8934 36.1854 17.4587C36.1854 15.024 34.4244 13.0503 32.252 13.0503C31.2585 13.0503 30.351 13.4631 29.6587 14.144L29.5829 14.2205C29.6079 14.1946 29.6331 14.1691 29.6587 14.144V10.1338H26.9336V21.7038ZM33.4435 17.4587C33.4435 18.6833 32.6865 19.6629 31.5259 19.6629C30.382 19.6629 29.6587 18.6833 29.6587 17.4587C29.6587 16.2341 30.382 15.2545 31.5259 15.2545C32.6865 15.2545 33.4435 16.2341 33.4435 17.4587Z"
          fill="#334155"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0537 8.3485C12.5613 7.60535 15.0327 5.88821 17.7361 5.86646C20.7197 5.84241 21.6548 7.57164 22.0659 8.33191L22.0758 8.35029V8.36559C22.0511 8.36535 22.0262 8.36523 22.0012 8.36523C20.4915 8.36523 19.265 8.87373 19.265 9.76359V10.0306H22.0758V15.0373C22.0758 20.5769 17.5427 25.0659 12.0589 25.0659L11.4825 25.0662C11.874 24.7814 12.2938 24.427 12.7207 23.9854C14.0012 22.6608 14.0402 20.9934 13.5792 20.1459C13.472 21.1314 12.933 22.2451 12.0197 23.1899C11.0336 24.21 9.83583 24.8006 8.79785 24.8767C10.153 21.8145 10.9688 18.5034 12.0537 8.3485Z"
          fill="#334155"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_8993_2526"
          x="-8.53333"
          y="-8.53333"
          width="81.0667"
          height="49.0667"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.26667" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8993_2526" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8993_2526" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
