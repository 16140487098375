import { UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query"
import { errorHandler } from "@/services/api/helpers"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { useToast } from "../useToast"
import { useNavigate } from "react-router-dom"
import { routes } from "@/lib/routes.ts"
import { useTranslation } from "react-i18next"
import { AxiosError } from "axios"

interface Options extends Partial<UndefinedInitialDataOptions> {
  installationId: string | null
}

export const useLinkGitHubInstallation = ({ installationId }: Options) => {
  const { toast } = useToast()
  const { t } = useTranslation("Integrations")
  const navigate = useNavigate()

  const { error, isLoading, data } = useQuery({
    queryKey: ["github-webhook-callback", installationId],
    queryFn: async () => {
      try {
        if (!installationId) {
          throw new Error(t("NoInstallationId"))
        }

        const res = await axiosClient.patch(apiRoutes.githubIntegrations.patch(installationId))

        return res.data
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 404) {
            toast({
              title: t("RetryingConnection")
            })
            throw new Error(errorHandler(error))
          } else if (error.response?.status === 406) {
            toast({
              variant: "destructive",
              title: t("UserInstallationNotAllowed"),
              duration: 1000 * 60 * 5
            })
            navigate({ pathname: routes.onboarding.connect, search: "" })
          } else {
            toast({
              variant: "destructive",
              title: errorHandler(error.message)
            })
            navigate({ pathname: routes.settings.integrations({ provider: "github" }), search: "" })
            throw new Error(errorHandler(error))
          }
        }
      }
    },
    retry: 3,
    retryDelay: 3000
  })

  return { error, isLoading, data }
}
