import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { FetchContentArgs } from "@/models/FC"

interface Options {
  fetchArgs: FetchContentArgs | null
  path: string | null
  enabled?: boolean
}

export const useFetchContent = ({ fetchArgs, path, enabled = true }: Options) => {
  const { error, isLoading, data, refetch } = useQuery({
    queryKey: ["content", path],
    queryFn: async () => {
      const response = await axiosClient.post<string>(apiRoutes.content, fetchArgs)

      return response.data
    },
    enabled: enabled && fetchArgs !== null && path !== null
  })

  return { error, isLoading, data, refetch }
}
