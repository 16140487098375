import { ResultsDisplay } from "./components/ResultsDisplay"
import { Filter } from "./types"
import { useMemo } from "react"
import { ClearAllFilters } from "./components/ClearAllFilters"
import { SelectFilter } from "./variations/SelectFilter"
import { MultiSelect } from "./variations/MultiSelectFilter"
import { useSearchParams } from "react-router-dom"

interface Props {
  filters: Filter[]
  resultsCount?: number
  resultsName?: string
  isPaginated: boolean
  clearingException?: string | string[]
  localStorageKey?: string
}

export const Filters = ({
  filters,
  resultsCount,
  resultsName,
  isPaginated,
  clearingException,
  localStorageKey
}: Props) => {
  const [searchParams] = useSearchParams()

  const displayClearAll = useMemo(() => {
    return filters.some((filter) => searchParams.has(filter.urlKey))
  }, [searchParams, filters])

  return (
    <div className="flex items-center">
      <ResultsDisplay resultsCount={resultsCount} resultsName={resultsName} />

      <div className="flex gap-1">
        {filters.map((filter) =>
          filter.isMulti ? (
            <MultiSelect
              key={filter.urlKey}
              filter={filter}
              isPaginated={isPaginated}
              localStorageKey={localStorageKey}
            />
          ) : (
            <SelectFilter
              key={filter.urlKey}
              filter={filter}
              isPaginated={isPaginated}
              localStorageKey={localStorageKey}
            />
          )
        )}
      </div>

      {displayClearAll && (
        <ClearAllFilters clearingException={clearingException} localStorageKey={localStorageKey} variant="ghost" />
      )}
    </div>
  )
}
