import { Folder, File, ChevronDown, ChevronRight } from "lucide-react"
import SquarePlus from "@/assets/icons/SquarePlus.svg?react"
import SquareMinus from "@/assets/icons/SquareMinus.svg?react"
import CircleDot from "@/assets/icons/CircleDot.svg?react"
import ArrowRightSquare from "@/assets/icons/ArrowRightSquare.svg?react"
import { TreeNode } from "../utils"
import { FileStatus } from "@/models/FCC.ts"

interface RenderIconProps {
  isLast: boolean
  node: TreeNode
  expanded: boolean
}

export type MenuState = "open" | "close" | "draft"

export const stickyScrollOffset = 164
export const tBodyScrollOffset = 310

const renderExpansionIcon = ({ isLast, expanded }: { isLast: boolean; expanded: boolean }) => {
  if (isLast) return null
  return expanded ? (
    <ChevronDown width={16} height={16} className="cursor-pointer" />
  ) : (
    <ChevronRight width={16} height={16} className="cursor-pointer" />
  )
}

const renderTypeIcon = (editType: FileStatus) => {
  switch (editType) {
    case "added":
      return <SquarePlus />
    case "removed":
      return <SquareMinus />
    case "modified":
      return <CircleDot />
    case "renamed":
      return <ArrowRightSquare />
    default:
      return <></>
  }
}

export const renderIcons = ({ isLast, node, expanded }: RenderIconProps) => {
  return (
    <div className="flex gap-2">
      {renderExpansionIcon({ isLast, expanded })}
      {renderTypeIcon(node.editType)}
      {isLast ? <File width={16} height={16} /> : <Folder width={16} height={16} />}
    </div>
  )
}

export const scrollToPosition = ({ elementTop, offset }: { elementTop?: number; offset: number }) => {
  if (elementTop) {
    window.scroll({ top: elementTop - offset + window.scrollY, behavior: "smooth" })
  }
}

export const getVisibleElements = (elements: Element[]) => {
  const visibleElements: Element[] = []
  elements.forEach((element) => {
    const rect = element.getBoundingClientRect()
    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
      visibleElements.push(element)
    }
  })
  return visibleElements
}

export const getElements = () => {
  const elements: Element[] = []
  document.querySelectorAll("div[data-id=table-invisible-header]").forEach((element) => {
    elements.push(element)
  })

  return elements
}

export const scrollToNext = (elements: Element[] | null) => {
  if (elements === null) return

  const visibleElements = getVisibleElements(elements)
  const first = visibleElements[0]
  const next = visibleElements[1]

  if (next) {
    const top = next.getBoundingClientRect().top
    scrollToPosition({ elementTop: top, offset: stickyScrollOffset })
    return
  }

  // file can be too big and only one visible element is visible
  // need to check if we are not at the end of the array and continue scrolling
  const index = elements.findIndex((element) => element.id === first.id)

  if (index < elements.length - 1) {
    const top = elements[index + 1].getBoundingClientRect().top
    return scrollToPosition({ elementTop: top, offset: stickyScrollOffset })
  }
}

export const scrollToPrev = (elements: Element[] | null) => {
  if (elements === null) return

  const visibleElements = getVisibleElements(elements)
  const first = visibleElements[0]

  const index = elements.findIndex((element) => element.id === first.id)

  if (index > 0) {
    const top = elements[index - 1].getBoundingClientRect().top
    return scrollToPosition({ elementTop: top, offset: stickyScrollOffset })
  }
}
