import * as React from "react"
import { ArrowRight } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components"
import { BazzyCodeOptions } from "@/models/Bazzy"

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onSend?: (content: string, option?: BazzyCodeOptions) => void
  shouldFocus?: boolean
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, onSend, shouldFocus = false, value, onChange, ...props }, ref) => {
    const [content, setContent] = React.useState("")
    const textareaRef = React.useRef<HTMLTextAreaElement>(null)

    React.useImperativeHandle(ref, () => textareaRef.current as HTMLTextAreaElement)

    React.useEffect(() => {
      if (shouldFocus) {
        textareaRef.current?.focus()
      }
    }, [shouldFocus])

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setContent(e.target.value)
      onChange?.(e)
    }

    const handleSend = () => {
      if (content.trim() && onSend) {
        onSend(content)
        setContent("")
      }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey && onSend) {
        e.preventDefault()
        handleSend()
      }
    }

    return (
      <div className="relative">
        <textarea
          className={cn(
            "flex w-full rounded-md border border-border bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-hint focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-secondary disabled:cursor-not-allowed disabled:opacity-50",
            "align-middle leading-[normal]",
            onSend && "pr-10",
            className
          )}
          ref={textareaRef}
          value={content}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          {...props}
        />
        {onSend && (
          <Button
            onClick={handleSend}
            size="icon"
            className="absolute right-2 top-2 flex size-8 items-center justify-center bg-transparent hover:bg-transparent"
            variant="ghost"
            disabled={!content.trim()}
          >
            <ArrowRight className="size-5 text-primary" />
          </Button>
        )}
      </div>
    )
  }
)
Textarea.displayName = "Textarea"

export { Textarea }
