import { BazzyContext, BazzyErrorType, BazzyQuestionsCategories } from "@/models/Bazzy"
import { SelectedLines } from "@/models/FCC"

interface CreateBazzyContextProps {
  selectedTableFilePath: string | null
  selectedLines: SelectedLines
  firstSelectedSide: "left" | "right" | null
  type?: BazzyQuestionsCategories
  relevantInsight?: string
  insightType?: BazzyErrorType
}

export const createBazzyContext = ({
  selectedTableFilePath,
  selectedLines,
  firstSelectedSide,
  type,
  relevantInsight,
  insightType
}: CreateBazzyContextProps): BazzyContext | null => {
  if (!selectedLines.startLine || !selectedLines.endLine || !selectedTableFilePath) {
    console.error("Missing required context for bazzy (file name, start line number, end line number")
    return null
  }

  return {
    filePath: selectedTableFilePath,
    lines: selectedLines,
    side: firstSelectedSide,
    type: type ?? "code",
    relevantInsight,
    insightType
  }
}
