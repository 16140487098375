import { useCallback, useMemo } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  ExternalLink,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCreateIntegration } from "@/hooks/mutations/useCreateIntegration.tsx"
import { toast } from "@/hooks/useToast.ts"

interface CreateDatadogIntegrationModalProps {
  isOpen: boolean
  onClose: () => void
}

const APPROVED_URLS = {
  us1: "datadoghq.com",
  us3: "us3.datadoghq.com",
  us5: "us5.datadoghq.com",
  eu1: "eu1.datadoghq.com",
  ap1: "ap1.datadoghq.com"
}

export const DatadogIntegrationModal = ({ isOpen, onClose }: CreateDatadogIntegrationModalProps) => {
  const { t } = useTranslation("Integrations")
  const { t: commonT } = useTranslation("Common")
  const { mutateAsync: createIntegration, status } = useCreateIntegration()

  const formSchema = useMemo(
    () =>
      z.object({
        org_name: z.string().min(1, {
          message: t("datadog.org_name_required")
        }),
        base_url: z.nativeEnum(APPROVED_URLS),
        api_key: z.string().min(1, {
          message: t("datadog.api_key_required")
        }),
        app_key: z.string().min(1, {
          message: t("datadog.app_key_required")
        })
      }),
    [t]
  )

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      api_key: "",
      app_key: "",
      base_url: APPROVED_URLS.us1,
      org_name: ""
    }
  })

  const { handleSubmit, control, setError } = form

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      try {
        await createIntegration({
          integration_type: "datadog",
          credentials: {
            api_key: values.api_key,
            app_key: values.app_key
          },
          metadata: {
            org_name: values.org_name,
            base_url: values.base_url
          }
        })
        onClose()
        toast({
          variant: "default",
          description: t("datadog.success")
        })
      } catch (e) {
        console.error(e)
        toast({
          variant: "destructive",
          description: t("datadog.failure")
        })
        setError("api_key", { message: t("datadog.failure") })
        setError("app_key", { message: t("datadog.failure") })
        setError("base_url", { message: t("datadog.failure") })
      }
    },
    [createIntegration, onClose, setError, t]
  )

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogContent
        onInteractOutside={onClose}
        onOpenAutoFocus={(event) => event.preventDefault()}
        className="max-w-[600px] gap-[2px] rounded-sm border bg-background p-1"
      >
        <DialogTitle className="m-2 font-semibold text-primary">{t("datadog.title")}</DialogTitle>
        <DialogDescription className="mb-2 ml-2">{t("datadog.subtitle")}</DialogDescription>
        <Form {...form}>
          <form className="rounded-lg border border-border bg-background p-3">
            <FormField
              control={control}
              name="org_name"
              render={({ field }) => {
                return (
                  <FormItem className="mb-3 items-baseline">
                    <FormLabel>{t("datadog.org_name")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={control}
              name="base_url"
              render={({ field }) => {
                return (
                  <FormItem className="mb-3 items-baseline">
                    <FormLabel>{t("datadog.site")}</FormLabel>
                    <FormControl>
                      <Select onValueChange={field.onChange} defaultValue={APPROVED_URLS.us1}>
                        <SelectTrigger className="bg-background text-primary">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.values(APPROVED_URLS).map((url) => (
                            <SelectItem key={url} value={url}>
                              {url}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={control}
              name="app_key"
              render={({ field }) => {
                return (
                  <FormItem className="mb-3 items-baseline">
                    <FormLabel>{t("datadog.app_key")}</FormLabel>
                    <FormControl>
                      <Input {...field} type="password" />
                    </FormControl>
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={control}
              name="api_key"
              render={({ field }) => {
                return (
                  <FormItem className="mb-3 items-baseline">
                    <FormLabel>{t("datadog.api_key")}</FormLabel>
                    <FormControl>
                      <Input {...field} type="password" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
          </form>
          <DialogDescription className="mb-2 ml-2">
            <ExternalLink
              href="https://docs.baz.co/code/integrations/datadog"
              label={t("datadog.more_info")}
            ></ExternalLink>
          </DialogDescription>
          <div className="flex items-center justify-center">
            <Button
              className="w-[100px]"
              size="icon"
              variant="secondary"
              onClick={handleSubmit(onSubmit)}
              disabled={status == "pending"}
            >
              <p>{status == "pending" ? commonT("Submitting") : commonT("Submit")}</p>
            </Button>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
