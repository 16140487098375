export class LocalStorageService {
  private storage: Storage
  private parsingError: string = "Error parsing stored value for key"

  constructor(storageType: "localStorage" | "sessionStorage" = "localStorage") {
    if (storageType === "localStorage") {
      this.storage = window.localStorage
    } else if (storageType === "sessionStorage") {
      this.storage = window.sessionStorage
    } else {
      throw new Error(`${storageType} is not supported in this browser.`)
    }
  }

  setItem(key: string, value: unknown): void {
    const serializedValue = typeof value === "string" ? value : JSON.stringify(value)
    this.storage.setItem(key, serializedValue)
  }

  hasItem(key: string): boolean {
    return this.storage.getItem(key) !== null
  }

  getItem<T = string>(key: string, isParsed: boolean = false): T | null {
    const storedValue = this.storage.getItem(key)

    if (storedValue === null) {
      return null
    }

    try {
      if (isParsed) {
        const parsedValue = JSON.parse(storedValue)
        return parsedValue as T
      }
      return storedValue as T
    } catch (error) {
      console.error(`${this.parsingError} "${key}":`, error)
      throw new Error(`${this.parsingError} "${key}": ${String(error)}`)
    }
  }

  removeItem(key: string): void {
    this.storage.removeItem(key)
  }

  clear(): void {
    this.storage.clear()
  }
}
