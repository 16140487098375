import { Button, GithubWriteAccessButtonWrapper } from "@/components"
import { useApprove } from "@/hooks/mutations/useApprove.tsx"
import { ChangeRequest, Review } from "@/models/Changes.ts"
import { MutationStatus } from "@/models/Common.ts"
import { User } from "@/models/User.ts"
import { usePermissions } from "@/services/store/usePermissions.ts"
import { CheckCheck } from "lucide-react"
import { useTranslation } from "react-i18next"
import { useCallback } from "react"

interface Props {
  changeRequest: ChangeRequest
}

const isUserApproved = (user: User | null, reviews: Review[]): boolean => {
  const review = reviews.find((review) => user?.id === review.assignee_id)

  if (!review) return false
  return review.review_state === "approved"
}

const displayIcon = (isApproved: boolean, status: MutationStatus) => {
  return isApproved || status === "success" ? null : <CheckCheck width={16} height={16} className="mr-1" />
}
const displayText = (isApproved: boolean, status: MutationStatus) => {
  return isApproved || status === "success" ? "Approved" : "Approve"
}

export const ApproveButton = ({ changeRequest }: Props) => {
  const { t } = useTranslation("Toolbar")
  const { mutateAsync, status } = useApprove()
  const { user } = usePermissions()
  const isApproved = isUserApproved(user, changeRequest.reviews)
  const isDisabled = isApproved || status === "pending" || status === "success"
  const isAuthor = user?.github_login === changeRequest.author_name

  const onClick = useCallback(async () => {
    await mutateAsync(changeRequest.id)
  }, [mutateAsync, changeRequest])

  if (isAuthor) return <></>

  return (
    <div>
      <GithubWriteAccessButtonWrapper repoFullName={changeRequest.base_repo_full_name} side="bottom">
        {(hasAccess) => (
          <Button
            disabled={isDisabled || !hasAccess}
            onClick={onClick}
            className="h-8 bg-primary py-0 text-xs"
            size="sm"
          >
            {displayIcon(isApproved, status)}
            {t(displayText(isApproved, status))}
          </Button>
        )}
      </GithubWriteAccessButtonWrapper>
    </div>
  )
}
