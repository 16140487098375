import { FileWithDiffs } from "@/models/FCC"
import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
interface Options {
  changeId: string
  tool: string | null
  topicId: string | null
}

interface QueryResponse {
  files: FileWithDiffs[]
  total: number
}

export const useFetchCodeDiff = ({ changeId, tool = "git", topicId }: Options) => {
  const computedTool = tool === "ast" ? "difftastic" : tool
  const computedParams = topicId ? { tool: computedTool, topic_id: topicId } : { tool: computedTool }

  const { error, isLoading, data } = useQuery<QueryResponse>({
    queryKey: ["codeDiff", changeId, tool, topicId],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.changes.changeDiff.get(changeId), {
        params: computedParams
      })
      return response.data
    },
    enabled: !!changeId
  })

  return { error, isLoading, data: data?.files || [] }
}
