const GitBranchesSVG = ({ primaryColor = "var(--base-primary)", secondaryColor = "var(--base-primary)", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round" d="M16 18a2 2 0 100-4 2 2 0 000 4z" />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 18a2 2 0 100-4 2 2 0 000 4zM8 10a2 2 0 100-4 2 2 0 000 4z"
      />
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.667 8h2A1.333 1.333 0 0116 9.333V14"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.667 8h2A1.333 1.333 0 0116 9.333V14"
      />
      <path stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round" d="M8 10v8" />
      <path stroke={secondaryColor} strokeLinecap="round" strokeLinejoin="round" d="M8 10v8" />
    </svg>
  )
}

export default GitBranchesSVG
