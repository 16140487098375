import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"

export const useFetchSlackDetails = () => {
  const { error, isLoading, data } = useQuery({
    queryKey: ["slack"],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.slack.get)
      return response.data
    }
  })

  return { error, isLoading, data }
}
