import { Button } from "@/components"
import BazLogoLight from "@/assets/icons/BazLogo-light.tsx"
import { REVIEWER_TYPES } from "@/pages/settings/reviewer/ReviewerTypes.tsx"
import { useTranslation } from "react-i18next"
import { useCallback } from "react"
import { useUpdateOnboardingReviewerStatus } from "@/hooks/mutations/useUpdateOnboardingReviewerStatus"

const BazLogo = BazLogoLight

interface ReviewerWorkflowProps {
  wasStuck: boolean
}

export function ReviewerWorkflow({ wasStuck }: ReviewerWorkflowProps) {
  const { t } = useTranslation("OnBoarding")
  const { mutateAsync: updateOnboardingReviewerStatus, status } = useUpdateOnboardingReviewerStatus()

  const handleSubmit = useCallback(async () => {
    try {
      await updateOnboardingReviewerStatus(wasStuck)
    } catch (error) {
      console.error("Error updating reviewer status:", error)
    }
  }, [updateOnboardingReviewerStatus, wasStuck])

  return (
    <section data-testid="reviewer-workflow" className="flex w-[414px] flex-col">
      <div className="mb-6 flex h-12 w-full items-center justify-center">
        <BazLogo width="169" height="50" />
      </div>

      <div className="mb-9 w-full rounded-2xl bg-white px-8 pb-8 pt-10 shadow-xl">
        <h3 className="mb-4 text-center text-xl text-primary">{t("ReviewerWorkflow")}</h3>
        <h2 className="mb-4 px-9 text-center text-s font-normal text-secondary">{t("ReviewerDetails")}</h2>
        {REVIEWER_TYPES.map((item, index) => (
          <li key={index} className="flex items-center space-x-2 border-b border-border py-2 last:border-none">
            <div className="rounded bg-link p-1">
              <item.icon className="size-4 text-background" />
            </div>
            <span className="pl-4 text-s font-medium text-primary">{item.display}</span>
          </li>
        ))}
        <Button disabled={status === "pending"} className="my-6 w-full" onClick={handleSubmit}>
          {t("LetsGo")}
        </Button>
        <span className="text-xxs text-secondary">{t("Customize")}</span>
      </div>
    </section>
  )
}
