import { useMemo } from "react"
import { getBranchStatus } from "../../utils"
import { BranchInfo } from "@/models/Repository"
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from "@/components"

interface Props {
  branches: BranchInfo[]
  branchName?: string
  defaultBranch?: string
}

export const BranchStatus = ({ branches, branchName, defaultBranch }: Props) => {
  const computedBranchStatus = useMemo(
    () => getBranchStatus({ branches, branchName, defaultBranch }),
    [defaultBranch, branchName, branches]
  )

  return (
    <div className="my-4">
      <TooltipProvider>
        <Tooltip>
          {computedBranchStatus ? (
            <p className="text-xxs text-primary">
              <TooltipTrigger className="cursor-default">{computedBranchStatus?.substring(0, 30)} ...</TooltipTrigger>
            </p>
          ) : null}

          <TooltipContent>{computedBranchStatus}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
