import { File, Sparkles, Unlink2 } from "lucide-react"
import { getFileLanguageFromPath, splitPath } from "@/lib/utils"
import { CIRunWithErrors, CodeLocation } from "@/models/Changes"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import {
  Badge,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { CommentCreationPlacementData, useNewCommentBubble } from "@/hooks/useNewCommentbubble"
import { useTranslation } from "react-i18next"
import { Link } from "@/components/designSystem/Link"
import { isLineInScope, scrollToLineOrBody } from "./helpers"
import { useDiscussions } from "@/services/store/useDiscussions"
import { BazzyErrorType, BazzyInsightOptions, BazzyQuestionsCategories } from "@/models/Bazzy"
import { useCallback } from "react"
import { useBazzyMessages } from "@/hooks/useBazzyMessages"

interface Props {
  errorItem: CIRunWithErrors
  onFileClick: (path: string) => void
  handleClose: (forceClose: boolean) => void
}

const LogErrorContent = ({ errorItem, onFileClick, handleClose }: Props) => {
  const { tables } = useChangeRequest()
  const { expandDiscussionsContainer } = useDiscussions()
  const { ci_name } = errorItem
  const { handleAddCommentClick } = useNewCommentBubble()
  const { t } = useTranslation("Topics")
  const { addUserQuestionToBazzy } = useBazzyMessages()

  const handleGoToFile = (filePath: string) => {
    onFileClick(filePath)
    handleClose(true)
  }

  const handleOpenBazzy = useCallback(
    (codeLocation: CodeLocation) => {
      handleClose(true)

      const insightMessage = `${errorItem.name} - ${errorItem.errors[0].err_description}`

      addUserQuestionToBazzy(
        BazzyInsightOptions.EXPLAIN,
        t("Explain"),
        BazzyQuestionsCategories.INSIGHT,
        insightMessage,
        {
          filePath: codeLocation.fileName,
          lines: {
            startLine: codeLocation.lineNumber,
            endLine: codeLocation.lineNumber
          },
          side: codeLocation.side || "right",
          type: "insight",
          relevantInsight: insightMessage,
          insightType: BazzyErrorType.LOG_ERROR
        },
        BazzyErrorType.LOG_ERROR
      )
      requestAnimationFrame(() => {
        scrollToLineOrBody(codeLocation.fileName, codeLocation.lineNumber)
      })
    },
    [handleClose, errorItem.name, errorItem.errors, addUserQuestionToBazzy, t]
  )

  const handleCommentClick = useCallback(
    async (data: CommentCreationPlacementData) => {
      try {
        expandDiscussionsContainer()
        await handleAddCommentClick(data)
        scrollToLineOrBody(data.filePath, data.lineNumber)
        handleClose(true)
      } catch (error) {
        console.error("Error handling comment click:", error)
      }
    },
    [expandDiscussionsContainer, handleAddCommentClick, handleClose]
  )

  const renderCodeLocation = (fileName: string, lineNumber: number) => {
    const { fileName: shortName, path } = splitPath(fileName)
    const rows =
      tables[fileName]?.rows?.filter(
        ({ new_line_number }) =>
          new_line_number && [lineNumber - 1, lineNumber, lineNumber + 1].includes(new_line_number)
      ) || []

    return (
      <section className="my-2 flex flex-col rounded-md border border-border">
        <div className="flex w-full items-center justify-between pr-1">
          <div className="flex items-center pl-2">
            <File width={16} height={16} />
            <Link onClick={() => handleGoToFile(fileName)} className="px-2">
              <p>{shortName}</p>
            </Link>
            <p className="mr-2 text-xxs font-normal text-secondary">/{path}/</p>
          </div>
          <Badge className="h-6 text-xs font-medium">{getFileLanguageFromPath(fileName)}</Badge>
        </div>
        <Table className="overflow-scroll rounded-md border-b-0 border-t bg-background font-menlo text-[12px] font-normal">
          <TableBody className="flex max-w-dialog flex-col">
            {rows.map(({ new_line_number, new_content }) => (
              <TableRow key={new_line_number} className="border-b-0">
                <TableCell className="h-7 w-5 border-r px-4 py-1">{new_line_number}</TableCell>
                <TableCell className="h-7 whitespace-pre py-0 pl-4">{new_content} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
    )
  }

  return (
    <div className="max-h-[500px] max-w-dialog gap-[2px] overflow-auto rounded-sm bg-background p-1 font-bold">
      {errorItem.errors.map((CIError, index) => {
        return (
          <div key={`${CIError.name}-${index}`}>
            {CIError.codeLocations.map((codeLocation: CodeLocation) =>
              renderCodeLocation(codeLocation.fileName, codeLocation.lineNumber)
            )}

            <section className="flex flex-col rounded-md border border-border text-xxs font-normal">
              <div className="flex w-full items-center justify-between border-b py-1 pl-2 pr-1">
                <div className="flex items-center">
                  <Unlink2 width={16} height={16} className="mr-2" />
                  <p className="mr-2 font-menlo text-[12px]">{CIError.name}</p>
                </div>
                <Badge className="text-xs font-medium">{ci_name}</Badge>
              </div>
              <p className="w-full p-2 font-menlo text-[12px]">{CIError.err_description}</p>
            </section>

            <TooltipProvider>
              <Tooltip>
                {isLineInScope(CIError.codeLocations[0].fileName, CIError.lineNumber) ? (
                  <div className="flex items-center">
                    <Button
                      onClick={() =>
                        handleCommentClick({
                          filePath: CIError.codeLocations[0]?.fileName,
                          lineNumber: CIError.codeLocations[0]?.lineNumber
                        })
                      }
                      className="my-2 h-6 px-2 text-xs font-normal"
                    >
                      {t("Comment")}
                    </Button>
                    <Button
                      className="my-2 ml-2 h-6 px-2 text-xs font-normal"
                      onClick={() => handleOpenBazzy(CIError.codeLocations[0])}
                    >
                      <Sparkles width={14} className="mr-1" />
                      {t("AskBazzy")}
                    </Button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <TooltipTrigger className="cursor-not-allowed">
                      <Button disabled className="my-2 h-6 px-2 text-xs font-normal">
                        {t("Comment")}
                      </Button>
                    </TooltipTrigger>
                    <TooltipTrigger className="cursor-not-allowed">
                      <Button disabled className="my-2 ml-2 h-6 px-2 text-xs font-normal">
                        <Sparkles width={14} className="mr-1" />
                        {t("AskBazzy")}
                      </Button>
                    </TooltipTrigger>
                  </div>
                )}
                <TooltipContent className="font-inter text-xxs font-normal">{t("OutOfScope")}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )
      })}
    </div>
  )
}

export default LogErrorContent
