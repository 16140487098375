import { useTranslation } from "react-i18next"
import { canLoad } from "@/components/designSystem/FCC/utils"

export type EdgeFile = "Lock" | "Binary" | "Empty" | "Renamed" | "Removed" | "TooLong" | "Formatting"

interface Props {
  fileType: EdgeFile | null
  minimized: boolean
  setForceRender: () => void
}

export function EdgeFile({ fileType, minimized, setForceRender }: Props) {
  const { t } = useTranslation("FCC")

  return (
    <div
      className={`flex w-full flex-col items-center justify-center space-y-4 border-b  bg-background p-6 shadow-sm ${minimized ? "hidden" : "visible"}`}
    >
      <p className="text-center text-sm text-primary">{fileType ? t(fileType) : ""}</p>
      {canLoad(fileType) ? (
        <div className="inline-block cursor-pointer" onClick={setForceRender}>
          <p className="p-2 text-center text-sm font-bold text-link">{t("LoadDiff")}</p>
        </div>
      ) : null}
    </div>
  )
}
