import { useQuery } from "@tanstack/react-query"
import { Change, ChangesTimeSort } from "@/models/Changes"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"

interface FetchChangesProps {
  statuses?: string | string[] | null
  authors?: string | string[] | null
  page?: string | null
  repositories?: string | string[] | null
  sort?: ChangesTimeSort
}

interface FetchChangesRequest {
  statuses?: string | string[] | null
  authors?: string | string[] | null
  page_num: number | null
  repositories?: string | string[] | null
  sort?: ChangesTimeSort
}

interface ChangesResponse {
  changes: Change[]
  has_more: boolean
  per_page: number
  total: number
}

export const useFetchChanges = ({ statuses, authors, page, repositories, sort }: FetchChangesProps) => {
  return useQuery<ChangesResponse>({
    queryKey: ["changes", statuses, authors, page, repositories, sort],
    queryFn: async () => {
      const requestBody: FetchChangesRequest = {
        statuses,
        authors,
        page_num: page ? Number(page) : null,
        repositories,
        sort
      }

      const { data } = await axiosClient.post(apiRoutes.changes.search.post, requestBody)

      return data
    }
  })
}
