import { Progress } from "@/components"

interface Props {
  trialRemainingDays: number
  className?: string
}

const TRIAL_PERIOD = 14

export function TrialProgress({ trialRemainingDays, className }: Props): JSX.Element {
  const daysUsed = TRIAL_PERIOD - trialRemainingDays
  const progressPercentage = (daysUsed / TRIAL_PERIOD) * 100
  return (
    <Progress
      value={progressPercentage}
      className={`my-2 h-1 bg-border [&>div]:rounded-full [&>div]:bg-base-purple ${className}`}
    />
  )
}
