import { Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { useTranslation } from "react-i18next"
import { BreakingChangeDetails } from "@/components/designSystem/Insights/helpers.tsx"
import APIAddressSVG from "@/assets/icons/APIAddressSVG.tsx"
import { useEffect, useRef, useState } from "react"

interface Props {
  breakingChange: BreakingChangeDetails
}
export const BreakingChangeItem = ({ breakingChange }: Props) => {
  const { t } = useTranslation("Insight")
  const { title, times } = breakingChange
  const [isTextTruncated, setIsTextTruncated] = useState(false)
  const titleRef = useRef<HTMLParagraphElement>(null)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  useEffect(() => {
    if (titleRef.current) {
      setIsTextTruncated(titleRef.current.scrollWidth > titleRef.current.clientWidth)
    }
  }, [])

  return (
    <div className="my-1 flex items-center pl-0 pr-1 hover:bg-inherit">
      <APIAddressSVG color="var(--red-error-dark)" className="mr-2" />
      <Tooltip open={isTooltipOpen}>
        <TooltipTrigger
          className="flex items-baseline text-xs font-normal"
          onMouseEnter={() => (isTextTruncated ? setIsTooltipOpen(true) : null)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          disabled={!isTextTruncated}
          asChild
        >
          <p ref={titleRef} className="mr-2 max-w-[400px] truncate">
            {title}
          </p>
        </TooltipTrigger>
        <TooltipContent align="start" className="max-w-80 overflow-auto">
          <p>{title}</p>
        </TooltipContent>
      </Tooltip>
      <p className="text-xxs text-hint">
        {times} {breakingChange && times > 1 ? t("BreakingChanges") : t("BreakingChange")}
      </p>
    </div>
  )
}
