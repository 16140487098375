import { useTranslation } from "react-i18next"

export const DiscussionSeparator = () => {
  const { t } = useTranslation("Discussions")
  return (
    <div className="mb-4 flex w-full items-center">
      <div className="h-px w-[310px] bg-error-dark" />
      <span className="ml-2 text-xxs font-semibold text-error-dark">{t("New")}</span>
    </div>
  )
}
