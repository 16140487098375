import { Button, CopyToClipboard } from "@/components"
import { ClipboardCopy } from "lucide-react"
import { RelatedSuggestion } from "@/lib/mdTransformer/RenderCodeBlock.tsx"
import { useSuggestion } from "@/services/store/useSuggestion.ts"
import { useSelection } from "@/services/store/useSelection.ts"
import { useTranslation } from "react-i18next"

interface Props {
  relatedSuggestionInfo: RelatedSuggestion
}

export const SuggestionButton = ({ relatedSuggestionInfo }: Props) => {
  const { setSuggestionModalOpen, setSuggestionValue, setNewSuggestionValue } = useSuggestion()
  const { firstSelectedSide, selectedTableFilePath } = useSelection()
  const { t } = useTranslation("Bazzy")

  const handleSuggestion = () => {
    setSuggestionModalOpen()
    setSuggestionValue({
      selectedSide: firstSelectedSide,
      selectedFile: selectedTableFilePath,
      selectedLines: {
        startLine: relatedSuggestionInfo.lines[0],
        endLine: relatedSuggestionInfo.lines[1]
      }
    })
    setNewSuggestionValue(relatedSuggestionInfo.text)
  }

  return (
    <div className="mt-1 flex items-center gap-2">
      <CopyToClipboard text={relatedSuggestionInfo.text}>
        <ClipboardCopy width={16} height={16} className="cursor-pointer text-primary" />
      </CopyToClipboard>

      <Button
        variant="outline"
        className="h-6 cursor-pointer px-2 font-inter text-xs"
        size="sm"
        onClick={handleSuggestion}
      >
        {t("CreateSuggestion")}
      </Button>
    </div>
  )
}
