import { Button } from "@/components"

interface Props {
  onClick: () => void
  children: React.ReactNode
  className?: string
}
export const Link = ({ onClick, children, className }: Props) => {
  return (
    <Button
      variant="ghost"
      className={`h-8 justify-start p-0 font-menlo text-xs text-link outline-none hover:bg-inherit focus:border-none ${className}`}
      onClick={onClick}
      tabIndex={-1}
    >
      {children}
    </Button>
  )
}
