interface Props {
  children: JSX.Element
}

export const Content = ({ children }: Props) => {
  return (
    <div data-testid="settings-content" className="w-full py-6 pb-24 pl-6 pr-12 text-xs text-primary">
      {children}
    </div>
  )
}
