import {
  calculateLineMarkTop,
  percentageToPixels,
  topNavigationHeight
} from "@/pages/crFileChanges/components/Scrollbar/utils"
import { colorsMap } from "@/pages/crFileChanges/components/Scrollbar/index"
import { forwardRef, MouseEvent, useCallback } from "react"
import { Discussion } from "@/models/Discussions"
import { calculateRowTopPosition } from "@/components/designSystem/Discussions/utils"
import { stickyScrollOffset } from "@/pages/crFileChanges/components/helpers"
import { DiscussionWithTop, useDiscussions } from "@/services/store/useDiscussions"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { formatName } from "@/lib/utils"
import { TooltipPortal } from "@radix-ui/react-tooltip"
import ResolvedIcon from "@/assets/icons/Resolved.svg?react"
import OutdatedIcon from "@/assets/icons/Outdated.svg?react"
import OpenIcon from "@/assets/icons/Open.svg?react"

interface LineMarkProps {
  discussion: DiscussionWithTop
}

const iconsMap = {
  green: <ResolvedIcon />,
  yellow: <OpenIcon />,
  gray: <OutdatedIcon />
} as const

export const LineMark = forwardRef<HTMLElement | null, LineMarkProps>(({ discussion }, ref) => {
  const { currentOpenedDiscussion, expandDiscussionsContainer, toggleManualRecalculationTrigger } = useDiscussions()

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>, current: Discussion) => {
      event.stopPropagation()
      expandDiscussionsContainer()
      toggleManualRecalculationTrigger()

      const top = calculateRowTopPosition(current)
      scrollTo({ top: top - stickyScrollOffset })
    },
    [expandDiscussionsContainer, toggleManualRecalculationTrigger]
  )

  const top = calculateLineMarkTop(ref, discussion.topPosition)
  const bgColor = discussion.discussion_state === "resolved" ? "green" : discussion.outdated ? "gray" : "yellow"
  const isDiscussionOpen = currentOpenedDiscussion?.id === discussion.id

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger>
          <div
            onClick={(event) => handleClick(event, discussion)}
            style={{ top: top + "%" }}
            key={discussion.id}
            className={`
            absolute h-1 w-full origin-center cursor-pointer transition-transform duration-300 
            hover:scale-y-[200%] 
            ${colorsMap[bgColor].bg} 
            ${isDiscussionOpen ? `scale-y-[300%] border-y ${colorsMap[bgColor].border} hover:scale-y-[300%]` : ""}`}
          />
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            style={{ top: percentageToPixels(top, window.innerHeight - topNavigationHeight) - 20 }}
            className="absolute right-full min-w-40 shadow-md"
          >
            <span className="flex w-full items-center gap-1">
              {iconsMap[bgColor]} {formatName(discussion?.comments[0]?.author)}
            </span>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
})
