import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { en } from "@/services/i18n/locales/en"

export const defaultNS = "common"
export const resources = {
  en
} as const

i18next.use(initReactI18next).init({
  fallbackLng: "en",
  lng: navigator.language || "en",
  resources
})

export default i18next

export type TranslationKey<T extends keyof typeof en> = NonNullable<keyof (typeof en)[T]>
