import { Card, CardContent } from "@/components"
import Datadog from "@/assets/icons/datadog.svg?react"
import { CheckCircle, AlertTriangle } from "lucide-react"
import { DataDogIntegration, IntegrationStatus } from "@/models/Integration.ts"
import { Timestamp } from "@/components/ui/Timestamp"

interface Props {
  installation: DataDogIntegration
}

const Icon = ({ status }: { status: IntegrationStatus }) => {
  return status === "success" ? (
    <CheckCircle width={12} height={12} className="ml-1 text-success-normal" />
  ) : (
    <AlertTriangle width={12} height={12} className="ml-1 text-error-normal" />
  )
}

export const InstallationCard = ({ installation }: Props) => {
  return (
    <Card className="w-full rounded-md border shadow-none" data-testid="github-repo-card">
      <CardContent className="flex items-center p-4 text-fill">
        <Datadog width={32} height={30} className="mr-4" />

        <div>
          <h1 className="flex items-center text-sm text-primary">
            {installation.metadata.org_name} ({installation.metadata.base_url}) <Icon status={installation.status} />
          </h1>

          <Timestamp time={installation.updated_at} />
        </div>
      </CardContent>
    </Card>
  )
}
