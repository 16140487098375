import { Discussion as DiscussionI, NewCommentBubble } from "@/models/Discussions"
import { useCallback, useEffect, useState, useMemo } from "react"
import { calculateRowTopPosition } from "../utils"
import { DiscussionDrawer } from ".."
import { useDiscussions } from "@/services/store/useDiscussions"
import { stickyScrollOffset } from "@/pages/crFileChanges/components/helpers"
import { ExpandedDiscussion } from "./ExpandedDiscussion"
import { DiscussionChip } from "./ChipDiscussion"
import { useBazzy } from "@/services/store/useBazzy"
import { useSyncElementPosition } from "@/components/designSystem/Discussions/Inline/useSyncElementPosition"

interface Props {
  current: DiscussionI
  prev?: DiscussionI | NewCommentBubble
  discussionNumber: number
  CRId: string
  commitSha: string
  total: number
  discussions: Array<DiscussionI | NewCommentBubble>
}

export const Discussion = ({ current, discussionNumber, prev, CRId, commitSha, total, discussions }: Props) => {
  const { isOpen: isBazzyOpen } = useBazzy()
  const [isResolved, setIsResolved] = useState(current.discussion_state === "resolved")
  const {
    setCurrentOpenedDiscussion,
    clearCurrentOpenedDiscussion,
    toggleManualRecalculationTrigger,
    discussionsExpanded,
    updateDiscussionWithTop,
    currentOpenedDiscussion
  } = useDiscussions()

  const { topPosition } = useSyncElementPosition({
    current,
    discussionNumber,
    discussions,
    total,
    prev
  })

  const isOpen = useMemo(
    () => current.id === currentOpenedDiscussion?.id && !isBazzyOpen,
    [current.id, currentOpenedDiscussion?.id, isBazzyOpen]
  )

  const handleClose = useCallback(() => {
    clearCurrentOpenedDiscussion()
  }, [clearCurrentOpenedDiscussion])

  const handleOpen = useCallback(() => {
    setCurrentOpenedDiscussion(current)
    const top = calculateRowTopPosition(current)
    scrollTo({ top: top - stickyScrollOffset, behavior: "smooth" })
  }, [setCurrentOpenedDiscussion, current])

  const handleResolveToggle = useCallback(
    (resolveState: boolean) => {
      setIsResolved(!resolveState)
      toggleManualRecalculationTrigger()
    },
    [toggleManualRecalculationTrigger]
  )

  useEffect(() => {
    if (!topPosition) return

    const timeoutId = setTimeout(() => {
      updateDiscussionWithTop(current.id, topPosition)
    }, 100)

    return () => clearTimeout(timeoutId)
  }, [topPosition, updateDiscussionWithTop, current.id])

  return (
    <>
      {discussionsExpanded ? (
        <ExpandedDiscussion
          current={current}
          discussionNumber={discussionNumber}
          handleOpen={handleOpen}
          isResolved={isResolved}
          handleResolveToggle={handleResolveToggle}
          topPosition={topPosition}
        />
      ) : (
        <DiscussionChip
          current={current}
          discussionNumber={discussionNumber}
          isResolved={isResolved}
          handleOpen={handleOpen}
          topPosition={topPosition}
        />
      )}

      <DiscussionDrawer
        open={isOpen && !isBazzyOpen}
        discussion={current}
        onClose={handleClose}
        CRId={CRId}
        commitSha={commitSha}
        handleResolveToggle={handleResolveToggle}
        isResolved={isResolved}
      />
    </>
  )
}
