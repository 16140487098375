import { CommentProps } from "@/components/designSystem/Discussions/components/Comment/index.tsx"
import { isWordTooLong } from "./utils"
import { parseAndSanitizeComment } from "@/lib/utils"
import { splitCommentAndSuggestion } from "@/components/designSystem/Discussions/utils"
import { mdTransformer } from "@/lib/mdTransformer"
import { useSettings } from "@/services/store/useSettings"

export const RenderComment = ({ comment, isFullComment, isClickable, onCommentClick }: CommentProps) => {
  const { comment: commentText } = splitCommentAndSuggestion(comment.comment_body)
  const { selectedTheme } = useSettings()

  if (!commentText) return null

  return (
    <div
      className={`mb-2 ${isClickable ? "cursor-pointer" : "cursor-default"} 
      ${isFullComment ? "" : "line-clamp-3"} text-ellipsis 
      ${isWordTooLong(commentText) ? "break-all" : "break-words"} text-xs`}
      tabIndex={0}
      role="button"
      aria-label="comment with rich editor"
      onClick={isClickable ? onCommentClick : undefined}
    >
      <div>
        {comment.body_content_type === "html" ? (
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: parseAndSanitizeComment(commentText) }}
          ></div>
        ) : (
          mdTransformer(commentText, selectedTheme)
        )}
      </div>
    </div>
  )
}
