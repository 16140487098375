import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { FileWithDiffs } from "@/models/FCC"
import { Commit } from "@/models/FC"
import { useId } from "react"
import { Change } from "@/models/Changes"

interface Args {
  repoId: string
  fromRef: string
  toRef: string
}

export interface NewDiffResponse {
  files: FileWithDiffs[]
  commits: Commit[]
  existing_open_change?: Change
}

export const useFetchNewCRDiff = ({ repoId, fromRef, toRef }: Args) => {
  const enabled = !!repoId && !!fromRef && !!toRef
  const id = useId()

  const { error, isLoading, data } = useQuery<NewDiffResponse, unknown>({
    queryKey: ["newCRDiff", repoId, fromRef, toRef, id],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.diff.get(repoId, fromRef, toRef), {
        params: {
          tool: "git"
        }
      })
      return response.data
    },
    enabled
  })

  return { error, isLoading, data }
}
