import { AxiosError } from "axios"
import { clientUrl } from "@/lib/urls.ts"

export const errorHandler = (err: unknown): string => {
  // Todo: add for future monitor
  console.error(err)

  if (err instanceof AxiosError) {
    const error = err || "Unexpected error"

    if (error.response?.data && typeof error.response?.data === "string") {
      return error.response.data
    }

    if ("message" in error) {
      return error.message
    }

    if (typeof error === "string") {
      return error
    }

    return JSON.stringify(error)
  } else if (err instanceof Error) {
    return err.message
  }

  return "Unexpected error"
}

export const ghWriteErrorHandler = (err: unknown): string => {
  if (err instanceof AxiosError) {
    const error = err || "Unexpected error"

    if (error.response?.status === 401) {
      // redirect to privilege flow, so the new token will be good
      window.open(clientUrl("/privilege"), "_blank")
    }
  }
  return errorHandler(err)
}

export const isDev = import.meta.env.MODE === "development"
