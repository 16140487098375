import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { Discussion } from "@/models/Discussions"

interface Args {
  changeId: string
}

export const useFetchDiscussions = ({ changeId }: Args) => {
  const { error, isLoading, data } = useQuery<Discussion[]>({
    queryKey: ["discussions", changeId],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.changes.discussions(changeId))
      return response?.data?.discussions || []
    },
    enabled: !!changeId
  })

  return { error, isLoading, data }
}
