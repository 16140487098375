import { useMutation } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient.ts"
import { apiRoutes } from "@/services/api/api-routes.ts"

export const useExportUsers = () => {
  return useMutation({
    mutationFn: async () => {
      const response = await axiosClient.post(
        apiRoutes.billing.exportUsers,
        {},
        {
          responseType: "blob",
          params: {
            format: "csv"
          }
        }
      )
      const blob = new Blob([response.data], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `users-${new Date().toISOString()}.csv`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)

      return response.data
    }
  })
}
