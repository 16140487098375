import { Discussion, NewCommentBubble } from "@/models/Discussions"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useEffect, useState } from "react"
import { calculateRowTopPosition, getDiscussionActualPosition, getDiscussionHeight } from "../utils"

interface Args {
  current: Discussion | NewCommentBubble
  prev?: Discussion | NewCommentBubble
  discussionNumber: number
  total: number
  discussions: Array<Discussion | NewCommentBubble>
}

export const marginInPixels = 8

export const useSyncElementPosition = ({ current, discussionNumber, discussions, total, prev }: Args) => {
  const [topPosition, setTopPosition] = useState<number | null>(null)
  const { manualRecalculationTrigger } = useDiscussions()

  // the first effect places the discussions naively to where they should be rendered
  useEffect(() => {
    const prevDiscussionTop = prev ? calculateRowTopPosition(prev) : null
    const prevDiscussionHeight = getDiscussionHeight(discussionNumber - 1)

    let prevBottom = 0
    if (prevDiscussionTop && prevDiscussionHeight) {
      prevBottom = prevDiscussionTop + prevDiscussionHeight
    }

    const currentDesiredPosition = calculateRowTopPosition(current)
    setTopPosition(currentDesiredPosition)

    if (currentDesiredPosition - marginInPixels <= prevBottom) {
      setTopPosition(prevBottom + marginInPixels)
    }
  }, [current, discussionNumber, setTopPosition, prev, manualRecalculationTrigger])

  // this effect is run after the the first one and corrects the discussion positions based on the real DOM discussion prev value
  useEffect(() => {
    for (let i = 0; i < total; i++) {
      setTimeout(() => {
        const prevDiscussionPosition = getDiscussionActualPosition(discussionNumber - 1)
        const currentDesiredPosition = calculateRowTopPosition(current)

        if (prevDiscussionPosition && currentDesiredPosition) {
          if (prevDiscussionPosition.bottom >= currentDesiredPosition) {
            setTopPosition(prevDiscussionPosition.bottom + marginInPixels)
          }
        }
      }, 200)
    }
  }, [discussionNumber, setTopPosition, total, discussions, current, manualRecalculationTrigger])

  return { topPosition }
}
