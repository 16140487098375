import { LocalStorageService } from "@/lib/localStorageService.ts"
import { backendUrl } from "@/lib/urls.ts"
import axios from "axios"
import { routes } from "@/lib/routes"

const baseURL = backendUrl("/")

export const axiosClient = axios.create({
  baseURL,
  withCredentials: true
})

axiosClient.interceptors.request.use(function (config) {
  const localStorageService = new LocalStorageService()
  const token = localStorageService.getItem("DS")
  config.headers.Authorization = token ? `Bearer ${token}` : ""
  return config
})

axiosClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.status === 401) {
      const localStorageService = new LocalStorageService()
      localStorageService.removeItem("DS")
      localStorageService.removeItem("DSR")

      window.location.reload()
    }
    if (error?.response?.status === 402) {
      if (window.location.pathname !== routes.disabled) {
        window.location.assign(routes.disabled)
      } else {
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)
