import { TableHead, TableHeader as BaseTableHeader, TableRow } from "@/components"
import { Table, flexRender } from "@tanstack/react-table"
import { FileCodeLine } from "../types"
import { calculateHeaderSize } from "../utils"

interface Props {
  table: Table<FileCodeLine>
}
const TableHeader = ({ table }: Props) => {
  return (
    <BaseTableHeader className="border-b-0">
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow className="border-0" key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const headerSize = header.getSize()
            const width: string = calculateHeaderSize(headerSize)

            return (
              <TableHead className="h-0" key={header.id} style={{ width }}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHead>
            )
          })}
        </TableRow>
      ))}
    </BaseTableHeader>
  )
}

export default TableHeader
